<div class="d-flex flex-column" style="height: 100%; margin: 0;">
    <div class="d-flex navbar-app">
        <app-navbar class="w-100"></app-navbar>
    </div>
    <div class="map-area-app">
        <app-maparea></app-maparea>
    </div>
    <div class="footbar-app align-items-center">
        <app-footbar></app-footbar>
    </div>
</div>
