import { Injectable, EventEmitter, Output } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { WebSocketManagerService } from './webSocketManager.service';
import { VehicleService } from './vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketDataService {

  @Output() callback: EventEmitter<any> = new EventEmitter();
  whiteList : string[] = [];
  constructor(
    private WebSocketManagerService: WebSocketManagerService,
    private vehicleService: VehicleService
  ) {
    this.WebSocketManagerService.connectToSockets();
    this.start();
    this.listen();
  }

  start(){
    if(!this.vehicleService.statusDataVehicle){
      this.vehicleService.dataCompleted.subscribe(vhs => {
        this.whiteList = vhs.map((vh:any) => vh.IMEI.toString());
        console.log('WebSocket Info WhiteList',this.whiteList);
      });
    }else{
      this.whiteList = this.vehicleService.vehicles.map((vh:any) => vh.IMEI.toString());
    }
  }

  listen = ()=>{
    console.log('WebSocket DATA LISTEN');
    this.WebSocketManagerService.listWebSocket.forEach(instance => {
      // console.log('WebSocket Listen Socket',instance);
      instance.socket.ioSocket.on('envio', (res:string, data: any)  => {

          if (this.whiteList.includes(data.IMEI.toString())) {
          // Process the received data
          // if(instance.url == 'https://escucha2.glmonitoreo.com/'){
          //   console.log('WebSocket ENVIO '+instance.url,data);
          //   console.log('WebSocket INFO ',data);
          //   console.log('WebSocket IMEI include is',this.whiteList.includes(data.IMEI.toString()));
          // }
          this.callback.emit(data);
        }
      });
    });
  }
}
