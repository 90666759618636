import { Component, OnInit } from '@angular/core';

import { RepProgramadosService } from '../../services/rep-programados.service';


@Component({
  selector: 'app-rp-main',
  templateUrl: './rp-main.component.html',
  styleUrls: ['./rp-main.component.scss']
})
export class RpMainComponent implements OnInit {

  constructor(
    public repProgramadosService: RepProgramadosService,
  ) { }

  ngOnInit(): void {
  }

}
