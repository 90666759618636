<div
        class="d-flex flex-column justify-content-center align-items-center"
        style="position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 999999;" *ngIf="isUnderConstruction">
        <img
            src="./assets/images/en_construccion.png"
            alt="under_construction"
            style="display: flex; max-width: 50%; max-height: 20rem; margin-bottom: 1rem;">
        <span style="color: #fff;">Panel en construcción</span>
    </div>

<div class="container-fluid dashboard-wrapper" style="position: relative;" *ngIf="!isUnderConstruction">

    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingDashboardSpinner" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "true"><p style="color: white" class="text-center"> Cargando Dashboard... </p></ngx-spinner>

    <div class="row header-padding"></div>
    <div class="row dashboard-content p-3 overflow-auto justify-content-center">

        <div class="row p-card p-2 mb-3">
            <div class="col-12">
                <h3>Dash Board</h3>
                <hr>
            </div>
        </div>

        <br>
        <div class="row p-card p-2 mb-3 rounded">
            <div class="col-12 mb-3">
                <h4>Estatus general de la Flota Vehicular</h4>
                <hr>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-12 d-flex justify-content-center align-items-center">
                        <div class="pie-chart-container">
                            <ngx-charts-pie-chart
                            [scheme]="colorScheme"
                            [results]="data"
                            [legend]="showLegend"
                            [legendPosition]="'left'"
                            [legendTitle]="''"
                            [animations]="false"
                            >
                            </ngx-charts-pie-chart>
                        </div>
                    </div>
                    <div class="col-lg col-12">
                        <div class="col-sm h-100 d-flex flex-column pb-3">
                            <div class="d-flex gap-2 mb-3">
                                <div class="flex-grow-1 general-status-header">Relación de Vehículos</div>
                                <!-- <button type="button" class="btn btn-success">cc</button> -->
                            </div>
                            <div class="flex-grow-1 pie-chart-table">
                                <table class="h-100">
                                    <thead class="visible-on-scroll">
                                        <tr>
                                            <th>En movimiento</th>
                                            <th>Detenidos encendidos</th>
                                            <th>Detenidos apagados</th>
                                            <th>Sin transmision</th>
                                            <th>Sin cobertura</th>
                                            <th>GPS Sin señal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="imeiGreen.length == 0">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr *ngFor="let imei of imeiGreen; let i = index;">
                                            <td>{{ imeiGreen[i] }}</td>
                                            <td>{{ imeiBlue[i] }}</td>
                                            <td>{{ imeiPurple[i] }}</td>
                                            <td>{{ imeiBlack[i] }}</td>
                                            <td>{{ imeiOrange[i] }}</td>
                                            <td>{{ imeiRed[i] }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="row p-card p-2 mb-3 rounded">
            <div class="col-12 mb-3">
                <h4>Unidades en servicio y sin programación</h4>
                <hr>
            </div>
            <div class="col-12 mb-3">
                <div class="row">
                    <div class="col d-flex justify-content-center align-items-center text-center">
                        <span>
                            En servicio
                            <br>{{in_service}}/{{in_service_percentage}}%
                        </span>
                    </div>
                    <div class="col-7" style="height: 6rem;">
                        <ngx-charts-bar-horizontal-stacked
                            *ngIf="'bar-horizontal-stacked' === 'bar-horizontal-stacked'"
                            class="chart-container"
                            [gradient]="true"
                            [scheme]="colorSchemeHorizontalChart"
                            [results]="horizontalChart"
                            [animations]="false"
                        ></ngx-charts-bar-horizontal-stacked>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center text-center">
                        <span>
                            Sin programación
                            <br>{{without_programming}}/{{without_programming_percentage}}%
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row flex-row-reverse p-0 gap-3 mb-3">
            <div class="col-md col-12">
                <div class="row p-card p-2 rounded h-100">
                    <div class="col-12 mb-3">
                        <h4>Lugar donde se ubican las unidades</h4>
                        <hr>
                    </div>
                    <div class="col-12">

                    </div>
                </div>
            </div>
            <div class="col-md col-12">
                <div class="row p-card p-2 rounded h-100 flex-column">

                    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingInfractionSpinner" size = "small" color = "#fff" type = "ball-pulse" ><p style="color: white" class="text-center"> Cargando Infracciones... </p></ngx-spinner>
                    <div class="col-12 mb-3">
                        <h4>Vehículos que cometieron infracción</h4>
                        <hr>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="d-flex gap-2 p-fluid p-calendar-row gl-input-container">
                            <p-calendar
                                [(ngModel)]="date_infraction"
                                [dateFormat] = "'dd/mm/yy'"
                                [readonlyInput]="true"
                                inputId="range"
                                selectionMode="range"
                                class="flex-grow-1"
                                [appendTo]="'body'"
                                [showIcon]="true"
                                icon="pi pi-calendar"
                            >
                            </p-calendar>
                            <button btn btn-secondary class="btn-dark-hover" (click)="getInfraction()">Ir</button>
                        </div>
                    </div>
                    <div class="col-12 mb-4">

                    </div>
                    <div class="col-12 mb-3 px-lg-5 flex-grow-1">
                        <div class="infraccion-card-table">
                            <table>
                                <thead class="visible-on-scroll">
                                    <tr>
                                        <th scope="col">Vehículo</th>
                                        <th scope="col">No de Infracciones</th>
                                        <th scope="col">Infracción mas Grave</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="infraction.length == 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let vehicle of infraction">
                                        <td>{{ vehicle.name }}</td>
                                        <td>{{ vehicle.amount }}</td>
                                        <td>{{ vehicle.higher_speed }}</td>
                                    </tr>
                                    <!-- <tr *ngFor="let vehicle of infraction">
                                        <td>{{vehicle.name}}</td>
                                        <td>{{vehicle.amount}}</td>
                                        <td>{{vehicle.higher_speed}}</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="row p-0 gap-3">
            <div class="col-md col-12">
                <div class="row p-card p-2 rounded h-100 flex-column">

                    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingGpsSpinner" size = "small" color = "#fff" type = "ball-pulse" ><p style="color: white" class="text-center"> Cargando Alertas Gps... </p></ngx-spinner>
                    <div class="col-12 mb-3">
                        <h4>Vehículos que generaron alertas GPS</h4>
                        <hr>
                    </div>

                    <div class="col-12 mb-4">
                        <div class="d-flex gap-2 p-fluid p-calendar-row gl-input-container">
                            <p-calendar
                            [(ngModel)]="date_gps"
                            [dateFormat] = "'dd/mm/yy'"
                            [readonlyInput]="true"
                            inputId="range"
                            selectionMode="range"
                            [appendTo]="'body'"
                            [showIcon]="true"
                            icon="pi pi-calendar"
                            class="flex-grow-1"
                            ></p-calendar>
                            <button btn btn-secondary class="btn-dark-hover" (click)="getEventGps()">Ir</button>
                        </div>
                    </div>

                    <div class="col-12 px-4 mb-4 d-flex flex-column gap-2">
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Batería desconectada
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countgpsEvents.bateria_desconectada"
                                    [max]="gpsEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countgpsEvents.bateria_desconectada}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Aceleración brusca
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countgpsEvents.aceleracion_brusca"
                                    [max]="gpsEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countgpsEvents.aceleracion_brusca}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Frenada Brusca
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countgpsEvents.frenada_brusca"
                                    [max]="gpsEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countgpsEvents.frenada_brusca}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                SOS
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countgpsEvents.sos"
                                    [max]="gpsEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countgpsEvents.sos}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Motor apagado
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countgpsEvents.motor_apagado"
                                    [max]="gpsEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countgpsEvents.motor_apagado}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Motor encendido
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countgpsEvents.motor_encendido"
                                    [max]="gpsEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countgpsEvents.motor_encendido}}
                            </div>
                        </div>

                    </div>

                    <div class="col-12 mb-3 px-lg-5 flex-grow-1">
                        <div class="gps-alert-card-table">
                            <table>
                                <thead class="visible-on-scroll">
                                    <tr>
                                        <th scope="col">Vehículo</th>
                                        <th scope="col">Alerta</th>
                                        <th scope="col">Incidencias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="gpsEvents.length == 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let event of gpsEvents">
                                        <td>{{event.vehicle}}</td>
                                        <td>{{event.event}}</td>
                                        <td>{{event.amount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md col-12">
                <div class="row p-card p-2 rounded h-100 flex-column">

                    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingSegSpinner" size = "small" color = "#fff" type = "ball-pulse" ><p style="color: white" class="text-center"> Cargando Alertas Seg. vehicular... </p></ngx-spinner>
                    <div class="col-12 mb-3">
                        <h4>Vehículos que generaron alertas Seg. vehicular</h4>
                        <hr>
                    </div>

                    <div class="col-12 mb-4">
                        <div class="d-flex gap-2 p-fluid p-calendar-row gl-input-container">
                            <p-calendar
                            [(ngModel)]="date_safety"
                            [dateFormat] = "'dd/mm/yy'"
                            [readonlyInput]="true"
                            inputId="range"
                            selectionMode="range"
                            [appendTo]="'body'"
                            [showIcon]="true"
                            icon="pi pi-calendar"
                            class="flex-grow-1"
                            ></p-calendar>
                            <button btn btn-secondary class="btn-dark-hover" (click)="getVehicleSafety()">Ir</button>
                        </div>
                    </div>

                    <div class="col-12 px-4 mb-4 d-flex flex-column gap-2">
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Ausencia de rostro
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.no_rostro"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.no_rostro}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Fatiga extrema
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.fatiga_extrema"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.fatiga_extrema}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Posible fatiga
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.posible_fatiga"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.posible_fatiga}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Distracción
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.distraccion"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.distraccion}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Anticolisión frontal
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.colision_delantera"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.colision_delantera}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Colisión con peatones
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.colision_con_peatones"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.colision_con_peatones}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Desvío de carril izquierda
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.desvio_de_carril_hacia_la_izquierda"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.desvio_de_carril_hacia_la_izquierda}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Desvío de carril derecha
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.desvio_de_carril_hacia_la_derecha"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.desvio_de_carril_hacia_la_derecha}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 text-end text-truncate">
                                Bloq. de visión de Mobileye
                            </div>
                            <div class="col-lg-5 col-6">
                                <ngb-progressbar
                                    type="primary"
                                    [value]="countVehicleSafetyEvents.bloqueo_de_vision_del_mobileye"
                                    [max]="safetyEventsTotal"
                                    [height]="'1.5rem'"
                                ></ngb-progressbar>
                            </div>
                            <div class="col-1 text-lg-start text-end">
                                {{countVehicleSafetyEvents.bloqueo_de_vision_del_mobileye}}
                            </div>
                        </div>

                    </div>

                    <div class="col-12 mb-3 px-lg-5 flex-grow-1">
                        <div class="seg-vehicular-card-table">
                            <table>
                                <thead class="visible-on-scroll">
                                    <tr>
                                        <th scope="col">Vehículo</th>
                                        <th scope="col">Alerta</th>
                                        <th scope="col">Incidencias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="vehicleSafetyEvents.length == 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let event of vehicleSafetyEvents">
                                        <td>{{event.vehicle}}</td>
                                        <td>{{event.event}}</td>
                                        <td>{{event.amount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>



                </div>
            </div>
        </div>
</div>
