
<p-dialog [(visible)]="display" (onHide)="onHide()" (onShow)="onShow()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" class="gl-p-dialog">
    <ng-template class="p-dialog-title" pTemplate="header">
      <div>
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        <strong>Detalle de la actividad</strong>
      </div>
    </ng-template>

    <div class="dialog-body-container">

            <div style="width: 100%; max-width: 100%; margin-bottom: 1.5rem;">

              <div class="row">
                <div *ngIf="old" [ngClass]="{'d-none': !display_old, 'd-block': display_old,'col-0': !old, 'col-5': old}">
                  <b>Antiguo</b>
                </div>
                <div *ngIf="old" [ngClass]="{'d-none': !display_old, 'd-flex': display_old, 'col-0': !old, 'col-2': old}">
                  
                </div>
                <div *ngIf="attributes" [ngClass]="{'d-none': !display_new, 'd-block': display_new, 'col-12': !old, 'col-5': old}">
                  <b>Nuevo</b>
                </div>
                <hr *ngIf="attributes || old" [ngClass]="{'d-none': !display_new, 'd-block': display_new}">
                <div *ngIf="old" [ngClass]="{'d-none': !display_old, 'd-block': display_old, 'col-0': !old, 'col-5': old}">
                  
                  <app-audit-info-activity [properties]="old" [subject_type]="subject_type" [color]="'red'"></app-audit-info-activity>
                  
                </div>
                <div *ngIf="old" [ngClass]="{'d-none': !display_old, 'd-flex align-items-center': display_old, 'col-0': !old, 'col-2': old}">
                  <i class="fas fa-arrow-right m-auto"></i>
                </div>
                <div [ngClass]="{'col-12': !old, 'col-5': old}">

                  <app-audit-info-activity [properties]="attributes" [subject_type]="subject_type" [color]="'green'"></app-audit-info-activity>
                
                </div>
              </div>
            </div>
          
    </div>

    
</p-dialog>
