import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WaitTime } from 'src/app/alerts/models/alert.interface';
import { CipiaMultimediaParam } from 'src/app/multiview/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class WaitTimeService {
  private times: WaitTime[] = [];
  private timesSubject: BehaviorSubject<WaitTime[]> = new BehaviorSubject<
    WaitTime[]
  >([]);
  constructor() {
    this.loadWaitTimesServiceFromLocalStorage();
  }
  get waitTimes$() {
    return this.timesSubject.asObservable();
  }
  startTime(time: WaitTime): void {
    if (this.existTimeById(time.id)) {
    } else {
      this.times.push(time);
      this.updateTimes();
    }
    console.log('time: startTime', this.times);
  }
  /**
   * Función que actualizará el end time el cual se le proporciona por entrada
   * @param time Dato de end time que se va a actualizar
   */
  stopTimes(time: WaitTime): void {
    if (this.existTime(time)) {
      const timeIndex = this.times.findIndex((t) => t.id === time.id);
      if (timeIndex !== -1) {
        this.times[timeIndex] = {
          ...this.times[timeIndex],
          itemsId: time.itemsId,
          end: time.end,
        };
        this.updateTimes();
      }
      this.updateTimes();
    }
  }
  getAllTimes(): WaitTime[] {
    console.log('waitTime: all times ', this.times);
    return this.times;
  }
  /**
   * Función que actualizará el end time dentro de la misma
   * @param id Identificador del evento
   * @param item Identificador del elemento
   */
  stopTimeOfCipiaByIds(id: string, item: CipiaMultimediaParam) {
    const timeIndex = this.times.findIndex((t) => t.id === id);
    const stop = Date.now();
    if (timeIndex !== -1) {
      this.times[timeIndex].itemsId.push(item.eventId as string);
      this.times[timeIndex].end.push(stop);
      this.times[timeIndex].type.push(item.type);
      this.times[timeIndex].duration.push(
        this.calcDuration(this.times[timeIndex].start, stop)
      );
    }
    this.updateTimes();
    console.log('SAVED IN INDEXEDDB: stopTimeByIds', this.times);
  }
  getDurationOfCipiaByIds(id: string, index: number): number {
    return this.times.find((t) => t.id == id)?.duration[index] ?? 0;
  }
  /**
   * Función que actualiza el end time dentro de las misma
   * @param id Identificador del evento
   * @param itemsId Arreglo de elementos
   */
  stopTimesByIds(id: string, itemsId: string[]) {
    const timeIndex = this.times.findIndex((t) => t.id === id);
    const timeStop = Date.now();
    const timesStop: number[] = Object.keys(itemsId).map(() => timeStop);
    if (timeIndex !== -1) {
      this.times[timeIndex] = {
        ...this.times[timeIndex],
        itemsId: itemsId,
        end: timesStop,
      };
    }
    this.updateTimes();
  }

  deleteTimeById(id: string) {
    this.times = this.times.filter((t) => t.id !== id);
    this.updateTimes();
  }
  /**
   * Busca y devuelve un WaitTime o null
   * @param id Identificador
   * @returns Retorna un WaitTime o null
   */
  getTime(id: string): WaitTime | null {
    return this.times.find((t) => t.id === id) || null;
  }
  /**
   * Función que devuelve inicio y fin de un elemento
   * @param id Identificador del evento
   * @param itemId Identificador del elemento
   * @returns Objeto de inicio y fin del elemento
   */
  getTimeByIds(id: string, itemId: string): { start: number; end: number } {
    const e = this.times.find((t) => t.id === id);
    return {
      start: e?.start ?? 0,
      end: e?.end[e.itemsId.findIndex((t) => t === itemId)] ?? 0,
    };
  }
  calcDuration(start: number, end: number): number {
    return end > start ? parseFloat(((end - start) / 1000).toFixed(1)) : 0;
  }
  getTimeDurationByIds(id: string, itemId: string): number {
    const e = this.times.find((t) => t.id === id);
    const start = e?.start ?? 0;
    const end = e?.end[e.itemsId.findIndex((t) => t === itemId)] ?? 0;

    return end > start ? (end - start) / 1000 : 0;
  }
  getTimeDurationOfCipiaByIds(id: string, index: number): number {
    const e = this.times.find((t) => t.id === id);
    const start = e?.start ?? 0;
    const end = e?.end[index] ?? 0;
    return this.calcDuration(start, end);
  }
  /**
   * Función que excluye lo ingresado
   * @param time Dato a eliminar
   */
  deleteTime(time: WaitTime) {
    this.times = this.times.filter((t) => t.id !== time.id);
    this.updateTimes();
  }
  existTime(time: WaitTime): boolean {
    return this.times.filter((t) => t.id === time.id).length > 0 ? true : false;
  }
  existTimeById(id: string): boolean {
    return this.times.filter((t) => t.id === id).length > 0 ? true : false;
  }
  private updateTimes() {
    this.timesSubject.next(this.times);
    localStorage.setItem('waitTimesService', JSON.stringify(this.times));
  }
  private loadWaitTimesServiceFromLocalStorage() {
    const storedTimes = localStorage.getItem('waitTimesService');
    if (storedTimes) {
      this.times = JSON.parse(storedTimes);
      this.timesSubject.next(this.times);
    }
  }
}
