<span class="btn-label">
  <!-- <object data="assets/images/filterimages/imei.webp" style="width: 30px;"></object> -->
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="793.000000pt"
    height="503.000000pt"
    viewBox="0 0 793.000000 503.000000"
    preserveAspectRatio="xMidYMid meet"
    style="width: auto;height: 20px;"
  >
    <g
      transform="translate(0.000000,503.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M775 4720 c-101 -14 -186 -39 -275 -83 -68 -33 -102 -59 -171 -127
-97 -96 -141 -171 -186 -315 l-28 -90 0 -1630 0 -1630 23 -85 c75 -281 244
-452 527 -532 l80 -23 3190 -3 c2388 -2 3212 0 3279 8 184 25 314 87 436 210
127 126 183 249 210 454 7 55 10 582 8 1646 l-4 1565 -22 84 c-83 312 -306
504 -636 550 -92 13 -6334 13 -6431 1z m6486 -326 c157 -49 233 -127 277 -287
16 -58 17 -175 17 -1642 0 -1717 3 -1629 -56 -1746 -55 -110 -149 -169 -313
-198 -41 -8 -1050 -11 -3203 -11 -3441 0 -3223 -4 -3350 56 -105 50 -179 162
-203 306 -13 84 -14 3099 0 3183 35 213 148 321 375 355 28 4 1477 7 3220 6
l3170 -1 66 -21z"
      />
      <path
        d="M1599 3476 c-71 -25 -142 -104 -154 -170 -3 -17 -5 -398 -3 -847 l3
-816 30 -48 c47 -76 101 -109 186 -113 92 -6 143 14 202 77 34 37 48 60 57
102 8 39 10 270 8 854 -3 798 -3 800 -25 840 -59 110 -190 162 -304 121z"
      />
      <path
        d="M2435 3477 c-110 -37 -205 -120 -252 -222 l-28 -60 -3 -765 c-2 -534
0 -776 8 -803 14 -48 62 -103 113 -129 32 -16 54 -19 107 -16 83 4 136 36 177
105 l28 48 5 635 c3 349 7 632 8 628 3 -8 144 -498 262 -911 97 -338 116 -381
198 -441 71 -51 137 -69 235 -64 58 3 94 11 125 27 57 29 118 85 147 135 13
23 78 230 145 461 211 730 231 800 236 804 2 2 4 -273 4 -613 0 -412 4 -632
11 -660 43 -171 277 -213 386 -69 22 30 33 58 38 95 3 29 5 384 3 788 l-3 735
-23 58 c-33 81 -116 168 -200 209 -62 31 -73 33 -172 33 -97 0 -110 -2 -170
-31 -78 -37 -149 -104 -183 -171 -14 -27 -69 -202 -122 -388 -53 -187 -128
-453 -168 -592 -39 -139 -75 -249 -79 -245 -3 4 -54 171 -111 372 -58 201
-126 437 -152 525 -26 88 -56 191 -66 229 -25 89 -57 142 -120 201 -73 68
-144 97 -254 101 -58 3 -104 -1 -130 -9z"
      />
      <path
        d="M6269 3462 c-68 -35 -114 -95 -129 -165 -7 -37 -10 -295 -8 -842 3
-878 -1 -828 71 -904 47 -49 100 -71 177 -71 80 0 133 23 179 75 64 73 61 25
61 930 0 819 0 826 -21 870 -57 121 -209 170 -330 107z"
      />
      <path
        d="M4791 3469 c-75 -22 -147 -86 -182 -164 -17 -38 -19 -87 -19 -830 l0
-790 24 -50 c29 -63 77 -111 141 -141 l50 -24 498 0 c541 0 524 -2 579 57 82
86 58 234 -47 292 -26 14 -81 17 -395 21 l-365 5 -3 297 -2 297 328 3 c326 3
329 3 363 27 45 31 66 60 80 113 22 80 -11 161 -81 202 -33 20 -55 21 -360 26
l-325 5 -3 139 c-2 105 1 141 10 148 7 4 162 8 343 8 323 0 331 1 369 23 68
38 106 132 86 208 -13 48 -64 107 -107 125 -26 11 -127 14 -490 13 -262 0
-472 -5 -492 -10z"
      />
    </g>
  </svg>
</span>
