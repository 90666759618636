

import { EventEmitter, Injectable, Output } from '@angular/core';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';

declare let L: any;
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.es';
import { MapService } from 'src/app/vehicles/services/map.service';

declare var $: any;
@Injectable({
  providedIn: 'root',
})
export class ToolbarMapService {
  map!: L.Map; //guardara el mapa
  toolbar: any;
  showDialog:boolean = false;

  showDistance:boolean = false;

  measureControl = new L.control.measure(
    { position: 'topright',
      primaryLengthUnit: 'kilometers',
      secondaryLengthUnit: 'feet',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'hectares',
      localization: 'es',
      activeColor: 'blue',
      completedColor: '#9900ff',
      captureZIndex: 10000
   });

   svgIcon = L.divIcon({
    html: this.geopointHTMLMarkerIcon('black'),
    className: '',
    iconSize: [24, 41.86],
    iconAnchor: [12, 41.86],
  });

  // btn4 = {
  //   marker : L.marker([0, 0],
  //     {
  //       icon: this.svgIcon ,
  //       // icon: L.icon({
  //       //   iconUrl: '/assets/images/realestate.png',
  //       //   iconAnchor: [16, 37],
  //       // }),
  //       draggable: true
  //     }),
  //   popup : L.popup( {offset: new L.Point(0, -25)}),
  //   visible : false, // visualizar el marcado al desaparece el popup
  //   cont : 1,
  // }

  btn5 = {
    marker : L.marker([0, 0],
      {
        icon: this.svgIcon ,
        // icon: L.icon({
        //   iconUrl: '/assets/images/realestate.png',
        //   iconAnchor: [16, 37],
        // }),
        draggable: true
      }),
    popup : L.popup( {offset: new L.Point(0, -25)}),
    tooltip:  L.tooltip({
            permanent: true,
            offset: [0, -75],
            direction: 'center',
            className: 'text',
          }),
    visible : false, 
  }

  container = L.DomUtil.create(
    'div',
    'leaflet-bar leaflet-control leaflet-control-custom'
  );

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() searchClick: EventEmitter<boolean> = new EventEmitter();

  constructor(private mapService: MapService) {}

  createToolbar(map: L.Map) {
    this.map = map;
    this.toolbar = {
      options: {
        position: 'topright',
      },

      onAdd: (map: any) => {
        this.createGroupAndUngroup();
        this.createLocation();
        this.createCoordinate();
        this.createDistance();
        this.createSearchItems();
        this.createCenterLatLng();
        return this.container;
      },
    };

    const toolbar = L.Control.extend(this.toolbar);
    return new toolbar()
  }

  createGroupAndUngroup() {
    var group = L.DomUtil.create('a', 'leaflet-link', this.container);

    group.title = "Agrupar / Desagrupar";

    var iGroup = L.DomUtil.create('i', 'fa fa-crosshairs', group);

    group.onclick = () => {

      if(this.mapService.getClustering){

        this.mapService.markerClusterGroup.disableClustering();
      }else{
        this.mapService.markerClusterGroup.enableClustering();
      }

      this.mapService.changeClusteringVisibility(!this.mapService.getClustering);


    };
  }

  createLocation() {
    var location = L.DomUtil.create('a', 'leaflet-link', this.container);

    location.title = "Mi ubicación";

    var iLocation = L.DomUtil.create('i', 'fa fa-map-marker btn1', location);

    location.onclick = () => {
      var marker: any, circle: any, lat, long, accuracy;

      if (!navigator.geolocation) {
        console.log("Your browser doesn't support geolocation feature!");
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          lat = position.coords.latitude;
          long = position.coords.longitude;
          accuracy = position.coords.accuracy;

          marker = L.marker([lat, long], {
            icon: L.icon({
              iconUrl: '/assets/images/realestate.png',
              iconAnchor: [16, 37],
            }),
          });

          let popup = L.popup({ offset: new L.Point(0, -20) })
            .setLatLng([lat, long])
            .setContent(
              '<span>Usted está a ' +
                position.coords.accuracy +
                ' metros ala <br>redonda aproximadamente.</span>'
            )
            .addTo(this.map);

          circle = L.circle([lat, long], accuracy, {
            radius: accuracy,
            color: 'green',
            fillColor: 'green',
            fillOpacity: 0.1,
            opacity: 0.3,
            weight: 1,
          });

          var featureGroup = L.featureGroup([marker, circle]).addTo(this.map);

          popup.on('remove', () => {
            this.map.removeLayer(featureGroup);
          });

          this.map.setZoom(15);
          this.map.panTo([lat, long]);
        });
      }
    };
  }

  createCoordinate(){
    var coordinate = L.DomUtil.create('a', 'leaflet-link', this.container);

    coordinate.title = "Coordenadas";

    var iCoordinate = L.DomUtil.create('i', 'fa fa-arrows', coordinate);

    coordinate.onclick = () => {
      //metodo
      this.change.emit(true);
    };
  }

  createSearchItems(){
    var coordinate = L.DomUtil.create('a', 'leaflet-link', this.container);

    coordinate.title = "Búsqueda";

    L.DomUtil.create('i', 'fa fa-search', coordinate);

    coordinate.onclick = () => {
      this.searchClick.emit(true);
    };
  }

  createDistance(){

    var distance = L.DomUtil.create('a', 'leaflet-link', this.container);

    distance.title = "Distancia";

    var iDistance = L.DomUtil.create('i', 'fa fa-road', distance);

    distance.onclick = () => {

       if(this.showDistance == false){
          this.measureControl.addTo(this.map);
          $('.leaflet-control-measure').css("max-width", "400px");
          this.showDistance = true;
       }else {
          this.map.removeControl(this.measureControl);
          this.showDistance = false;
       }
    };
  }


  createCenterLatLng(){

    var CenterLatLng = L.DomUtil.create('a', 'leaflet-link', this.container);

    CenterLatLng.title = "Latitud - Longitud";

    var iCenterLatLng = L.DomUtil.create('i', 'fa fa-podcast', CenterLatLng);

    CenterLatLng.onclick = () => {


        if (this.btn5.visible) {

          console.log("====== click borrar");
          this.btn5.visible = false;
          this.map.removeLayer(this.btn5.tooltip);
          this.map.removeLayer(this.btn5.marker);

        } else {

            var centro = this.map.getCenter();
            var a = centro.lat;
            var b = centro.lng;
            var tbl = "<table><tr><td>Latitud</td><td>:</td><td> "+centro.lat.toFixed(8)+"</td></tr><tr><td>Longitud</td><td>:</td><td> "+centro.lng.toFixed(8)+"</td></tr></table>";

            // // lat = position.coords.latitude;
            // // long = position.coords.longitude;
            // // this.map.getCenter();
            // this.map.removeLayer(this.btn4.marker);
            // this.map.removeLayer(this.btn4.popup);
            // // console.log("===========================");
            // // console.log(this.btn4);
            // // console.log(this.map.getCenter());
            // // console.log(centro.lat);
            // // console.log(centro.lng);
            // this.btn4.marker.setIcon(this.svgIcon);
            // this.btn4.marker.setLatLng(centro).addTo(this.map);

            // setTimeout(() => {
            //   this.btn4.popup.setLatLng([a, b]).setContent(tbl);//.openOn(this.map);
            //   this.map.addLayer(this.btn4.popup);
            //   // map.removeLayer(popup);
            //   this.btn4.visible = true;
            //   this.btn4.cont = 1;
            // }, 500);
        
            this.map.removeLayer(this.btn5.marker);
            this.map.removeLayer(this.btn5.popup);

            this.btn5.marker.setLatLng([a, b]);
            this.btn5.marker.addTo(this.map);
            this.btn5.visible = true;

            this.btn5.tooltip
                .setContent(tbl)
                .setLatLng([a, b])
                .addTo(this.map);

        }
    };


    // this.btn4.popup.on("remove", (e:any) => {
    //   // console.log("============== remove ============");
    //   // console.log(this.btn4.visible);
    //   if (this.btn4.visible) {
    //     this.map.removeLayer(this.btn4.marker);
    //     this.btn4.visible = false;
    //   }
    // });

    // this.btn4.popup.on("add", (e:any) => {
    //   console.log("============== add ============");
    //   console.log(this.btn4.visible);
    // });

    // this.btn4.marker.on("dragstart", () => {
    //       // console.log("====== dragstart");
    //       this.btn4.visible = false; // no ocultar el marcador
    //       this.map.removeLayer(this.btn4.popup);
    //   });

    // this.btn4.marker.on('dragend', (e:any) => {
    //       // console.log("====== dragend");
    //       // console.log(e);
          
    //       var a = e.target._latlng.lat;
    //       var b = e.target._latlng.lng;
    //       this.btn4.visible = true;

    //       var tbl = "<table><tr><td>Latitud</td><td>:</td><td> "+a.toFixed(8)+"</td></tr><tr><td>Longitud</td><td>:</td><td> "+b.toFixed(8)+"</td></tr></table>";
    //       this.btn4.popup.setLatLng([a, b]).setContent(tbl).openOn(this.map);
    //   });


      //==============================================
  
      this.btn5.marker.on("dragstart", () => {
          // console.log("====== dragstart");
          this.btn5.visible = false; // no ocultar el marcador
          this.map.removeLayer(this.btn5.tooltip);
        });

      this.btn5.marker.on('dragend', (e:any) => {
            // console.log("====== dragend");            
            var a = e.target._latlng.lat;
            var b = e.target._latlng.lng;
            this.btn5.visible = true;

            var tbl = "<table><tr><td>Latitud</td><td>:</td><td> "+a.toFixed(8)+"</td></tr><tr><td>Longitud</td><td>:</td><td> "+b.toFixed(8)+"</td></tr></table>";

            this.btn5.tooltip.setContent(tbl)
            this.btn5.tooltip.setLatLng([a, b])
            this.btn5.tooltip.addTo(this.map);
        });

  }

  public geopointHTMLMarkerIcon(color: string) {
    return (
      `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892"><g transform="matrix(1.18559 0 0 1.18559 -965.773 -331.784)"><path d="M817.112 282.971c-1.258 1.343-2.046 3.299-2.015 5.139.064 3.845 1.797 5.3 4.568 10.592.999 2.328 2.04 4.792 3.031 8.873.138.602.272 1.16.335 1.21.062.048.196-.513.334-1.115.99-4.081 2.033-6.543 3.031-8.871 2.771-5.292 4.504-6.748 4.568-10.592.031-1.84-.759-3.798-2.017-5.14-1.437-1.535-3.605-2.67-5.916-2.717-2.312-.048-4.481 1.087-5.919 2.621z" style="fill:` +
      color +
      `;"/><circle r="3.035" cy="288.253" cx="823.031" style="fill:#fff"/></g></svg>`
    );
  }

}
