<div id="panel_dashboard" class="modal-body p-fluid p-0 overflow-auto" style="position: relative; height: 100%;">
    <!-- <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 999999;"
        *ngIf="isUnderConstruction">
        <img
            src="./assets/images/en_construccion.png"
            alt="under_construction"
            style="display: flex; max-width: 50%; max-height: 20rem; margin-bottom: 1rem;">
        <span style="color: #fff;">Panel en construcción</span>
    </div> -->

    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingDashboard" size = "medium" color = "#fff" type = "ball-rotate" [fullScreen] = "false"><p style="color: white"> Cargando información... </p></ngx-spinner>

    <div class="panel-izq-container h-100" *ngIf="isUnderConstruction">
        <div class="panel-izq-form">
            <div class="gl-middleline gl-permanent-middleline gl-row-underline">
                <div class="row">
                    <div class="col p-field w-25">
                        <div class="row">
                            <div class="col-12">
                                <label for="">GRUPO</label>
                            </div>
                            <div class="col-12">
                                <p-dropdown
                                    [(ngModel)]="selectedGroup"
                                    (onChange)="changeGroup()"
                                    [filter]="true"
                                    filterBy="label"
                                    [options]="group"
                                    optionLabel="label"
                                    optionValue="value"
                                    [showClear]="true"
                                    [appendTo]="'body'"
                                    placeholder="Seleccione el grupo..."></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col p-field w-25">
                        <div class="row">
                            <div class="col-12">
                                <label for="">CONVOY</label>
                            </div>
                            <div class="col-12">
                                <p-dropdown
                                    [(ngModel)]="selectedConvoy"
                                    [options]="convoy"
                                    optionLabel="label"
                                    [filter]="true"
                                    filterBy="label"
                                    placeholder="Seleccione un convoy..."
                                    optionValue="value"
                                    [appendTo]="'body'"
                                    (onChange)="changeConvoy()"
                                    [disabled]="!isGroupSelected"
                                >
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mt-4 panel-izq-container-footer">
                <!-- <div class="col-6">
                  <button type="button" class="btn-gl-cancel btn-dark-hover w-100" >Ocultar</button>
                </div> -->
                <div class="col-6">
                    <button type="button" class="btn-gl-save btn-dark-hover w-100" id="btnMostrarHistorial" ui-sref="alertas-accesorios" (click)="send()" [disabled]="!isGroupSelected">Mostrar</button>
                </div>
              </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col">



        </div>
        <div class="col">
            <label for="">Convoy</label>
            <p-dropdown
                [options]="convoy"
                optionLabel="label"
                [filter]="true"
                filterBy="label"
                defaultLabel="Todas las unidades"
                placeholder="Todas las unidades"
                optionValue="value"
            >
            </p-dropdown>
        </div>

    </div> -->
</div>