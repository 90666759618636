<div class="d-flex justify-content-between w-100 h-100 gap-2 align-items-center px-4">
    <div class="d-flex justify-content-end  gap-2 align-items-center ps-2">
        <img [pTooltip]="showPanel?'Ocultar':'Mostrar'" [tooltipPosition]="'top'" appendTo="body" alt="logo" src="assets/images/on_off_workspace.png" class="footer-icon" (click)="hidePanel()" />
    </div>
    <app-screen-recorder [display]="showScreenRecorder" (onClose)="closeScreenRecorder();">
    </app-screen-recorder>
    <div class="d-flex justify-content-around gap-2 align-items-center">

        <img src="assets/images/separator.svg" alt="" class=" separator" style="width: 1rem; height:1.25rem">

        <i *ngIf="carousel.qtyItems > 0" [pTooltip]="showPopupDialog?'Ocultar Popups':'Mostrar Popups'" [class]="showPopupDialog?'pi pi-window-minimize footer-icon':'pi pi-window-maximize footer-icon'" pBadge severity="danger" badgeClass="p-badge-danger" (click)="showPopupDialog = !showPopupDialog" ></i>
        <i *ngIf="carousel.qtyItems == 0" pTooltip="No hay ventanas emergentes disponibles" class="pi pi-clone footer-icon" ></i>

        <img *ngIf="carousel.qtyItems > 0" [pTooltip]="'Limpiar Popups'" alt="logo" src="assets/images/delete_popups.png" class="footer-icon" (click)="clearPopups()" />
        <img *ngIf="carousel.qtyItems == 0" [pTooltip]="'No hay popups disponibles para limpiar'" alt="logo" src="assets/images/delete_popups.png" class="footer-icon disabled" />

        <button #btn type="button" pButton pRipple icon="pi pi-ellipsis-h" label="" class="p-button-text p-button-secondary p-0" (click)="menu.toggle($event)"></button>
        <p-slideMenu [appendTo]="'body'" #menu [model]="itemsMenu" [popup]="true" [viewportHeight]="250"></p-slideMenu>

        <div *ngIf="connectionLevel === 'good-connection'">
        <svg fill="green" height="20" width="20" version="1.1" id="good-connection" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
          viewBox="0 0 365.892 365.892" xml:space="preserve">
        <g>
        <circle cx="182.945" cy="286.681" r="41.494"/>
        <path d="M182.946,176.029c-35.658,0-69.337,17.345-90.09,46.398c-5.921,8.288-4.001,19.806,4.286,25.726
            c3.249,2.321,6.994,3.438,10.704,3.438c5.754,0,11.423-2.686,15.021-7.724c13.846-19.383,36.305-30.954,60.078-30.954
            c23.775,0,46.233,11.571,60.077,30.953c5.919,8.286,17.437,10.209,25.726,4.288c8.288-5.92,10.208-17.438,4.288-25.726
            C252.285,193.373,218.606,176.029,182.946,176.029z"/>
        <path d="M182.946,106.873c-50.938,0-99.694,21.749-133.77,59.67c-6.807,7.576-6.185,19.236,1.392,26.044
            c3.523,3.166,7.929,4.725,12.32,4.725c5.051-0.001,10.082-2.063,13.723-6.116c27.091-30.148,65.849-47.439,106.336-47.439
            s79.246,17.291,106.338,47.438c6.808,7.576,18.468,8.198,26.043,1.391c7.576-6.808,8.198-18.468,1.391-26.043
            C282.641,128.621,233.883,106.873,182.946,106.873z"/>
        <path d="M360.611,112.293c-47.209-48.092-110.305-74.577-177.665-74.577c-67.357,0-130.453,26.485-177.664,74.579
            c-7.135,7.269-7.027,18.944,0.241,26.079c3.59,3.524,8.255,5.282,12.918,5.281c4.776,0,9.551-1.845,13.161-5.522
            c40.22-40.971,93.968-63.534,151.344-63.534c57.379,0,111.127,22.563,151.343,63.532c7.136,7.269,18.812,7.376,26.08,0.242
            C367.637,131.238,367.745,119.562,360.611,112.293z"/>
        </g>
        </svg>
        </div>

        <div *ngIf="connectionLevel === 'unstable-connection'">
          <!-- <img src="assets/images/footbar/unstable-connection.png" alt="Unstable Connection" height="20" width="20"> -->
          <svg fill="orange" height="20" width="20" version="1.1" id="unstable-connection" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
          viewBox="0 0 365.892 365.892" xml:space="preserve">
          <g>
          <circle cx="182.945" cy="286.681" r="41.494"/>
          <path d="M182.946,176.029c-35.658,0-69.337,17.345-90.09,46.398c-5.921,8.288-4.001,19.806,4.286,25.726
            c3.249,2.321,6.994,3.438,10.704,3.438c5.754,0,11.423-2.686,15.021-7.724c13.846-19.383,36.305-30.954,60.078-30.954
            c23.775,0,46.233,11.571,60.077,30.953c5.919,8.286,17.437,10.209,25.726,4.288c8.288-5.92,10.208-17.438,4.288-25.726
            C252.285,193.373,218.606,176.029,182.946,176.029z"/>
          <path d="M182.946,106.873c-50.938,0-99.694,21.749-133.77,59.67c-6.807,7.576-6.185,19.236,1.392,26.044
            c3.523,3.166,7.929,4.725,12.32,4.725c5.051-0.001,10.082-2.063,13.723-6.116c27.091-30.148,65.849-47.439,106.336-47.439
            s79.246,17.291,106.338,47.438c6.808,7.576,18.468,8.198,26.043,1.391c7.576-6.808,8.198-18.468,1.391-26.043
            C282.641,128.621,233.883,106.873,182.946,106.873z"/>
            <path fill="lavender" d="M360.611,112.293c-47.209-48.092-110.305-74.577-177.665-74.577c-67.357,0-130.453,26.485-177.664,74.579
            c-7.135,7.269-7.027,18.944,0.241,26.079c3.59,3.524,8.255,5.282,12.918,5.281c4.776,0,9.551-1.845,13.161-5.522
            c40.22-40.971,93.968-63.534,151.344-63.534c57.379,0,111.127,22.563,151.343,63.532c7.136,7.269,18.812,7.376,26.08,0.242
            C367.637,131.238,367.745,119.562,360.611,112.293z"/>
          
          </g>
          </svg>
        </div>

        <div *ngIf="connectionLevel === 'no-connection'">
          <!-- <img src="assets/images/footbar/no-connection.png" alt="No Connection" height="20" width="20"> -->
      
          <svg fill="red" height="20" width="20" version="1.1" id="no-connection" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
          viewBox="0 0 365.892 365.892" xml:space="preserve">
          <g>
          <circle fill="red" cx="182.945" cy="286.681" r="41.494"/>
          <path fill="red" d="M182.946,176.029c-35.658,0-69.337,17.345-90.09,46.398c-5.921,8.288-4.001,19.806,4.286,25.726
            c3.249,2.321,6.994,3.438,10.704,3.438c5.754,0,11.423-2.686,15.021-7.724c13.846-19.383,36.305-30.954,60.078-30.954
            c23.775,0,46.233,11.571,60.077,30.953c5.919,8.286,17.437,10.209,25.726,4.288c8.288-5.92,10.208-17.438,4.288-25.726
            C252.285,193.373,218.606,176.029,182.946,176.029z"/>
          <path fill="lavender" d="M182.946,106.873c-50.938,0-99.694,21.749-133.77,59.67c-6.807,7.576-6.185,19.236,1.392,26.044
            c3.523,3.166,7.929,4.725,12.32,4.725c5.051-0.001,10.082-2.063,13.723-6.116c27.091-30.148,65.849-47.439,106.336-47.439
            s79.246,17.291,106.338,47.438c6.808,7.576,18.468,8.198,26.043,1.391c7.576-6.808,8.198-18.468,1.391-26.043
            C282.641,128.621,233.883,106.873,182.946,106.873z"/>
            <path fill="lavender" d="M360.611,112.293c-47.209-48.092-110.305-74.577-177.665-74.577c-67.357,0-130.453,26.485-177.664,74.579
            c-7.135,7.269-7.027,18.944,0.241,26.079c3.59,3.524,8.255,5.282,12.918,5.281c4.776,0,9.551-1.845,13.161-5.522
            c40.22-40.971,93.968-63.534,151.344-63.534c57.379,0,111.127,22.563,151.343,63.532c7.136,7.269,18.812,7.376,26.08,0.242
            C367.637,131.238,367.745,119.562,360.611,112.293z"/>


              <line x1="100" y1="200" x2="265.892" y2="365.892" stroke="red" stroke-width="50" stroke-linecap="round"/>
              <line x1="265.892" y1="200" x2="100" y2="365.892" stroke="red" stroke-width="50" stroke-linecap="round"/>
            </g>
          </svg>
        </div>
    </div>

</div>
<app-carousel [display]="showPopupDialog" #carousel></app-carousel>

<p-dialog header="Mostrar Punto" [(visible)]="showCipiaExample" (onHide)="showCipiaExample=false" [modal]="true" id="xavy">
    <app-cipia></app-cipia>
</p-dialog>
