<div class="view-container d-flex flex-column">
  <div class="grid-section" [id]="container" #MapView></div>
</div>

<p-dialog
  header="Mostrar Punto"
  [(visible)]="mapServicesService.display"
  id="grebo"
>
  <table cellspacing="0" border="0">
    <tbody>
      <tr>
        <td>Latitud:</td>
        <td align="right">
          <input id="dialog_show_point_lat" type="text" />
        </td>
      </tr>
      <tr>
        <td>Longitud:</td>
        <td align="right">
          <input id="dialog_show_point_lng" type="text" value="" />
        </td>
      </tr>
    </tbody>
  </table>
  <p-footer>
    <div class="row justify-content-center gap-3">
      <button
        class="btn-gl-save btn-dark-hover fw-bold col-5"
        (click)="showCoordinate()"
      >
        Mostrar
      </button>
      <button
        class="btn-gl-red btn-dark-hover fw-bold col-5"
        (click)="hideCoordinate()"
      >
        Cancelar
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  *ngIf="vehiclesIsLoaded"
  [(visible)]="mapServicesService.showSearch"
  id="searchItems"
  [showHeader]="false"
  [closable]="true"
  [position]="'top-right'"
  [contentStyle]="{ padding: '0', background: 'transparent' }"
  [style]="{
    'margin-right': '45px',
    'margin-top': 'calc(var(--navbar-height) + 270px)'
  }"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  [modal]="true"
  (onShow)="showSearchDropdown()"
>
  <ng-template let-vehicle pTemplate="content">
    <p-dropdown
      #_searchDropdown
      [options]="mapService.vehicleService.vehicles"
      (onChange)="searchItem($event); mapServicesService.showSearch = false"
      optionLabel="plate_number"
      [filter]="true"
      filterBy="plate_number,cod_interno,name,empresa,IMEI"
      [showClear]="true"
      placeholder="Búsqueda"
      appendTo="body"
      [style]="{ width: '14rem' }"
    >
      <ng-template let-vehicle pTemplate="item">
        <!--div>{{vehicle.name.toLowerCase() == vehicle.plate_number.toLowerCase() ? vehicle.name : vehicle.name + ' - ' +vehicle.plate_number}}</div-->
        <div>{{ vehicle.name }}</div>
      </ng-template>
    </p-dropdown>
  </ng-template>
</p-dialog>
<p-toast
  key="multimediaOnDemand"
  [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
>
  <ng-template let-message pTemplate="message">
    <div class="d-flex justify-content-start gap-2 align-content-center">
      <span class="pi pi-spin pi-spinner m-auto" style="font-size: 2rem">
      </span>
      <div class="d-flex" style="flex: 1">
        <div class="p-text-center">
          <h4>{{ message.summary }}</h4>
          <p>{{ message.detail }}</p>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast
  key="regular"
  [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
></p-toast>
<p-toast key="toast" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="d-flex align-items-start gap-2 w-100">
      <span
        *ngIf="message.severity == 'error'"
        class="pi pi-times-circle"
        style="font-size: 1.5rem"
      >
      </span>
      <span
        *ngIf="message.severity == 'success'"
        class="pi pi-check-circle"
        style="font-size: 1.5rem"
      >
      </span>
      <span
        *ngIf="message.severity == 'info'"
        class="pi pi-info"
        style="font-size: 1.5rem"
      ></span>
      <span
        *ngIf="message.severity == 'warn'"
        class="pi pi-info-circle"
        style="font-size: 1.5rem"
      >
      </span>
      <div class="d-flex" style="flex: 1">
        <div class="p-text-center">
          <h6 style="font-size: 1.3rem">{{ message.summary }}</h6>
          <span style="font-size: 0.75rem">{{ message.data.unidad }}</span>
          <span style="font-size: 0.75rem"><br />{{ message.data.fecha }}</span>
          <span style="font-size: 0.75rem"><br />{{ message.data.fecha }}</span>
          <span
            style="font-size: 0.75rem"
            *ngIf="message.data.type == 'duplicate'"
          >
            <br />{{ message.detail }} :
            <span
              [style.color]="'blue'"
              [style.cursor]="'pointer'"
              (click)="
                comunicationService.eventData(
                  message.data.dataEvent,
                  message.data.dataHTML
                )
              "
              >Evento</span
            >
          </span>
          <span style="font-size: 0.75rem" *ngIf="message.data.type == 'time'">
            <br />{{ message.detail }} :
            <span
              [style.color]="'blue'"
              [style.cursor]="'pointer'"
              (click)="
                comunicationService.eventData(
                  message.data.dataEvent,
                  message.data.dataHTML
                )
              "
              >Evento</span
            >
          </span>
          <span
            style="font-size: 0.75rem"
            *ngIf="message.data.type == 'unknown'"
            ><span
              [style.color]="'blue'"
              [style.cursor]="'pointer'"
              (click)="
                comunicationService.eventData(
                  message.data.dataEvent,
                  message.data.dataHTML
                )
              "
              ><br />Evento</span
            >: {{ message.detail }}
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<div
  id="custom-toast-container"
  class="d-flex flex-column position-fixed top-0 end-0 me-4 gap-1"
  [style]="{
    'z-index': '1000',
    'margin-top': '4rem',
    'max-width': '360px',
    width: '360px'
  }"
>
  <div
    *ngFor="let toast of customToast"
    class="p-2 d-flex flex-column"
    [ngClass]="{
      'custom-toast-success': toast.severity == 'success',
      'custom-toast-info': toast.severity == 'info',
      'custom-toast-init': toast.severity == 'init',
      'custom-toast-warn': toast.severity == 'warn',
      'custom-toast-error': toast.severity == 'error',
      'custom-toast-none': !toast.severity
    }"
  >
    <div class="d-flex align-items-start gap-2 w-100">
      <span
        *ngIf="toast.severity == 'success'"
        class="pi pi-check-circle mt-1"
        style="font-size: 1.5rem"
      >
      </span>
      <span
        *ngIf="toast.severity == 'init'"
        class="pi pi-clock mt-1"
        style="font-size: 1.5rem"
      >
      </span>
      <span
        *ngIf="toast.severity == 'info'"
        class="pi pi-info-circle mt-1"
        style="font-size: 1.5rem"
      >
      </span>
      <span
        *ngIf="toast.severity == 'error'"
        class="pi pi-times-circle mt-1"
        style="font-size: 1.5rem"
      ></span>
      <span
        *ngIf="toast.severity == 'warn'"
        class="pi pi-info-circle mt-1"
        style="font-size: 1.5rem"
      >
      </span>
      <div class="d-flex flex-column w-100">
        <div class="p-text-center">
          <span
            *ngIf="toast.data.dataEvent"
            [style]="{ 'font-size': '.9rem', 'font-weight': 'bold' }"
            [style.cursor]="'pointer'"
            (click)="
              comunicationService.eventData(
                toast.data.dataEvent,
                toast.data.dataHTML
              )
            "
            >{{ toast.summary }}
          </span>
          <span
            *ngIf="!toast.data.dataEvent"
            [style]="{ 'font-size': '.9rem', 'font-weight': 'bold' }"
            >{{ toast.summary }}
          </span>
          <div [style]="{ 'font-size': '0.7rem' }">
            <span
              *ngIf="toast.severity == 'warn'"
              style="color: #a52a2a; font-weight: bold"
              >{{ toast.detail }}</span
            >
            <span *ngIf="toast.severity != 'warn'">{{ toast.data.unit }}</span>
            <span *ngIf="!toast.data.moment && toast.severity != 'warn'"
              ><br />{{ toast.data.datetime }}</span
            >
            <span *ngIf="toast.data.type == 'duplicate' && toast.severity == 'error'">
              <br />{{toast.data.error}}
            </span>
            <ng-container *ngIf="toast.data.moment">
              <span *ngIf="toast.data.moment.periodo"
                ><br />{{ toast.data.moment.periodo }}</span
              >
              <span *ngIf="toast.severity == 'error'"
                ><br />{{ toast.data.moment.error }}</span
              >
            </ng-container>
            <ng-container *ngIf="toast.data.dataEvent && false">
              <span *ngIf="toast.severity == 'warn'">
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
              <span *ngIf="toast.severity == 'success'">
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
              <span *ngIf="toast.severity == 'info'">
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
              <span *ngIf="toast.data.moment && toast.severity == 'error'">
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
              <span *ngIf="toast.data.type == 'duplicate'"
                ><br />{{ toast.detail }} :
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
              <span *ngIf="toast.data.type == 'time'"
                ><br />{{ toast.detail }} :
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
              <span *ngIf="toast.data.type == 'unknown'"
                ><br /><i
                  style="font-size: 0.6rem"
                  class="pi pi-info-circle"
                  [pTooltip]="toast.data.rawError"
                  tooltipPosition="bottom"
                ></i>
                {{ toast.detail }} : {{ toast.data.nowTime }}
                <span
                  [style.color]="'blue'"
                  [style.cursor]="'pointer'"
                  (click)="
                    comunicationService.eventData(
                      toast.data.dataEvent,
                      toast.data.dataHTML
                    )
                  "
                  ><br />Evento</span
                >
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <button
        *ngIf="!toast.closable"
        (click)="deleteCustomToastById(toast)"
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-sm p-button-rounded p-button-secondary p-button-text"
      ></button>
    </div>
    <p-progressBar
      *ngIf="toast.severity == 'info' || toast.severity == 'init'"
      mode="indeterminate"
      [style]="{ height: '3px' }"
    ></p-progressBar>
  </div>
</div>
