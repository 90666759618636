<div cdkDropListGroup class="grid-container">
  <ng-container *ngIf="items && items.length > 0">
    <ng-container *ngFor="let item of items">
      <div
        class="grid-item"
        [style.gridColumnStart]="item.structure.col"
        [style.gridRowStart]="item.structure.row"
        [style.gridColumnEnd]="item.structure.col + item.structure.span"
        [id]="item.label"
      >
        <ng-container *ngIf="item.show_only_label">
          <app-drag-item [items]="[item.structure]" [showHandler]="false" (itemsChange)="onChangeDrops($event)">
            <div>
              {{item.label}}
            </div>
          </app-drag-item>
        </ng-container>
        <ng-container *ngIf="!item.show_only_label">
          <app-drag-item [items]="[item.structure]" (itemsChange)="onChangeDrops($event)">
            <div class="w-100 h-100">
              <ng-container *ngIf="item.content_type == 'minimap'">
                <div class="h-100 w-100">
                    <app-minimap (onDelete)="deleteGrid($event)" [title]="item.title" [configuration]="item" [idContainer]="item.label"></app-minimap>
                </div>
            </ng-container>
            </div> 
          </app-drag-item>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>