<!-- <div class="ag-theme-alpine" >
  <ag-grid-angular
  #agGrid
  style="width: 100%; height: 450px; font-size: 12px;"
  class="ag-theme-alpine"
  [rowData]="alerts"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [rowHeight]="rowHeight"

  (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div> -->
<!-- <div style="height: calc(100vh - 150px);"> -->
<div class="table-content-component-panel-monitoreo" style="position: relative;">
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingAlertsSpinner" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" > Cargando Alertas... </p></ngx-spinner>
  <div class="card-body p-fluid" id="rowBusqueda">
    <div class="d-flex">
      <div class="d-flex flex-grow-1">
        <span class="p-input-icon-right flex-grow-1 me-2">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input type="text" class="form-control" placeholder="Alertas" [(ngModel)]="strSearched" (keyup)="onSearch()"/>
        </span>
      </div>

      <!-- <button type="button" class="btn-gl-blue btn-dark-hover btn-circle flex-none me-2">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button> -->

      <div ngbDropdown container="body" placement="right-top left-top" class="flex-none">
        <button class="btn-gl-gray-blue btn-dark-hover btn-circle" ngbDropdownToggle>
          <i class="fa fa-fw fa-bars fa-1" aria-hidden="true"></i>
        </button>
        <div ngbDropdownMenu class="gl-custom-dropdown dropdown-alert-panel">
          <div ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS')">
            <span>Todas las Alertas</span>
          </div>

          <div *ngIf="checkAlertCategory('gps')" ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-GPS')">
            <span>Alertas GPS Tracker</span>
          </div>
          <div *ngIf="checkAlertCategory('platform')" ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-PLATFORMS')">
            <span>Alertas Plataforma</span>
          </div>
          <div *ngIf="checkAlertCategory('security')" ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-SECURITY')">
            <span>Alertas Seguridad Vehicular</span>
          </div>
          <div *ngIf="checkAlertCategory('mobile')" ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-MOBILE')">
            <span>Alertas Soluciones Móviles</span>
          </div>
          <div *ngIf="checkAlertCategory('360')" ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-360')">
            <span>Alertas Fatiga 360</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-6">
        <p-dropdown [options]="tipoEvento" [(ngModel)]="selectedEvent" optionLabel="option" optionValue="tipo" [appendTo]="'body'" [showClear]="false" placeholder="Tipo de Evento"></p-dropdown>
      </div>
    </div> -->
  </div>

  <div class="panel-izq-table-container">
    <!-- <div class="table-responsive" style="width: 100%; height: 450px; font-size: 12px;"> -->
    <table>
      <thead class="visible-on-scroll">
        <tr>
          <th>Nº</th>
          <th class="text-start">Nombre</th>
          <th class="text-start">Tipo</th>
          <th>Activos</th>
          <th>Sistema</th>
          <th>@</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let alert of alerts; let i = index ">
          <td>{{ i + 1 }}</td>
          <td>{{ alert?.nombre }}</td>
          <td>{{ alert?.tipo }}</td>
          <td class="text-center inline-png-checkbox">
            <!-- <input type="checkbox" [(ngModel)]="alert.activo_bol" class="ng-pristine ng-untouched ng-valid ng-not-empty" disabled /> -->
            <p-checkbox [(ngModel)]="alert.activo_bol" [binary]="true" [disabled]="true"></p-checkbox>
          </td>
          <td class="text-center inline-png-checkbox">
            <!-- <input type="checkbox" [(ngModel)]="alert.sonido_sistema_bol" class="ng-pristine ng-untouched ng-valid ng-not-empty" disabled /> -->
            <p-checkbox [(ngModel)]="alert.sonido_sistema_bol" [binary]="true" [disabled]="true"></p-checkbox>
          </td>
          <td class="text-center inline-png-checkbox">
            <!-- <input type="checkbox" [(ngModel)]="alert.notificacion_email_bol" class="ng-pristine ng-untouched ng-valid ng-not-empty" disabled /> -->
            <p-checkbox [(ngModel)]="alert.notificacion_email_bol" [binary]="true" [disabled]="true"></p-checkbox>
          </td>
        </tr>
        <tr *ngIf="noResults">
          <td colspan="6" class="text-center">Sin coinicidencias</td>
        </tr>
        <tr *ngIf="isRowDataEmpty">
          <td colspan="6" class="text-center">No hay alertas configuradas</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
