<p-dialog [(visible)]="driversService.modalProgramacionActive" [modal]="true" [resizable]="false" [style]="{'width':'70%'}" >

  <ng-template class="p-dialog-title" pTemplate="header">
    <!-- <div>
      <i class="fa fa-user me-2" aria-hidden="true"></i>
      <strong [innerHTML]="Agregar nuevo Conductor" ></strong>
    </div> -->
    <div *ngIf="(driversService.modalProgramacionComponent == 'IMPORTAR')">
      Importar Programación
    </div>

    <div *ngIf="(driversService.modalProgramacionComponent == 'AGREGAR')">
      Agregar Programación
    </div>

    <div *ngIf="(driversService.modalProgramacionComponent == 'LISTAR')">
      Programaciones Registradas
    </div>




    
  </ng-template>


    <ng-container *ngIf="(driversService.modalProgramacionComponent == 'IMPORTAR')">
      <app-drivers-programacion-importar></app-drivers-programacion-importar>
    </ng-container>

    <ng-container *ngIf="(driversService.modalProgramacionComponent == 'AGREGAR')">
      <app-drivers-programacion-agregar ></app-drivers-programacion-agregar>
    </ng-container>

    <ng-container *ngIf="(driversService.modalProgramacionComponent == 'LISTAR')">
      <app-drivers-programacion-listar ></app-drivers-programacion-listar>
    </ng-container>


    <ng-template pTemplate="footer" style="width: 60vw;">
    </ng-template>
  
</p-dialog>