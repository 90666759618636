import { Component, OnInit } from '@angular/core';

import { DriversService } from '../../services/drivers.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-drivers-programacion-importar',
  templateUrl: './drivers-programacion-importar.component.html',
  styleUrls: ['./drivers-programacion-importar.component.scss']
})
export class DriversProgramacionImportarComponent implements OnInit {



  name = 'This is XLSX TO JSON CONVERTER';
  // willDownload = false;

  myFiles: [] = [];

  nameFile:string = "-"

  programaciones:any= [];


  constructor(
    public driversService: DriversService
  ) { }

  ngOnInit(): void {
    this.nameFile = "Subir Archivo"
    this.programaciones = [];
        //   { 
        //     code:'2',
        //     name:'3',
        //     category:'4',
        //     Quantity: '5'
        //   }
        // ];
  }




  upload_document($event:any){

    console.log("================  upload_document ========");

     console.log("Upload",$event);
     var ev = $event;
     // UPLOAD LOGIC
     this.myFiles = [];

     console.log(ev.files[0].name);
     this.nameFile = ev.files[0].name;
    
    
     


     console.log(this.myFiles);



     let workBook:any = null;
     let jsonData = null;
     const reader = new FileReader();
     const file = ev.files[0]; //ev.target.files[0];
     reader.onload = (event) => {
       const data = reader.result;
       workBook = XLSX.read(data, { type: 'binary' });
       jsonData = workBook.SheetNames.reduce((initial:any, name:any) => {
         const sheet = workBook.Sheets[name];
         initial[name] = XLSX.utils.sheet_to_json(sheet);
         return initial;
       }, {});
       const dataString = JSON.stringify(jsonData);
       console.log("=======  JSON =========");

       console.log(dataString);
       console.log(jsonData.Hoja1);
       console.log(jsonData.Hoja1[0]);
       console.log(jsonData.Hoja1[0].nombre);
       console.log(jsonData.Hoja1[0].placa);

       this.programaciones = jsonData.Hoja1;
  
    

    


      //  document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      //  $("#output").html(dataString.slice(0, 300).concat("..."));
      //  this.setDownload(dataString);
     }
     reader.readAsBinaryString(file);

  }

  // setDownload(data:any) {
  //   this.willDownload = true;
  //   setTimeout(() => {
  //     // const el:any = document.querySelector("#download");
  //     // el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
  //     // el.setAttribute("download", 'xlsxtojson.json');


  //     $("#download").attr("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
  //     $("#download").attr("download", 'xlsxtojson.json');



  //   }, 1000)
  // }


}
