<div class="d-flex" style="height: 100%; overflow:auto;">

    <div class="modal-body p-fluid flex-grow-1 p-0 overflow-auto" style="position: relative;">
        <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 999999;"
            *ngIf="isUnderConstruction">
            <img
                src="./assets/images/en_construccion.png"
                alt="under_construction"
                style="display: flex; max-width: 50%; max-height: 20rem; margin-bottom: 1rem;">
            <span style="color: #fff;">Panel en construcción</span>
        </div>

        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingSubcuentas" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" class="text-center"> Cargando información... </p></ngx-spinner>

        <div class="panel-izq-container h-100" *ngIf="!isUnderConstruction">
            <div class="panel-izq-form">

              Hola Mundo
            </div>
        </div>
    </div>

</div>
