<h1 style="text-align:center">
  Login Example
</h1>
<div class="login">
  <h2 class="login-header">Log in</h2>
  <form [formGroup]="loginForm" class="login-container" (ngSubmit)="onSubmit()">

    <p [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
      <input type="text" placeholder="Email" formControlName="username">
    </p>

    <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
      <div *ngIf="formControls.email.errors.required">Email is required</div>
    </div>

    <p [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
      <input type="password" placeholder="Password" formControlName="password">
    </p>

    <div *ngIf="isSubmitted && formControls.password.errors" class="help-block">
      <div *ngIf="formControls.password.errors.required">Password is required</div>
    </div>

    <p>
      <input type="submit" value="Log in">
    </p>
  </form>
</div>
