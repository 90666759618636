import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionExpiredSubject = new Subject<void>();
  private sessionRestoredSubject = new Subject<void>();


  constructor() {
     window.addEventListener('storage', this.onStorageChange.bind(this));
  }

  onStorageChange(event: StorageEvent) {
    if (event.key === 'sessionExpired' && event.newValue === 'true') {
      this.sessionExpiredSubject.next();
    }

    if (event.key === 'sessionRestored' && event.newValue === 'true') {
      this.sessionRestoredSubject.next();
    }
  }

  sessionExpired(): Observable<void> {
    return this.sessionExpiredSubject.asObservable();
  }
  sessionRestored(): Observable<void> {
    return this.sessionRestoredSubject.asObservable();
  }

  triggerSessionExpired(isManualLogout: boolean = false) {
    /*localStorage.setItem('sessionExpired', 'true');
    this.sessionExpiredSubject.next();
    localStorage.clear();
 
    // Recarga la página para asegurar que todos los cambios se apliquen
    window.location.reload();*/
    localStorage.setItem('sessionExpired', 'true');
    localStorage.setItem('manualLogout', isManualLogout ? 'true' : 'false');
    this.sessionExpiredSubject.next();
    if (isManualLogout) {
      localStorage.clear();
      window.location.reload(); // Redirigir a la página de inicio de sesión de la aplicación Angular
    }

  }
  triggerSessionRestored() {
    localStorage.setItem('sessionRestored', 'true');
    this.sessionRestoredSubject.next();
    localStorage.removeItem('sessionRestored');
  }

  clearSessionExpired() {
    localStorage.removeItem('sessionExpired');
    localStorage.removeItem('manualLogout');
  }

}
