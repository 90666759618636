import { Injectable} from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

// Interfaz para representar el par de socket y URL
interface SocketInfo {
  socket: Socket;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketManagerService {

  public listWebSocket: SocketInfo[] = [];

  constructor() { }
  connectToSockets() {
    console.log('WebSocket Manager CONNECTING');
    const socketUrls = environment.socketUrls;
    socketUrls.forEach(url => {
      const socket = new Socket({
        url: url,
        options: {
          //@ts-ignore
          withCredentials: false,
          cors: {
            origin: '*',
          },
        }
      });
      socket.connect();
      console.log('WebSocket Manager CONNECT URL ',url);
      this.listWebSocket.push({ socket: socket, url: url });
    });
  }
}
