import {
  Injectable,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as L from 'leaflet';
import * as moment from 'moment';
import 'leaflet.markercluster';
import 'leaflet.markercluster.freezable';

import { WebSocketDataService } from 'src/app/vehicles/services/webSocketData.service';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';
import { FollowService } from 'src/app/vehicles/services/follow.service';
import { IMapMinimap, UserTracker } from '../models/interfaces';
import { EventService } from 'src/app/events/services/event.service';
import { UserDataService } from 'src/app/profile-config/services/user-data.service';
import { DriversService } from 'src/app/drivers/services/drivers.service';
import { IfStmt } from '@angular/compiler';
import { MomentService } from 'src/app/vehicles/services/moment.service';
@Injectable({
  providedIn: 'root',
})
export class MinimapService {
  @ViewChild('popupText') popupText!: ElementRef;

  public maps: IMapMinimap[] = [];
  public respuesta: any = [];
  public markerClusterGroupOnda!: any;

  public markerClusterData!: any;

  public leafletEvent!: L.LeafletEvent;

  private marker: any = [];

  private clustering = true;

  dif_mayor = 0.0;
  dif_divide = 0.0;
  dif_X = 0.0;
  dif_Y = 0.0;
  direction = '';
  final_direction = '';
  direction_X = '';
  direction_Y = '';
  vehicleServiceIsReady = false;

  timeNow: any = []; // Array para almacenar los tiempos
  timeWait: number = 7200000; // 7200000 milisegundos en milisegundos

  @Output() sendData = new EventEmitter<any>();
  @Output() vehicleServiceReady = new EventEmitter<any>();
  @Output() changeEye = new EventEmitter<any>();

  constructor(
    private vehicleService: VehicleService,
    private webSocketDataService: WebSocketDataService,
    private userDataService: UserDataService,
    public eventService: EventService,
    public driversService: DriversService,
    private momentService: MomentService,
  ) {
    // this.driversService.initialize();
    this.userDataService.getUserData();
    this.userDataService.userDataCompleted.subscribe((res) => {
      this.driversService.initialize(); //NECESITA INFO DE USER DATA
      this.vehicleService.setDefaultStatusDataVehicle();
      this.vehicleService.initialize();
    });

    this.vehicleService.dataCompleted.subscribe((vehicles) => {
      console.log('user data completadoooo', vehicles);
      this.vehicleServiceIsReady = true;
      this.vehicleServiceReady.emit(vehicles);
    });
    this.webSocketDataService.callback.subscribe((res) => {
      this.monitor(res);
    });

    this.vehicleService.calcTimeStop.subscribe((data) => {
      // console.log("event time stop res = ",data);

      let tiempoParada = '';
      //
      let aux_vehicle = this.vehicleService.getVehicle(data.imei);
      //
      let a = moment(new Date(data.dt_tracker));
      let b = moment(new Date());
      let d = moment(new Date(aux_vehicle.dt_tracker));

      /*
      c es el tiempo que demora en retornar la info desde backend (latencia)
      e corresponde a la ultima transmision???
      */
      let c = b.diff(a, 'seconds'); //retorno
      let e = d.diff(a, 'seconds'); //diff fecha
      // let c = 100;
      //dt_tracker es fecha tracker
      // console.log("speed  = ",data.speed);
      // console.log("calc c = ",c);
      // console.log("calc e = ",e);
      if (e == 0) {
        if (data.speed > 3 && c > 60) {
          // console.log("se cumplio condicion : item.speed > 3 km/h && c > 60 seg ");
          tiempoParada = '0 s';
        } else if (parseInt(data.res) == -1) {
          tiempoParada = 'Apagado mas de un día'; //"No se encontro Datos";
        } else if (parseInt(data.res) == -2) {
          tiempoParada = 'Duración mayor a 1 Dia';
        } else if (parseInt(data.res) == -3) {
          tiempoParada = 'En movimiento...';
        } else if (parseInt(data.res) == -5) {
          //inicio de parada
          tiempoParada = '0 s';
        } else if (parseInt(data.res) == -6) {
          //inicio de parada
          tiempoParada = '...Avise a soporte';
        } else if (parseInt(data.res) == -4) {
          // var tiempoParada = "En movimiento...";
          // item.paradaDesde  = item.paradaDesde;

          //var f_now = new Date( item.dt_tracker.replace(/-/g, "/") );
          let f_now = new Date();
          let f_past = new Date(data.paradaDesde);
          // var anios=f_now.diff(f_past,"seconds");
          let duracion = this.string_diffechas(f_past, f_now);
          //var tiempoParada = item.paradaDesde  + " - " +item.dt_tracker+ " - " +duracion;
          tiempoParada = duracion;
        } else {
          data.paradaDesde = data.res;

          //var f_now = new Date( item.dt_tracker.replace(/-/g, "/") );
          // let aux = moment(data.paradaDesde).subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss');
          let f_now = new Date();
          // let f_past = new Date( aux );
          let f_past = new Date(data.paradaDesde);
          // let anios=f_now.diff(f_past,"seconds");
          let duracion = this.string_diffechas(f_past, f_now);
          // let tiempoParada = responseP[0] + " - " +item.dt_tracker+ " - " +duracion;
          tiempoParada = duracion;
        }
        // console.log("tiempoParada = ",tiempoParada);
        let aux = {
          imei: data.imei,
          name: data.name,
          dt_tracker: data.dt_tracker,
          nameconvoy: data.nameconvoy,
          namegrupo: data.namegrupo,
          nameoperation: data.nameoperation,
          namedriver: data.namedriver,
          longitud: data.longitud,
          latitud: data.latitud,
          speed: data.speed,
          ref: data.direction,
          tiempoParada: tiempoParada,
        };
        // console.log("auxxxx",aux);

        //Una vez llegado el evento con la data de tiempo de parada de un vehiculo,
        //Buscamos a que mapas pertenece el vehiculo y actualizamos el valor de imeiPopu y time_stop
        // de cada mapa ya que seran tomados al llamar a printPopup con el mapa correspondiente
        this.maps.forEach((item) => {
          item.minimapConf!.vehicles?.forEach((element) => {
            if (element.IMEI!.toString() == data.imei.toString()) {
              item.imeiPopup = data.imei;
              item.time_stop = tiempoParada;
              this.printPopup(aux, item); //que actualice el popup en el mapa con tal id
            }
          });
        });
      }
    });

    this.eventService.newEventStream.subscribe((events) => {
      this.onEvent(events);
    });
  }
  onEvent(events: any) {
    // console.log(events);
    const aux = events[0];
    this.maps.forEach((item) => {
      //console.log("vehicles in item: ", item.configuration?.vehicles);
      const resultado = item.minimapConf?.vehicles!.find(
        (vehi) => vehi.IMEI?.toString() == aux.tracker_imei.toString()
      );
      if (resultado) {
        item.minimapConf!.events?.unshift(aux);
        item.minimapConf!.nEvents = item.minimapConf?.events?.filter(
          (ev) => ev.viewed == false
        ).length;
      }
    });
  }

  resizeMaps() {
    this.maps.forEach((item) => {
      item.map?.invalidateSize();
    });
  }
  getVehicles() {
    return this.vehicleService.vehicles;
  }

  setNameGroup(nameOpe: string, nameGru: string, nameCon: string): string {
    if (nameCon != 'Unidades Sin Convoy') {
      if (nameGru != 'Unidades Sin Grupo') {
        if (nameOpe != 'Unidades Sin Operacion') {
          return nameOpe + ' / ' + nameGru + ' / ' + nameCon;
        } else {
          return nameCon + ' / ' + nameGru;
        }
      } else {
        return nameCon;
      }
    } else {
      return '';
      // return 'Vehículo Sin Agrupación';
    }
  }
  onDrawIcon(mapItem: IMapMinimap): void {
    console.log('onDrawIcon');
    const e = mapItem.minimapConf!.vehicles!;

    const transmissionStatusColor: any = {
      10: 'green',
      20: 'blue',
      30: 'purple',
      40: 'black',
      50: 'orange',
      60: 'red',
      100: 'transm-color-not-defined',
    };

    mapItem.markerClusterGroup.clearLayers();
    mapItem.markerClusterGroupOnda.clearLayers();

    console.log('mapitem', mapItem);
    for (const property in e) {
      // console.log('e----- ', property);
      // console.log('e.hasOwnProperty(property)', e.hasOwnProperty(property));
      // console.log('e[property].eye', e[property].eye);
      if (e.hasOwnProperty(property) && e[property].eye == true) {
        this.drawIcon(e[property], mapItem);
      }
    }

    if (mapItem.minimapConf.mapConf!.dataFitBounds!.length > 0) {
      // //console.log("dataFitBounds map",this.dataFitBounds);
      mapItem.setFitBounds(mapItem.minimapConf.mapConf?.dataFitBounds!);
    }
    //EVENTO cluster lista
    mapItem.markerClusterGroup.on('clusterclick', function (a: any) {
      //console.log('click cluster...........');
      var coords = a.layer.getLatLng();
      //console.log(a.layer.getAllChildMarkers());
      var lista = '<table class="infovehiculos"><tbody>';
      var array = a.layer.getAllChildMarkers();
      //console.log('array'+array);
      for (const i in array) {
        var aaa = array[i]['_tooltip']['_content'];
        //console.log(aaa.replace(/<\/?[^>]+(>|$)/g, ""));
        var vehicleData = e.find(
          (vehicle: UserTracker) =>
            vehicle.name == aaa.replace(/<\/?[^>]+(>|$)/g, '')
        );
        var transmissionColorClass =
          typeof vehicleData != 'undefined'
            ? transmissionStatusColor[vehicleData.point_color!]
            : 'transm-color-not-defined';
        lista =
          lista +
          '<tr><td><div class="dot-vehicle ' +
          transmissionColorClass +
          '"></div></td><td>' +
          aaa +
          '</td></tr>';
      }
      // for (var i=0; i<array.length; i++){
      //     var aaa = array[i].label._content.split(' <');
      //     // //console.log(aaa[0]);
      //     var bbb = array[i].label._content.split('color:');
      //     var ccc = bbb[1].split(';');
      //     //console.log(aaa[0]+" - color css : "+ccc[0]);
      //     lista = lista + '<tr style="margin:10px;"><td><div style="border-radius: 50%; width: 10px; height: 10px; background-color:' + ccc[0] + ';"></div></td><td style="font-size: 14px;">' + aaa[0] + '</td></tr>';
      // }

      lista = lista + '</tbody></table>';

      var popupMarker = L.popup({
        maxHeight: 400,
        closeButton: false,
        closeOnClick: true,
        autoClose: true,
        className: 'popupList',
      })
        .setLatLng([coords.lat, coords.lng])
        .setContent(lista)
        .openOn(mapItem.map!);
    });
  }

  public async getUrlByStateOndas(data: any): Promise<string> {
    if (data?.speed != 0 && data?.speed! < data?.limit_speed!) {
      return './assets/images/objects/nuevo/gif/onda_verde.gif';
    } else if (data?.speed != 0 && data?.speed! > data?.limit_speed!) {
      return './assets/images/objects/nuevo/gif/onda_roja.gif';
    } else if (data?.speed == 0) {
      return './assets/images/objects/nuevo/gif/onda_azul.gif';
    } else {
      return './assets/images/objects/nuevo/gif/blanco.png';
    }
  }

  private async drawIcon(data: any, mapItem: IMapMinimap): Promise<void> {
    /* let iconUrl = await MinimapService.loadAndConvertSVGToPNG('./assets/images/objects/nuevo/'+data.icon); */
    // let iconUrl = await MinimapService.loadAndConvertSVGToPNG(data.custom_svg);
    // let iconUrl = await MinimapService.loadAndConvertSVGToPNG(await this.userDataService.getDirectionSvg(data.tipo!,'default'));
    let iconUrl;
    if(await this.hasParamIgnition(data)){
      if(await this.checkParamIgnition(data)){
        iconUrl = await this.setIconUrlByState(data);
      }else{
        iconUrl = await MinimapService.loadAndConvertSVGToPNG(await this.userDataService.getDirectionSvg(data.tipo!,'default'));
      }
    }else{
      iconUrl = await MinimapService.loadAndConvertSVGToPNG(await this.userDataService.getDirectionSvg(data.tipo!,'default'));
    }
    if (this.userDataService.changeItemIcon == 'ondas') {
      let iconUrl2 ='./assets/images/objects/nuevo/gif/blanco.png';
      if(await this.hasParamIgnition(data)){
        if(await this.checkParamIgnition(data)){
          iconUrl2 = await this.setIconUrlOndaByState(data);
        }
      }
      const myIcono = L.icon({
        iconUrl: iconUrl2,
        iconSize: [60, 60],
        iconAnchor: [33, 43],
      });

      const tempMarker1 = L.marker([data.latitud, data.longitud], {
        icon: myIcono,
      }).setZIndexOffset(-1);
      mapItem.markerClusterGroupOnda.addLayer(tempMarker1, {
        renderer: L.canvas(),
      });
      mapItem.markerClusterGroupOnda.addTo(mapItem.map, {
        renderer: L.canvas(),
      });
    }

    const iconMarker = L.icon({
      iconUrl: iconUrl,
      iconSize: [55, 55],
      iconAnchor: [30, 45],
      popupAnchor: [-3, -40],
    });
    //console.log('data.name',data.name);
    let nameGroup = this.setNameGroup(
      data.nameoperation,
      data.namegrupo,
      data.nameconvoy
    );
    const googleMapsLink = `https://www.google.com/maps?q=${data.latitud},${data.longitud}`;
    if (nameGroup) {
      var popupText =
        '<div class="row"><div class="col-6" align="left"><strong>' +
        data.name +
        '</strong></div><div class="col-6" align="right"><strong class="bg-secondary bg-opacity-25 p-1 rounded ">' +
        data.speed +
        ' km/h</strong></div></div>' +
        '<aside #popupText class="">' +
        '<small>' +
        nameGroup +
        '</small><br>' +
        // '<small>CONVOY: '+data.nameconvoy+'</small><br>'+
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Conductor"><i class="fas fa-user-circle"> Conductor: </i></span>' +
        data.namedriver +
        '</small><br>' +
        // '<small>UBICACION: '+data.latitud+', '+data.longitud+'</small><br>'+
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Ubicacion"><i class="fas fa-crosshairs"> Ubicacion: </i></span><a href=" ' +
        googleMapsLink +
        '" target="_blank">' +
        data.latitud +
        ', ' +
        data.longitud +
        '</a></small><br>' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Referencia"><i class="fas fa-map-marked-alt"> Referencia: </i></span> ' +
        'NN' +
        '</small><br>' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Fecha de Transmision"><i class="fas fa-calendar-alt"> Fehca de Transmision: </i></span> ' +
        data.dt_tracker +
        '</small><br>' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tiempo de Parada"><i class="fas fa-hourglass-half"> Tiempo de Parada: </i></span>' +
        mapItem.time_stop +
        '</small>' +
        '</aside>';
    } else {
      var popupText =
        '<div class="row"><div class="col-6" align="left"><strong>' +
        data.name +
        '</strong></div><div class="col-6" align="right"><strong class="bg-secondary bg-opacity-25 p-1 rounded ">' +
        data.speed +
        ' km/h</strong></div></div>' +
        '<aside #popupText class="">' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Conductor"><i class="fas fa-user-circle"> Conductor: </i></span>' +
        data.namedriver +
        '</small><br>' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Ubicacion"><i class="fas fa-crosshairs"> Ubicacion: </i></span><a href=" ' +
        googleMapsLink +
        '" target="_blank">' +
        data.latitud +
        ', ' +
        data.longitud +
        '</a></small><br>' +
        // '<small>UBICACION: '+data.latitud+', '+data.longitud+'</small><br>'+
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Referencia"><i class="fas fa-map-marked-alt"> Referencia: </i></span> ' +
        'NN' +
        '</small><br>' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Fecha de Transmision"><i class="fas fa-calendar-alt"> Fecha de Transmision: </i></span> ' +
        data.dt_tracker +
        '</small><br>' +
        '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tiempo de Parada"><i class="fas fa-hourglass-half"> Tiempo de Parada: </i></span>' +
        mapItem.time_stop +
        '</small>' +
        '</aside>';
    }

    // const tempMarker = L.marker([data.latitud, data.longitud], {icon: iconMarker});//.addTo(map).bindPopup(popupText);
    const tempMarker = L.marker([data.latitud, data.longitud], {
      icon: iconMarker,
    }).setZIndexOffset(1)
      .bindPopup(popupText);

    tempMarker.bindTooltip(`<span>${data.name}</span>`, {
      permanent: true,
      offset: [0, 12],
      className: 'vehicle-tooltip',
    });
    let options = {
      imei: data.IMEI,
      name: data.name,
      nameconvoy: data.nameconvoy,
      namegrupo: data.namegrupo,
      nameoperation: data.nameoperation,
      namedriver: data.namedriver,
      longitud: data.longitud,
      latitud: data.latitud,
      speed: data.speed,
      dt_tracker: data.dt_tracker,
      paradaDesde: '',
      vehicleService: this.vehicleService,
    };
    // console.log('envia cero data',data.speed);
    // console.log('envia cero XD',options);

    // tempMarker.on('click', this.timeStop, options);
    tempMarker.on('click', () => this.timeStop(options));

    // tempMarker.on('click',this.timeStop,options);
    // // this
    this.marker[data.IMEI] = tempMarker;

    mapItem.markerClusterGroup.addLayer(tempMarker, { renderer: L.canvas() });
    mapItem.markerClusterGroup.addTo(mapItem.map, { renderer: L.canvas() });
  }

  public timeStop(vhDataDefault: any): void {
    // consultar data actual
    // let vehicle = this.vehicleService.getVehicle(this.imei);
    let vehicle = this.vehicleService.getVehicle(vhDataDefault.imei);

    vhDataDefault.speed = vehicle.speed;
    vhDataDefault.dt_tracker = vehicle.dt_tracker;
    vhDataDefault.longitud = vehicle.longitud;
    vhDataDefault.latitud = vehicle.latitud;

    console.log("calculando time stop ...");

    let ar_vel = vhDataDefault.speed;
    if (vhDataDefault.speed > 3) {
      ar_vel = vhDataDefault.speed;
      vhDataDefault.paradaDesde = false;
    } else {
      if (vhDataDefault.paradaDesde == false) {
        ar_vel = vhDataDefault.speed;
      } else {
        ar_vel = -1; // velocidad mayor a 6 para que no traiga historial
      }
    }

    // let f_ini = moment(new Date())
    //   .add(-1, 'days')
    //   .add(5, 'hours')
    //   .format('YYYY-MM-DD HH:mm:ss');
    // let f_fin = moment(new Date())
    //   .add(5, 'hours')
    //   .format('YYYY-MM-DD HH:mm:ss');

    let f_ini = this.momentService.getDateUTCWithOptions(-1,'days','YYYY-MM-DD HH:mm:ss');
    let f_fin = this.momentService.getDateUTCWithOptions(undefined,undefined,'YYYY-MM-DD HH:mm:ss');

    let params = {
      imei: vhDataDefault.imei,
      name: vehicle.name,
      nameconvoy: vehicle.nameconvoy,
      namegrupo: vehicle.namegrupo,
      nameoperation: vehicle.nameoperation,
      namedriver: vehicle.namedriver,
      longitud: vhDataDefault.longitud,
      latitud: vhDataDefault.latitud,
      speed: vhDataDefault.speed,
      dt_tracker: vhDataDefault.dt_tracker,
      paradaDesde: vhDataDefault.paradaDesde,
      fecha_i: f_ini,
      fecha_f: f_fin,
      vel: ar_vel,
    };

    this.vehicleService.postTimeStop(params);
  }

  printPopup(data: any, mapItem: IMapMinimap): void {
    console.log('data final printpopup', data);
    let layers = mapItem.markerClusterGroup.getLayers();

    let key = Object.keys(layers).find(key => layers[key]._leaflet_id == this.marker[data.imei!]._leaflet_id)
    if(key != undefined && key){
      const nameGroup = this.setNameGroup(
        data.nameoperation,
        data.namegrupo,
        data.nameconvoy
      );
      const googleMapsLink = `https://www.google.com/maps?q=${data.latitud},${data.longitud}`;
      let newContent;
      if (nameGroup) {
        // mapItem.markerClusterGroup.getLayers()[key]['_popup'].setContent(
        newContent = '<div class="row"><div class="col-6" align="left"><strong>' +
            data.name +
            '</strong></div><div class="col-6" align="right"><strong class="bg-secondary bg-opacity-25 p-1 rounded ">' +
            data.speed +
            ' km/h</strong></div></div>' +
            '<aside class="">' +
            '<small>' +
            nameGroup +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Conductor"><i class="fas fa-user-circle"> Conductor: </i></span>' +
            data.namedriver +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Ubicacion"><i class="fas fa-crosshairs"> Ubicacion: </i></span><a href=" ' +
            googleMapsLink +
            '" target="_blank">' +
            data.latitud +
            ', ' +
            data.longitud +
            '</a></small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Referencia"><i class="fas fa-map-marked-alt"> Referencia: </i></span> ' +
            data.ref +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Fecha de Transmision"><i class="fas fa-calendar-alt"> Fehca de Transmision: </i></span> ' +
            data.dt_tracker +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tiempo de Parada"><i class="fas fa-hourglass-half"> Tiempo de Parada: </i></span>' +
            data.tiempoParada +
            '</small>' +
            '</aside>';
        // );
      } else {
        // mapItem.markerClusterGroup.getLayers()[key]['_popup'].setContent(
          newContent = '<div class="row"><div class="col-6" align="left"><strong>' +
            data.name +
            '</strong></div><div class="col-6" align="right"><strong class="bg-secondary bg-opacity-25 p-1 rounded ">' +
            data.speed +
            ' km/h</strong></div></div>' +
            '<aside class="">' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Conductor"><i class="fas fa-user-circle"> Conductor: </i></span>' +
            data.namedriver +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Ubicacion"><i class="fas fa-crosshairs"> Ubicacion: </i></span><a href=" ' +
            googleMapsLink +
            '" target="_blank">' +
            data.latitud +
            ', ' +
            data.longitud +
            '</a></small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Referencia"><i class="fas fa-map-marked-alt"> Referencia: </i></span> ' +
            data.ref +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Fecha de Transmision"><i class="fas fa-calendar-alt"> Fecha de Transmision: </i></span> ' +
            data.dt_tracker +
            '</small><br>' +
            '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tiempo de Parada"><i class="fas fa-hourglass-half"> Tiempo de Parada: </i></span>' +
            data.tiempoParada +
            '</small>' +
            '</aside>';
        // );
      }
      mapItem.markerClusterGroup.getLayers()[key]['_popup'].setContent(newContent)
    }

    for (const key in layers) {
      if (
        layers[key]['_tooltip']['_content'] ==
        this.marker[data.imei]._tooltip._content
      ) {
        // console.log("this.markerClusterGroup.getLayers()[key]",this.markerClusterGroup.getLayers()[key]);

        // Eliminar el tooltip activo
        /*  const activeTooltip = document.querySelector('.tooltip');
         if (activeTooltip) {
           activeTooltip.remove();
         } */

        }
    }
  }
  string_diffechas(a: any, b: any): any {
    let c = Math.floor((b - a) / 1000) % 60;
    let d = Math.floor((b - a) / 60000) % 60;
    let e = Math.floor((b - a) / 3600000) % 24;
    let f = Math.floor((b - a) / 86400000);
    let g = '';
    if (f > 0) {
      g = '' + f + ' d ' + e + ' h ' + d + ' min ' + c + ' s';
    } else if (e > 0) {
      g = '' + e + ' h ' + d + ' min ' + c + ' s';
    } else if (d > 0) {
      g = '' + d + ' min ' + c + ' s';
    } else if (c >= 0) {
      g = '' + c + ' s';
    }
    return g;
  }

  diffSecondsNow(fecha: string): boolean {
    // Obtener la fecha y hora actual
    const fechaActual = moment();
    // Parsear la fecha proporcionada
    const fechaComparar = moment(fecha);
    // Calcular la diferencia en segundos
    const diferenciaEnSegundos = fechaActual.diff(fechaComparar, 'seconds');
    // Comprobar si la diferencia es mayor a 15 segundos
    // console.log('Diff ',diferenciaEnSegundos);
    // console.log('Resulta ',diferenciaEnSegundos>15);
    return diferenciaEnSegundos > 15;
  }

  public async calculateDirection(coord1: any, coord2: any): Promise<string> {
    const angle =
      Math.atan2(coord1.lat - coord2.lat, coord1.lng - coord2.lng) *
      (180 / Math.PI);

    if (angle > -22.5 && angle <= 22.5) return 'right';
    if (angle > 22.5 && angle <= 67.5) return 'upright';
    if (angle > 67.5 && angle <= 112.5) return 'up';
    if (angle > 112.5 && angle <= 157.5) return 'upleft';
    if (angle > 157.5 || angle <= -157.5) return 'left';
    if (angle > -157.5 && angle <= -112.5) return 'downleft';
    if (angle > -112.5 && angle <= -67.5) return 'down';
    if (angle > -67.5 && angle <= -22.5) return 'downright';

    return '';
  }

  public getStateVehicle(vehicle: UserTracker): string {
    //console.log("vehicle",vehicle)
    if (vehicle.speed != 0 && vehicle.speed! < vehicle.limit_speed!) {
      return 'movement';
    } else if (vehicle.speed == 0) {
      return 'relenti';
    } else if (vehicle.speed != 0 && vehicle.speed! > vehicle.limit_speed!) {
      return 'excess';
    }
    return 'default';
  }

  private async monitor(data: any): Promise<void> {
    // console.log('DATA INGRESADA ->',data);
    this.maps.forEach(async (item) => {
      const resultado = item.minimapConf?.vehicles!.find(
        (vehi) => vehi.IMEI?.toString() == data.IMEI.toString()
      );

      if (resultado) {
        const index = item.minimapConf!.vehicles!.indexOf(resultado);
        console.log('resultado e indice: ', resultado, index);

        // data.fecha_tracker = moment(data.fecha_tracker).subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss');
        data.fecha_tracker = this.momentService.toLocal(data.fecha_tracker,true) as string;

        // TRAMAS EXCLUIDAS POR VOLCADO
        if(item.minimapConf!.vehicles![index].dt_tracker! > data.fecha_tracker){
          // console.log('TRAMA **************************',data.IMEI);
          // console.log('TRAMA dt vehicle:',vehicles[index].dt_tracker);
          // console.log('TRAMA dt (VOLCADO):',fecha_tracker);
          return;
        }

        if (
          item.minimapConf!.vehicles![index].dt_tracker != data.fecha_tracker &&
          item.minimapConf!.vehicles![index].driver_id != data.driver_id &&
          !this.diffSecondsNow(data.fecha_tracker)
        ) {
          // OBTENER EL NOMBRE EN BASE AL ID DRIVER
          item.minimapConf!.vehicles![index].namedriver = this.driversService.getDriverById(data.driver_id);
          item.minimapConf!.vehicles![index].id_conductor = data.driver_id;
          console.log('DRIVER MINIMAP->',data.IMEI,' - ',data.driver_id,':',item.minimapConf!.vehicles![index].namedriver);
        }
        item.minimapConf!.vehicles![index].latitud = data.Latitud.toString();
        item.minimapConf!.vehicles![index].longitud = data.Longitud.toString();
        item.minimapConf!.vehicles![index].speed = data.Velocidad;
        item.minimapConf!.vehicles![index].dt_server = data.fecha_server;
        // item.minimapConf!.vehicles![index].dt_tracker = moment(data.fecha_tracker).subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss');
        item.minimapConf!.vehicles![index].dt_tracker = data.fecha_tracker;
        item.minimapConf!.vehicles![index].altitud = data.Altitud;
        item.minimapConf!.vehicles![index].señal_gps = data.señal_gps;
        item.minimapConf!.vehicles![index].señal_gsm = data.señal_gsm;
        item.minimapConf!.vehicles![index].parametros = data.Parametros;
        item.minimapConf!.vehicles![index] = this.vehicleService.formatVehicle(
          item.minimapConf!.vehicles![index]
        );

        if (item.imeiPopup == data.IMEI.toString()) {
          let options = {
            imei: data.IMEI,
            name: item.minimapConf!.vehicles![index].name,
            nameconvoy: item.minimapConf!.vehicles![index].nameconvoy,
            namegrupo: item.minimapConf!.vehicles![index].namegrupo,
            nameoperation: item.minimapConf!.vehicles![index].nameoperation,
            namedriver: item.minimapConf!.vehicles![index].namedriver,
            longitud: data.Longitud,
            latitud: data.Latitud,
            speed: data.Velocidad,
            dt_tracker: item.minimapConf!.vehicles![index].dt_tracker, //luego de pasar por filtro
            paradaDesde: '',
            // vehicleService: this.vehicleService,
          };
          this.timeStopAux(options);
        }

        if (item.minimapConf!.vehicles![index].eye) {
          let cont = 0;
          let object = item.markerClusterGroup.getLayers();

          let key = Object.keys(object).find(key => object[key]._leaflet_id == this.marker[item.minimapConf!.vehicles![index].IMEI!]['_leaflet_id']);
          if(key != undefined && key){
          // }
            console.log(item.minimapConf!.vehicles![index]);
            let oldCoords = item.markerClusterGroup
                .getLayers()
                [key].getLatLng();

            let coord = {
              lat: parseFloat(item.minimapConf!.vehicles![index].latitud!),
              lng: parseFloat(item.minimapConf!.vehicles![index].longitud!),
            };
          // for (const key in object) {
            // if (
            //   object[key]['_tooltip']['_content'] ==
            //   '<span>' + item.minimapConf!.vehicles![index].name + '</span>'
            // ) {

              if (item.minimapConf!.vehicles![index].parametros!.includes('di4=') || item.minimapConf!.vehicles![index].parametros!.includes('Custom_ign=')) {

                let iconUrl = await MinimapService.loadAndConvertSVGToPNG(
                  await this.userDataService.getDirectionSvg(item.minimapConf!.vehicles![index].tipo!,'default')
                );

                if (item.minimapConf!.vehicles![index].parametros!.includes('di4=1') || item.minimapConf!.vehicles![index].parametros!.includes('Custom_ign=1')) {
                  if (this.userDataService.changeRotateIcon == true && this.userDataService.stateCustomDirectionSvg == true)
                  {
                    this.dif_mayor = 0.0;
                    this.dif_divide = 0.0;
                    this.dif_X = 0.0;
                    this.dif_Y = 0.0;
                    this.direction = '';
                    this.final_direction = '';
                    this.direction_X = '';
                    this.direction_Y = '';

                    if (coord.lat != oldCoords.lat && coord.lng != oldCoords.lng) {
                      this.direction = await this.calculateDirection(coord,oldCoords);
                      iconUrl = await this.userDataService.getDirectionSvg(item.minimapConf!.vehicles![index].tipo!,'default',this.direction);
                    }
                  }

                  if (this.userDataService.changeItemIcon == 'vehicles') {
                    if (this.userDataService.changeRotateIcon == true && this.userDataService.stateVehiclesDirectionSvg == true) {
                      this.dif_mayor = 0.0;
                      this.dif_divide = 0.0;
                      this.dif_X = 0.0;
                      this.dif_Y = 0.0;
                      this.direction = '';
                      this.final_direction = '';
                      this.direction_X = '';
                      this.direction_Y = '';

                      if (coord.lat != oldCoords.lat && coord.lng != oldCoords.lng) {
                        this.direction = await this.calculateDirection(coord,oldCoords);
                        let state = this.getStateVehicle(item.minimapConf!.vehicles![index]);
                        iconUrl = await this.userDataService.getDirectionSvg(item.minimapConf!.vehicles![index].tipo!,state,this.direction);
                      }
                    } else {
                      let state = this.getStateVehicle(item.minimapConf!.vehicles![index]);
                      iconUrl = await this.userDataService.getDirectionSvg(item.minimapConf!.vehicles![index].tipo!,state);
                    }
                  } else if (this.userDataService.changeItemIcon == 'ondas') {
                    let iconUrl2 = await this.getUrlByStateOndas(item.minimapConf!.vehicles![index]);
                    item.markerClusterGroupOnda.getLayers()[key]['options']['icon']['options']['iconUrl'] = iconUrl2;
                    item.markerClusterGroupOnda.getLayers()[key].setLatLng(coord);
                  }

                  if (item.minimapConf!.vehicles![index].speed != 0){
                    this.dif_mayor = 0.0;
                    this.dif_divide = 0.0;
                    this.dif_X = 0.0;
                    this.dif_Y = 0.0;
                    this.direction = '';
                    this.final_direction = '';
                    this.direction_X = '';
                    this.direction_Y = '';

                    if (coord.lat != oldCoords.lat && coord.lng != oldCoords.lng) {
                      if (coord.lat > oldCoords.lat) {
                        //arriba
                        this.direction_Y = 'up';
                        this.dif_Y = coord.lat - oldCoords.lat;
                        if (this.dif_Y >= this.dif_mayor) {
                          this.dif_mayor = this.dif_Y;
                          this.direction = 'up';
                          this.dif_divide = this.dif_Y / 2;
                        }
                      } else {
                        //abajo
                        this.direction_Y = 'down';
                        this.dif_Y = oldCoords.lat - coord.lat;
                        if (this.dif_Y >= this.dif_mayor) {
                          this.dif_mayor = this.dif_Y;
                          this.direction = 'down';
                          this.dif_divide = this.dif_Y / 2;
                        }
                      }

                      if (coord.lng > oldCoords.lng) {
                        //derecha
                        this.direction_X = 'right';
                        this.dif_X = coord.lng - oldCoords.lng;
                        if (this.dif_X >= this.dif_mayor) {
                          this.dif_mayor = this.dif_X;
                          this.direction = 'right';
                          this.dif_divide = this.dif_X / 2;
                        }
                      } else {
                        //izquierda
                        this.direction_X = 'left';
                        this.dif_X = oldCoords.lng - coord.lng;
                        if (this.dif_X >= this.dif_mayor) {
                          this.dif_mayor = this.dif_X;
                          this.direction = 'left';
                          this.dif_divide = this.dif_X / 2;
                        }
                      }

                      if(this.direction == 'up' || this.direction == 'down'){
                        if(this.dif_X >= this.dif_divide){
                          this.final_direction = `${this.direction}-${this.direction_X}`;
                        }else{
                          this.final_direction = `${this.direction}`;
                        }
                      }else{
                        if(this.dif_Y >= this.dif_divide){
                          this.final_direction = `${this.direction_Y}-${this.direction}`;
                        }else{
                          this.final_direction = `${this.direction}`;
                        }
                      }

                      if (this.userDataService.changeItemIcon == 'cursor' && item.minimapConf!.vehicles![index].speed! > item.minimapConf!.vehicles![index].limit_speed!
                      ) {
                        item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = `./assets/images/excessCursor/arrow_${this.final_direction}.webp`;
                        item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowAnchor'] = [14, 60];
                      } else {
                        item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = `./assets/images/arrow_${this.final_direction}.webp`;
                        item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowAnchor'] = [14, 60];
                      }
                    } else {
                      if (item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'])
                        {
                          let old_direction = item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'].split('_');
                          if (this.userDataService.changeItemIcon == 'cursor' && item.minimapConf!.vehicles![index].speed! > item.minimapConf!.vehicles![index].limit_speed!)
                          {
                            item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = `./assets/images/excessCursor/arrow_${old_direction[1]}`;
                            item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowAnchor'] = [14, 60];
                          } else {
                            item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = `./assets/images/arrow_${old_direction[1]}`;
                            item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowAnchor'] = [14, 60];
                          }
                        } else {
                          item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = '';
                        }
                    }
                  }else if(this.userDataService.changeItemIcon == 'cursor' && item.minimapConf!.vehicles![index].speed == 0) {
                    if (item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl']) {
                      let old_direction = item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'].split('_');
                      item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = `./assets/images/relentiCursor/arrow_${old_direction[1]}`;
                      item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowAnchor'] = [14, 60];
                    } else {
                      item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = `./assets/images/relentiCursor/arrow_down-left.webp`;
                      item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowAnchor'] = [14, 60];
                    }
                  }else{
                    item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = '';
                  }

                  item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['iconUrl'] = iconUrl;
                  item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowSize'] = [30, 30];
                }else{
                  // if(item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl']){
                  //   console.log('Remover Cursor',item.minimapConf!.vehicles![index].IMEI);
                  //   item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = '';
                  // }
                }

                // REVISAR FUNCION ES NECESARIO?
                // this.timeChangeIconUrl(item.minimapConf!.vehicles![index].IMEI!,item.minimapConf!.vehicles![index].tipo!,item,key);

                const googleMapsLink = `https://www.google.com/maps?q=${data.latitud},${data.longitud}`;
                const nameGroup = this.setNameGroup(
                  item.minimapConf!.vehicles![index].nameoperation!,
                  item.minimapConf!.vehicles![index].namegrupo!,
                  item.minimapConf!.vehicles![index].nameconvoy!
                );
                if (nameGroup) {
                  item.markerClusterGroup.getLayers()[key]['_popup'][
                    '_content'
                  ] =
                    '<div class="row"><div class="col-6" align="left"><strong>' +
                    item.minimapConf!.vehicles![index].name +
                    '</strong></div><div class="col-6" align="right"><strong class="bg-secondary bg-opacity-25 p-1 rounded ">' +
                    item.minimapConf!.vehicles![index].speed +
                    ' km/h</strong></div></div>' +
                    '<aside class="">' +
                    // '<small>CONVOY: '+item.minimapConf!.vehicles![index].nameconvoy+'</small><br>'+
                    '<small>' +
                    nameGroup +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Conductor"><i class="fas fa-user-circle"> Conductor: </i></span>' +
                    item.minimapConf!.vehicles![index].namedriver +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Ubicacion"><i class="fas fa-crosshairs"> Ubicacion: </i></span><a href=" ' +
                    googleMapsLink +
                    '" target="_blank">' +
                    item.minimapConf!.vehicles![index].latitud +
                    ', ' +
                    item.minimapConf!.vehicles![index].longitud +
                    '</a></small><br>' +
                    // '<small>UBICACION: '+item.minimapConf!.vehicles![index].latitud+', '+item.minimapConf!.vehicles![index].longitud+'</small><br>'+
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Referencia"><i class="fas fa-map-marked-alt"> Referencia: </i></span> ' +
                    'Calculando ...' +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Fecha de Transmision"><i class="fas fa-calendar-alt"> Fehca de Transmision: </i></span> ' +
                    item.minimapConf!.vehicles![index].dt_tracker +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tiempo de Parada"><i class="fas fa-hourglass-half"> Tiempo de Parada: </i></span> Calculando ...</small>' +
                    '</aside>';
                } else {
                  item.markerClusterGroup.getLayers()[key]['_popup'][
                    '_content'
                  ] =
                    '<div class="row"><div class="col-6" align="left"><strong>' +
                    item.minimapConf!.vehicles![index].name +
                    '</strong></div><div class="col-6" align="right"><strong class="bg-secondary bg-opacity-25 p-1 rounded ">' +
                    item.minimapConf!.vehicles![index].speed +
                    ' km/h</strong></div></div>' +
                    '<aside class="">' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Conductor"><i class="fas fa-user-circle"> Conductor: </i></span> ' +
                    item.minimapConf!.vehicles![index].namedriver +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Ubicacion"><i class="fas fa-crosshairs"> Ubicacion: </i></span><a href=" ' +
                    googleMapsLink +
                    '" target="_blank">' +
                    item.minimapConf!.vehicles![index].latitud +
                    ', ' +
                    item.minimapConf!.vehicles![index].longitud +
                    '</a></small><br>' +
                    // '<small>UBICACION: '+item.minimapConf!.vehicles![index].latitud+', '+item.minimapConf!.vehicles![index].longitud+'</small><br>'+
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Referencia"><i class="fas fa-map-marked-alt"> Referencia: </i></span> ' +
                    'Calculando ...' +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Fecha de Transmision"><i class="fas fa-calendar-alt"> Fehca de Transmision: </i></span> ' +
                    item.minimapConf!.vehicles![index].dt_tracker +
                    '</small><br>' +
                    '<small><span data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tiempo de Parada"><i class="fas fa-hourglass-half"> Tiempo de Parada: </i></span> Calculando ...</small>' +
                    '</aside>';
                }

              }else{
                // console.log('TRAMA IGNORADA NO CAMBIA ESTADO VEHICULO ----->',vehicles[index].IMEI);
              }
              item.markerClusterGroup.getLayers()[key].setLatLng(coord);
              // let aux = item.markerClusterGroup.getLayers()[key];
              const auxdataFitBounds = item.getDataFitBounds(item.minimapConf.vehicles!);
              item.setViewMap(auxdataFitBounds);
              if (auxdataFitBounds.length > 0) {
                item.setFitBounds(auxdataFitBounds);
              }
            // }
          }
        }
      }
    });
  }

  public addMap(mapItem: IMapMinimap): void {
    console.log('loadMapp--------');
    this.maps.push(mapItem);
    this.onDrawIcon(mapItem);
  }

  public timeStopAux(data: any): void {
    // console.log("calculando time stop aux ...");

    let ar_vel = data.speed;
    if (data.speed > 3) {
      ar_vel = data.speed;
      data.paradaDesde = false;
    } else {
      if (data.paradaDesde == false) {
        ar_vel = data.speed;
      } else {
        ar_vel = -1; // velocidad mayor a 6 para que no traiga historial
      }
    }

    // let f_ini = moment(new Date())
    //   .add(-1, 'days')
    //   .add(5, 'hours')
    //   .format('YYYY-MM-DD HH:mm:ss');
    // let f_fin = moment(new Date())
    //   .add(5, 'hours')
    //   .format('YYYY-MM-DD HH:mm:ss');
    let f_ini = this.momentService.getDateUTCWithOptions(-1,'days','YYYY-MM-DD HH:mm:ss');
    let f_fin = this.momentService.getDateUTCWithOptions(undefined,undefined,'YYYY-MM-DD HH:mm:ss');

    let params = {
      imei: data.imei,
      name: data.name,
      nameconvoy: data.nameconvoy,
      namegrupo: data.namegrupo,
      nameoperation: data.nameoperation,
      namedriver: data.namedriver,
      longitud: data.longitud,
      latitud: data.latitud,
      speed: data.speed,
      dt_tracker: data.dt_tracker,
      paradaDesde: data.paradaDesde,
      fecha_i: f_ini,
      fecha_f: f_fin,
      vel: ar_vel,
    };

    this.vehicleService.postTimeStop(params);
  }

  public changeClusteringVisibility(visibility: boolean) {
    this.clustering = visibility;
  }

  public get getClustering(): boolean {
    return this.clustering;
  }
  static async loadAndConvertSVGToPNG(svgURL: string): Promise<string> {
    // Crear un elemento de imagen
    const img = new Image();

    // Cargar el SVG desde la URL
    return new Promise<string>((resolve, reject) => {
      img.onload = async () => {
        // Crear un elemento de lienzo para convertir la imagen
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          // Dibujar la imagen en el lienzo
          ctx.drawImage(img, 0, 0, img.width, img.height);

          // Convertir el lienzo a un formato PNG
          const dataURL = canvas.toDataURL('image/png');
          canvas.remove();
          resolve(dataURL);
        } else {
          reject('Failed to get 2D context for canvas');
        }
      };

      img.onerror = () => {
        reject('Failed to load SVG image');
      };

      img.src = svgURL;
    });
  }
  public timeChangeIconUrl(imei: string,type: string,itemMap: any,key:any) {
    // this.timeWait = 5000; // 150 segundos en milisegundos

    if (this.timeNow[imei]) {
      clearTimeout(this.timeNow[imei]); // Limpia el temporizador existente si hay uno para este índice
      // console.log('SE LIMPIO EL TEMPORIZADOR del -->',imei);
    }
    const tiempoUltimaActualizacion = Date.now(); // Actualiza el tiempo de la última actualización para este índice
    // console.log('CREANDO TEMPORIZADOR NUEVO -->',imei);
    this.timeNow[imei] = setTimeout(async () => {
      // Verifica si ha pasado el tiempo especificado desde la última actualización
      const tiempoActual = Date.now();
      const tiempoTranscurrido = tiempoActual - tiempoUltimaActualizacion;
      if (tiempoTranscurrido >= this.timeWait) {
        // Realiza la acción si ha pasado el tiempo especificado sin actualización
        const iconUrl = await MinimapService.loadAndConvertSVGToPNG(await this.userDataService.getDirectionSvg(type,'default'));
        console.log('*************** PASO 2 Horas Cambiando color a default ---->',imei);

        // item.markerClusterGroup.getLayers()[key]['options']['icon']['options']['iconUrl']=iconUrl;
        itemMap.markerClusterGroup.getLayers()[key]['options']['icon']['options']['iconUrl'] = iconUrl;
        itemMap.markerClusterGroup.getLayers()[key]['options']['icon']['options']['shadowUrl'] = '';
        if (this.userDataService.changeItemIcon == 'ondas') {
          itemMap.markerClusterGroupOnda.getLayers()[key]['options']['icon']['options']['iconUrl'] = './assets/images/objects/nuevo/gif/blanco.png';
        }
      }
    }, this.timeWait);
  }

  private async hasParamIgnition (vehicle:UserTracker) :Promise<boolean>{
    return (vehicle.parametros!.includes('di4=') || vehicle.parametros!.includes('Custom_ign='));
  }
  private async checkParamIgnition (vehicle:UserTracker) :Promise<boolean>{
    // return (vehicle.parametros!.includes('di4=1') || vehicle.parametros!.includes('Custom_ign=1') ||  vehicle.v_on == 1);
    return (vehicle.v_on == 1);
  }
  private async setIconUrlByState(vehicle:UserTracker){
    let state:string = 'default';
    let iconUrl:any;
    if(this.userDataService.changeItemIcon == 'vehicles' && this.userDataService.stateVehiclesDirectionSvg == true ){
      state = this.getStateVehicle(vehicle);
      iconUrl = await MinimapService.loadAndConvertSVGToPNG(await this.userDataService.getDirectionSvg(vehicle.tipo!,state));
    }else{
      iconUrl = await this.userDataService.getDirectionSvg(vehicle.tipo!,state);
    }
    // console.log(vehicle.IMEI,vehicle.tipo,state);
    return iconUrl;
  }
  private async setIconUrlOndaByState(vehicle:UserTracker){
    const iconOndaUrl = await this.getUrlByStateOndas(vehicle);
    return iconOndaUrl;
  }

}
