<p>rp-show works!</p>

<p-dialog [(visible)]="repProgramadosService.modalReportesProgramadosActiveDetalle" [style]="{ width: '50%' }" header="Detalle Reporte" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <!-- <img [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image" [alt]="product.image" class="block m-auto pb-3" *ngIf="product.image" /> -->
        
        
        <div class="row">
          <div class="col-3">
            <label>Nombre</label>
          </div>
          <div class="col-1">
            <label>:</label>
          </div>
          <div class="col-8">
            {{reporteProgramado.nombre_programacion}}
          </div>
        </div>
  
        <br>
  
        <div class="row">
          <div class="col-3">
            <label>Tipo de Reporte</label>
          </div>
          <div class="col-1">
            <label>:</label>
          </div>
          <div class="col-8">
            {{reporteProgramado.nombre_reporte}}
          </div>
        </div>
  
        <br>
        
        <div class="row">
          <div class="col-3">
            <label>Vehículos</label>
          </div>
          <div class="col-1">
            <label>:</label>
          </div>
          <div class="col-8">
  
  
            <ol type="1" >
              <li *ngFor="let unidad of reporteProgramado.unidades">
                <span >{{unidad.IMEI}}</span>
                <span > --> </span>
                <span >{{unidad.name}}</span>
              </li>
            </ol>
  
  
          </div>
        </div>
        
        <br>
        
        <div class="row">
          <div class="col-3">
            <label>Rango de Fecha</label>
          </div>
          <div class="col-1">
            <label>:</label>
          </div>
          <div class="col-8">
            {{reporteProgramado.hora_inicio }} - {{reporteProgramado.hora_fin }} 
          </div>
        </div>
  
        <br>
        
        <div class="row">
          <div class="col-3">
            <label>Hora Programada</label>
          </div>
          <div class="col-1">
            <label>:</label>
          </div>
          <div class="col-8">
            A las {{reporteProgramado.notificacion_hora }} horas. (DIARIAMENTE)
          </div>
        </div>
  
        <br>
        
        <div class="row">
          <div class="col-3">
            <label>Correos para envio:</label>
          </div>
          <div class="col-1">
            <label>:</label>
          </div>
          <div class="col-8">
            {{reporteProgramado.notificacion_email_lista }}  
          </div>
        </div>
        
    </ng-template>
  
    <ng-template pTemplate="footer">
        <!-- <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> -->
        <!-- <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" (click)="reportService.modalReportesProgramadosActiveDetalle = false;"></button> -->
        <p-button label="Ok" icon="pi pi-check" (click)="repProgramadosService.modalReportesProgramadosActiveDetalle = false;"></p-button>
  
    </ng-template>
  </p-dialog>
  