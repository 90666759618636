import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ABGR,
  CircCoordinate,
  ExportGeofence,
  ExportKML,
  ITag,
  Operation,
  Placemark,
} from '../../models/interfaces';
import {
  CircleMarkerOptions,
  LatLng,
  LatLngLiteral,
  LatLngTuple,
} from 'leaflet';
import { MapServicesService } from 'src/app/map/services/map-services.service';
// import { CircleOptions } from 'leaflet';
import Swal from 'sweetalert2';
import { GeofencesService } from '../../services/geofences.service';
import { CircularGeofencesService } from '../../services/circular-geofences.service';
import { Dropdown } from 'primeng-lts/dropdown';

interface IG {
  geo_elemento?: MapServicesService & {
    options?: CircleMarkerOptions;
    _latlng?:
      | LatLng
      | LatLngLiteral
      | (LatLngTuple & {
          lat: number;
          lng: number;
          alt?: number | undefined;
        });
    _mRadius: Number;
  };
  tiempo_act_zona?: number | string;
  tiempo_zona?: number | string;
  description?: string;
  zone_radio?: string;
  zone_area?: string;
  zone_vertices?: string;
  vel_act_zona?: string | boolean;
  tag_name_font_size?: number | string;
  zone_opt_advanced?: string;
  zone_no_int_color?: string;
  zone_name_visible_old?: string;
}

@Component({
  selector: 'app-geofence-export',
  templateUrl: './geofence-export.component.html',
  styleUrls: ['./geofence-export.component.scss'],
})
export class GeofenceExportComponent implements OnInit, OnDestroy {
  @Output() onHideEvent = new EventEmitter<boolean>();
  @Input('showExport') showDialog: boolean = true;

  private geofencesOfServices: ExportGeofence[] = [];

  listGeofences: ExportGeofence[] = [];
  selectedGeofences: ExportGeofence[] = [];

  listGeofencesToExport: ExportGeofence[] = [];
  selectedGeofencesToExport: ExportGeofence[] = [];

  loading: boolean = false;
  exportStatus: boolean = true;
  selectedTag!: number | null;
  listTags: ITag[] = [];
  selectedOperation!: number | null;
  listOperations: Operation[] = [];

  errorToExport: boolean = false;
  constructor(
    private geofenceServices: GeofencesService,
    private geofenceCircularServices: CircularGeofencesService
  ) {}
  /**
   * inicializa variables a usar
   */
  ngOnInit(): void {
    this.ngOnDestroy();
    // this.listGeofences = [];
    // this.selectedGeofences = [];
    // this.listGeofencesToExport = [];
    // this.selectedGeofencesToExport = [];
    // this.selectedTag = 0;// comentado para que empiece en null
    this.listTags = this.geofenceServices.getTagss();
    this.geofenceServices.geofences.forEach((geofence: any) => {
      const geofencePoligonal: ExportGeofence = {
        id: geofence.id ?? 0,
        type: geofence.type ?? 'polig',
        geoCoordinate: geofence.geo_coordenadas ?? '',
        geoVertices: this.genPolCoords(geofence.zone_vertices),
        geoName: geofence.zone_name ?? '',
        perimetry: 0,
        area: 0,
        colorGeofence: geofence.zone_color ?? '#888888',
        colorText: geofence.tag_name_color ?? '#000000',
        sizeText: geofence.tag_name_font_size ?? 10,
        hasSpeed: this.checkBoolean(geofence.vel_act_zona) ?? false,
        maxSpeed: geofence.vel_max ?? 0,
        tolerableSpeed: geofence.vel_zona ?? 0,
        seriousSpeed: geofence.vel2_zona ?? 0,
        verySeriousSpeed: geofence.vel3_zona ?? 0,
        idOperation: geofence.idoperation ?? 0,
        nameOperation: geofence.nameoperation ?? '',
        idTags: geofence.tags ?? [],
        tags: JSON.stringify(geofence.tags) ?? '',
        description: geofence.orden ?? '',
        showGeo: this.checkBoolean(geofence.zone_visible) ?? false,
        showGeoName: this.checkBoolean(geofence.zone_name_visible) ?? false,
      };
      this.geofencesOfServices.push(geofencePoligonal);
    });
    this.geofenceCircularServices.circular_geofences.forEach(
      (geofence: any) => {
        const geofenceCircular: ExportGeofence = {
          id: geofence.id ?? '',
          type: geofence.type ?? 'circ',
          geoCoordinate:
            geofence.geo_coordenadas.trim().replace(/[<>]+/g, '') ?? '',
          geoVertices: this.genCircCoords(
            this.genLonLatRad(
              geofence.geo_coordenadas.trim().replace(/[<>]+/g, '')
            ).lat,
            this.genLonLatRad(
              geofence.geo_coordenadas.trim().replace(/[<>]+/g, '')
            ).lon,
            this.genLonLatRad(
              geofence.geo_coordenadas.trim().replace(/[<>]+/g, '')
            ).radio
          ),
          geoName: geofence.zone_name ?? '',
          perimetry: 0,
          area: 0,
          colorGeofence: geofence.zone_color ?? '',
          colorText: geofence.tag_name_color ?? '',
          sizeText: geofence.tag_name_font_size ?? '',
          hasSpeed: this.checkBoolean(geofence.vel_act_zona) ?? false,
          maxSpeed: geofence.vel_max ?? '',
          tolerableSpeed: geofence.vel_zona ?? '',
          seriousSpeed: geofence.vel2_zona ?? '',
          verySeriousSpeed: geofence.vel3_zona ?? '',
          idOperation: geofence.idoperation ?? '',
          nameOperation: geofence.nameoperation ?? '',
          idTags: geofence.tags ?? [],
          tags: JSON.stringify(geofence.tags) ?? '',
          description: geofence.orden ?? '',
          showGeo: this.checkBoolean(geofence.zone_visible) ?? false,
          showGeoName: this.checkBoolean(geofence.zone_name_visible) ?? false,
        };
        this.geofencesOfServices.push(geofenceCircular);
      }
    );
    console.log('geoImp: ngOnInit', this.geofencesOfServices);
    this.listGeofences = this.geofencesOfServices;
    this.getTags();
    this.loading = true;
    this.listOperations = this.geofenceServices.geofences
      .map((v: any) => {
        let operation: Operation = {
          name: v.nameoperation ?? '',
          key: v.idoperation ?? 0,
        };
        return operation;
      })
      .reduce((acc: Operation[], _: any) => {
        if (!acc.some((o) => o.key === _.key)) {
          acc.push(_);
        }
        return acc;
      }, []);
  }
  /**
   * finaliza variables usadas
   */
  ngOnDestroy(): void {
    this.listGeofences = [];
    this.selectedGeofences = [];
    this.listGeofencesToExport = [];
    this.selectedGeofencesToExport = [];
    this.geofencesOfServices = [];
    this.selectedTag = null;
    this.selectedOperation = null;
    this.listTags = [];
    this.listOperations = [];
    console.log('geoExp: destroyer');
  }
  /**
   * modifica variable del padre para desavilitar la vista de este componente
   */
  onHide() {
    this.onHideEvent.emit(false);
    this.ngOnInit();
  }
  /**
   * actualiza la lista de listGeofencesToExport
   */
  upListGeofencesToExport() {
    this.errorToExport = false;
    let aux: any = [];
    //recupero valores upListGeofencesToExport
    for (const key in this.listGeofencesToExport) {
      aux.push(this.listGeofencesToExport[key]);
    }
    // inserto valores nuevos
    for (const key in this.selectedGeofences) {
      // let index = aux.indexOf(this.selectedGeofences[key]);
      aux.push(this.selectedGeofences[key]);
    }
    //inserto valores en listGeofencesToExport
    this.listGeofencesToExport = aux;
    //vacio valores de list 1
    let aux2: any = [];
    let aux_status = false;
    for (const key in this.listGeofences) {
      let aux_status = false;
      for (const key2 in this.selectedGeofences) {
        if (this.listGeofences[key] == this.selectedGeofences[key2]) {
          aux_status = true;
        }
      }
      if (!aux_status) {
        aux2.push(this.listGeofences[key]);
      }
    }
    this.listGeofences = aux2;
    this.selectedGeofences = [];
  }
  /**
   * actualiza la lista de listGeofences
   */
  upListGeofences() {
    let aux: any = [];
    //recupero valores upListGeofencesToExport
    for (const key in this.listGeofences) {
      aux.push(this.listGeofences[key]);
    }
    // inserto valores nuevos
    for (const key in this.selectedGeofencesToExport) {
      aux.push(this.selectedGeofencesToExport[key]);
    }
    //inserto valores en listGeofences
    this.listGeofences = aux;
    //vacio valores de list 2
    let aux2: any = [];
    for (const key in this.listGeofencesToExport) {
      let aux_status = false;
      for (const key2 in this.selectedGeofencesToExport) {
        if (
          this.listGeofencesToExport[key] ==
          this.selectedGeofencesToExport[key2]
        ) {
          aux_status = true;
        }
      }
      if (!aux_status) {
        aux2.push(this.listGeofencesToExport[key]);
      }
    }
    this.listGeofencesToExport = aux2;
    this.selectedGeofencesToExport = [];
  }
  /**
   * filtra la lista de listGeofences
   */
  listedByTag() {
    if (this.selectedOperation != null) {
      this.listTags = [];
      this.listTags.push({ id: '-1', var_name: 'Sin etiqueta' });
    }
    if (this.selectedOperation == null) this.clearFilterOfListTags();

    let geofencesFiltered: ExportGeofence[] = [];
    this.geofencesOfServices
      .filter((geofence) => {
        if (
          this.selectedOperation != null &&
          geofence.idOperation == this.selectedOperation
        ) {
          this.geofenceServices.getTagss().map((t) => {
            geofence.idTags.includes(parseInt(t.id))
              ? this.listTags.includes(t)
                ? null
                : this.listTags.push(t)
              : null;
          });
        }
        return (
          this.selectedOperation == null ||
          geofence.idOperation == this.selectedOperation
        );
      })
      .forEach((item: ExportGeofence) => {
        if (this.selectedTag == null) {
          this.listGeofencesToExport.includes(item)
            ? ``
            : geofencesFiltered.push(item);
        } else if (this.selectedTag == -1) {
          item.idTags.length === 0 ? geofencesFiltered.push(item) : ``;
          this.listGeofencesToExport.includes(item)
            ? geofencesFiltered.pop()
            : ``;
        } else {
          item.idTags?.includes(this.selectedTag)
            ? geofencesFiltered.push(item)
            : ``;
          this.listGeofencesToExport.includes(item)
            ? geofencesFiltered.pop()
            : ``;
        }
      });
    this.listGeofences = geofencesFiltered;
  }
  /**
   * Enlista los tag disponibles en la variable local listTags
   */
  getTags() {
    this.listTags = this.geofenceServices.getTagss();
    console.log('geoExp: tags', this.listTags);
    this.listTags.push({ id: '-1', var_name: 'Sin etiqueta' });
  }
  // =================================================================================
  /**
   *
   * @param t :string, tipo de archivo a exportar [kml,kmz,csv]
   * @param expGeo
   */
  export(t: string, expGeo: ExportGeofence[] = this.listGeofencesToExport) {
    if (expGeo.length <= 0) {
      Swal.fire({
        icon: 'error',
        title: '¡Vaya!',
        text: 'Seleccione al menos una geocerca para exportar',
      });
      return;
    }
    this.exportStatus = false;
    const exportDictionary: Record<string, () => Promise<any> | any> = {
      kml: () => this.exportKML(expGeo),
      kmz: () => this.exportKMZ(expGeo),
      csv: () => this.exportCSV(expGeo),
    };

    const exportFunction =
      exportDictionary[t] ??
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formato no disponible para exportar',
      });
    const handleExport = async () => {
      try {
        this.downloadFile(`Geofence.${t}`, await exportFunction());
        this.errorToExport = false;
        this.exportStatus = true;
      } catch (error) {
        console.error('geoExp: error in download', error);
        this.exportStatus = false;
      }
    };
    handleExport();
    Swal.fire({
      icon: 'success',
      title: 'Exportación exitosa',
      text: 'Las geocercas se han exportado exitosamente',
    });
    this.onHide();
  }
  /**
   *
   * @param G :IGeofence[]
   * @param fileName :string, opcional
   */
  async exportKML(
    G: ExportGeofence[],
    fileName: string = 'Geofences.kml'
  ): Promise<string> {
    let kml: string = '<?xml version="1.0" encoding="UTF-8"?>\n';
    kml += await this.bodyOfXML(G, fileName);
    return kml;
  }
  /**
   *
   * @param G :IGeofence[]
   * @param xml :string, opcional al cual se va a anadir contenido
   * @returns :string, cuerpo del xml (kml,kmz)
   */
  async bodyOfXML(
    G: ExportGeofence[],
    fileName: string,
    xmlToExport: string = ''
  ): Promise<string> {
    let exportKml: ExportKML = {
      kml: {
        Document: {
          name: fileName,
          Placemark: [],
        },
      },
    };
    const placemark: Placemark[] = Object.values(G).map((item) => {
      let placemark: Placemark = {
        GLTracker: item,
        name: item.geoName,
        description: item.description,
        Style: {
          LineStyle: {
            width: 4,
            color: this.colorHexToABGR(item.colorGeofence),
          },
          PolyStyle: {
            color: this.colorHexToABGR(item.colorGeofence, 64), // mas transparencia
          },
        },
        Polygon: {
          outerBoundaryIs: {
            LinearRing: {
              coordinates:
                item.type == 'circ'
                  ? this.genCircCoords(
                      this.genLonLatRad(item.geoCoordinate).lat,
                      this.genLonLatRad(item.geoCoordinate).lon,
                      this.genLonLatRad(item.geoCoordinate).radio
                    )
                  : item.geoVertices,
            },
          },
        },
      };
      return placemark;
    });
    exportKml.kml.Document.Placemark = placemark;
    xmlToExport = this.exportKMLToString(exportKml);
    console.log('geoExp: kml', exportKml, xmlToExport);
    return xmlToExport;
  }
  exportKMLToString(exportKml: ExportKML, tabulator: string = ``): string {
    let result = '';
    Object.entries(exportKml).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          result += `${tabulator}<${key}>\n${this.exportKMLToString(
            item,
            tabulator + '\t'
          )}${tabulator}</${key}>\n`;
        });
      } else if (typeof value === 'object' && value !== null) {
        result += `${tabulator}<${key}>\n${this.exportKMLToString(
          value,
          tabulator + '\t'
        )}${tabulator}</${key}>\n`;
      } else if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      ) {
        result += `${tabulator}<${key}>\n${tabulator}\t${value}\n${tabulator}</${key}>\n`;
      }
    });
    return result;
    // return Object.entries(exportKml)
    //   .map(([key, value]) => {
    //     return this.isArray(value)
    //       ? `${tabulator}<${key}>\n${value
    //           .map((v: any) => this.exportKMLToString(v, tabulator + '\t'))
    //           .join('')}${tabulator}</${key}>\n`
    //       : this.isObject(value)
    //       ? `${tabulator}<${key}>\n${this.exportKMLToString(
    //           value,
    //           tabulator + '\t'
    //         )}${tabulator}</${key}>\n`
    //       : `${tabulator}<${key}>${value}</${key}>\n`;
    //   })
    //   .join('');
  }
  isArray(v: any): boolean {
    return Array.isArray(v);
  }
  isObject(v: any): boolean {
    return typeof v === 'object' && v !== null && !Array.isArray(v);
  }
  /**
   * ! No implementado por el tipo de archivo que es el kmz (supuestamente es binario)
   * @param G :IGeofence[]
   * @param fileName :string, opcional
   */
  async exportKMZ(
    G: ExportGeofence[],
    fileName: string = 'Geofences.kmz'
  ): Promise<string> {
    let kmz = '';
    /*
    import { Injectable } from '@angular/core';
    import * as JSZip from 'jszip';
    import { saveAs } from 'file-saver';

    @Injectable({
      providedIn: 'root'
    })
    export class KmzService {

      constructor() { }

      generateKMZ(kmlContent: string, images: { [filename: string]: Blob }) {
        const zip = new JSZip();

        // Añadir el archivo KML al zip
        zip.file('doc.kml', kmlContent);

        // Añadir las imágenes al zip
        for (const filename in images) {
          if (images.hasOwnProperty(filename)) {
            zip.file(filename, images[filename]);
          }
        }

        // Generar el archivo KMZ (zip con extensión .kmz)
        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, 'map.kmz');
        });
      }
    }

     */
    return kmz;
  }
  /**
   * * Usado en export()
   * @param G :IGeofence[]
   * @param fileName :string, opcional
   */
  async exportCSV(
    G: ExportGeofence[],
    fileName: string = 'Geofences.csv'
  ): Promise<string> {
    let csvToExport: string = '';
    const S = '|';
    const keys = Object.keys(G[0]) as Array<keyof ExportGeofence>;
    csvToExport += `${keys.join(S)}`;
    G.map((g) => {
      var geo: any[] = [];
      keys.forEach((e) => {
        geo.push(g[e]);
      });
      csvToExport += `\n${geo.join(S)}`;
    });

    console.log('geoExp: csv', G, csvToExport);
    return csvToExport;
  }
  genLonLatRad(geoCoordinate: string): CircCoordinate {
    let circCoordinate: CircCoordinate = {
      lat: 0,
      lon: 0,
      radio: 0,
    };
    if (!geoCoordinate.trim()) {
      return circCoordinate;
    }
    const points: string[] = geoCoordinate
      .trim()
      .replace(/[<>()]+/g, '')
      .split(',');
    circCoordinate.lat = parseFloat(points[0]);
    circCoordinate.lon = parseFloat(points[1]);
    circCoordinate.radio = parseFloat(points[2]);
    return circCoordinate;
  }
  /**
   *
   * @param longitud :number, longitud de una coordenada
   * @param latitud :number, latitud de una coordenada
   * @param radio :number, radio en metros
   * @returns :string, coordenadas(lat, lng, alt) en formato kml
   */
  genCircCoords(longitud: number, latitud: number, radio: number): string {
    let geoPoints: string = '';
    if (!radio) {
      return geoPoints;
    }
    let x0 = 0;
    let y0 = 0;
    const points = Math.max(
      Math.min(((radio - 1) * (100 - 20)) / (1000000 - 1) + 20, 100),
      20
    );
    radio = (radio / 6371000) * (180 / Math.PI);

    for (let i = 0; i < points; i++) {
      const angulo = (i * (2 * Math.PI)) / points;
      const x = longitud + radio * Math.cos(angulo);
      const y = latitud + radio * Math.sin(angulo);
      if (i == 0) {
        x0 = x;
        y0 = y;
      }
      geoPoints += `${y},${x},0 `;
    }
    geoPoints += `${y0},${x0},0 `;
    return geoPoints;
  }
  /**
   * Funcion que formatea de leaflet a Google Earth
   * @param geoVertices Coordenadas de Leaflet (lat lon, lat lon)
   * @returns Coordenadas de Google earth lat,lon,alt lat,lon,alt
   */
  genPolCoords(geoVertices: string): string {
    let geoPointsTemp: string = '';
    if (!geoVertices || !geoVertices.trim()) {
      return geoPointsTemp;
    }
    geoVertices
      .toString()
      .replace(/[()]/g, '')
      .split(',')
      .forEach((item) => {
        const coo = item.trim().split(' ');
        geoPointsTemp += `${coo[0]},${coo[1]},0 `;
      });
    return geoPointsTemp;
  }
  colorHexToABGR(color: string, alfa?: number): string {
    color = color.replace('#', '');
    let abgr: ABGR = {
      A: 'ff',
      B: '00',
      G: '00',
      R: '00',
    };
    if (color.length === 8 || color.length === 6) {
      alfa
        ? (abgr.A = alfa.toString(16).padStart(2, '0'))
        : color.length === 8
        ? (abgr.A = parseInt(color.substring(6, 8), 16)
            .toString(16)
            .padStart(2, '0'))
        : (abgr.A = (255).toString(16).padStart(2, '0')),
        (abgr.B = parseInt(color.substring(4, 6), 16)
          .toString(16)
          .padStart(2, '0'));
      abgr.G = parseInt(color.substring(2, 4), 16)
        .toString(16)
        .padStart(2, '0');
      abgr.R = parseInt(color.substring(0, 2), 16)
        .toString(16)
        .padStart(2, '0');
    }
    return `${abgr.A}${abgr.B}${abgr.G}${abgr.R}`;
  }
  checkBoolean(input: string | number | boolean): boolean {
    if (typeof input === 'boolean') {
      return input;
    }
    const booleanValues: Record<string, boolean> = {
      true: true,
      false: false,
      '1': true,
      '0': false,
    };
    if (typeof input === 'number') {
      return booleanValues[input.toString()] ?? false;
    }
    if (typeof input === 'string') {
      const trimmedInput = input.trim().toLowerCase();
      return booleanValues[trimmedInput] ?? false;
    }
    return false;
  }

  /**
   * * Usado en exportCSV()
   * @returns fecha y hora de ahora
   */
  getDMAHMS(): string {
    const now = new Date();

    const AAAA = now.getFullYear();
    const MM = now.getMonth() + 1; // Los meses comienzan desde 0, por lo que se suma 1
    const DD = now.getDate();
    const hh = now.getHours();
    const mm = now.getMinutes();
    const ss = now.getSeconds();
    // console.log(`iog: A/M/D h/m/s _${AAAA}${MM}${DD}_${hh}.${mm}.${ss}_`);
    return `_${AAAA}${MM}${DD}_${hh}.${mm}.${ss}`;
  }
  /**
   * usado en exportCSV(), exportKML(), exportKMZ()
   * @param fn :string nombre del archivo
   * @returns :string renombra el archivo
   */
  fileRename(fn: string): string {
    const p = fn.lastIndexOf('.'); // p es puntero
    const m = fn.substring(0, p); // m es nombre
    const e = fn.substring(p); // e es extencion
    const f = this.getDMAHMS();
    return m + f + e;
  }
  /**
   * Usado en exportCSV(), exportKML(), exportKMZ()
   * @param fileName :string, nombre del documento a guardar
   * @param xml :string, contenido del documento a guardar
   */
  downloadFile(fileName: string, xml: string = '') {
    const Mimetype: Record<string, string> = {
      kml: 'application/vnd.google-earth.kml+xml',
      kmz: 'application/vnd.google-earth.kmz',
      csv: 'text/csv',
    };
    // console.log('geoExp: Mimetype', Mimetype[fileName.slice(-3)]);
    const blob = new Blob([xml], {
      type: Mimetype[fileName.slice(-3)].toString() ?? 'text/plain',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    fileName = this.fileRename(fileName);
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }
  clearFilterOfListTags() {
    this.selectedTag = null;
  }
}
