import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-alerts-accessories',
  templateUrl: './panel-alerts-accessories.component.html',
  styleUrls: ['./panel-alerts-accessories.component.scss']
})
export class PanelAlertsAccessoriesComponent implements OnInit {

  constructor(
    ) { }

  ngOnInit(): void {
  }

}
