import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StoreWhatsAppResponse,
  WhatsAppBody,
  WhatsAppResponse,
} from '../models/interfaces';
import { Alert, Evaluation } from 'src/app/alerts/models/alert.interface';
import moment from 'moment';
import { AlertService } from 'src/app/alerts/service/alert.service';
import { environment } from 'src/environments/environment';
import { ResponseInterface } from 'src/app/core/interfaces/response-interface';

@Injectable({
  providedIn: 'root',
})
export class WhatsappService {
  private url: string = environment.WhatsAppUrl;
  private WhatsAppKey: string = environment.WhatsAppKey;
  private numberTi: string = 'Central GL Tracker 51 978304473';

  _360: any[] = [];
  _gps: any[] = [];
  _mobile: any[] = [];
  _security: any[] = [];
  _platform: any[] = [];

  IMEIsBelongsToAntapaccayAccount: string[] = [];

  constructor(private http: HttpClient, private alertService: AlertService) {
    this.init();
    this.getVehicleIsFromAntapaccay();
  }
  async init() {
    this._360 = await this.alertService.getEventsByType('360');
    this._gps = await this.alertService.getEventsByType('gps');
    this._mobile = await this.alertService.getEventsByType('mobile');
    this._security = await this.alertService.getEventsByType('security');
    this._platform = await this.alertService.getEventsByType('platform');
  }
  public async sendWhatsAppAlertEvaluated(
    evaluation: Evaluation,
    event: any | Alert
  ): Promise<WhatsAppResponse[]> {
    let res: Promise<WhatsAppResponse>[] = [];
    if (
      event.bol_evaluation == 1 &&
      (true || event.WhatsAppWillBeSent == true)
    ) {
      if (this.filteringByEventType(event, '360')) {
        let _numbers: string[] = [];
        const alert: any = this.alertService.getAlertById(
          event.evento_id.toString()
        );
        if (
          alert?.notificacion_whatsapp &&
          this.checkBoolean(alert?.notificacion_whatsapp)
        ) {
          _numbers =
            alert?.notificacion_whatsapp_lista &&
            typeof alert.notificacion_whatsapp_lista == 'string'
              ? alert.notificacion_whatsapp_lista.split(',')
              : [];
          if (_numbers.length > 0) _numbers.push('51959163102');
        }
        console.log('whatsapp', event);
        Object.entries(_numbers).map(([_, n]) => {
          const requestBodies = this.getTemplateOfWhatsApp(
            event,
            n,
            evaluation
          );
          console.log('whatsapp', requestBodies);

          if (requestBodies) {
            requestBodies.map((requestBody) => {
              res.push(this.postWhatsApp(requestBody));
            });
          }
        });
      }
    }
    try {
      return await Promise.all(res);
    } catch (error) {
      return [];
    }
  }
  /**
   * @deprecated
   * @param event: any | Alert
   */
  public sendWhatsAppAlert(event: any | Alert) {
    if (true || event.WhatsAppWillBeSent == true) {
      if (this.filteringByEventType(event, '360')) {
        const _numbers: number[] | string[] = [];
        Object.entries(_numbers).map((n) => {
          const requestBodies = this.getTemplateOfWhatsApp(event, n.toString());
          if (requestBodies) {
            requestBodies.map((requestBody) => {
              this.postWhatsApp(requestBody)
                .then((response) => {
                  console.log('whatsapp: ', response);
                })
                .catch((error) => {
                  console.log('whatsapp: ', error);
                });
            });
          }
        });
      }
    }
  }
  /**
   *
   * @param event : any | Alert
   * @param typeEvent : string ['360', 'gps', 'mobile', 'security', 'platform']
   * @returns : boolean
   */
  private filteringByEventType(event: any | Alert, typeEvent: string): boolean {
    const listOfEventsByEventType: Record<string, any[]> = {
      '360': this._360,
      gps: this._gps,
      mobile: this._mobile,
      security: this._security,
      platform: this._platform,
    };

    return (
      listOfEventsByEventType[typeEvent].some((e) => e.slug === event.evento) ??
      false
    );
  }
  private getTemplateOfWhatsApp(
    a: any,
    phone: string,
    e?: Evaluation
  ): WhatsAppBody[] {
    let templates: WhatsAppBody[] = [];
    const whatsappMessageTemplates: Record<string, (T: any) => WhatsAppBody> = {
      events: (t) => {
        const T: WhatsAppBody = {
          messaging_product: 'whatsapp',
          to: phone,
          type: 'template',
          template: {
            name: 'events',
            language: {
              code: 'es',
            },
            components: [
              {
                type: 'body',
                parameters: [
                  { type: 'text', text: t.nombre },
                  {
                    type: 'text',
                    text: this.searchObservationOrCriteria(t),
                  },
                  {
                    type: 'text',
                    text: t.nombre_objeto ?? 'Vehículo no identificado',
                  },
                  {
                    type: 'text',
                    text: t.velocidad + ' km/h',
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[0],
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[1],
                  },
                  { type: 'text', text: this.numberTi },
                ],
              },
            ],
          },
        };
        return T;
      },
      events_with_location: (t) => {
        const T: WhatsAppBody = {
          messaging_product: 'whatsapp',
          to: phone,
          type: 'template',
          template: {
            name: 'events_with_location',
            language: {
              code: 'es',
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'location',
                    location: {
                      longitude: t.longitud,
                      latitude: t.latitud,
                      name: t.nombre,
                      address: t.referencia ?? '',
                    },
                  },
                ],
              },
              {
                type: 'body',
                parameters: [
                  { type: 'text', text: t.nombre },
                  {
                    type: 'text',
                    text: this.searchObservationOrCriteria(t),
                  },
                  {
                    type: 'text',
                    text: t.nombre_objeto ?? 'Vehículo no identificado',
                  },
                  {
                    type: 'text',
                    text: t.velocidad + ' km/h',
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[0],
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[1],
                  },
                  { type: 'text', text: this.createGoogleMapsUrl(t) },
                  { type: 'text', text: this.numberTi },
                ],
              },
            ],
          },
        };
        return T;
      },
      cipia_360_events_with_location: (t) => {
        const T: WhatsAppBody = {
          messaging_product: 'whatsapp',
          to: phone,
          type: 'template',
          template: {
            name: 'cipia_360_events_with_location',
            language: {
              code: 'es',
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'location',
                    location: {
                      longitude: t.longitud,
                      latitude: t.latitud,
                      name: t.nombre,
                      address: t.referencia ?? '',
                    },
                  },
                ],
              },
              {
                type: 'body',
                parameters: [
                  { type: 'text', text: t.nombre },
                  {
                    type: 'text',
                    text: this.searchObservationOrCriteria(t),
                  },
                  {
                    type: 'text',
                    text: t.nombre_objeto ?? 'Vehículo no identificado',
                  },
                  {
                    type: 'text',
                    text: t.velocidad + ' km/h',
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[0],
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[1],
                  },
                  // { type: 'text', text: this.createGoogleMapsUrl(t) },
                  { type: 'text', text: t.referencia },
                  { type: 'text', text: this.numberTi },
                ],
              },
            ],
          },
        };
        return T;
      },
      cipia_360_events_with_image: (t) => {
        const T: WhatsAppBody = {
          messaging_product: 'whatsapp',
          to: phone,
          type: 'template',
          template: {
            name: 'cipia_360_events_with_image',
            language: {
              code: 'es',
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'image',
                    image: {
                      link: this.createMediaUrl(t, 'image'),
                    },
                  },
                ],
              },
              {
                type: 'body',
                parameters: [
                  { type: 'text', text: t.nombre },
                  {
                    type: 'text',
                    text: this.searchObservationOrCriteria(t),
                  },
                  {
                    type: 'text',
                    text: t.nombre_objeto ?? 'Vehículo no identificado',
                  },
                  {
                    type: 'text',
                    text: t.velocidad + ' km/h',
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[0],
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[1],
                  },
                  { type: 'text', text: t.referencia },
                  { type: 'text', text: this.numberTi },
                ],
              },
            ],
          },
        };
        return T;
      },
      cipia_360_events_with_video: (t) => {
        const T: WhatsAppBody = {
          messaging_product: 'whatsapp',
          to: phone,
          type: 'template',
          template: {
            name: 'cipia_360_events_with_video',
            language: {
              code: 'es',
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'video',
                    video: { link: this.createMediaUrl(t, 'video') },
                  },
                ],
              },
              {
                type: 'body',
                parameters: [
                  { type: 'text', text: t.nombre },
                  {
                    type: 'text',
                    text: this.searchObservationOrCriteria(t),
                  },
                  {
                    type: 'text',
                    text: t.nombre_objeto ?? 'Vehículo no identificado',
                  },
                  {
                    type: 'text',
                    text: t.velocidad + ' km/h',
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[0],
                  },
                  {
                    type: 'text',
                    text: t.fecha_tracker?.split(' ')[1],
                  },
                  { type: 'text', text: t.referencia },
                  { type: 'text', text: this.numberTi },
                ],
              },
            ],
          },
        };
        return T;
      },
      hello_world: (t) => {
        console.log(t);
        const T: WhatsAppBody = {
          messaging_product: 'whatsapp',
          to: phone,
          type: 'en_US',
          template: {
            name: 'hello_world',
            language: {
              code: 'es',
            },
          },
        };
        return T;
      },
    };
    if (
      a.wpTypeTemplate &&
      typeof a.wpTypeTemplate == 'string' &&
      !a.wpTypeTemplate.trim()
    ) {
      // templates.push(whatsappMessageTemplates[a.wpTypeTemplate](a));
    } else {
      const selectedTemplate =
        a.parametros?.has_video == 1 &&
        a.parametros.deviceId &&
        a.parametros.eventId
          ? whatsappMessageTemplates['cipia_360_events_with_video'](a)
          : a.parametros?.has_image == 1 &&
            a.parametros.deviceId &&
            a.parametros.eventId
          ? whatsappMessageTemplates['cipia_360_events_with_image'](a)
          : a.latitud && a.longitud
          ? whatsappMessageTemplates['cipia_360_events_with_location'](a)
          : whatsappMessageTemplates['events'](a);

      templates.push(selectedTemplate);

      // if (a.latitud && a.longitud) {
      //   // templates.push(whatsappMessageTemplates['evento_cipia_360'](a));
      //   templates.push(
      //     whatsappMessageTemplates['cipia_360_events_with_location'](a)
      //   );
      // } else {
      //   templates.push(whatsappMessageTemplates['events'](a));
      // }
      // if (
      //   a.parametros &&
      //   a.parametros.has_image &&
      //   a.parametros.has_image == 1 &&
      //   a.parametros.deviceId &&
      //   a.parametros.eventId
      // ) {
      //   templates.push(
      //     whatsappMessageTemplates['cipia_360_events_with_image'](a)
      //   );
      // }
      // if (
      //   a.parametros &&
      //   a.parametros.has_video &&
      //   a.parametros.has_video == 1
      // ) {
      //   templates.push(
      //     whatsappMessageTemplates['cipia_360_events_with_video'](a)
      //   );
      // }
    }
    return templates;
  }
  private async postWhatsApp(t: WhatsAppBody): Promise<WhatsAppResponse> {
    try {
      const r = await fetch(this.url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.WhatsAppKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(t),
      });
      return {
        status: r.status,
        phone: t.to,
        response: r,
        json: await r.json(),
      };
    } catch (r) {
      let res: WhatsAppResponse = {
        status: 0,
        phone: t.to,
        response: undefined,
        json: JSON.stringify({ template: t }),
      };
      return res;
    }
  }
  private checkBoolean(input: string | number | boolean): boolean {
    if (typeof input === 'boolean') {
      return input;
    }
    const booleanValues: Record<string, boolean> = {
      true: true,
      false: false,
      '1': true,
      '0': false,
    };
    if (typeof input === 'number') {
      return booleanValues[input.toString()] ?? false;
    }
    if (typeof input === 'string') {
      const trimmedInput = input.trim().toLowerCase();
      return booleanValues[trimmedInput] ?? false;
    }
    return false;
  }
  searchObservationOrCriteria(t: any): string {
    let message: string = ' - ';
    if (t && t.evaluations) {
      if (Array.isArray(t.evaluations)) {
        if (
          t.evaluations[0] &&
          (t.evaluations[0].criterio_evaluacion ||
            t.evaluations[0].observacion_evaluacion)
        ) {
          message =
            t.evaluations[0].observacion_evaluacion.trim() ??
            t.evaluations[0].criterio_evaluacion.trim();
        }
      } else {
        const observacion = t.evaluations.observacion_evaluacion?.trim();
        const criterio = t.evaluations.criterio_evaluacion?.trim();

        if (observacion || criterio) {
          message = observacion || criterio;
        }
      }
    }
    return message;
  }
  createGoogleMapsUrl(t: any): string {
    let url: string = '';
    if (t.longitud && t.latitud) {
      url = `https://www.google.com/maps/search/${t.latitud},+${t.longitud}`;
    }
    return url;
  }
  createMediaUrl(t: any, type: string): string {
    let url: string = '';
    if (t.parametros && t.parametros.deviceId && t.parametros.eventId) {
      url = `${environment.apiUrl}/api/media/${type}/${t.parametros.deviceId}/${t.parametros.eventId}/CABIN`;
    }
    return url;
  }
  async sendResponseOfPostWhastApp(res: StoreWhatsAppResponse) {
    console.log('whatsapp:', res);
    this.http
      .post<ResponseInterface>(
        environment.apiUrl + '/api/updateEvaluationWhatsApp',
        res
      )
      .toPromise()
      .then((r) =>
        console.log(
          'whatsapp sendResponseOfPostWhastApp',
          r,
          JSON.stringify(res)
        )
      )
      .catch();
  }
  async getMessagesSentByWhatsApp(
    idUser: string,
    uuid: string
  ): Promise<WhatsAppResponse[]> {
    try {
      return this.http
        .get(
          `${environment.apiUrl}/api/getEvaluationInfoWhatsApp/${idUser}/${uuid}`
        )
        .toPromise()
        .then((r: any) => JSON.parse(r.data ?? '[]') ?? [])
        .catch((r) => []);
    } catch (error) {
      return [];
    }
  }
  async getVehicleIsFromAntapaccay() {
    this.http
      .get<ResponseInterface>(`${environment.apiUrl}/api/get_imeis_antapaccay`)
      .toPromise()
      .then((r) => (this.IMEIsBelongsToAntapaccayAccount = r.data))
      .catch();
  }
}
