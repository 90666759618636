import { Injectable } from '@angular/core';
import { UserDataService } from 'src/app/profile-config/services/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private userDataService: UserDataService,
  ) {}
  async hasPermission(permissionName: string): Promise<boolean>{
    try {
      const permissions =  this.userDataService.userPermissionData.length >0? this.userDataService.userPermissionData:JSON.parse(localStorage.getItem('PermissionList')??'[]');
      console.log('Saint: Permissosn del usuario',permissionName,permissions.includes(permissionName), permissions);
      
      return permissions.includes(permissionName);
    } catch (error){
      console.log('Saint: Permissosn del usuario',error);
      
      return false;
    }
  }

}
