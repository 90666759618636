<p-dialog [(visible)]="reportService.modalActive" >
  <ng-template pTemplate="header" >

   
        <!-- <div class="row">
          <div class="col"> -->
       
                  VEHÍCULO: {{vehiculo}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    
          <!-- </div>
          <div class="col"> -->
                PERIODO: {{periodo}}
          <!-- </div>
        </div> -->

 
  </ng-template>

  <!-- 5. REPORTE GENERAL-->
  <div id="scrolly" style="width: 60vw;">
  <!-- <div class="container grand-container"> -->
    <div *ngFor="let v_table of data;  let i = index;" class="p-card" style="padding: 1rem;">

      <!-- <div class="subtable-title" style="margin-bottom: 0.25rem;">
        <div>VEHÍCULO: {{vehiculo}}</div>
        <div>PERIODO: {{periodo}}</div>
      </div> -->

      <ng-container >
        <table datatable [dtOptions]="dtOptions" class="table table-striped table-border row-border hover">
          <thead>
            <!-- <tr>
                <th>Nº</th>
                <th *ngIf="!chkDateHour">Fecha/Hora</th>
                <th *ngIf="chkDateHour">Fecha</th>
                <th *ngIf="chkDateHour">Hora</th>

                <th *ngIf="v_table[2].fServidor && !chkDateHour">Fecha/Hora Servidor</th>
                <th *ngIf="v_table[2].fServidor && chkDateHour">Fecha Servidor</th>
                <th *ngIf="v_table[2].fServidor && chkDateHour">Hora Servidor</th>

                <th *ngIf="v_table[2].ubicacion" >Ubicación</th>
                <th *ngIf="v_table[2].velGPS && numRep  != 'R036'" >Velocidad GPS</th>
                <th *ngIf="v_table[2].velGPS && numRep  == 'R036'" >Velocidad 360°</th>
                <th *ngIf="v_table[2].velECO" >Velocidad ECO</th>
                <th *ngIf="v_table[2].velGPS_speed" >Velocidad GPS speed</th>
                <th *ngIf="v_table[2].velMobileye_ME460" >Velocidad Mobileye</th>

                <th *ngIf="v_table[2].altitud" >Altitud</th>
                <th *ngIf="v_table[2].angulo" >Angulo</th>
                <th *ngIf="v_table[2].fatiga" >Fatiga</th>
                <th *ngIf="v_table[2].fBrusca" >Frenada Brusca</th>
                <th *ngIf="v_table[2].aBrusca" >Aceleración Brusca</th>
                <th *ngIf="v_table[2].RPMAlta" >RPM Alta</th>

                <th *ngIf="v_table[2].alcoholemia" >Alcoholemia</th>
                <th *ngIf="v_table[2].parametros" >Parámetros</th>
                <th *ngIf="v_table[2].cNivel" >Nivel de Combustible</th>
                <th *ngIf="v_table[2].cRestante" >C.Restante</th>
                <th *ngIf="v_table[2].cMotor" >C.Motor</th>
                <th *ngIf="v_table[2].odometro" >Odómetro</th>
                <th *ngIf="v_table[2].onOff" >On/Off</th>
                <th *ngIf="v_table[2].RxM" >Rev.X Min.</th>
                <th *ngIf="v_table[2].recFacial" >Reconocimiento Facial</th>
                <th *ngIf="v_table[2].velCAN" >Velocidad CAN</th>
                <th *ngIf="v_table[2].pCercano" >Punto Cercano</th>
                <th *ngIf="v_table[2].referencia" >Referencia</th>

                <th *ngIf="v_table[2].DUOT2state" >DUOT2 state</th>
            </tr> -->
            <tr>
              <th>Nº</th>
              <th *ngIf="!chkDateHour">Fecha/Hora</th>
              <th *ngIf="chkDateHour">Fecha</th>
              <th *ngIf="chkDateHour">Hora</th>

              <th *ngIf="v_table[2].fServidor && !chkDateHour">Fecha/Hora Servidor</th>
              <th *ngIf="v_table[2].fServidor && chkDateHour">Fecha Servidor</th>
              <th *ngIf="v_table[2].fServidor && chkDateHour">Hora Servidor</th>

              <th *ngIf="v_table[2].pCercano" >Punto Cercano</th>

              <th *ngIf="v_table[2].velCAN" >Velocidad CAN</th>
              <th *ngIf="v_table[2].velGPS && numRep  != 'R036'" >Velocidad GPS</th>
              <th *ngIf="v_table[2].velGPS && numRep  == 'R036'" >Velocidad 360°</th>
              <th *ngIf="v_table[2].velGPS_speed" >Velocidad GPS speed</th>
              <th *ngIf="v_table[2].velMobileye_ME460" >Velocidad Mobileye</th>
              <th *ngIf="v_table[2].velECO" >Velocidad ECO</th>
              <th *ngIf="v_table[2].DUOT2state" >DUOT2 state</th>

              <th *ngIf="v_table[2].RxM" >RPM</th>
              <th *ngIf="v_table[2].RPMAlta" >RPM Alta</th>

              <th *ngIf="v_table[2].cNivel" >Nivel de Combustible</th>
              <th *ngIf="v_table[2].cRestante" >C.Restante</th>

              <th *ngIf="v_table[2].cMotor" >C.Motor</th>
              <th *ngIf="v_table[2].odometro" >Odómetro</th>

              <th *ngIf="v_table[2].altitud" >Altitud</th>
              <th *ngIf="v_table[2].angulo" >Angulo</th>

              <th *ngIf="v_table[2].alimentGps" >Alimentación GPS</th>
              <th *ngIf="v_table[2].nivelBateria" >Nivel de Batería</th>

              <th *ngIf="v_table[2].nivelCobertura" >Nivel de Cobertura</th>
              <th *ngIf="v_table[2].temperaturaGps" >Temperatura GPS</th>

              <th *ngIf="v_table[2].satelite" >Satélite</th>
              <th *ngIf="v_table[2].recFacial" >Reconocimiento Facial</th>

              <th *ngIf="v_table[2].onOff" >Ignición</th>
              <th *ngIf="v_table[2].alcoholemia" >Alcoholemia</th>

              <th *ngIf="v_table[2].parametros" >Parámetros</th>
              <th *ngIf="v_table[2].ubicacion" >Ubicación</th>

              <th *ngIf="v_table[2].referencia" >Referencia</th>

              <!-- YA NO SE USAN -->
              <th *ngIf="v_table[2].fatiga" >Fatiga</th>
              <!-- <th *ngIf="v_table[2].fExBrusca" >Frenada Extrema Brusca</th> -->
              <th *ngIf="v_table[2].fBrusca" >Frenada Brusca</th>
              <th *ngIf="v_table[2].aBrusca" >Aceleración Brusca</th>
            </tr>
          </thead>

          <tbody>
            <!-- <tr *ngFor="let row of v_table[1];  let i = index;">

                <td>{{i + 1}}</td>
                <td *ngIf="!chkDateHour">{{row.fecha}}</td>
                <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
                <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

                <td *ngIf="v_table[2].fServidor && !chkDateHour">{{row.fServidor}}</td>
                <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[0]}}</td>
                <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[1]}}</td>


                <td *ngIf="v_table[2].ubicacion">
                  <a href="http://maps.google.com/maps?q={{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}&amp;t=m" target="_blank">{{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}</a>
                </td>
                <td *ngIf="v_table[2].velGPS" [ngStyle]="{'background-color': row.velGPS > v_table[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velGPS}}</td>
                <td *ngIf="v_table[2].velECO" [ngStyle]="{'background-color': row.velECO > v_table[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velECO}}</td>
                <td *ngIf="v_table[2].velGPS_speed" >{{row.velGPS_speed}}</td>
                <td *ngIf="v_table[2].velMobileye_ME460" >{{row.velMobileye_ME460}}</td>

                <td *ngIf="v_table[2].altitud" >{{row.altitud}}</td>
                <td *ngIf="v_table[2].angulo" >{{row.angulo}}</td>
                <td *ngIf="v_table[2].fatiga" [ngStyle]="{'background-color': row.fatiga == 'si' ? 'rgb(28, 243, 9)' : {} }">{{row.fatiga}}</td>
                <td *ngIf="v_table[2].fBrusca" >{{row.fBrusca}}</td>
                <td *ngIf="v_table[2].aBrusca" >{{row.aBrusca}}</td>
                <td *ngIf="v_table[2].RPMAlta" >{{row.RPMAlta}}</td>

                <td *ngIf="v_table[2].alcoholemia && row.alcohol_state == 4" [ngStyle]="{'background-color': 'rgb(28, 243, 9)'}">{{row.alcohol_nombre}}</td>
                <td *ngIf="v_table[2].alcoholemia && row.alcohol_state == 5" [ngStyle]="{'background-color': 'rgb(255,  0, 0)'}">{{row.alcohol_nombre}}</td>
                <td *ngIf="v_table[2].alcoholemia && row.alcohol_state != 4 && row.alcohol_state != 5" >{{row.alcohol_nombre}}</td>

                <td *ngIf="v_table[2].parametros" >{{row.parametros}}</td>
                <td *ngIf="v_table[2].cNivel" >{{row.cNivel}}</td>
                <td *ngIf="v_table[2].cRestante" >{{row.cRestante}}</td>
                <td *ngIf="v_table[2].cMotor" >{{row.cMotor}}</td>
                <td *ngIf="v_table[2].odometro" >{{row.odometro}}</td>
                <td *ngIf="v_table[2].onOff" >{{row.onOff}}</td>
                <td *ngIf="v_table[2].RxM" >{{row.RxM}}</td>
                <td *ngIf="v_table[2].recFacial" >{{row.recFacial}}</td>
                <td *ngIf="v_table[2].velCAN" [ngStyle]="{'background-color': row.velCAN > v_table[3][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velCAN}}</td>
                <td *ngIf="v_table[2].pCercano" >{{row.pCercano}}</td>
                <td *ngIf="v_table[2].referencia" >{{row.referencia}}</td>

                <td *ngIf="v_table[2].DUOT2state" >{{row.DUOT2state}}</td>
            </tr> -->

            <tr *ngFor="let row of v_table[1];  let i = index;">

              <td>{{i + 1}}</td>
              <td *ngIf="!chkDateHour">{{row.fecha}}</td>
              <td *ngIf="chkDateHour">{{row.fecha.split(' ')[0]}}</td>
              <td *ngIf="chkDateHour">{{row.fecha.split(' ')[1]}}</td>

              <td *ngIf="v_table[2].fServidor && !chkDateHour">{{row.fServidor}}</td>
              <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[0]}}</td>
              <td *ngIf="v_table[2].fServidor && chkDateHour" >{{row.fServidor.split(' ')[1]}}</td>

              <td *ngIf="v_table[2].pCercano" >{{row.pCercano}}</td>

              <td *ngIf="v_table[2].velCAN" [ngStyle]="{'background-color': row.velCAN > v_table[3][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velCAN}}</td>
              <td *ngIf="v_table[2].velGPS" [ngStyle]="{'background-color': row.velGPS > v_table[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velGPS}}</td>
              <td *ngIf="v_table[2].velGPS_speed" >{{row.velGPS_speed}}</td>
              <td *ngIf="v_table[2].velMobileye_ME460" >{{row.velMobileye_ME460}}</td>
              <td *ngIf="v_table[2].velECO" [ngStyle]="{'background-color': row.velECO > v_table[2][8] ? 'rgb(28, 243, 9)' : {} }">{{row.velECO}}</td>

              <td *ngIf="v_table[2].DUOT2state" >{{row.DUOT2state}}</td>
              <td *ngIf="v_table[2].RxM" >{{row.RxM}}</td>
              <td *ngIf="v_table[2].RPMAlta" >{{row.RPMAlta}}</td>

              <td *ngIf="v_table[2].cNivel" >{{row.cNivel}}</td>
              <td *ngIf="v_table[2].cRestante" >{{row.cRestante}}</td>

              <td *ngIf="v_table[2].cMotor" >{{row.cMotor}}</td>
              <td *ngIf="v_table[2].odometro" >{{row.odometro}}</td>

              <td *ngIf="v_table[2].altitud" >{{row.altitud}}</td>
              <td *ngIf="v_table[2].angulo" >{{row.angulo}}</td>

              <!-- NEW -->
              <td *ngIf="v_table[2].alimentGps" >{{row.alimentGps}}</td>
              <td *ngIf="v_table[2].nivelBateria" >{{row.nivelBateria}}</td>

              <td *ngIf="v_table[2].nivelCobertura" >{{row.nivelCobertura}}</td>
              <td *ngIf="v_table[2].temperaturaGps" >{{row.temperaturaGps}}</td>

              <td *ngIf="v_table[2].satelite" >{{row.satelite}}</td>
              <td *ngIf="v_table[2].recFacial" >{{row.recFacial}}</td>

              <td *ngIf="v_table[2].onOff" >{{row.onOff}}</td>
              <td *ngIf="v_table[2].alcoholemia && row.alcohol_state == 4" [ngStyle]="{'background-color': 'rgb(28, 243, 9)'}">{{row.alcohol_nombre}}</td>
              <td *ngIf="v_table[2].alcoholemia && row.alcohol_state == 5" [ngStyle]="{'background-color': 'rgb(255,  0, 0)'}">{{row.alcohol_nombre}}</td>
              <td *ngIf="v_table[2].alcoholemia && row.alcohol_state != 4 && row.alcohol_state != 5" >{{row.alcohol_nombre}}</td>

              <td *ngIf="v_table[2].parametros" >{{row.parametros}}</td>
              <td *ngIf="v_table[2].ubicacion">
                <a href="http://maps.google.com/maps?q={{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}&amp;t=m" target="_blank">{{row.lat.toFixed(6)}},{{row.lng.toFixed(6)}}</a>
              </td>
              <td *ngIf="v_table[2].referencia" >
                <!-- {{row.referencia}} -->
                <span *ngIf="row.referencia != 'desconocido ...'" [innerHTML]="row.referencia"></span>
                <button *ngIf="row.referencia == 'desconocido ...'" class="btn btn-success" (click)="showGeneralDireccion(row, v_table[1]);">Ver</button>
              </td>

              <td *ngIf="v_table[2].fatiga" [ngStyle]="{'background-color': row.fatiga == 'si' ? 'rgb(28, 243, 9)' : {} }">{{row.fatiga}}</td>
              <td *ngIf="v_table[2].fBrusca" >{{row.fBrusca}}</td>
              <td *ngIf="v_table[2].aBrusca" >{{row.aBrusca}}</td>
              <!-- <td *ngIf="v_table[2].referencia" >{{row.referencia}}</td> -->
            </tr>
          </tbody>

        </table>

        <!-- <ng-container *ngIf="v_table[1].length > 0" > -->
        <div *ngIf="v_table[1].length > 0" class="p-card text-left" style="padding: 1rem;">
            <h6>RESUMEN DE TOTALES</h6>
            <p>TOTAL de Excesos de Velocidad(GPS) : <span>{{v_table[3][0]}}</span></p>
            <p>TOTAL de Excesos de Velocidad(ECO) : <span>{{v_table[3][1]}}</span></p>
            <p>TOTAL de Excesos de Velocidad(CAN) : <span>{{v_table[3][2]}}</span></p>

            <p *ngIf="v_table[2].fatiga" >TOTAL de Fatigas : <span>{{v_table[3][3]}}</span></p>
            <!-- <p ng-if="dato[2].fExBrusca" >TOTAL de Frenada Extremadamente Brusca : <span>{{dato[3][4]}}</span></p> -->
            <p *ngIf="v_table[2].fBrusca" >TOTAL de Frenada Brusca : <span>{{v_table[3][5]}}</span></p>
            <p *ngIf="v_table[2].RPMAlta" >TOTAL RPM Alta : <span>{{v_table[3][6]}}</span></p>
            <p *ngIf="v_table[2].alcoholemia" >TOTAL de alerta de alcohol en la sangre : <span>{{v_table[3][7]}}</span></p>
        </div>
        <!-- </ng-container> -->

      </ng-container>

      <div *ngIf="v_table[1].length == 0" class="p-card text-left" style="padding: 1rem;">
        <p>Ningún dato encontrado en su consulta.</p>
      </div>


    </div>
  </div>
  <!-- </div> -->

  <ng-template pTemplate="footer" style="width: 60vw;">


      <!-- <ng-container> -->

        <div class="row">

          <div class="col">
          </div>

          
          <div *appHasPermission="['reports_excel_R036','reports_excel_R006']" class="col">
            <div class="col-md-auto">
              <!-- <button class="btn btn-success" (click)="showSelectExcel('exportExcelGeneral');">EXPORTAR EXCEL</button> -->
              <button class="btn btn-gl btn-success btn-dark-hover" (click)="showSelectExcel('exportExcelGeneral');">EXPORTAR EXCEL</button>
            </div>
          </div>

          <div class="col">
            <div class="col-md-auto">
              <!-- <button class="btn btn-success" (click)="exportPdfGeneral();">EXPORTAR PDF</button> -->
              <button class="btn btn-gl btn-gl-red btn-dark-hover" (click)="exportPdfGeneral();">EXPORTAR PDF</button>
            </div>
          </div>

          <div class="col">
            <div class="col-md-auto">
              <!-- <button class="btn btn-success" (click)="exportPdfGeneral();">EXPORTAR PDF</button> -->
              <button class="btn btn-gl btn-gl-red btn-dark-hover" style="background-color: #fd7e14;" (click)="exportHtmlGeneral();">EXPORTAR HTML</button>
            </div>
          </div>


          <div class="col">
          </div>

          <!-- <div class="col">
            <div class="col-md-auto">
              <p-button (click)="reportService.modalActive=false" label="Cerrar"></p-button>
            </div>
          </div> -->

        </div>

      <!-- </ng-container> -->


  </ng-template>





</p-dialog>
