<p-dialog [(visible)]="display" (onShow)="onShow()" [breakpoints]="{'1200px': '65vw', '960px': '75vw', '640px': '100vw'}" [style]="{width: '55vw'}" [modal]="true" [resizable]="false">
    <ng-template class="p-dialog-title" pTemplate="header">
      <div>
        <i class="fa fa-pencil me-2" aria-hidden="true"></i>
        <strong>Configuración de Unidad</strong>
      </div>
    </ng-template>

    <div class="dialog-body-container">

      <div class="p-fluid p-formgrid p-grid" style="font-size: 12px;">
        <div class="dialog-form-container gl-middleline" [ngStyle]="{'display':formDisplay}">

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="firstname">Nombre:</label>
                </div>
                <div class="col-7">
                  <input #name id="firstname" type="text" value="{{config.name}}"  pInputText class="p-inputtext-sm" placeholder="">
                </div>
              </label>
            </div>
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="lastname">Código Interno:</label>
                </div>
                <div class="col-7">
                  <input id="lastname" type="text" value="{{config.cod_interno}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="sim">Número SIM:</label>
                </div>
                <div class="col-7">
                  <input #sim id="sim" type="text" value="{{config.sim_number}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </label>
            </div>
            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="lastname">IMEI:</label>
                </div>
                <div class="col-7">
                  <input id="lastname" type="text" value="{{config.IMEI}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="empresa">Empresa:</label>
                </div>
                <div class="col-7">
                  <input #empresa id="empresa" type="text" value="{{config.empresa}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </label>
            </div>
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="placa">Placa:</label>
                </div>
                <div class="col-7">
                  <input #placa id="placa" type="text" value="{{config.plate_number}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                <!-- <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="cisterna_tolva">Cisterna / Tolva:</label>
                </div>
                <div class="col-7">
                  <input #tolva id="tolva" type="text" value="{{config.tolva}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div> -->
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="operacion">Operación:</label>
                </div>
                <div class="col-7">
                  <input #empresa id="operacion" type="text" value="{{config.nameoperation}}" pInputText class="p-inputtext-sm" placeholder="" disabled>

                  <!-- <span class="p-inputtext-sm">{{config.nameoperation}}</span> -->
                  <!-- <input id="lastname" type="text" value="{{config.grupo}}" pInputText class="p-inputtext-sm" placeholder="" disabled> -->
                </div>
              </label>
            </div>
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="conductor">Conductor:</label>
                </div>
                <div class="col-7">
                  <input id="conductor" type="text" value="{{config.namedriver}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="grupo">Grupo:</label>
                </div>
                <div class="col-7">
                  <input #empresa id="grupo" type="text" value="{{config.namegrupo}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                  <!-- <span class="p-inputtext-sm">{{config.namegrupo}}</span> -->
                </div>
              </div>
            </div>
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="tipo-unidad">Tipo de Unidad :</label>
                </div>
                <div class="col-7">
                  <input #empresa id="tipo-unidad" type="text" value="{{config.nametype_vehicle}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="convoy">Convoy:</label>
                </div>
                <div class="col-7">
                  <input #empresa id="convoy" type="text" value="{{config.nameconvoy}}" pInputText class="p-inputtext-sm" placeholder="" disabled>
                  <!-- <span class="p-inputtext-sm">{{config.nameconvoy}}</span> -->
                </div>
              </div>
            </div>
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="state">Icono:</label>
                </div>
                <div class="col-7">
                  <div style="width: 40px; height: 40px;">
                    <!-- <div  id="svgContainer" style="width: 40px; height: 40px;" #svgContainer> -->
                    <img [src]="iconUrl"  class="vehicle-icon flag flag-vehicle-edit" />
                  </div>
                </div>

              </div>
            </div>

          </div>

          <!-- <div class="row d-flex justify-content-center">
            <div class="col-sm-12 col-md-10 align-self-center p-field">
              <div class="row">
                <div class="col-3 d-flex align-items-center justify-content-end text-end fw-bold">
                  <label>Capacidad del Tanque:</label>
                </div>
                <div class="col-8">
                  <input id="cap-tanque" type="text" value="{{config.capacidad_tanque_text}}"  pInputText class="p-inputtext-sm" placeholder="" disabled>
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>

    </div>


    <p-footer>
      <div class="row d-flex justify-content-center">
        <div class="col-4">
          <button (click)="onClickCancel()" _ngcontent-cic-c118="" type="button" class="btn-gl-cancel btn-dark-hover btn-sm w-100" [disabled]="loading">Cerrar</button>
          <!-- <button (click)="onClickCancel()" _ngcontent-cic-c118="" type="button" class="btn-gl-cancel btn-dark-hover btn-sm w-100" [disabled]="loading">Cerrar</button> -->
        </div>
        <div class="col-4">
          <button (click)="confirm()" _ngcontent-cic-c118="" type="button" class="btn-gl-save btn-dark-hover btn-sm w-100" [disabled]="loading">Guardar</button>
        </div>
      </div>
      <div class="" *ngIf="loading">

      </div>
    </p-footer>
</p-dialog>
