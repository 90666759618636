<!-- <div *ngIf="value == true" class="">
  <span (click)="onClickEye()" class="btn-label" style="cursor: pointer;">
    <i class="fas fa-eye ag-grid-header-icon"></i>
  </span>
</div>
<div *ngIf="value == false" class="">
  <span (click)="onClickEye()" class="btn-label" style="cursor: pointer;">
    <i class="fa fa-eye-slash ag-grid-header-icon"></i>
  </span>
</div> -->
<p-inputSwitch [(ngModel)]="value.state" (onChange)="onClickEye()" ngDefaultControl></p-inputSwitch>
