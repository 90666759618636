import { Injectable } from '@angular/core';
import moment, { DurationInputArg2 } from 'moment-timezone';
import { UserDataService } from 'src/app/profile-config/services/user-data.service';
@Injectable({
  providedIn: 'root'
})
export class MomentService {
  formatDefault:string = 'YYYY/MM/DD HH:mm:ss';

  tzDefault:string = 'America/Lima';
  tzCustom: string = '- 5 hour';
  tzOffset: string = '-05:00';
  tzPHP: string    = ' +5 hours';
  constructor(
    public userDataService:UserDataService,
  ) {
    this.userDataService.userDataCompleted.subscribe(() => {
      this.setTimeZoneDefault();
    });
  }


  get(toFormat?:string) {
    if(toFormat){
      return this.setFormat(moment(new Date()),toFormat);
    }
    return moment(new Date());
  }
  getDate(toFormat?:string) {
    if(toFormat){
      return this.setFormat(moment(new Date()),toFormat);
    }
    return moment(new Date());
  }


  getUTCWithOptions(value?:number,type?:DurationInputArg2,toFormat?:string){
    const parsedDate = moment().utc();
    if (value !== undefined && type) {
      if (value < 0) {
        parsedDate.subtract(Math.abs(value), type);
      } else if (value > 0) {
        parsedDate.add(value, type);
      }
    }
    if(toFormat){
      return this.setFormat(parsedDate,toFormat);
    }
    return parsedDate;
  }
  getDateUTCWithOptions(value?:number,type?:DurationInputArg2,toFormat?:string){
    const parsedDate = moment(new Date()).utc();
    if (value !== undefined && type) {
      if (value < 0) {
        parsedDate.subtract(Math.abs(value), type);
      } else if (value > 0) {
        parsedDate.add(value, type);
      }
    }
    if(toFormat){
      return this.setFormat(parsedDate,toFormat);
    }
    return parsedDate;
  }
  // Convierte una hora UTC a local y agrega o quita | seconds/minutes/hours
  toLocalWithOptions(date:string,value?:number,type?:DurationInputArg2,toFormat?:boolean,){
    const parsedDate = moment.utc(date,this.formatDefault).local();
    if (value !== undefined && type) {
      if (value < 0) {
        parsedDate.subtract(Math.abs(value), type);
      } else if (value > 0) {
        parsedDate.add(value, type);
      }
    }
    if(toFormat){
      return this.setFormat(parsedDate);
    }
    return parsedDate;
  }

  // Convierte una hora a UTC y  agrega o quita | seconds/minutes/hours
  toUTCWithOptions(date:string,value?:number,type?:DurationInputArg2,toFormat?:boolean,){
    const parsedDate =moment(date,this.formatDefault).utc();
    if (value !== undefined && type) {
      if (value < 0) {
        parsedDate.subtract(Math.abs(value), type);
      } else if (value > 0) {
        parsedDate.add(value, type);
      }
    }
    if(toFormat){
      return this.setFormat(parsedDate);
    }
    return parsedDate;
  }

  // Convierte una hora enviada local a la zona horaria de Perú
  toPeru(dateTime: string,toFormat?:boolean) {
    const parsedDate = moment(dateTime,this.formatDefault).local();
    if(toFormat){
      return this.setFormat(moment.tz(parsedDate, 'America/Lima'))
    }
    return moment.tz(parsedDate, 'America/Lima');
  }
  // Convierte una hora enviada local a la hora UTC
  toUTC(dateTime: string,toFormat?:boolean) {
    const localMoment = moment(dateTime,this.formatDefault);
    if(toFormat){
      return this.setFormat(localMoment.utc());
    }
    return localMoment.utc();
  }
  // Convierte una hora UTC a la hora local del sistema
  toLocal(utcDateTime: string, toFormat?: boolean) {
    const utcMoment = moment.utc(utcDateTime,this.formatDefault);
    if (toFormat) {
      return this.setFormat(utcMoment.local());
    } else {
      return utcMoment.local();
    }
  }

  setFormat(date:moment.Moment,newFormat?:string): string {
    if(newFormat){
      return date.format(newFormat);
    }
    return date.format(this.formatDefault);
  }

  toFormat(date:string,currentFormat:string,newFormat:string):string{
    return moment(date,currentFormat).format(newFormat);
  }

  toMoment(date:string,toFormat?:boolean){
    const parsedDate = moment(date,this.formatDefault);
    if(toFormat){
      return this.setFormat(parsedDate)
    }
    return parsedDate;
  }

  getUTCOffset(date:string): number {
    return moment(date,this.formatDefault).utcOffset() / 60;
  }

  // Convierte una fecha a moment y agrega o quita | seconds/minutes/hours
  setWithOptions(date:string,value?:number,type?:DurationInputArg2,toFormat?:boolean,){
    const parsedDate = moment(date,this.formatDefault).local();
    if (value !== undefined && type) {
      if (value < 0) {
        parsedDate.subtract(Math.abs(value), type);
      } else if (value > 0) {
        parsedDate.add(value, type);
      }
    }
    if(toFormat){
      return this.setFormat(parsedDate);
    }
    return parsedDate;
  }

  // Convierte una hora de una ZonaHoraria a la hora local del sistema
  toLocalByTimeZone(dateTime: string,timeZone:string,toFormat?:boolean) {
    const parsedDate = moment.tz(dateTime,this.formatDefault,timeZone);
    if(toFormat){
      return this.setFormat(parsedDate.local());
    }else{
      return parsedDate.local()
    }
  }

  // Devuelve diferencia de horas para las consultas  - Default: '- 5 hour'
  customUtcDatabase(){
    const utcOffset = this.getUTCOffset(this.get('YYYY/MM/DD HH:mm:ss') as string);
    const formattedOffset = `${utcOffset < 0 ? '-' : '+'} ${Math.abs(utcOffset)} hour`;
    return formattedOffset;
  }
  // Devuelve diferencia de horas para el backend - Default: ' +5 hours'
  customUtcPhp(){
    const utcOffset = this.getUTCOffset(this.get('YYYY/MM/DD HH:mm:ss') as string);
    const formattedOffset = `${utcOffset < 0 ? '+' : '-'}${Math.abs(utcOffset)} hours`;
    return formattedOffset;
  }

  setTimeZoneDefault(){
    const timezone = moment.tz.guess(); // Devuelve la zona horaria del sistema
    const fecha = moment().tz(timezone);
    const offset = fecha.format('Z');

    const offSetDatabase = this.customUtcDatabase();
    const offSetPHP = this.customUtcPhp();

    this.tzDefault= timezone;
    this.tzOffset= offset;
    this.tzCustom= offSetDatabase;
    this.tzPHP= offSetPHP;

    console.log(`MomentService tzDefault : ${timezone}`);
    console.log(`MomentService tzOffset  : ${offset}`);
    console.log(`MomentService tzCustom  : ${offSetDatabase}`);
    console.log(`MomentService tzPHP     : ${offSetPHP}`);
  }

}

