import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { RepProgramadosService } from '../../services/rep-programados.service';
import { NgxSpinnerService } from 'ngx-spinner';



import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rp-list',
  templateUrl: './rp-list.component.html',
  styleUrls: ['./rp-list.component.scss']
})
export class RpListComponent implements OnInit {

  constructor(
    public repProgramadosService: RepProgramadosService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,

  ) {}

  ventana_visible = false;
  form: any;

  reports: any=[];
  reportesProgramados: any=[];
  fullScreenSpinnerMsg:any = '';

  ngOnInit(): void {
    // this.dateInit = new Date(moment(hoy).format("MM/DD/YYYY"));
    // // this.dateEnd = new Date(moment(hoy).format("MM/DD/YYYY"));
    // this.dateEnd = this.dateInit;
    this.ventana_visible = true;
    console.log(" =====> INNITIT ");
    // console.log(this.reportService.paramsReportesProgramados);

    
    const hoy = Date.now();
    this.form = {
        nombre : '',
        hora_programado : {label:'00:00', value:0},
        hora_programado2 : new Date(moment(hoy).format("MM/DD/YYYY")),
        notificacion_email:true,
        bol_activo:true,
        notificacion_email_lista:''

    };


    this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
      next: data => {


        this.http.post(environment.apiUrl + '/api/getReports', {}).subscribe({
          next: data2 => {
            console.log("reportProgranados", data);
            console.log("ListaReportes", data2);

            this.reports = data2;
            this.repProgramadosService.listReportes = this.reports;

            this.reportesProgramados = data;
            this.repProgramadosService.reportesProgramados = this.reportesProgramados;


            for (let i = 0; i < this.reportesProgramados.length; i++) {
              const element = this.reportesProgramados[i];
              element.unidades = JSON.parse(element.p_vehiculos);
              element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
              element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
              element.nombre_reporte  = element.p_numRep;
              for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                const reporte = this.repProgramadosService.listReportes[j];
                if (element.p_numRep == reporte.codigo ) {
                  element.nombre_reporte = reporte.value;
                }
              }
            }

          },
          error: () => {
            console.log('Hubo un error al procesar la solicitud');
          }
        });
    
      },
      error: () => {
        console.log('Fallo al obtener reportesProgramados');
        // this.errorFlag++;
        // this.endInit();
      }
    });


  }

  agregarReporteProgramado0() {
    console.log('=======> agregarReporteProgramado0 ');
    this.repProgramadosService.modal_add = true;
    this.repProgramadosService.modal_add0 = true;
    // this.repProgramadosService.modal_add = true;
    // this.titulo_modal_add = "Agregar Nuevo Reporte Programado";
    // this.reportService.modalReportesProgramadosActiveAddEdit = true;
  }


    editReporteProgramado(rp:any) {
      console.log('=======> editReporteProgramado ');
      this.repProgramadosService.modal_add = false;
      this.repProgramadosService.titulo_modal_add_edit = "Editar Reporte Programado";
      this.repProgramadosService.modalReportesProgramadosActiveAddEdit = true;
      this.repProgramadosService.reporteProgramado = rp;
    }

    
    verDetalleReporteProgramado(rp:any){
      console.log("==== verDetalleReporteProgramado ====");
      console.log(rp);
      this.repProgramadosService.modalReportesProgramadosActiveDetalle = true;
      this.repProgramadosService.reporteProgramado = rp;
    }

    EliminarReporteProgramado(rp:any){
      console.log("==== EliminarReporteProgramado ====");
      console.log(rp);

      Swal.fire({
        // title: 'Título de la Alerta',
        text: '¿Desea Eliminiar el Reporte Programado : '+rp.nombre_programacion+' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        confirmButtonColor: '#30a9d9',
        cancelButtonText: 'No',
        cancelButtonColor: '#e3e3e3',
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log('Sí');
          this.fullScreenSpinnerMsg = 'Eliminando Reporte Programado...';
          this.spinner.show("reloadSpinner");
  
          // this.fullScreenSpinnerMsg = "Eliminando Reporte Programado...";
          // this.spinner.show("reloadSpinner");
          
          this.http.post(environment.apiUrl + '/api/deleteReporteProgramado', rp).subscribe({
            next: data => {
              //console.log(this.selectedConvoy.length);
              console.log(typeof data);
              console.log(data);
              //this.spinner.hide("reloadSpinner");
              // this.reportService.modalReportesProgramadosActive = false;
              this.fullScreenSpinnerMsg = "Actualizando ...";
              //=============================================================================================================
              this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
                next: data => {
                    console.log("reportProgranados", data);
                    this.reportesProgramados = data;
                    for (let i = 0; i < this.reportesProgramados.length; i++) {
                      const element = this.reportesProgramados[i];
                      element.unidades = JSON.parse(element.p_vehiculos);
                      element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                      element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                      for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                        const reporte = this.repProgramadosService.listReportes[j];
                        if (element.p_numRep == reporte.codigo ) {
                          element.nombre_reporte = reporte.value;
                        }
                      }
                    }
                    this.spinner.hide("reloadSpinner");
                },
                error: () => {
                  console.log('Fallo al obtener reportesProgramados');
                }
              });
              //=============================================================================================================
        
            },
          });


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('No');
          // this.spinner.show("reportSpinner");
 
        } else {
          //CUANDO ES ESC O X
          console.log('Cancel');
        }
      });




    }

}
