import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-panel-alerts-platform',
  templateUrl: './panel-alerts-platform.component.html',
  styleUrls: ['./panel-alerts-platform.component.scss']
})
export class PanelAlertsPlatformComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
