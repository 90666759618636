import { Injectable,EventEmitter,Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Operator } from '../models/interfaces';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  private URL_NAME = environment.apiUrl + '/api/operators';
  @Output() dataComplete = new EventEmitter<any>();

  public operators: Operator[] = [];
  public listOperators = [
    {
      label: 'Operadores',
      items: [
        {
          label: 'ATENCIO ALFARO, Claudia Isabel',
          value: 'ATENCIO ALFARO, Claudia Isabel',
        },
        {
          label: 'FERNANDEZ CRUZ, Rosellia Yanina',
          value: 'FERNANDEZ CRUZ, Rosellia Yanina',
        },
        {
          label: 'HAÑARI SALCEDO, Katherine Liliana',
          value: 'HAÑARI SALCEDO, Katherine Liliana',
        },
        { label: 'MAMANI SIERRA, Raquel', value: 'MAMANI SIERRA, Raquel' },
        {
          label: 'NUÑEZ CHAMBI, Michelle Solzire',
          value: 'NUÑEZ CHAMBI, Michelle Solzire',
        },
        {
          label: 'NUÑONCA ALARCON, Daniela Stefany',
          value: 'NUÑONCA ALARCON, Daniela Stefany',
        },
        {
          label: 'PACCI QUISPE, Betzy Belquis',
          value: 'PACCI QUISPE, Betzy Belquis',
        },
        {
          label: 'PACHECO MOLLO, Cesar Vidal',
          value: 'PACHECO MOLLO, Cesar Vidal',
        },
        {
          label: 'ROJAS RONDON, Carla Beatriz',
          value: 'ROJAS RONDON, Carla Beatriz',
        },
        {
          label: 'SUARES PACURI, Ayelen Melani',
          value: 'SUARES PACURI, Ayelen Melani',
        },
      ],
    },
  ];
  public stateOperators: boolean = false;
  constructor(
    private http: HttpClient,
  ) {}

  async initialize() {
    this.getOperators();
  }
  public getOperators (){
    this.http.get<any>(this.URL_NAME).toPromise()
    .then(async response =>{
      console.log('getOperadores:',response);
      this.operators = response;
      this.listOperators = await this.generateOperatorsItems(response);
      this.stateOperators = true;
      this.dataComplete.emit(this.operators);
    })
    .catch(error =>{
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: `Ocurrió un error al obtener la lista de operadores, comunicarse con el área de TI.`,
        icon: 'error',
        allowOutsideClick: false,
      });
    });

  }
  public async generateOperatorsItems(listItems: Operator[]): Promise<any> {
    this.listOperators = [];
    var auxOperators: { label: string; items: Operator[] }[] = [
      {
        label: 'Operadores',
        items: [],
      },
    ];
    listItems.forEach((item: Operator) => {
      const newItem: Operator = { label: item.label, value: item.label };
      auxOperators[0].items.push(newItem);
    });
    return auxOperators;
  }

}
