import { EventEmitter, Injectable, Output } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import html2canvas from 'html2canvas';
import { from, Observable, of, Subject, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  timeout,
} from 'rxjs/operators';
import {
  CipiaMultimediaParam,
  IntervalTime,
  IntervalType,
  MultimediaItem,
  SourceCipiaMultimedia,
  TypeCipiaMultimedia,
  VideoOnDemandTime,
} from '../models/interfaces';
import { ResponseInterface } from 'src/app/core/interfaces/response-interface';
import { WebSocketDataService } from 'src/app/vehicles/services/webSocketData.service';
import Swal from 'sweetalert2';
import moment, { duration } from 'moment';
import { ToastService } from 'src/app/shared/services/toast.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';
import { CustomToastMessagessService } from 'src/app/map/services/custom-toast-messagess.service';
import { error } from 'console';
import { Evaluation, Alert } from 'src/app/alerts/models/alert.interface';
import { event } from 'jquery';
import { WaitTimeService } from 'src/app/events/services/wait-time.service';
import { EventService } from 'src/app/events/services/event.service';
import { HistorialService } from 'src/app/historial/services/historial.service';
import { MomentService } from 'src/app/vehicles/services/moment.service';
interface IMultimedias {
  [key: string]: MultimediaItem[];
}

const EVENTS_MULTIMEDIA_KEY = 'multimedia_items';

@Injectable({
  providedIn: 'root',
})
export class MultimediaService {
  constructor(
    private http: HttpClient,
    private webSocketDataService: WebSocketDataService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private indexedDBService: IndexedDbService,
    private sanitizer: DomSanitizer,
    private customToastMessagess: CustomToastMessagessService,
    private waitTimeService: WaitTimeService,
    private eventService: EventService,
    private historialService: HistorialService,
    private momentService: MomentService,
  ) {
    if (indexedDBService.isReady) {
      this.loadMultimediaCipiaItemsFromLocalStorage();
    } else {
      this.indexedDBService.completed.subscribe(() => {
        this.loadMultimediaCipiaItemsFromLocalStorage();
      });
    }
  }

  private mediaStream: any;
  private canvasStream: any;
  private audioStream: any;
  private recorder: any;
  private _mediaStream = new Subject<any>();
  private _blob = new Subject<any>();
  private _stateChange = new Subject<any>();
  //@Output() onStop: EventEmitter<boolean> = new EventEmitter<boolean>();

  public blob: any;
  private canvas: HTMLCanvasElement | null = null;
  private canvas2d: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;

  private captureInterval: any;
  public isRecording = false;

  public multimediaCipiaItems: IMultimedias = {};
  public onDemandLoader = false;

  public isLoadedMultimediaCipia = false;
  public completedMultimediaCipia: EventEmitter<any> = new EventEmitter<any>();
  private destroy$ = new Subject<void>();

  getMediaStream() {
    return this._mediaStream.asObservable();
  }
  getBlob() {
    return this._blob.asObservable();
  }

  getStateChanges() {
    return this._stateChange.asObservable();
  }

  async startRecordingWindow() {
    await this.handleRecording();
  }

  async startRecording(element: HTMLElement) {
    try {
      this.canvas2d = document.createElement('canvas')!; // OUR OWN invisible CANVAS
      this.ctx = this.canvas2d.getContext('2d')!;
      this.canvas2d.width = element.clientWidth;
      this.canvas2d.height = element.clientHeight;
      //this.canvas2d.className = "d-none";
      (document.body || document.documentElement).appendChild(this.canvas2d);

      this.captureInterval = setInterval(async () => {
        if (
          this.recorder &&
          this.recorder.getBlob() &&
          this.recorder.getBlob().size
        ) {
          // this line checks whether recorder is stopped
          return;
        }
        // looper keeps calling this method until recording stops
        const canvasresult = await html2canvas(element, {
          useCORS: true,
          allowTaint: true,
        });
        this.ctx!.clearRect(0, 0, this.canvas2d!.width, this.canvas2d!.height);

        // draw html2canvas resulting canvas on our own canvas
        this.ctx!.drawImage(
          canvasresult,
          0,
          0,
          this.canvas2d!.width,
          this.canvas2d!.height
        );
        // Dibuja la marca de tiempo en el canvas
        const currentTime = new Date().toLocaleString();
        this.ctx!.fillStyle = 'black';
        this.ctx!.shadowColor = 'white';
        this.ctx!.shadowBlur = 10;
        this.ctx!.font = '24px Arial';
        const x = 20; // Centrado horizontal
        const y = this.canvas2d!.height - 20; // Parte inferior
        this.ctx!.fillText(currentTime, x, y);
      }, 1000);
      //requestAnimationFrame(() => {});

      // Obtener el audioStream
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      console.log('Obtener el audioStream', element);
      //const canvas = await html2canvas(element)
      console.log('html2canvas.default(element);');

      // Capturar el stream del canvas
      // @ts-ignore
      this.canvasStream = this.canvas2d.captureStream();
      console.log('Capturar el stream del canvas');

      // Crear una nueva MediaStream para combinar las pistas de audio y video
      this.mediaStream = new MediaStream();
      console.log(
        'Crear una nueva MediaStream para combinar las pistas de audio y video'
      );

      // Obtener y agregar las pistas de audio a finalStream
      const audioTracks = this.audioStream.getAudioTracks();
      audioTracks.forEach((track: any) => {
        this.mediaStream.addTrack(track);
        console.log('add audiotrack en el stream del canvas');
      });

      // Obtener y agregar las pistas de video a finalStream
      const videoTracks = this.canvasStream.getVideoTracks();
      videoTracks.forEach((track: any) => {
        this.mediaStream.addTrack(track);
        console.log('add videorack en el stream del canvas');
      });

      // Iniciar la grabación con RecordRTC
      this._mediaStream.next(this.mediaStream);
      console.log('enviar por observable');

      this.recorder = new RecordRTC(this.mediaStream, {
        type: 'video',
      });
      console.log('instanciar recorder');

      await this.recorder.startRecording();
      this.isRecording = true;
      console.log('iniciar grabacion recorder');
    } catch (error) {
      this.isRecording = false;
      console.error('Error al iniciar la grabación:', error);
    }
  }

  async handleRecording() {
    try {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      // @ts-ignore
      this.mediaStream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true,
      });
      this.mediaStream = new MediaStream([
        ...this.mediaStream.getTracks(),
        ...this.audioStream.getTracks(),
      ]);
      this._mediaStream.next(this.mediaStream);
      this.recorder = new RecordRTC(this.mediaStream, { type: 'video' });
      this.recorder.onStateChanged = function (state: any) {
        console.log('STATEEE: ', state);
        this._stateChange.next(state);
      };
      await this.recorder.startRecording();
      this.isRecording = true;
    } catch (error) {
      this.isRecording = false;
    }
  }

  async stopRecording() {
    if (this.captureInterval) {
      clearInterval(this.captureInterval);
    }
    console.log('STARTING STOP RECORDING-------1');
    console.log(this.recorder);

    if (!this.recorder) {
      console.log('STARTING STOP RECORDING-------2');
      return;
    }
    console.log('STARTING STOP RECORDING-------3');

    await this.recorder.stopRecording(() => {
      this.isRecording = false;
      this.blob = this.recorder.getBlob();
      this._blob.next(URL.createObjectURL(this.blob));
      if (this.canvas2d) {
        this.canvas2d!.remove();
      }
      this.mediaStream.getTracks().forEach((track: any) => track.stop());
      console.log('mediastream.stop');
      this.audioStream.getTracks().forEach((track: any) => track.stop());
      console.log('audiotream.stop');
      if (this.canvasStream) {
        this.canvasStream.getTracks().forEach((track: any) => track.stop());
        console.log('canvasstream.stop');
      }
      this.recorder.destroy();
    });
  }

  downloadRecording() {
    RecordRTC.invokeSaveAsDialog(this.blob, `${Date.now()}.webm`);
  }

  clearRecording() {
    this.blob = null;
    this.canvas!.remove();
    this.recorder = null;
    this.mediaStream = null;
    this.audioStream = null;
    this.canvasStream = null;
    this.isRecording = false;
  }

  async screenShot(element: HTMLElement) {
    this.canvas2d = document.createElement('canvas')!; // OUR OWN invisible CANVAS
    this.ctx = this.canvas2d.getContext('2d')!;
    this.canvas2d.width = element.clientWidth;
    this.canvas2d.height = element.clientHeight;
    this.canvas2d.className = 'd-none';
    (document.body || document.documentElement).appendChild(this.canvas2d);

    const img = await html2canvas(element, {
      removeContainer: false,
      useCORS: true,
      allowTaint: true,
    });
    // Toma la captura del canvas como una imagen PNG

    this.ctx!.clearRect(0, 0, this.canvas2d!.width, this.canvas2d!.height);

    // draw html2canvas resulting canvas on our own canvas
    this.ctx!.drawImage(img, 0, 0, this.canvas2d!.width, this.canvas2d!.height);

    // Dibuja la marca de tiempo en el canvas
    const currentTime = new Date().toLocaleString();
    this.ctx.fillStyle = 'black';
    this.ctx.shadowColor = 'white';
    this.ctx.shadowBlur = 10;
    this.ctx.font = '24px Arial';
    const x = 20; // Centrado horizontal
    const y = this.canvas2d.height - 20; // Parte inferior
    this.ctx.fillText(currentTime, x, y);
    // Crea un elemento de enlace
    const dataURL = this.canvas2d.toDataURL('image/png');
    const a = document.createElement('a');
    a.href = dataURL;
    a.download = `${Date.now()}.png`; // Nombre del archivo a descargar
    // Simula un clic en el enlace para iniciar la descarga
    a.click();
    this.canvas2d!.remove();
  }

  getMediaFromEvent(
    imei: string,
    eventId: string,
    type: string = 'video',
    source: string = 'CABIN',
    index: number = 0,
    time_before_call = 0,
    maxRetries = 2,
    retryInterval = 5000,
    uuid: string,
    event?: {
      event: Alert;
      element?: HTMLElement;
      multimediaParams: CipiaMultimediaParam;
    }
  ): Observable<any> {
    return timer(time_before_call * 500).pipe(
      mergeMap(() => {
        return this.tryGetMedia(
          imei,
          eventId,
          type,
          source,
          index,
          maxRetries,
          retryInterval,
          uuid
        );
      }),
      catchError((e) => {
        const errorMap: Record<number, string> = {
          401: 'No se cuenta con la autorización requerida.',
          403: 'El dispositivo CIPIA no se encuentra registrado en el servidor.',
          404: 'No se ha encontrado información disponible para la fecha solicitada en el servidor. Inténtelo mas tarde. Si el problema persiste, póngase en contacto con el área de TI.',
          408: 'El dispositivo CIPIA de la unidad no se encuentra disponible. Por favor, inténtelo de nuevo más tarde.',
          422: 'No se ha encontrado información disponible para la fecha solicitada en el equipo CIPIA. Favor de considerar una fecha diferente.',
          500: 'No se pudo conectar al servidor del dispositivo CIPIA. Si el problema persiste, póngase en contacto con el área de TI.',
        };

        console.log('ERRORRRSS: ', e);
        console.log('Moment EventError : ',event);

        // this.customToastMessagess.addCustomToast({
        //   id: `${eventId}`,
        //   severity: 'error',
        //   summary: `SOLICITUD NO PROCESADA`,
        //   detail:
        //     errorMap[e.status] ??
        //     'El archivo multimedia aún no fue enviado por el dispositivo CIPIA. Verifique que la unidad se encuentre disponible e inténtelo nuevamente.',
        //   data: {
        //     unit: `Unidad: ${event?.event.nombre_objeto}`,
        //     datetime: `Fecha: ${event?.event.fecha_tracker}`,
        //     moment: {
        //       // periodo: `Periodo: ${moment(event?.multimediaParams.from ?? event?.event.fecha_tracker,'YYYY/MM/DD HH:mm:ss')
        //       //   .subtract(0, 'hours')
        //       //   .format('YYYY/MM/DD')}
        //       //   (${moment(event?.multimediaParams.from ?? event?.event.fecha_tracker,'YYYY/MM/DD HH:mm:ss')
        //       //   .subtract(0, 'hours')
        //       //   .format('HH:mm:ss')} - ${moment(event?.multimediaParams.from ?? event?.event.fecha_tracker,'YYYY/MM/DD HH:mm:ss')
        //       //   .add(event?.multimediaParams.type == 'video'? event?.multimediaParams.seconds ?? 10: 0,'seconds')
        //       //   .subtract(0, 'hours')
        //       //   .format('HH:mm:ss')})`,
        //       periodo: `Periodo:
        //       ${this.momentService.toFormat(event?.multimediaParams.from ?? event?.event.fecha_tracker!,'YYYY/MM-DD HH:mm:ss','YYYY/MM/DD')} (
        //       ${this.momentService.toFormat(event?.multimediaParams.from ?? event?.event.fecha_tracker!,'YYYY/MM-DD HH:mm:ss','HH:mm:ss')} -
        //       ${this.momentService.toFormat(this.momentService.setWithOptions(event?.multimediaParams.from ?? event?.event.fecha_tracker!
        //         ,event?.multimediaParams.type == 'video'? event?.multimediaParams.seconds ?? 10 : 0,'seconds',true) as string,'YYYY/MM-DD HH:mm:ss','HH:mm:ss')})`,
        //       error:
        //         errorMap[e.status] ??
        //         'El archivo multimedia aún no fue enviado por el dispositivo CIPIA. Verifique que la unidad se encuentre disponible e inténtelo nuevamente.',
        //     },
        //     dataEvent: event?.event,
        //     dataHTML: null!,
        //   },
        //   sticky: false,
        //   closable: false,
        // });
        return throwError(e);
      })
    );
  }

  private tryGetMedia(
    imei: string,
    eventId: string,
    type: string,
    source: string,
    index: number,
    maxRetries: number,
    retryInterval: number,
    uuid: string
  ): Observable<any> {
    const endpoint =
      environment.apiUrl +
      '/api/media/' +
      type +
      '/' +
      imei +
      '/' +
      eventId +
      '/' +
      source +
      '/' +
      parseInt(index.toString());
    return this.http.get(endpoint, { responseType: 'blob' }).pipe(
      map(
        async (blob) =>
          await this.saveBlobToIndexedDb(blob, blob.type, {
            imei: imei,
            eventId: eventId,
            type: type as TypeCipiaMultimedia,
            from: uuid, // deberia ser date in UTC0 "2023-10-09 19:12:00" pero se esta usando para almacenar el uuid
            seconds: retryInterval,
            source: source as SourceCipiaMultimedia,
          })
      ),
      catchError((e) => {
        if (e.status != 404) {
          return throwError(e);
        }
        if (maxRetries > 0) {
          maxRetries -= 1;
          // Reintentar después de un intervalo de tiempo
          return timer(retryInterval).pipe(
            mergeMap(() =>
              this.tryGetMedia(
                imei,
                eventId,
                type,
                source,
                index,
                maxRetries,
                retryInterval,
                uuid
              )
            )
          );
        } else {
          return throwError(e);
        }
      })
    );
  }

  async saveBlobToIndexedDb(
    blob: Blob,
    type: string,
    multimediaItemWaitTime: CipiaMultimediaParam
  ): Promise<any> {
    console.log('CALL saveBlobToIndexedDb', blob);
    console.log('CALL TYPE BLOB', type);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const arrayBuffer = event.target.result;
        try {
          const id = await this.indexedDBService
            .saveBlob(arrayBuffer, type)
            .toPromise();
          const blobUrl = URL.createObjectURL(blob);
          this.waitTimeService.stopTimeOfCipiaByIds(
            multimediaItemWaitTime.from!,
            multimediaItemWaitTime
          );
          console.log('SAVED IN INDEXEDDB: ', id, blobUrl);
          resolve({
            id: id,
            url: blobUrl,
          });
          await new Promise((resolveTimeout) => {
            setTimeout(() => {
              this.indexedDBService
                .deleteBlob(id)
                .toPromise()
                .then(resolveTimeout);
            }, 1000 * 60 * 10);
          });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  retrieveVideoFrom(mediaRequest: CipiaMultimediaParam): Observable<any> {
    console.log('mediaService: mediaRequest init', mediaRequest);
    mediaRequest.from = this.momentService.toUTC(mediaRequest.from!,true) as string;
    console.log('Media in UTC : ',mediaRequest.from);
    return new Observable((observer) => {
      if (mediaRequest.seconds! > 60 || mediaRequest.seconds! < 10) {
        // Swal.fire('Error', 'La duración del video debe estar entre 10 y 60 segundos.', 'error');
        observer.error(
          'La duración del video no está dentro del rango requerido (10-60 segundos). Por favor, vuelve a intentarlo.'
        );
        observer.complete();
        return;
      }
      const endpoint = environment.apiUrl + '/api/media/retrieve';

      const frameSubscriptionSocket = this.webSocketDataService.callback.pipe(
        filter((frame) => {
          console.log('frame', frame);
          return (
            mediaRequest.imei === frame.IMEI.toString() &&
            frame.Parametros.includes('ExternalEvent')
          );
        }),
        mergeMap((frame) => {
          console.log('frame', frame);
          const objParams: any = {};
          frame.Parametros.split('|').forEach((item: string) => {
            const [key, value] = item.split('=');
            objParams[key] = value;
          });
          frame.Parametros = objParams;
          return from([frame]);
          //return this.getMediaFromEvent(mediaRequest.imei, frame.Parametros.eventId, "video", mediaRequest.source, 0, mediaRequest.seconds!<31?15:30);
        }),
        take(1), // Solo toma la primera URL válida y completa la suscripción
        timeout(mediaRequest.seconds! * 2000)
      );
      // Aplicar timeout al Observable
      const frameSubscription = frameSubscriptionSocket.subscribe(
        (frame) => {
          observer.next(frame);
          observer.complete();
        },
        (error) => {
          if (error.name === 'TimeoutError') {
            console.error('Tiempo de espera agotado.');
            // Swal.fire('Error', 'Tiempo de espera agotado.', 'error');
            observer.error(
              'Se supero el tiempo de espera para la obtención del video, verifique que la unidad se encuentra encendida e inténtelo de nuevo.'
            );
          } else {
            console.error('Error en obtener la trama:', error);
            observer.error(
              'Ocurrió un error al obtener el video. Por favor, vuelva a intentarlo más tarde.'
            );
          }
          // observer.next('');
          // observer.complete();
        }
      );

      this.http.post<ResponseInterface>(endpoint, mediaRequest).subscribe(
        (resp) => {
          console.log('respuesta de retrieve: ', resp, mediaRequest);
          if (!resp.success) {
            // Swal.fire('Error', resp.message, 'error');
            observer.error(resp);
            observer.complete();
            frameSubscription.unsubscribe();
          }

        },
        (error) => {
          const errorCod: Record<number, string> = {
            401: 'Tu sesión no tiene los permisos necesarios. Verifica tus credenciales e inténtalo nuevamente.',
            403: 'El dispositivo no se encuentra registrado en el servidor.',
            404: 'No se ha encontrado información disponible para la fecha solicitada en el equipo CIPIA. Favor de considerar una fecha diferente.',
            408: 'El dispositivo CIPIA de la unidad aún no se encuentra disponible. Por favor, inténtelo de nuevo más tarde',
            422: 'No se ha encontrado información disponible para la fecha solicitada en el equipo CIPIA. Favor de considerar una fecha diferente.',
            500: 'No se pudo conectar al servidor del dispositivo CIPIA. Si el problema persiste, póngase en contacto con el área de TI.',
          };
          observer.error(
            errorCod[error.status] ??
              'Ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde.'
          );
          observer.complete();
          // Swal.fire('Error', error.error.messages, 'error');
          console.error(
            'Error al llamar al endpoint /api/media/retrieve:',
            error,
            mediaRequest,
            endpoint
          );
          frameSubscription.unsubscribe();
        }
      );

      // DEVOLVER A LA HORA CORRECTA DEL SISTEMA
      mediaRequest.from = this.momentService.toLocal(mediaRequest.from!,true) as string;
      console.log('Media in Retorno : ',mediaRequest.from);

    });
  }

  recordVideo(mediaRequest: CipiaMultimediaParam): Observable<any> {
    return new Observable((observer) => {
      if (mediaRequest.seconds! > 60 || mediaRequest.seconds! < 10) {
        // Swal.fire('Error', 'La duración del video debe estar entre 10 y 60 segundos.', 'error');
        observer.error(
          'La duración del video no está dentro del rango requerido (10-60 segundos). Por favor, vuelve a intentarlo.'
        );
        observer.complete();
      }
      // Only for video
      const endpoint = environment.apiUrl + '/api/media/record';
      // Si la solicitud fue exitosa, escucharemos las tramas que llegan esperando la que tenga el video/imagen solicitado
      const frameSubscriptionSocket = this.webSocketDataService.callback.pipe(
        filter((frame) => {
          //console.log("frame",frame);
          return (
            mediaRequest.imei === frame.IMEI.toString() &&
            frame.Parametros.includes('ExternalEvent')
          );
        }),
        mergeMap((frame) => {
          console.log('frame', frame);
          const objParams: any = {};
          frame.Parametros.split('|').forEach((item: string) => {
            const [key, value] = item.split('=');
            objParams[key] = value;
          });
          frame.Parametros = objParams;
          return from([frame]);
          //return this.getMediaFromEvent(mediaRequest.imei, frame.Parametros.eventId, "video", mediaRequest.source, 0, mediaRequest.seconds!+10);
        }),
        take(1), // Solo toma la primera URL válida y completa la suscripción
        timeout(mediaRequest.seconds! * 2000)
      );
      // Aplicar timeout al Observable
      const frameSubscription = frameSubscriptionSocket.subscribe(
        (frame) => {
          observer.next(frame);
          observer.complete();
        },
        (error) => {
          console.log('mediaService: error recordVideo:', error);

          if (error.name === 'TimeoutError') {
            console.error('Tiempo de espera agotado');
            // Swal.fire('Error', 'No se pudo grabar, tiempo de espera agotado.', 'error');
            this.onDemandLoader = false;
            // this.toastService.clearToastMessage('multimediaOnDemand');
            observer.error(
              'Se supero el tiempo de espera para grabar del video, verifique que la unidad se encuentra encendida e inténtelo de nuevo.'
            );
            console.log('mediaService: mediaRequest', mediaRequest, error);
          } else {
            console.error('Error en obtener la trama:', error);
            observer.error(
              'Ocurrió un error inesperado al solicitar el video. Por favor, vuelva a intentarlo más tarde.'
            );
          }
          // observer.next('');
          observer.complete();
        }
      );
      this.http.post<ResponseInterface>(endpoint, mediaRequest).subscribe(
        (resp) => {
          console.log('respuesta de record: ', resp);
          if (!resp.success) {
            this.onDemandLoader = false;
            console.log('mediaService: mediaRequest', mediaRequest, resp);
            // Swal.fire('Error', resp.message, 'error');
            frameSubscription.unsubscribe();
            observer.error(resp.message);
            observer.complete();
          }
        },
        (error) => {
          this.onDemandLoader = false;
          console.log('mediaService: mediaRequest', mediaRequest, error);
          // Swal.fire('Error', error.error.messages, 'error');
          console.error(
            'Error al llamar al endpoint /api/media/record:',
            error
          );
          frameSubscription.unsubscribe();
          observer.error(error.error.messages);
          observer.complete();
        }
      );
    });
  }

  async getVideoOnDemand(
    option: VideoOnDemandTime = 'now',
    multimediaParams: CipiaMultimediaParam,
    event: Alert,
    element: HTMLElement
  ) {
    console.log('mediaService: getVideoOnDemand');

    this.onDemandLoader = true;

    if (option == 'now') {
      console.log('record video with params: ', multimediaParams);
      // this.toastService.emitToastMessage({
      //   key: 'multimediaOnDemand',
      //   severity: 'info',
      //   summary: `Grabar video 360(${multimediaParams.imei})`,
      //   detail: 'Se le notificará cuando el video esté disponible.',
      //   sticky: true,
      //   closable: false,
      // });
      this.customToastMessagess.addCustomToast({
        id: `${multimediaParams.eventId}_${multimediaParams.from!}`,
        severity: 'info',
        summary: `SOLICITUD RECIBIDA`,
        detail: `Se le notificará cuando el video se encuentre disponible.`,
        data: {
          unit: `Unidad: ${event.nombre_objeto}`,
          datetime: `Fecha: ${event.fecha_tracker}`,
          moment: {
            periodo: `Periodo:
            ${this.momentService.toMoment(multimediaParams.from!,true)} (
            ${this.momentService.toMoment(multimediaParams.from!,true)} -
            ${this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true)})`,
          },
          dataEvent: event!,
          dataHTML: element!,
        },
        sticky: true,
        closable: false,
      });
      this.recordVideo(multimediaParams).subscribe(
        async (frame) => {
          console.log('frame obtained: ', frame);
          const auxMultimediaParams = { ...multimediaParams };
          auxMultimediaParams.eventId = frame.Parametros.eventId;
          await this.addMultimediaCipiaItem(
            multimediaParams.eventId!,
            {
              type: multimediaParams.type,
              params: auxMultimediaParams,
              description:
                'Desde: ' +
                this.momentService.toMoment(multimediaParams.from!,true) +
                '  hasta: ' +
                this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true),
              url: '',
              blobId: '',
              interval: this.getInterval(
                multimediaParams.from!,
                0,
                multimediaParams.seconds!,
                'recording'
              ),
            },
            event.uuid_event,
            { event, element, multimediaParams }
          );
          console.log('Multimedia Item added: ', {
            type: multimediaParams.type,
            params: auxMultimediaParams,
            url: '',
            description: frame.Parametros.eventDateTime,
          });
          this.onDemandLoader = false;
          //this.updateSliderBackground();
          console.log(
            'this.multimediaService.multimediaCipiaItems: ',
            this.multimediaCipiaItems
          );
          this.customToastMessagess.addCustomToast({
            id: `${multimediaParams.eventId}_${multimediaParams.from!}`,
            severity: 'success',
            summary: `SOLICITUD COMPLETADA`,
            detail: `La grabación está disponible. Por favor, diríjase al evento para verlo.`,
            data: {
              unit: `Unidad: ${event.nombre_objeto}`,
              datetime: `Fecha: ${event.fecha_tracker}`,
              moment: {
                periodo: `Periodo:
                ${this.momentService.toMoment(multimediaParams.from!,true)} (
                ${this.momentService.toMoment(multimediaParams.from!,true)} -
                ${this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true)})`,
              },
              dataEvent: event!,
              dataHTML: element!,
            },
            sticky: true,
            closable: false,
          });
          // this.toastService.clearToastMessage('multimediaOnDemand');
          // this.toastService.emitToastMessage({
          //   key: 'regular',
          //   severity: 'success',
          //   summary: `Grabación exitosa(${multimediaParams.imei})`,
          //   detail:
          //     'La grabación ha terminado con éxito. Vaya al evento correspondiente para ver el video.',
          // });
        },
        (error) => {
          console.error(error);
          this.onDemandLoader = false;
          this.customToastMessagess.addCustomToast({
            id: `${multimediaParams.eventId}_${multimediaParams.from}`,
            severity: 'error',
            summary: `SOLICITUD NO PROCESADA`,
            detail: `${error}`,
            data: {
              unit: `Unidad: ${event.nombre_objeto}`,
              datetime: `Fecha: ${event.fecha_tracker}`,
              moment: {
                periodo: `Periodo:
                ${this.momentService.toMoment(multimediaParams.from!,true)} (
                ${this.momentService.toMoment(multimediaParams.from!,true)} -
                ${this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true)})`,
                error: error,
              },
              dataEvent: event!,
              dataHTML: element!,
            },
            sticky: true,
            closable: false,
          });
          // this.toastService.clearToastMessage('multimediaOnDemand');
          // this.toastService.emitToastMessage({
          //   key: 'regular',
          //   severity: 'warn',
          //   summary: `Grabación no disponible(${multimediaParams.imei})`,
          //   detail: 'La grabación no se pudo obtener. Inténtelo nuevamente.',
          // });
        }
      );
    } else {
      // multimediaParams.from = moment(
      //   multimediaParams.from,
      //   'YYYY/MM/DD HH:mm:ss'
      // )
      //   .subtract(6, 'days')
      //   .format('YYYY/MM/DD HH:mm:ss');
      console.log('retrieving video with params: ', multimediaParams);
      console.log('Moment Record Recibida :',multimediaParams.from);
      this.customToastMessagess.addCustomToast({
        id: `${multimediaParams.eventId}_${multimediaParams.from}`,
        severity: 'info',
        summary: `SOLICITUD RECIBIDA`,
        detail: `Se le notificará cuando el video se encuentre disponible.`,
        data: {
          unit: `Unidad: ${event.nombre_objeto}`,
          datetime: `Fecha: ${event.fecha_tracker}`,
          moment: {
            periodo: `Periodo:
            ${this.momentService.toMoment(multimediaParams.from!,true)} (
            ${this.momentService.toMoment(multimediaParams.from!,true)} -
            ${this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true)})`,
          },
          dataEvent: event!,
          dataHTML: element!,
        },
        sticky: true,
        closable: false,
      });
      // this.toastService.emitToastMessage({key: 'multimediaOnDemand', severity:'info', summary: `Obteniendo video 360 ${dataEvent.nombre}`, detail: 'Se le notificará cuando el video se encuentre disponible.', sticky: true, closable: false});
      this.retrieveVideoFrom(multimediaParams).subscribe(
        async (frame) => {
          console.log('frame obtained: ', frame, element);
          console.log('Moment Retrieve Type :',multimediaParams.from);
          const auxMultimediaParams = { ...multimediaParams };
          auxMultimediaParams.eventId = frame.Parametros.eventId;
          await this.addMultimediaCipiaItem(
            multimediaParams.eventId!,
            {
              type: multimediaParams.type,
              params: auxMultimediaParams,
              description:
                'Desde: ' +
                this.momentService.toMoment(multimediaParams.from!,true) +
                '  hasta: ' +
                this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true),
              url: '',
              blobId: '',
              interval: this.getInterval(
                multimediaParams.from!,
                0,
                multimediaParams.seconds!,
                'retrieve'
              ),
            },
            event.uuid_event,
            { event, element, multimediaParams }
          );
          console.log('Multimedia Item added: ', {
            type: multimediaParams.type,
            params: auxMultimediaParams,
            url: '',
            description: frame.Parametros.eventDateTime,
            interval: this.getInterval(
              frame.Parametros.eventDateTime,
              0,
              multimediaParams.seconds!,
              'retrieve'
            ),
          });
          this.onDemandLoader = false;
          // this.updateSliderBackground();
          console.log(
            'this.multimediaService.multimediaCipiaItems: ',
            this.multimediaCipiaItems
          );
          console.log('Moment Retrieve Completada :',multimediaParams.from);

          this.customToastMessagess.addCustomToast({
            id: `${multimediaParams.eventId}_${multimediaParams.from!}`,
            severity: 'success',
            summary: `SOLICITUD COMPLETADA`,
            detail: `El video está disponible. Por favor, diríjase al evento para verlo.`,
            data: {
              unit: `Unidad: ${event.nombre_objeto}`,
              datetime: `Fecha: ${event.fecha_tracker}`,
              moment: {
                periodo: `Periodo:
                ${this.momentService.toMoment(multimediaParams.from!,true)} (
                ${this.momentService.toMoment(multimediaParams.from!,true)} -
                ${this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true)})`,
              },
              dataEvent: event!,
              dataHTML: element!,
            },
            sticky: true,
            closable: false,
          });
          // this.toastService.clearToastMessage('multimediaOnDemand');
          // this.toastService.emitToastMessage({key: 'regular', severity:'success', summary: `Video obtenido(${multimediaParams.imei})`, detail: 'El video ya se encuentra disponible. Vaya al evento correspondiente para verlo.'});
        },
        (error) => {
          console.log('Moment Retrieve No Procesada :',multimediaParams.from);

          this.onDemandLoader = false;
          this.customToastMessagess.addCustomToast({
            id: `${multimediaParams.eventId}_${multimediaParams.from}`,
            severity: 'error',
            summary: `SOLICITUD NO PROCESADA`,
            detail: `${error}`,
            data: {
              unit: `Unidad: ${event.nombre_objeto}`,
              datetime: `Fecha: ${event.fecha_tracker}`,
              moment: {
                periodo: `Periodo:
                ${this.momentService.toMoment(multimediaParams.from!,true)} (
                ${this.momentService.toMoment(multimediaParams.from!,true)} -
                ${this.momentService.setWithOptions(multimediaParams.from!,multimediaParams.seconds,'seconds',true)})`,
                error: error,
              },
              dataEvent: event!,
              dataHTML: element!,
            },
            sticky: true,
            closable: false,
          });
          // this.toastService.clearToastMessage('multimediaOnDemand');
          // this.toastService.emitToastMessage({key: 'regular', severity:'warn', summary: 'Video no disponible', detail: 'El video no se pudo obtener, refresque la página e inténtelo nuevamente.'});
          // this.toastService.emitToastMessage({key: 'regular', severity:'warn', summary: `Video(${multimediaParams.imei}): error`, detail: error});
          console.log(
            'mediaService: error getVideoOnDemand',
            error,
            multimediaParams
          );
        }
      );
    }
  }

  getInterval(
    event_date_time: string,
    add_start_number: number,
    add_end_numbrer: number,
    type?: IntervalType
  ): IntervalTime {
    return {
      start: this.momentService.toLocalWithOptions(event_date_time,add_start_number,'seconds',true) as string,
      // start: moment(event_date_time, 'YYYY/MM/DD HH:mm:ss')
      //   .add(add_start_number, 'seconds')
      //   .subtract(5, 'hours')
      //   .format('YYYY/MM/DD HH:mm:ss'),
      end: this.momentService.toLocalWithOptions(event_date_time,add_end_numbrer,'seconds',true) as string,
      // end: moment(event_date_time, 'YYYY/MM/DD HH:mm:ss')
      //   .add(add_end_numbrer, 'seconds')
      //   .subtract(5, 'hours')
      //   .format('YYYY/MM/DD HH:mm:ss'),
      type: type ?? 'event',
    };
  }

  loadMediaFromMultimediaItem(
    activeIndex: number,
    parentEventId: string,
    untilDestroy: Subject<void>,
    uuid: string,
    event?: {
      event: Alert;
      element?: HTMLElement;
      multimediaParams: CipiaMultimediaParam;
    }
  ): Promise<void> {
    const media = this.multimediaCipiaItems[parentEventId][activeIndex];
    console.log('parentID: ', parentEventId);
    console.log('activeIndex: ', activeIndex);
    console.log('media: ', media);
    console.log('mediaService: media', media);

    return new Promise<void>((resolve, reject) => {
      if (!media.url!) {
        console.log('mediaService: true load', media);
        if (event) {
          console.log('EVENTO LLEGO : ',event);
          this.customToastMessagess.addCustomToast({
            id: `${event.multimediaParams.eventId}_${event.multimediaParams.from}`,
            severity: 'init',
            summary: `SOLICITUD EN PROCESO`,
            detail: `Se le notificará cuando el video se encuentre disponible.`,
            data: {
              unit: `Unidad: ${event.event.nombre_objeto}`,
              datetime: `Fecha: ${event.event.fecha_tracker}`,
              moment: {
                periodo: `Periodo:
                ${this.momentService.toMoment(event.multimediaParams.from!,true)} (
                ${this.momentService.toMoment(event.multimediaParams.from!,true)} -
                ${this.momentService.setWithOptions(event.multimediaParams.from!,event.multimediaParams.seconds,'seconds',true)})`,
              },
              dataEvent: event.event!,
              dataHTML: event.element!,
            },
            sticky: true,
            closable: false,
          });
        }
        this.getMediaFromEvent(
          media.params!.imei,
          media.params!.eventId!,
          media.params!.type,
          media.params!.source,
          undefined,
          media.params!.seconds ?? undefined,
          10,
          7000,
          uuid,
          event ?? {
            event:
              (this.eventService.events.find(
                (t) => t.uuid_event === uuid
              ) as Alert) ??
              (this.eventService.eventsHistorial.find(
                (t: Alert) => t.uuid_event === uuid
              ) as Alert),
            multimediaParams: media.params as CipiaMultimediaParam,
          }
        )
          .pipe(takeUntil(untilDestroy))
          .toPromise()
          .then((blobItem) => {
            if (blobItem) {
              this.updateUrlToMultimediaCipiaItem(
                parentEventId,
                this.sanitizer.bypassSecurityTrustUrl(blobItem.url) as SafeUrl,
                blobItem.id,
                activeIndex
              );
            }
            resolve();
          })
          .catch((error) => {
            console.log('mediaSevice: error load:', error);
            reject();
          });
      } else {
        console.log('mediaService: else load', media);
        resolve();
      }
    });
  }

  async loadMultimediaCipiaItemsFromLocalStorage() {
    let events = this.localStorageService.getItem(
      EVENTS_MULTIMEDIA_KEY
    ) as string[];
    if (events) {
      events.map(async (event) => {
        //console.log("retrieving MultimediaCipiaItems from: ", event);

        let auxMultimediaCipiaItems: MultimediaItem[] =
          this.localStorageService.getItem(event) as MultimediaItem[];
        console.log('MultimediaCipiaItems data: ', auxMultimediaCipiaItems);
        if (auxMultimediaCipiaItems) {
          const promises: Promise<MultimediaItem>[] =
            auxMultimediaCipiaItems.map(async (item: MultimediaItem) => {
              const blobItem = await this.indexedDBService
                .getBlob(item.blobId!)
                .toPromise();
              //console.log("blobItem getBlob", blobItem);
              item.url = this.sanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(blobItem)
              ) as SafeUrl;
              return item;
            });

          // Esperar a que todas las promesas se resuelvan
          const resolvedItems = await Promise.all(promises);
          this.multimediaCipiaItems[event] = resolvedItems;
        }
      });
    }
    this.isLoadedMultimediaCipia = true;
    this.completedMultimediaCipia.emit();
    //console.log(" MultimediaCipiaItems loaded: ", this.multimediaCipiaItems);
  }

  async addMultimediaCipiaItem(
    eventId: string,
    item: MultimediaItem,
    uuid: string,
    event?: {
      event: Alert;
      element?: HTMLElement;
      multimediaParams: CipiaMultimediaParam;
    }
  ) {
    this.multimediaCipiaItems[eventId].push(item);
    this.localStorageService.updateItem(
      eventId,
      this.multimediaCipiaItems[eventId]
    );
    //console.log("addMultimediaCipiaItem LocalStorage: ", this.localStorageService.getItem(eventId));
    await this.loadMediaFromMultimediaItem(
      this.multimediaCipiaItems[eventId].length - 1,
      eventId,
      this.destroy$,
      uuid,
      event
    );
  }

  initializeNewMultimediaCipiaItem(eventId: string) {
    this.multimediaCipiaItems[eventId] = [];
    this.localStorageService.setItem(eventId, []);
    this.addEventToListInLocalStorage(eventId);
    //console.log("update LocalStorage: ", this.localStorageService.getItem(eventId));
  }

  updateUrlToMultimediaCipiaItem(
    eventId: string,
    url: SafeUrl,
    blobId: string,
    index: number
  ) {
    this.multimediaCipiaItems[eventId][index].url = url;
    this.multimediaCipiaItems[eventId][index].blobId = blobId;
    this.localStorageService.updateItem(
      eventId,
      this.multimediaCipiaItems[eventId]
    );
    //console.log("update LocalStorage: ", this.localStorageService.getItem(eventId));
  }

  addEventToListInLocalStorage(eventId: string) {
    let events = this.localStorageService.getItem(
      EVENTS_MULTIMEDIA_KEY
    ) as string[];
    console.log('eventsInLocalStorage: ', events);
    if (events) {
      events.push(eventId);
      this.localStorageService.updateItem(EVENTS_MULTIMEDIA_KEY, [...events]);
      //console.log("eventsInLocalStorageAfter: ", this.localStorageService.getItem(EVENTS_MULTIMEDIA_KEY) as string[]);
    } else {
      this.localStorageService.setItem(EVENTS_MULTIMEDIA_KEY, [eventId]);
      //console.log("eventsInLocalStorage append: ", this.localStorageService.getItem(EVENTS_MULTIMEDIA_KEY) as string[]);
    }
  }

  async clearMultimediaStorage() {
    let events = this.localStorageService.getItem(
      EVENTS_MULTIMEDIA_KEY
    ) as string[];

    if (events) {
      for (const event of events) {
        //console.log("deleting MultimediaCipiaItems from: ", event);

        let auxMultimediaCipiaItems: MultimediaItem[] =
          this.localStorageService.getItem(event) as MultimediaItem[];
        if (auxMultimediaCipiaItems) {
          for (const item of auxMultimediaCipiaItems) {
            await this.indexedDBService.deleteBlob(item.blobId!).toPromise();
            console.log('deleted: ', item.blobId!);
          }
        }
      }
    }
  }
  async clearAllMultimediaStorage() {
    await this.indexedDBService.deleteAllBlob().toPromise();
  }
}
