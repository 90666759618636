<p-dialog *ngIf="!loading_evaluation" [contentStyle]="{ padding: '0 0 0 0' }" [(visible)]="showDialog" position="bottom"
  (onHide)="onHide()" [closeIcon]="showDialogContent ? 'pi pi-angle-down' : 'pi pi-angle-up'"
  (keydown.shift.enter)="saveEvaluationOnShiftEnter()">
  <ng-template pTemplate="header">
    Atención de eventos: {{ event.name }}
  </ng-template>

  <ng-container *ngIf="showDialogContent">
    <div *ngIf="loading_evaluation" class="d-flex justify-content-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <ng-container *ngFor="let evaluation of event.evaluations; let i = index">
      <tr *ngIf="i === event.evaluations.length - 1" [style]="{ padding: '0 0 0 0' }">
        <td colspan="5">
          <div style="font-size: smaller">
            <div class="ps-4 pe-2 py-2 d-flex flex-column gap-2">
              <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="firstname4">Fecha</label>
                <div class="col-7">
                  <span>{{ event.fecha_tracker }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="lastname4" class="">Nombre</label>
                <div class="col-7">
                  <span>{{ event.nombre_objeto }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="firstname4" class="">Tipo de Evento</label>
                <div class="col-7">
                  <span>{{ event.name }}</span>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="lastname4" class="">Criterio de Evaluación</label>
                <div class="col-7">
                  <p-dropdown *ngIf="evaluation.tipo_evento == 'Posible Fatiga'" [panelStyle]="{
                      'background-color': '#add5fa',
                      'font-size': '0.75rem'
                    }" [appendTo]="'body'" [disabled]="evaluation.id" [options]="evaluation_criteria_filter"
                    [(ngModel)]="evaluation.criterio_evaluacion" placeholder="Selecciona un criterio" [group]="true"
                    filterBy="label" emptyMessage="No se encontraron registros"
                    emptyFilterMessage="No se han encontrado resultados" filterMatchMode="contains"
                    (onChange)="criteriaSelected($event, evaluation)" [style]="{
                      width: '100%',
                      'max-width': '15rem',
                      padding: '0 0 0 0'
                    }"><ng-template let-group pTemplate="group">
                      <div class="p-d-flex p-ai-center d-flex justify-content-center">
                        <span>{{ group.label }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <!-- 
                  <p-autoComplete
                    [panelStyle]="{
                      'background-color': '#add5fa',
                      'font-size': '0.75rem'
                    }"
                    [placeholder]="
                      evaluation.id ? evaluation.criterio_evaluacion : ''
                    "
                    field="value"
                    [dataKey]="'value'"
                    [group]="true"
                    [dropdown]="true"
                    autoZIndex="true"
                    appendTo="body"
                    forceSelection="'true"
                    [(ngModel)]="evaluation.criterio_evaluacion"
                    [suggestions]="evaluationCriteria"
                    (completeMethod)="searchEvaluationCriteria($event)"
                    (onSelect)="criteriaSelected($event, evaluation)"
                    [disabled]="evaluation.id"
                    [minLength]="0"
                    autoHighlight="true"
                    [style]="{
                      width: '100%',
                      'max-width': '15rem',
                      padding: '0 0 0 0'
                    }"
                  >
                  </p-autoComplete> -->
                  <p-autoComplete *ngIf="evaluation.tipo_evento != 'Posible Fatiga'" [panelStyle]="{
                      'background-color': '#add5fa',
                      'font-size': '0.75rem'
                    }" autoZIndex="true" appendTo="body" [dropdown]="true" forceSelection="true"
                    [(ngModel)]="evaluation.criterio_evaluacion" [suggestions]="evaluationCriteria"
                    (completeMethod)="searchEvaluationCriteria($event)" (onSelect)="
                      criteriaSelectedFromAutoComplete($event, evaluation)
                    " [disabled]="evaluation.id" [minLength]="0" autoHighlight="true" readonly="true"
                    completeOnFocus="true" [style]="{
                      width: '100%',
                      'max-width': '15rem',
                      padding: '0 0 0 0'
                    }">
                  </p-autoComplete>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="lastname4" class=""
                  >Criterio de Evaluación</label
                >
                <div class="col-7">
                  <p-dropdown
                    [appendTo]="'body'"
                    [disabled]="evaluation.id"
                    [options]="evaluation_criteria_filter"
                    [(ngModel)]="evaluation.criterio_evaluacion"
                    placeholder="Selecciona un criterio"
                    [group]="true"
                    editable="true"
                    (onChange)="criteriaSelected($event, evaluation)"
                    [style]="{ 'max-width': '15rem', padding: '0 0 0 0' }"
                  >
                    <ng-template let-group pTemplate="group">
                      <div
                        class="p-d-flex p-ai-center d-flex justify-content-center"
                      >
                        <span>{{ group.label }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div> -->
              <div *ngIf="evaluation.senales_posible_fatiga" class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="firstname4" class="">Identificar video</label>
                <div class="col-7">
                  <span class="p-input-icon-left">
                    <i class="pi pi-link"></i>
                    <input [disabled]="evaluation.id" type="text" pInputText placeholder="url"
                      [(ngModel)]="evaluation.identificacion_video" />
                  </span>
                </div>
              </div>
              <div *ngIf="evaluation.senales_posible_fatiga" class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="firstname4" class="">Valoración</label>
                <div class="col-7">
                  <p-rating [disabled]="evaluation.id" [(ngModel)]="evaluation.valoracion_evento"
                    [cancel]="false"></p-rating>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="lastname4" class=""
                  >Observación</label
                >
                <div class="col-7">
                  <input
                    [disabled]="evaluation.id"
                    type="text"
                    pInputText
                    [(ngModel)]="evaluation.observacion_evaluacion"
                  />
                </div>
              </div> -->
              <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="lastname4" class="">Observación</label>
                <div class="col-7">
                  <p-autoComplete [panelStyle]="{
                      'background-color': '#add5fa',
                      'font-size': '0.75rem'
                    }" autoZIndex="true" appendTo="body" [(ngModel)]="evaluation.observacion_evaluacion"
                    [suggestions]="evaluationObservations" (completeMethod)="searchEvaluationObservationsTemp($event)"
                    [disabled]="evaluation.id" [minLength]="1" autoHighlight="true" [style]="{
                      width: '100%',
                      'max-width': '15rem',
                      padding: '0 0 0 0'
                    }"></p-autoComplete>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <label class="col-5" for="lastname4" class="">Operador de Monitoreo</label>
                <div class="col-7">
                  <p-dropdown [appendTo]="'body'" [disabled]="evaluation.id" [options]="operators"
                    [(ngModel)]="evaluation.operador_monitoreo" placeholder="Selecciona un operador" editable="true"
                    [group]="true" [style]="{
                      width: '100%',
                      'max-width': '15rem',
                      padding: '0 0 0 0'
                    }">
                  </p-dropdown>
                </div>
              </div>
              <div *appHasPermission="'events_sendMessageWhatsapp'">
                <div *ngIf="evaluation.id" [style]="{
                    color: !areMessagesSentCorrectly()
                      ? messagesSentByWhatsApp.length > 0
                        ? '#e68f3d'
                        : '#ff6262'
                      : '#13d420'
                  }">
                  {{ whatsappsSendingMessage }}
                </div>
                <div *ngIf="iCanSeeWhatsappSending() && !evaluation.id">
                  <p-checkbox [(ngModel)]="sendEvaluationByWhatsapp"
                    (onChange)="submitSendEvaluationByWhatsappEmitter()" [binary]="true"></p-checkbox>
                  Enviar evento evaluado por whatsapp
                </div>
                <div *ngIf="iCanSeeExternalSending() && !evaluation.id">
                  <p-checkbox [(ngModel)]="sendExternal" 
                  (onChange)="submitSendExternalEmitter()"
                    [binary]="true"></p-checkbox>
                  Enviar evento evaluado a APV
                </div>
              </div>
              <div class="d-flex justify-content-center gap-4 pt-2">
                <button type="button" [loading]="submitting" class="btn-gl-cancel btn-dark-hover w-25"
                  (click)="closeEvaluationExpandedEmiter(evaluation)">
                  Cancelar
                </button>
                <!--button type="button" pButton class="btn-gl-save btn-dark-hover w-25" (click)="submitEvaluation(evaluation,event)" [disabled]="evaluation.id || submitting">Guardar</button-->
                <button #submitEvaluationButton pButton pRipple class="btn-gl-save btn-dark-hover w-25"
                  icon="pi pi-save ms-1" [loading]="submitting" (click)="submitEvaluationEmiter(evaluation, event)"
                  [disabled]="evaluation.id || submitting">
                  {{ "Guardar " }}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</p-dialog>
