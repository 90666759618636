import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from './permission.service';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective {
  @Input() appHasPermission: string | string[] = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  async ngOnInit() {
    const permissions = Array.isArray(this.appHasPermission)
      ? this.appHasPermission
      : [this.appHasPermission];
    // console.log('Saint:', this.appHasPermission, permissions);
    const isNegative = permissions.filter(
      (permission) => permission.charAt(0) === '!'
    );
    const permissionsToCheck = permissions.filter(
      (permission) => permission.charAt(0) !== '!'
    );

    let hasPermission = false;
    for (const permission of permissionsToCheck) {
      const permissionGranted = await this.hasPermiss(permission);
      if (permissionGranted) {
        hasPermission = true;
        break; // Si se concede uno de los permisos, ya no es necesario verificar los demás
      }
    }

    if (
      (hasPermission && isNegative.length === 0) ||
      (!hasPermission && isNegative.length > 0)
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      // console.log('Permiso Valido');
    } else {
      this.viewContainer.clear();
      // console.log('Permiso Invalido');
    }
  }

  private async hasPermiss(permission: string): Promise<boolean> {
    return await this.permissionService.hasPermission(permission);
  }
}
