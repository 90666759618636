<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="reloadSpinner" size = "medium" color = "#fff" type = "ball-rotate" [fullScreen] = "true"><p style="color: white" > {{ fullScreenSpinnerMsg }} </p></ngx-spinner>


<p-dialog [(visible)]="reportService.modalReportesProgramadosActive" [style]="{ width: '800px' }" >
    <ng-template pTemplate="header" class="p-dialog-title" style="width: 60vw;">
        <ng-container style="width: 60vw;">
          <div class="row"> REPORTES PROGRAMADOS</div>
        </ng-container>
    </ng-template>
    
    <ng-template pTemplate="body" style="height: 50%;">

        <!-- <p-autoComplete [(ngModel)]="selectedCountry" [dropdown]="true" [suggestions]="filteredCountries" (completeMethod)="filterCountry($event)" field="name"></p-autoComplete> -->
        <!-- <p-card header="Detalles"> -->
        <p-card>

          <!-- <ng-template pTemplate="header">
            <img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />
          </ng-template> -->



          <div class="gl-row-underline">
            <div class="col-12">
              <label>NOMBRE:</label>
            </div>
            <div class="col-12">
              <input
                type="text"
                size="70"
                placeholder="Nombre de la alerta"
                pInputText
                [(ngModel)]="form.nombre"
              />
            </div>
          </div>

          <br>

          <div class="gl-row-underline">
            <div class="col-12">
              <label>HORA PROGRAMADA:</label>
            </div>
            <div class="col-12">
              <div class="p-field">
                  <p-calendar
                    [(ngModel)]="form.hora_programado2"
                    [timeOnly]="true"
                    [appendTo]="'body'"
                    [disabled]="false"
                    ></p-calendar>
              </div>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col-5">
              <label>AGREGAR CORREO:</label>
            </div>
            <div class="col-2">
                <p-checkbox
                  inputId="binary"
                  [binary]="true"
                  [(ngModel)]="form.notificacion_email"
                ></p-checkbox>
            </div>
            <div class="col-5">
            </div>
          </div>

          <br>

          <div class="gl-row-underline">
   
            <div class="col-12">
              <input
                type="text"
                size="70"
                placeholder="correo-1@mail.com,correo-2@mail.com..."
                pInputText
                [disabled]="!form.notificacion_email"
                [(ngModel)]="form.notificacion_email_lista"
              />
            </div>
          </div>


        <!-- emails -->
        <!-- <div class="row d-flex justify-content-center">
          <div class="col-5">
            <label class="row">
              <span class="col-9 form-check-label">Agregar Correo:</span>
              <div class="col-2 d-flex flex-column justify-content-center">
                <div class="form-check">
                  <p-checkbox
                    inputId="binary"
               
                    [binary]="true"
                    [(ngModel)]="form.notificacion_email"
               
                  ></p-checkbox>
                </div>
              </div>
            </label>
          </div>
          <div class="col-5"></div>
        </div> -->

        <div class="row"></div>

        <!-- <div class="form-group row">
          <div class="col-12 d-flex">
            <input
              type="text"
              placeholder="ejemplo@mail.com"
              class="flex-grow-1"
              pInputText
              [(ngModel)]="form.notificacion_email_lista"
              name="email"
              (keydown.enter)="addEmail()"
            />
            <button
              type="button"
              class="btn-gl-clear flex-none ms-2"
              (click)="addEmail()"
              [disabled]="!alertForm.value.chkCorreo"
            >
              <i
                class="fa fa-plus-circle fa-2x icon-gl-blue"
                data-placement="bottom"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div> -->

        <!-- <div class="form-group row">
          <div class="col-9">
            <div class="text">
              <ul>
                <li
                  *ngFor="
                    let email of alertForm.value.lista_emails;
                    let i = index
                  "
                  ng-bind-html-unsafe="opt"
                >
                  {{ email }}
                  <i
                    class="ms-1 fa fa-minus-circle"
                    (click)="restEmail(i)"
                    type="button"
                    data-placement="bottom"
                    aria-hidden="true"
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <!-- fin emails -->






          <!-- <div class="row">
              <div class="col-3">
                  Nombre:
              </div>
              <div class="col-9">
                  <input type="text" pInputText [(ngModel)]="form.nombre" />            
              </div>
              
              <div class="col-3">
                  Diariamente:
              </div>
              <div class="col-9">
                  <p-autoComplete [(ngModel)]="form.hora_programado" [virtualScroll]="true" [suggestions]="filteredItems" [virtualScrollItemSize]="34" (completeMethod)="filterItems($event)" field="label" [dropdown]="true"> </p-autoComplete>
              </div>
          </div> -->


          <ng-template pTemplate="footer">
              <p-button label="Guardar" icon="pi pi-check" (click)="guardarReporteProgramado();"></p-button>
              <p-button label="Cancelar" icon="pi pi-times" (click)="cancelarReporteProgramado();" styleClass="p-button-secondary" [style]="{ 'margin-left': '.5em' }"></p-button>
          </ng-template>


        </p-card>





        <p-table [value]="reportesProgramados" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm" >
          <ng-template pTemplate="header">
              <tr>
                  <th style="width:5%">N°</th>
                  <th style="width:40%">Nombre</th>
                  <th style="width:5%">Hora</th>

                  <th style="width:40%">Tipo de Reporte</th>
                  <th style="width:10%">Opciones</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-reporteProgramado let-rowIndex="rowIndex">
              <tr>
                <!-- <tr *ngFor="let row of detalleExcesosEnZona.detalle;  let i = index;">
                  <td>{{i + 1}}</td> -->

                  <td>{{rowIndex + 1}}</td>
                  <td>{{ reporteProgramado.nombre_programacion }}</td>
                  <td>{{ reporteProgramado.notificacion_hora }}</td>
                  <td>{{ reporteProgramado.nombre_reporte }}</td>
            
                  <td>
                      <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="verDetalleReporteProgramado(reporteProgramado)"></button>
                      <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="EliminarReporteProgramado(reporteProgramado)"></button>
                  </td>

                
              </tr>
          </ng-template>
        </p-table>


    </ng-template>
  
    <ng-template pTemplate="footer" style="width: 60vw; padding-top: 0;">

      
        <!-- <ng-container>
          <div class="row">
            <div class="col">
            </div>
            <div class="col">
              <div class="col-md-auto">
                <button class="btn btn-success" >EXPORTAR EXCEL</button>
              </div>
            </div>
            <div class="col">
              <div class="col-md-auto">
                <p-button (click)="reportService.modalReportesProgramadosActive=false" label="Cerrar"></p-button>
              </div>
            </div>
          </div>
        </ng-container> -->
    </ng-template> 
  
  
  
</p-dialog>

<p-dialog [(visible)]="reportService.modalReportesProgramadosActiveDetalle" [style]="{ width: '50%' }" header="Detalle Reporte" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
      <!-- <img [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image" [alt]="product.image" class="block m-auto pb-3" *ngIf="product.image" /> -->
      
      
      <div class="row">
        <div class="col-3">
          <label>Nombre</label>
        </div>
        <div class="col-1">
          <label>:</label>
        </div>
        <div class="col-8">
          {{reporteProgramado.nombre_programacion}}
        </div>
      </div>

      <br>

      <div class="row">
        <div class="col-3">
          <label>Tipo de Reporte</label>
        </div>
        <div class="col-1">
          <label>:</label>
        </div>
        <div class="col-8">
          {{reporteProgramado.nombre_reporte}}
        </div>
      </div>

      <br>
      
      <div class="row">
        <div class="col-3">
          <label>Vehículos</label>
        </div>
        <div class="col-1">
          <label>:</label>
        </div>
        <div class="col-8">


          <ol type="1" >
            <li *ngFor="let unidad of reporteProgramado.unidades">
              <span >{{unidad.IMEI}}</span>
              <span > --> </span>
              <span >{{unidad.name}}</span>
            </li>
          </ol>


        </div>
      </div>
      
      <br>
      
      <div class="row">
        <div class="col-3">
          <label>Rango de Fecha</label>
        </div>
        <div class="col-1">
          <label>:</label>
        </div>
        <div class="col-8">
          {{reporteProgramado.hora_inicio }} - {{reporteProgramado.hora_fin }} 
        </div>
      </div>

      <br>
      
      <div class="row">
        <div class="col-3">
          <label>Hora Programada</label>
        </div>
        <div class="col-1">
          <label>:</label>
        </div>
        <div class="col-8">
          A las {{reporteProgramado.notificacion_hora }} horas. (DIARIAMENTE)
        </div>
      </div>

      <br>
      
      <div class="row">
        <div class="col-3">
          <label>Correos para envio:</label>
        </div>
        <div class="col-1">
          <label>:</label>
        </div>
        <div class="col-8">
          {{reporteProgramado.notificacion_email_lista }}  
        </div>
      </div>
      
  </ng-template>

  <ng-template pTemplate="footer">
      <!-- <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> -->
      <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" (click)="reportService.modalReportesProgramadosActiveDetalle = false;"></button>
  </ng-template>
</p-dialog>
