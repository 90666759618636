<p-dialog [(visible)]="subcuentasService.modalActive" [modal]="true" [resizable]="false" [style]="{'width':'640px'}" >

  <ng-template class="p-dialog-title" pTemplate="header">
    <div>
      <i class="fa fa-user me-2" aria-hidden="true"></i>
      <strong [innerHTML]="this.subcuentasService.action == 'edit' ? 'Configurar Subcuenta' : 'Crear nueva Subcuenta' " ></strong>
    </div>
  </ng-template>


  <!-- 5. REPORTE GENERAL-->
  <!-- <div class="container grand-container"> -->
  <!-- </div> -->

  <form [formGroup]="subcuentasForm" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" autocomplete="off">

    <div class="dialog-body-container">

      <div class="p-fluid p-formgrid p-grid" style="font-size: 12px;">
        <!-- <div class="" *ngIf="true">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div> -->

        <div class="dialog-form-container mb-4" >

          <div class="row">

            <div class="col p-field">
              <label class="row d-flex justify-content-center mt-3">
                <div class="d-flex gap-3 justify-content-center fw-bold">
                  <span>Cuenta activada</span>
                  <p-checkbox [binary]="true" formControlName="chkSubUserActivado"></p-checkbox>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="dialog-form-container gl-middleline" *ngIf="subcuentasService.action == 'add'" style="margin-bottom: 0.75rem;">

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="nameCreate">Nombre</label>
                </div>
                <div class="col-7">
                  <input id="nameCreate" type="text" pInputText class="p-inputtext-sm" formControlName="nombre" autocomplete="no">
                </div>
              </label>
            </div>
            <div class="col p-field">
              <div class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="passCreate">Contraseña</label>
                </div>
                <div class="col-7">
                  <span class="p-input-icon-right">
                    <i class="pi pi-eye-slash" role="button" *ngIf="eyeStateCreate" (click)="eyeStateCreate = !eyeStateCreate; togglePassEye();"></i>
                    <i class="pi pi-eye" role="button" *ngIf="!eyeStateCreate" (click)="eyeStateCreate = !eyeStateCreate; togglePassEye();"></i>
                    <input id="passCreate" type="password" pInputText class="p-inputtext-sm" formControlName="contrasena" autocomplete="no" readonly onfocus="$(this).removeAttr('readonly');">
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="dialog-form-container d-flex" >

          <ng-container *ngIf="subcuentasService.action == 'edit'">

              <div class="row">
                <div class="col p-field">
                  <label class="row gl-row-underline">
                    <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                      <label for="nameEdit">Nombre :</label>
                    </div>
                    <div class="col-7">
                      <input id="nameEdit" type="text" value="{{getControlLabel('nombre')}}" pInputText class="p-inputtext-sm" placeholder="" autocomplete="no" disabled>
                    </div>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col p-field">
                  <label class="row gl-row-underline">
                    <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                      <label for="passEditBefore">Anterior Contraseña:</label>
                    </div>
                    <div class="col-7">
                      <span class="p-input-icon-right">
                        <i class="pi pi-eye-slash" role="button" *ngIf="eyeStateEditBefore" (click)="eyeStateEditBefore = !eyeStateEditBefore; togglePassEye();"></i>
                        <i class="pi pi-eye" role="button" *ngIf="!eyeStateEditBefore" (click)="eyeStateEditBefore = !eyeStateEditBefore; togglePassEye();"></i>
                        <input id="passEditBefore" type="password" pInputText class="p-inputtext-sm" formControlName="contrasena" readonly onfocus="$(this).removeAttr('readonly');">
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col p-field">
                  <label class="row gl-row-underline">
                    <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                      <label for="passEditAfter">Nueva Contraseña:</label>
                    </div>
                    <div class="col-7">
                      <span class="p-input-icon-right">
                        <i class="pi pi-eye-slash" role="button" *ngIf="eyeStateEditAfter" (click)="eyeStateEditAfter = !eyeStateEditAfter; togglePassEye();"></i>
                        <i class="pi pi-eye" role="button" *ngIf="!eyeStateEditAfter" (click)="eyeStateEditAfter = !eyeStateEditAfter; togglePassEye();"></i>
                        <input id="passEditAfter" type="password" pInputText class="p-inputtext-sm" formControlName="contrasenaNew" readonly onfocus="$(this).removeAttr('readonly');">
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col p-field">
                  <label class="row gl-row-underline">
                    <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                      <label for="passEditAfter2">Repita Nueva Contraseña:</label>
                    </div>
                    <div class="col-7">
                      <span class="p-input-icon-right">
                        <i class="pi pi-eye-slash" role="button" *ngIf="eyeStateEditAfter2" (click)="eyeStateEditAfter2 = !eyeStateEditAfter2; togglePassEye();"></i>
                        <i class="pi pi-eye" role="button" *ngIf="!eyeStateEditAfter2" (click)="eyeStateEditAfter2 = !eyeStateEditAfter2; togglePassEye();"></i>
                        <input id="passEditAfter2" type="password" pInputText class="p-inputtext-sm" formControlName="contrasenaNew2" readonly onfocus="$(this).removeAttr('readonly');">
                      </span>
                    </div>
                  </label>
                </div>
              </div>

          </ng-container>

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="tipo-unidad">Vehículos :</label>
                </div>
                <div class="col-7">
                  <div class="p-field">
                    <p-multiSelect
                    [options]="vehiculos"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="vehiculos"
                    defaultLabel="0 vehículos seleccionados"
                    [appendTo]="'body'"
                    [maxSelectedLabels]="3"
                    [selectedItemsLabel]="'{0} vehiculos seleccionados'"
                    ></p-multiSelect>
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="row">

            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="tipo-unidad">Geocercas :</label>
                </div>
                <div class="col-7">
                  <div class="p-field">
                    <p-multiSelect
                    [options]="geocercas"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="geocercas"
                    defaultLabel="0 geocercas seleccionados"
                    [appendTo]="'body'"
                    [maxSelectedLabels]="3"
                    [selectedItemsLabel]="'{0} geocercas seleccionados'"
                    ></p-multiSelect>
                  </div>
                </div>
              </label>
            </div>

          </div>

          <div class="row">

            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="tipo-unidad">Geopuntos :</label>
                </div>
                <div class="col-7">
                  <div class="p-field">
                    <p-multiSelect
                    [options]="geopuntos"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="geopuntos"
                    defaultLabel="0 geopuntos seleccionados"
                    [appendTo]="'body'"
                    [maxSelectedLabels]="3"
                    [selectedItemsLabel]="'{0} geopuntos seleccionados'"
                    ></p-multiSelect>
                  </div>
                </div>
              </label>
            </div>

          </div>

          <div class="row">

            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="tipo-unidad">Reportes :</label>
                </div>
                <div class="col-7">
                  <div class="p-field">
                    <p-multiSelect
                    [options]="reportes"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="reportes"
                    defaultLabel="0 reportes seleccionados"
                    [appendTo]="'body'"
                    [maxSelectedLabels]="3"
                    [selectedItemsLabel]="'{0} reportes seleccionados'"
                    ></p-multiSelect>
                  </div>
                </div>
              </label>
            </div>

          </div>

          <div class="row">

            <div class="col p-field">
              <label class="row gl-row-underline">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label for="tipo-unidad">Eventos o Alertas :</label>
                </div>
                <div class="col-7">
                  <div class="p-field">
                    <p-multiSelect
                    [options]="eventos"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="eventos"
                    defaultLabel="0 eventos seleccionados"
                    [appendTo]="'body'"
                    [maxSelectedLabels]="3"
                    [selectedItemsLabel]="'{0} eventos seleccionados'"
                    ></p-multiSelect>
                  </div>
                </div>
              </label>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- <ng-template pTemplate="footer" style="width: 60vw;"> -->
        <div class="row justify-content-center mt-4 pt-2 panel-izq-container-footer">
          <div class="col-4">
              <button type="button" class="btn-gl-cancel btn-dark-hover w-100" (click)="subcuentasService.modalActive=false">Cancelar</button>
          </div>
          <div class="col-4">
              <button type="submit" class="btn-gl-save btn-dark-hover w-100" >Guardar</button>
          </div>
        </div>
    <!-- </ng-template> -->


  </form>

  <ng-template pTemplate="footer" style="width: 60vw;">
  </ng-template>


</p-dialog>
