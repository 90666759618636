<span
  (click)="onClick()"
  class="btn-label"
  title="Velocidad"
  data-bs-toggle="tooltip"
  data-bs-placement="top"
>
  <!-- <i class="fa fa-car-alt ag-grid-header-icon"></i> -->

  <!-- <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="612.000000pt"
    height="412.000000pt"
    viewBox="0 0 612.000000 412.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,412.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2860 3693 c-690 -67 -1264 -334 -1759 -818 -157 -153 -263 -279
 -371 -440 -227 -338 -381 -742 -451 -1185 -17 -109 -22 -194 -26 -442 l-5
 -308 476 0 476 0 0 178 c0 390 44 611 171 866 72 144 160 267 274 382 151 152
 256 228 438 314 251 119 508 168 784 148 198 -14 364 -62 602 -174 63 -30 118
 -54 122 -54 15 0 131 182 122 190 -15 15 -127 75 -222 119 -500 230 -996 227
 -1498 -9 -546 -257 -880 -683 -991 -1265 -25 -127 -42 -287 -42 -386 l0 -69
 -237 2 -238 3 3 100 c19 658 218 1225 582 1660 89 106 274 285 380 367 307
 238 711 438 1074 532 212 55 335 70 586 70 262 1 355 -10 585 -69 463 -118
 927 -387 1285 -745 209 -208 338 -384 460 -629 172 -346 252 -680 275 -1158
 l7 -133 -595 0 -594 0 -7 150 c-7 166 -33 332 -72 466 -25 86 -82 234 -90 234
 -4 0 -206 -100 -212 -105 -1 -1 11 -31 27 -66 75 -166 102 -327 108 -657 l6
 -262 831 2 831 3 -1 260 c-2 348 -25 548 -94 811 -127 483 -362 899 -705 1253
 -463 477 -1074 779 -1731 856 -116 13 -459 19 -564 8z"
      />
      <path
        d="M3965 1911 c-55 -25 -578 -274 -750 -356 -77 -37 -182 -87 -233 -111
 -240 -114 -562 -280 -605 -311 -97 -72 -178 -197 -207 -323 -21 -86 -15 -252
 10 -335 25 -81 67 -161 118 -224 54 -66 177 -160 272 -205 l85 -41 140 0 c121
 0 146 3 187 22 78 36 159 97 238 179 119 125 162 193 249 395 43 101 115 270
 161 374 45 105 108 251 140 325 55 128 177 377 254 520 31 57 34 67 22 85 -18
 27 -34 28 -81 6z m-514 -764 c-321 -674 -336 -703 -390 -760 -152 -161 -379
 -168 -545 -16 -65 59 -101 125 -117 212 -23 124 18 256 106 342 43 41 182 115
 470 250 196 92 242 114 429 204 105 50 191 89 193 87 2 -2 -64 -145 -146 -319z"
      />
    </g>
  </svg> -->

  <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="1080"
    height="1080"
    viewBox="0 0 1080 1080"
    xml:space="preserve"
  >
    <desc>Created with Fabric.js 5.2.4</desc>
    <defs></defs>
    <rect x="0" y="0" width="100%" height="100%" fill="transparent"></rect>
    <g
      transform="matrix(1 0 0 1 540 540)"
      id="520a384a-b46d-42fa-bc2f-b7da909f9795"
    ></g>
    <g
      transform="matrix(1 0 0 1 540 540)"
      id="572168bc-1f87-4469-aa7f-3ba878193a51"
    >
      <rect
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill: rgb(255, 255, 255);
          fill-rule: nonzero;
          opacity: 1;
          visibility: hidden;
        "
        vector-effect="non-scaling-stroke"
        x="-540"
        y="-540"
        rx="0"
        ry="0"
        width="1080"
        height="1080"
      />
    </g>
    <g transform="matrix(1.37 0 0 2.07 543.92 557.47)">
      <g style="" vector-effect="non-scaling-stroke">
        <g transform="matrix(0.13 0 0 -0.13 -9.51 -38.33)">
          <path
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-dashoffset: 0;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            vector-effect="non-scaling-stroke"
            transform=" translate(-3101.5, -2099.37)"
            d="M 2860 3693 C 2170 3626 1596 3359 1101 2875 C 944 2722 838 2596 730 2435 C 503 2097 349 1693 279 1250 C 262 1141 257 1056 253 808 L 248 500 L 724 500 L 1200 500 L 1200 678 C 1200 1068 1244 1289 1371 1544 C 1443 1688 1531 1811 1645 1926 C 1796 2078 1901 2154 2083 2240 C 2334 2359 2591 2408 2867 2388 C 3065 2374 3231 2326 3469 2214 C 3532 2184 3587 2160 3591 2160 C 3606 2160 3722 2342 3713 2350 C 3698 2365 3586 2425 3491 2469 C 2991 2699 2495 2696 1993 2460 C 1447 2203 1113 1777 1002 1195 C 977 1068 960 908 960 809 L 960 740 L 723 742 L 485 745 L 488 845 C 507 1503 706 2070 1070 2505 C 1159 2611 1344 2790 1450 2872 C 1757 3110 2161 3310 2524 3404 C 2736 3459 2859 3474 3110 3474 C 3372 3475 3465 3464 3695 3405 C 4158 3287 4622 3018 4980 2660 C 5189 2452 5318 2276 5440 2031 C 5612 1685 5692 1351 5715 873 L 5722 740 L 5127 740 L 4533 740 L 4526 890 C 4519 1056 4493 1222 4454 1356 C 4429 1442 4372 1590 4364 1590 C 4360 1590 4158 1490 4152 1485 C 4151 1484 4163 1454 4179 1419 C 4254 1253 4281 1092 4287 762 L 4293 500 L 5124 502 L 5955 505 L 5954 765 C 5952 1113 5929 1313 5860 1576 C 5733 2059 5498 2475 5155 2829 C 4692 3306 4081 3608 3424 3685 C 3308 3698 2965 3704 2860 3693 z"
            stroke-linecap="round"
          />
        </g>
        <g transform="matrix(0.13 0 0 -0.13 -2.71 114.95)">
          <path
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-dashoffset: 0;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            vector-effect="non-scaling-stroke"
            transform=" translate(-3104.88, -965.73)"
            d="M 3965 1911 C 3910 1886 3387 1637 3215 1555 C 3138 1518 3033 1468 2982 1444 C 2742 1330 2420 1164 2377 1133 C 2280 1061 2199 936 2170 810 C 2149 724 2155 558 2180 475 C 2205 394 2247 314 2298 251 C 2352 185 2475 91 2570 46 L 2655 5 L 2795 5 C 2916 5 2941 8 2982 27 C 3060 63 3141 124 3220 206 C 3339 331 3382 399 3469 601 C 3512 702 3584 871 3630 975 C 3675 1080 3738 1226 3770 1300 C 3825 1428 3947 1677 4024 1820 C 4055 1877 4058 1887 4046 1905 C 4028 1932 4012 1933 3965 1911 z M 3451 1147 C 3130 473 3115 444 3061 387 C 2909 226 2682 219 2516 371 C 2451 430 2415 496 2399 583 C 2376 707 2417 839 2505 925 C 2548 966 2687 1040 2975 1175 C 3171 1267 3217 1289 3404 1379 C 3509 1429 3595 1468 3597 1466 C 3599 1464 3533 1321 3451 1147 z"
            stroke-linecap="round"
          />
        </g>
      </g>
    </g>
  </svg> -->

  <!-- <i class="fas fa-tachometer-alt fa-lg" style="color: #000000"></i> -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="#000000"
      d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 10 4.477 10 10z"
    />
  </svg>
</span>
