import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maparea',
  templateUrl: './maparea.component.html',
  styleUrls: ['./maparea.component.scss']
})

export class MapareaComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit(): void {
    
  }

}

