<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingAlertData" size = "small" color = "#fff" type = "ball-pulse" [fullScreen] = "true">
    <p style="color: white" class="text-center">Cargando detalles...</p>
</ngx-spinner>

<div *ngIf="subject_type == 'Point'">
    <div class="d-flex">
          <b>NOMBRE:</b><div style="margin-left: 0.5rem;">{{properties['nombre_punto']}}</div>
    </div>

    <div class="d-flex">
          <b>COLOR:</b><div style="margin-left: 0.5rem;">
            <svg width="20" height="20">
            <rect width="20" height="20" style="stroke-width:3;" [ngStyle]="{'fill': properties['color_punto'], 'stroke':properties['color_punto']}"/>
            </svg>
          </div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">MOSTRAR GEOPUNTO:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="visible_punto" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">MOSTRAR NOMBRE:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="nombre_visible_punto" [readonly]="true"></p-checkbox>
        </div>
    </div>
</div>

<div *ngIf="subject_type == 'Zone'" >
    <div class="d-flex">
          <b>NOMBRE:</b><div style="margin-left: 0.5rem;">{{properties['nombre_zona']}}</div>
    </div>

    <div class="d-flex">
          <b>COLOR DE GEOCERCA:</b><div style="margin-left: 0.5rem;">
            <svg width="20" height="20">
            <rect width="20" height="20" style="stroke-width:3;" [ngStyle]="{'fill': properties['color_zona'], 'stroke':properties['color_zona']}"/>
            </svg>
          </div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">VELOCIDAD:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="vel_act_zona" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex">
        <b>COLOR DE TEXTO:</b><div style="margin-left: 0.5rem;">
          <svg width="20" height="20">
          <rect width="20" height="20" style="stroke-width:3;" [ngStyle]="{'fill': properties['tag_name_color'], 'stroke':properties['tag_name_color']}"/>
          </svg>
        </div>
    </div>

    <div class="d-flex">
        <b>TAMAÑO DE TEXTO:</b><div style="margin-left: 0.5rem;">{{properties['tag_name_font_size']}}px</div>
    </div>

    <div class="d-flex">
        <b>LÍMITE DE VELOCIDAD:</b><div style="margin-left: 0.5rem;">{{properties['vel_max']}} km/h</div>
    </div>

    <div class="d-flex">
        <b>TOLERABLE:</b><div style="margin-left: 0.5rem;">{{properties['vel_zona']}} km/h</div>
    </div>

    <div class="d-flex">
        <b>GRAVE:</b><div style="margin-left: 0.5rem;">{{properties['vel2_zona']}} km/h</div>
    </div>

    <div class="d-flex">
        <b>MUY GRAVE:</b><div style="margin-left: 0.5rem;">{{properties['vel3_zona']}} km/h</div>
    </div>

</div>

<div *ngIf="subject_type == 'EventUser'">
    <div class="d-flex">
          <b>NOMBRE:</b><div style="margin-left: 0.5rem;">{{properties['nombre']}}</div>
    </div>

    <div class="d-flex">
        <b>ALERTA:</b><div style="margin-left: 0.5rem;">{{properties['tipo']}}</div>
    </div>

    <div class="d-flex" style="word-wrap: break-word;">
        <b>VEHÍCULOS:</b><div style="margin-left: 0.5rem; word-wrap: break-word;">{{placas_vehiculos}}</div>
    </div>

    <div *ngIf="properties['array_geo']" class="d-flex" style="word-wrap: break-word;">
        <b>GEOCERCAS:</b><div style="margin-left: 0.5rem; word-wrap: break-word;">{{nombres_geocercas}}</div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">EVENTO ACTIVADO:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="activo" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">ALERTA DEL SISTEMA:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="alerta_sistema" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex">
        <b>TIEMPO LIMITE:</b><div style="margin-left: 0.5rem;">
            {{properties['duracion_parada']}} 
            <span *ngIf="properties['duracion_formato_parada'] == 'S'"> Segundos</span>
            <span *ngIf="properties['duracion_formato_parada'] == 'M'"> Minutos</span>
            <span *ngIf="properties['duracion_formato_parada'] == 'H'"> Horas</span>
        </div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">FIJAR TIEMPO:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="bol_fijar_tiempo" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div *ngIf="bol_fijar_tiempo && properties['tiempo_limite_infraccion']" class="d-flex" style="word-wrap: break-word;">
        <b>FIJAR TIEMPO:</b><div style="margin-left: 0.5rem;">{{properties['tiempo_limite_infraccion']}} segundos</div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">FIJAR LIMITE DE VELOCIDAD:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="bol_fijar_velocidad" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div *ngIf="bol_fijar_velocidad && properties['velocidad_limite_infraccion']" class="d-flex" style="word-wrap: break-word;">
        <b>FIJAR LIMITE DE VELOCIDAD:</b><div style="margin-left: 0.5rem;">{{properties['velocidad_limite_infraccion']}} km/h</div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">ENVIAR AL CORREO ELECTRÓNICO:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="notificacion_email" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex" *ngIf="notificacion_email">
        <b>CORREO ELECTRÓNICO:</b><div style="margin-left: 0.5rem;">{{properties['notificacion_direcion_email']}}</div>
    </div>


</div>

<div *ngIf="subject_type == 'UserConfig'">
    <div class="d-flex">
        <b>NOMBRE DE USUARIO:</b><div style="margin-left: 0.5rem;">{{properties['nombre_usuario']}}</div>
    </div>

    <div class="d-flex">
        <b>PRIVILEGIOS:</b><div style="margin-left: 0.5rem;">{{properties['privilegios']}}</div>
    </div>

    <div class="d-flex">
        <b>EMAIL:</b><div style="margin-left: 0.5rem;">{{properties['email']}}</div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">ACTIVO:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="activo" [readonly]="true"></p-checkbox>
        </div>
    </div>
</div>

<div *ngIf="subject_type == 'UserDetail'">
    
    <div class="d-flex" style="word-wrap: break-word;">
        <b>VEHÍCULOS:</b><div style="margin-left: 0.5rem; word-wrap: break-word;">{{placas_vehiculos}}</div>
    </div>

    <div *ngIf="properties['array_zonas']" class="d-flex" style="word-wrap: break-word;">
        <b>GEOCERCAS:</b><div style="margin-left: 0.5rem; word-wrap: break-word;">{{nombres_geocercas}}</div>
    </div>

    <div *ngIf="properties['array_zonas']" class="d-flex" style="word-wrap: break-word;">
        <b>GEOPUNTOS:</b><div style="margin-left: 0.5rem; word-wrap: break-word;">{{nombres_puntos}}</div>
    </div>

</div>

<div *ngIf="subject_type == 'GroupConvoy'">
    <div class="d-flex">
        <b>NOMBRE DEL CONVOY:</b><div style="margin-left: 0.5rem;">{{properties['nombre']}}</div>
    </div>
</div>

<div *ngIf="subject_type == 'UserTracker'">
    
    <div class="d-flex" *ngIf="properties['vehiculos']">
        <b>VEHÍCULOS:</b><div style="margin-left: 0.5rem;">{{properties['vehiculos']}}</div>
    </div>

    <div *ngIf="!properties['vehiculos']">
        <div class="d-flex">
            <b>NOMBRE / COD. INTERNO:</b><div style="margin-left: 0.5rem;">{{properties['nombre']}}</div>
        </div>

        <div class="d-flex">
            <b>IMEI:</b><div style="margin-left: 0.5rem;">{{properties['tracker_imei']}}</div>
        </div>

        <div class="d-flex">
            <b>NÚMERO SIM:</b><div style="margin-left: 0.5rem;">{{properties['numero_sim']}}</div>
        </div>

        <div class="d-flex">
            <b>PLACA:</b><div style="margin-left: 0.5rem;">{{properties['numero_placa']}}</div>
        </div>

        <div class="d-flex">
            <b>CISTERNA / TOLVA:</b><div style="margin-left: 0.5rem;">{{(properties['numero_tolva'] !== null) ? (properties['numero_tolva']) : "-"}}</div>
        </div>

        <div class="d-flex">
            <b>CONDUCTOR:</b><div style="margin-left: 0.5rem;">{{(nombreConductor) ? (nombreConductor) : "-"}}</div>
        </div>

        <div class="d-flex">
            <b>EMPRESA:</b><div style="margin-left: 0.5rem;">{{(properties['empresa'] !== null) ? (properties['empresa']) : "-"}}</div>
        </div>

        <div class="d-flex">
            <b>GRUPO:</b><div style="margin-left: 0.5rem;">{{(properties['grupo_convoy_id'] !== null) ? (properties['nombre_grupo']) : "-"}}</div>
        </div>

        <div class="d-flex">
            <b>TIPO DE UNIDAD:</b><div style="margin-left: 0.5rem;">{{tipoVehiculo}}</div>
        </div>
        
        <div class="d-flex align-items-center" *ngIf="properties['icono']">
            <b>ICONO:</b><img style="margin-left: 0.5rem;" src="assets/images/objects/nuevo/{{properties['icono']}}"/>
        </div>
    </div>

</div>

<div *ngIf="subject_type == 'CircularZone'" >
    <div class="d-flex">
          <b>NOMBRE:</b><div style="margin-left: 0.5rem;">{{properties['var_nombre']}}</div>
    </div>

    <div class="d-flex">
        <b>AREA:</b><div style="margin-left: 0.5rem;">{{properties['dbl_area'] | number:'.0-2'}} km2</div>
    </div>

    <div class="d-flex">
        <b>RADIO:</b><div style="margin-left: 0.5rem;">{{properties['dbl_radio'] | number:'.0-2'}} km</div>
    </div>

    <div class="d-flex">
        <b class="form-check-label">RELLENO:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="relleno" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex">
          <b>COLOR DE GEOCERCA:</b><div style="margin-left: 0.5rem;">
            <svg width="20" height="20">
            <rect width="20" height="20" style="stroke-width:3;" [ngStyle]="{'fill': properties['var_color'], 'stroke':properties['var_color']}"/>
            </svg>
          </div>
    </div>

    <div class="d-flex">
        <b>COLOR DE TEXTO:</b><div style="margin-left: 0.5rem;">
          <svg width="20" height="20">
          <rect width="20" height="20" style="stroke-width:3;" [ngStyle]="{'fill': properties['tag_name_color'], 'stroke':properties['tag_name_color']}"/>
          </svg>
        </div>
    </div>

    <div class="d-flex">
        <b>TAMAÑO DE TEXTO:</b><div style="margin-left: 0.5rem;">{{properties['tag_name_font_size']}}px</div>
    </div>

    <!-- <div class="d-flex">
        <b class="form-check-label">VELOCIDAD:</b>
        <div style="margin-left: 0.5rem;">
          <p-checkbox name="groupname" [binary]="true" [(ngModel)]="bol_limite_velocidad_activo" [readonly]="true"></p-checkbox>
        </div>
    </div>

    <div class="d-flex">
        <b>LÍMITE DE VELOCIDAD:</b><div style="margin-left: 0.5rem;">{{properties['int_limite_velocidad_0']}} km/h</div>
    </div>

    <div class="d-flex">
        <b>TOLERABLE:</b><div style="margin-left: 0.5rem;">{{properties['int_limite_velocidad_1']}} km/h</div>
    </div>

    <div class="d-flex">
        <b>GRAVE:</b><div style="margin-left: 0.5rem;">{{properties['int_limite_velocidad_2']}} km/h</div>
    </div>

    <div class="d-flex">
        <b>MUY GRAVE:</b><div style="margin-left: 0.5rem;">{{properties['int_limite_velocidad_3']}} km/h</div>
    </div> -->

</div>

<div *ngIf="subject_type != 'Point' && 
            subject_type != 'Zone' && 
            subject_type != 'EventUser' && 
            subject_type != 'UserConfig' && 
            subject_type != 'UserDetail' &&
            subject_type != 'GroupConvoy' &&
            subject_type != 'UserTracker' &&
            subject_type != 'CircularZone'
            ">

    <div *ngFor="let item of properties | keyvalue" style="word-wrap: break-word;"><b [ngStyle]="{'color': color}">{{item.key}}</b>: {{(item.value !== null) ? (item.value) : "-"}}</div>
</div>

