import { Injectable } from '@angular/core';
import { DirectionStyle } from '../models/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectionStyleService {
  private directionStyle: DirectionStyle = {
    color: '#0a72ff',
    start: 'path/to/default-start-icon.svg',
    end: 'path/to/default-end-icon.svg',
    style: 'solid',
  };
  private directionStyleSubject: BehaviorSubject<DirectionStyle> =
    new BehaviorSubject<DirectionStyle>(this.directionStyle);

  constructor() {}
  get directionStyle$() {
    return this.directionStyleSubject.asObservable();
  }
  setDirectionStyle(newStyle: Partial<DirectionStyle>) {
    this.directionStyleSubject.next({ ...this.directionStyle, ...newStyle });
  }
  private updateDirectionStyle() {
    this.directionStyleSubject.next(this.directionStyle);
  }
}
