import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from 'src/app/alerts/models/alert.interface';

interface Data {
  alert: Alert;
  htmlElement: HTMLElement;
}
@Injectable({
  providedIn: 'root',
})
export class ComunicationService {
  private eventSource = new Subject<Data>();
  event$ = this.eventSource.asObservable();

  eventData(event: Alert, currentRow: HTMLElement): void {
    const data: Data = {
      alert: event,
      htmlElement: currentRow,
    };
    this.eventSource.next(data);
  }

  constructor() {}
}
