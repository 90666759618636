<div id="panelMonitoreo" class="{{panelService.clasePanelActivo}}">

  <div class="card w-100">
    <div class="card-header header-panel-monitoreo pt-2" *ngIf="hasHeader()">
      <!-- <div class="card-header header-panel-monitoreo" (click)="clickHidePanel()" > -->
      <div class="d-flex align-items-center">
        <div class="circled-fa"  (click)="clickHidePanel()">
          <div class="d-flex flex-column justify-content-center" style="height: 100%; width: 100%;">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <span>{{panelService.nombreCabecera}}</span>
        </div>
      </div>
      <!-- <i class="fa fas fa-caret-left"></i> -->
      <!-- <div ngbDropdown *ngIf="panelService.nombreComponente.includes('ALERTS')"  role="button" container="body" placement="right-top left-top" onclick="this.blur();" [autoClose]="true">
        <svg ngbDropdownToggle xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
        <ul ngbDropdownMenu aria-labelledby="dropdownAlert">
          <li><a ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS')">Todas las alertas</a></li>
          <li><a ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-GPS')">Alertas GPS</a></li>
          <li><a ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-PLATFORMS')">Alertas Plataformas</a></li>
          <li><a ngbDropdownItem (click)="panelService.clickHeaderToggle('ALERTS-ACCESSORIES')">Alertas Seguridad Vehicular</a></li>
        </ul>
      </div> -->

    </div>

    <div class="component-containers position-relative">
      <ng-container *ngIf="(panelService.nombreComponente == 'GEOPOINTS')">
        <app-panel-geopoints></app-panel-geopoints>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'HISTORIAL')">
        <app-panel-historial ></app-panel-historial>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'VEHICLES')">
        <app-panel-vehicles></app-panel-vehicles>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS')">
        <app-panel-alerts></app-panel-alerts>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-GPS')">
        <app-panel-alerts-gps></app-panel-alerts-gps>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-GPS-CREATE')">
        <app-gps-alerts-create></app-gps-alerts-create>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-GPS-EDIT')">
        <app-alert-gps-edit></app-alert-gps-edit>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-PLATFORMS')">
        <app-panel-alerts-platform></app-panel-alerts-platform>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-PLATFORM-CREATE')">
        <app-platform-alerts-create></app-platform-alerts-create>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-PLATFORM-EDIT')">
        <app-platform-alerts-edit></app-platform-alerts-edit>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-ACCESSORIES')
      ">
        <app-panel-alerts-accessories></app-panel-alerts-accessories>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-SECURITY')">
        <app-panel-alerts-accessories></app-panel-alerts-accessories>
      </ng-container>


      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-MOBILE')">
        <app-panel-alerts-accessories></app-panel-alerts-accessories>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-360')">
        <app-panel-alerts-accessories></app-panel-alerts-accessories>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-SECURITY-CREATE')">
        <app-alert-accessories-create></app-alert-accessories-create>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-MOBILE-CREATE')">
        <app-alert-accessories-create></app-alert-accessories-create>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-360-CREATE')">
        <app-alert-accessories-create></app-alert-accessories-create>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-SECURITY-EDIT')">
        <app-alert-accessories-edit></app-alert-accessories-edit>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-MOBILE-EDIT')">
        <app-alert-accessories-edit></app-alert-accessories-edit>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'ALERTS-360-EDIT')">
        <app-alert-accessories-edit></app-alert-accessories-edit>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'GEOFENCES')">
        <app-geocerca-main></app-geocerca-main>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'CIRCULAR-GEOFENCE')">
        <app-geocerca-circular-main></app-geocerca-circular-main>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'POLYLINE-GEOFENCE')">
        <app-geocerca-polyline-main></app-geocerca-polyline-main>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'EVENT-USER')">
        <app-event-board></app-event-board>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'USER-CONFIG')">
        <app-user-config></app-user-config>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'USER-INFO')">
        <app-user-info></app-user-info>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'DASHBOARD')">
        <app-panal-dashboard></app-panal-dashboard>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'AUDITORIA')">
        <app-audit></app-audit>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'SUBCUENTAS')">
        <app-subcuentas-panel></app-subcuentas-panel>
      </ng-container>

      <ng-container *ngIf="(panelService.nombreComponente == 'DRIVERS')">
        <app-drivers-panel></app-drivers-panel>
      </ng-container>

    </div>




  </div>
  <div id="dragbar">
    <div class="d-flex flex-column justify-content-center" style="padding: 0.75rem; height: 3rem; width: 3rem; margin-left: 16px; margin-bottom: 6px; border-radius: 50%;">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 767.5 580.5" xml:space="preserve">
        <path d="M0,290.25,290.25,580.5V0Z"/><path d="M767.5,290.25,477.25,0V580.5Z"/>
      </svg>
    </div>
  </div>
</div>
