<div class="">
  <div class="pb-4" [ngClass]="{ disabled: !geofenceDirectionActivated }">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          *ngFor="let geofenceDirection of geofenceDirections; let i = index"
          class="nav-link"
          [ngClass]="{
            active:
              (isAddingNew && i === geofenceDirections.length - 1) ||
              (!isAddingNew &&
                geofenceDirections.length <= directionsLimit &&
                i === 0)
          }"
          [id]="'nav-' + geofenceDirection.id + '-tab'"
          data-bs-toggle="tab"
          [attr.data-bs-target]="'#nav-' + geofenceDirection.id"
          role="tab"
          type="button"
          aria-selected="false"
          (click)="tabChange()"
          [attr.aria-controls]="'nav-' + geofenceDirection.id"
          style="padding: 0.5rem 0.5rem"
          [disabled]="!tabChangeEnabled()"
        >
          <div class="d-flex justify-content-between" style="max-width: 7rem">
            <div
              class="flex-fill text-center align-content-center"
              [style]="{
                'text-wrap': 'nowrap',
                'text-overflow': 'ellipsis',
                overflow: 'hidden'
              }"
            >
              {{ geofenceDirection.name }}
            </div>
            <div
              ngbDropdown
              class="btn-group dropend"
              container="body"
              placement="right-top right-bottom left-top left-bottom"
            >
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-ellipsis-v"
                class="p-button-sm p-button-text dropdown-toggle-split"
                style="color: currentColor"
                ngbDropdownToggle
              ></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button
                  *ngIf="false"
                  ngbDropdownItem
                  pButton
                  pRipple
                  label="Redibujar dirección"
                  type="button"
                  icon="pi pi-refresh"
                ></button>
                <button
                  ngbDropdownItem
                  pButton
                  pRipple
                  label="Modificar dirección"
                  (click)="updateDirection(geofenceDirection)"
                  type="button"
                  icon="pi pi-cog"
                  [disabled]="!tabChangeEnabled()"
                ></button>
                <button
                  ngbDropdownItem
                  pButton
                  pRipple
                  label="Eliminar dirección"
                  (click)="deleteDirection(geofenceDirection)"
                  type="button"
                  icon="pi pi-trash"
                  [disabled]="!tabChangeEnabled()"
                ></button>
              </div>
            </div>
          </div>
        </button>
        <div
          *ngIf="geofenceDirections.length < directionsLimit"
          class="d-flex flex-column justify-content-center align-items-center"
          role="tab"
        >
          <button
            pButton
            pRipple
            (click)="createDirection()"
            type="button"
            label="Añadir"
            icon="pi pi-plus"
            [class]="
              geofenceDirections.length == 0
                ? 'p-button-sm p-3 flex-grow-1'
                : 'p-button-sm p-button-text flex-grow-1'
            "
            [disabled]="!createDirectionEnable()"
          ></button>
        </div>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        *ngFor="let geofenceDirection of geofenceDirections; let i = index"
        class="tab-pane fade"
        [ngClass]="{
          active:
            (isAddingNew && i === geofenceDirections.length - 1) ||
            (!isAddingNew &&
              geofenceDirections.length <= directionsLimit &&
              i === 0),
          show:
            (isAddingNew && i === geofenceDirections.length - 1) ||
            (!isAddingNew &&
              geofenceDirections.length <= directionsLimit &&
              i === 0)
        }"
        [id]="'nav-' + geofenceDirection.id"
        role="tabpanel"
        [attr.aria-labelledby]="'nav-' + geofenceDirection.id + '-tab'"
        [tabindex]="i"
      >
        <div class="tab-content d-flex flex-column gap-5 mt-5">
          <!-- <div class="">
            <div class="row">
              <div class="col p-field">
                <div class="col-12">
                  <div class="p-float-label">
                    <p-dropdown
                      [options]="directions"
                      optionDisabled="selected"
                      [(ngModel)]="geofenceDirection.type"
                      optionLabel="label"
                      optionValue="value"
                      [appendTo]="'body'"
                      [autoDisplayFirst]="false"
                      showClear="true"
                      (onChange)="disabledOption($event, geofenceDirection.id)"
                    >
                    </p-dropdown>
                    <label for="float-input">Dirección:</label>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="">
            <div class="row">
              <div class="col p-field">
                <div class="col-12">
                  <div class="p-float-label">
                    <input
                      id="float-input"
                      [(ngModel)]="geofenceDirection.name"
                      type="text"
                      pInputText
                      aria-describedby="nameDirection-help"
                    />
                    <label for="float-input">Nombre de la Dirección:</label>
                  </div>
                  <!-- <small
                    *ngIf="!geofenceDirection.name.trim()"
                    id="nameDirection-help"
                    class="p-error"
                    >Nombre de la dirección no debe estar vacio.</small
                  > -->
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col p-field">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <label
                      [style]="{
                        left: '0.5rem',
                        color: '#6c757d'
                      }"
                      >Dirección con Velocidad</label
                    >
                  </div>
                </div>
              </div>
              <div class="col p-field">
                <div class="col-12">
                  <div class="p-float-label">
                    <p-dropdown
                      [options]="booleanOptions"
                      [(ngModel)]="geofenceDirection.hasSpeed"
                      optionLabel="label"
                      optionValue="value"
                      [appendTo]="'body'"
                      [autoDisplayFirst]="false"
                    ></p-dropdown>
                    <label for="float-input">Activar:</label>
                  </div>
                </div>
              </div>
              <div class="col p-field">
                <div class="col-12">
                  <div class="p-float-label">
                    <p-inputNumber
                      id="float-input"
                      class="flex-grow-1"
                      suffix=" Km/h"
                      [(ngModel)]="geofenceDirection.speed"
                      [disabled]="!geofenceDirection.hasSpeed"
                    ></p-inputNumber>
                    <label for="float-input">Velocidad:</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
