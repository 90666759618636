<div class="d-flex flex-column" style="height: 100%;">

  <div class="card-body p-fluid d-flex bg-gl-blue-dark" id="rowBusqueda" [innerHTML]="this.geopointsService.action == 'edit' ? 'Configurar Geopunto' : 'Nuevo Geopunto'">
  </div>

  <!-- <button type="submit" (click)="fin_editar()" class="btn btn-success btn-sm" >fin editar</button> -->

  <div class="modal-body panel-izq-container p-fluid overflow-auto" style="position: relative;">
    
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="spinnerLoading" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" class="text-center"> Guardando cambios... </p></ngx-spinner>

    <div class="panel-izq-form gl-form">
      <div class="gl-row-underline">
        <div class="col-12">
            <label>NOMBRE:</label>
        </div>
        <div class="col-12">
          <input type="text" class="form-control" id="geofence-name" name="nombre" [(ngModel)]="form.geopunto_name" required pInputText>
        </div>
      </div>

      <div class="gl-row-underline">
        <div class="col-12">
          <label>COLOR:</label>
        </div>
        <div class="row">
            <div class="col-12 d-flex">
              <input type="color" class="form-control form-control-color form-control-color-gl flex-grow-1" id="geoColor" (change)="changeGeoColor(form.geopunto_id)" [(ngModel)]="form.geopunto_color"  title="Elige un color">
              <label role="button" class="p-dropdown-trigger flex-none d-flex align-items-center" for="color_picker">
                <span class="p-dropdown-trigger-icon pi pi-chevron-down" style="color: #6c757d;"></span>
              </label>
            </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center">
        <div class="col-5 d-flex flex-column justify-content-center">
          <label class="row">
            <label class="col-9 form-check-label">MOSTRAR GEOPUNTO:</label>
            <div class="col-2 d-flex flex-column justify-content-center">
              <p-checkbox name="groupname" [binary]="true" [(ngModel)]="form.geopunto_visible_bol" [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
          </label>
        </div>
        <div class="col-5 d-flex flex-column justify-content-center">
          <label class="row">
            <label class="col-9 form-check-label">MOSTRAR NOMBRE:</label>
            <div class="col-2 d-flex flex-column justify-content-center">
              <p-checkbox name="groupname" [binary]="true" [(ngModel)]="form.geopunto_nombre_visible_bol" [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
          </label>
        </div>
      </div>

      <div class="row justify-content-center mt-4 panel-izq-container-footer">
        <div class="col-6">
            <button type="button" class="btn-gl-cancel btn-dark-hover w-100" ui-sref="alertas-accesorios" (click)="clickCancelar(form.geopunto_id)">Cancelar</button>
        </div>
        <div class="col-6">
            <button type="submit" class="btn-gl-save btn-dark-hover w-100" (click)="clickGuardar(form.geopunto_id)">Guardar</button>
        </div>
      </div>



    </div>



    <!-- <div class="panel-izq-form">
      <label class="row">
        <span class="col-4">Nombre:</span>
        <div class="col-8">
          <input type="text" class="form-control" id="geofence-name" name="nombre" [(ngModel)]="form.geopunto_name" required>
        </div>
      </label>

      <div class="row"></div>

      <div class="row d-flex justify-content-center">
        <div class="col-5 d-flex flex-column justify-content-center">
          <label class="row">
            <span class="col-9 form-check-label">Mostrar geopunto</span>
            <div class="col-2 d-flex flex-column justify-content-center">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="" icheck [(ngModel)]="form.geopunto_visible_bol">
              </div>
            </div>
          </label>
        </div>
        <div class="col-5 d-flex flex-column justify-content-center">
          <label class="row">
            <span class="col-9 form-check-label">Mostrar nombre</span>
            <div class="col-2 d-flex flex-column justify-content-center">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="" icheck [(ngModel)]="form.geopunto_nombre_visible_bol">
              </div>
            </div>
          </label>
        </div>
      </div>

      <div class="row"></div>

      <div class="row">
        <span class="col-4">Color:</span>
        <div class="col-8">
          <input type="color" class="form-control form-control-color form-control-color-gl" id="geoColor" (change)="changeGeoColor(form.geopunto_id)" [(ngModel)]="form.geopunto_color"  title="Elige un color">
        </div>
      </div>

      <div class="modal-footer">
        <div class="row w-100 justify-content-around">
          <div class="col-5">
            <button type="button" (click)="clickCancelar(form.geopunto_id)" class="btn btn-success w-100" >Cancelar</button>
          </div>
          <div class="col-5">
            <button type="submit" (click)="clickGuardar(form.geopunto_id)" class="btn btn-success w-100" >Guardar</button>
          </div>
        </div>
      </div>

    </div>
  </div> -->

</div>
