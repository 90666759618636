<span
  class="btn-label"
  title="Mostrar Conductor"
  data-bs-toggle="tooltip"
  data-bs-placement="top"
>
  <!--   <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="667.000000pt"
    height="617.000000pt"
    viewBox="0 0 667.000000 617.000000"
    preserveAspectRatio="xMidYMid meet"
    style="width: 100%;height: 30px;"
  >
    <g
      transform="translate(0.000000,617.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M3190 6024 c-240 -46 -431 -172 -552 -365 -47 -75 -84 -163 -104
-245 -21 -90 -22 -267 0 -356 56 -239 217 -439 436 -542 124 -59 186 -71 350
-71 136 1 151 3 236 32 294 102 492 334 541 634 45 272 -63 559 -276 735 -74
62 -215 136 -306 160 -59 16 -273 28 -325 18z"
      />
      <path
        d="M3290 4284 c-25 -2 -135 -8 -245 -15 -149 -8 -215 -16 -260 -31 -92
-30 -329 -125 -439 -176 l-99 -45 -166 51 c-91 28 -227 70 -302 93 -144 44
-173 46 -208 8 -10 -11 -103 -217 -206 -457 -103 -240 -421 -981 -706 -1645
-305 -710 -519 -1221 -519 -1239 0 -39 41 -77 106 -100 27 -9 198 -68 379
-131 1205 -420 1061 -380 1275 -352 63 9 452 60 864 115 413 55 767 100 788
100 23 0 214 -61 485 -156 595 -207 808 -279 866 -293 26 -6 89 -11 140 -11
83 0 94 2 105 21 7 11 124 298 258 637 135 340 326 820 424 1067 99 248 285
717 415 1042 152 384 235 605 235 628 0 39 -33 79 -74 91 -20 6 -283 89 -511
161 -60 19 -135 43 -165 53 -30 10 -91 29 -135 43 -44 14 -172 54 -285 90
-338 108 -303 101 -471 87 l-147 -12 -164 65 c-256 103 -666 257 -681 257 -8
0 -32 12 -53 28 l-39 27 -210 1 c-115 1 -230 0 -255 -2z m-1459 -345 c71 -23
129 -45 129 -48 0 -4 -55 -34 -122 -67 -110 -53 -128 -65 -170 -117 -57 -73
-102 -162 -131 -264 -22 -78 -22 -79 -22 -963 l0 -885 22 -40 c52 -97 81 -107
324 -114 l196 -6 -144 -360 c-79 -198 -166 -420 -195 -493 -28 -72 -56 -132
-63 -132 -6 0 -194 63 -416 141 -222 77 -507 176 -633 220 -126 44 -231 81
-233 82 -1 2 179 428 402 948 223 519 521 1213 662 1542 141 328 259 597 261
597 3 0 63 -19 133 -41z m3676 -375 c252 -80 508 -161 568 -180 61 -20 125
-40 143 -46 17 -5 32 -13 32 -18 0 -5 -120 -309 -266 -677 -146 -367 -290
-729 -319 -803 -29 -74 -181 -457 -338 -850 -156 -393 -292 -737 -302 -763
-15 -40 -22 -48 -39 -44 -12 3 -113 37 -226 77 -315 111 -602 211 -630 220
-113 37 -406 142 -411 148 -4 4 71 187 165 407 l173 400 449 5 450 5 49 25
c38 20 55 37 75 75 l25 49 0 891 c-1 986 2 949 -67 1097 -28 61 -29 70 -18 98
7 16 16 30 20 30 5 0 215 -66 467 -146z m-1668 -2130 c9 -8 -322 -765 -338
-773 -9 -5 -59 -14 -111 -20 -52 -6 -158 -20 -235 -31 -77 -11 -228 -31 -335
-45 -107 -14 -278 -37 -380 -51 -531 -71 -554 -74 -559 -69 -7 8 10 51 197
520 l187 470 445 5 c427 5 1120 1 1129 -6z"
      />
    </g>
  </svg> -->
  <i class="fas fa-male fa-lg" style="color: #000000;"></i>
</span>
