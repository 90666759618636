<div class="h-100 d-flex justify-content-center mx-2" (click)="onClickIcon()">
  <div style="width: 20px; height: 20px;margin-top: 10px;" class="flex-none justify-content-center align-items-center vehicle-status-column me-2 ">
    <div class="vehicle-transmission-status" style="background-color:{{nameColor}};" title="{{tooltipText}}" data-bs-toggle="tooltip" data-bs-placement="top" container="body"></div>
  </div>
  <div style="width: 25px; height: 25px;" class="flex-none icon-float-right p-0 vehicle-icon-column me-2">
    <img border="0" width="25" height="25" [src]="params.value.sanitizer_custom_svg">
    <!-- <div #svgContainer id="svgContainer_{{ params.value.id }}" border="0" style="width: 25px; height: 25px">
    </div> -->
  </div>
  <div class="flex-grow-1 d-flex flex-column justify-content-center text-start vehicle-data ps-0" style="line-height: 0.875rem;">
    <strong>{{params.value.name}}</strong>
    <span>{{params.value.dt_tracker}}</span>
  </div>
</div>
