<div class="table-content-component-panel-monitoreo" style="position: relative;">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" name="loadingCircularGeofencesSpiner" size="medium" color="#fff"
        type="ball-pulse" [fullScreen]="false">
        <p style="color: white"> Cargando Geocercas circulares... </p>
    </ngx-spinner>

    <div class="card-body p-fluid bg-gl-blue-dark" id="rowBusqueda">
        <div class="d-flex">
            <div class="d-flex flex-grow-1">
                <span class="p-input-icon-right flex-grow-1 me-2">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" (keyup)="onBusqueda()" [(ngModel)]="NomBusqueda" class="form-control" placeholder="Buscar por nombre">
                </span>
            </div>

            <button type="button" *ngIf="showBtnAdd" class="btn-gl-blue btn-dark-hover btn-circle flex-none" (click)="clickAgregarGeocerca()"
                title="Crear geocerca circular">
                <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <div class="panel-izq-table-container">
        <table id="tbl_circular_geofences">
            <colgroup>
                <col width="25%" />
                <col width="8%" />
                <col width="28%" />
                <col width="13%" />

                <col *ngIf="showBtnEdit" width="13%" />
                <col *ngIf="showBtnEdit" width="13%" />
                <col *ngIf="!showBtnEdit" width="26%" />
            </colgroup>
            <thead class="visible-on-scroll">
                <tr>
                    <th>
                        <p-inputSwitch [(ngModel)]="eyeInputSwitch" (click)="onClickEye()" class="d-flex justify-content-center align-items-center" ngDefaultControl>
                        </p-inputSwitch>
                    </th>
                    <th colspan="2">Geocerca</th>
                    <th>
                        <i class="fa fa-id-card fa-lg mini-eye-container" (click)="onClickTagNamesEye()">
                            <div>
                                <i *ngIf="tagNamesEyeState" class="fas fa-eye mini-eye"></i>
                                <i *ngIf="!tagNamesEyeState" class="far fa-eye-slash mini-eye"></i>
                            </div>
                        </i>
                    </th>
                    <th>
                        <svg class="ag-grid-header-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
                            y="0px" width="24px" height="24px" viewBox="0 4 48 48" enable-background="new 0 0 48 48"
                            xml:space="preserve">
                            <g>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.99,39.633l0.323,0.859c0.046,0.084,0.062,0.126,0.098,0.207
                      l0.343,0.581l1.36,1.353c0.293,0.202,0.644,0.394,0.985,0.503c0.251,0.078,0.198,0.12,0.437,0.178
                      c0.158,0.038,0.374,0.042,0.527,0.087c0.521,0.153,0.87,0.111,1.427,0.111l1.023-0.208c0.423-0.122,1.134-0.48,1.493-0.761
                      c0.036-0.031,0.049-0.039,0.087-0.067c0.127-0.091,0.225-0.162,0.345-0.271l0.278-0.285c0.51-0.561,0.601-0.754,0.977-1.428
                      c0.131-0.234,0.083-0.161,0.165-0.452c0.065-0.226,0.112-0.137,0.167-0.45c0.033-0.194,0.031-0.399,0.063-0.552
                      c0.147-0.728,0.044-1.678-0.206-2.328l-0.607-1.236c-0.055-0.081-0.082-0.141-0.14-0.219c-0.221-0.301-0.496-0.565-0.771-0.816
                      l-0.679-0.501c-0.01-0.004-0.021-0.013-0.03-0.02c-0.323-0.215-0.357-0.5-0.372-0.91c-0.017-0.503-0.171-0.77-0.209-1.277
                      c-0.013-0.167-0.06-0.45-0.103-0.616l-0.332-1.918c-0.042-0.311-0.026-0.395-0.107-0.664c-0.111-0.362-0.325-2.204-0.42-2.552
                      c-0.098-0.351-0.087-0.899-0.211-1.275c-0.118-0.359-0.11-0.856-0.216-1.269l-0.539-3.201c-0.026-0.212-0.028-0.388-0.169-0.551
                      c-0.04,0.172-0.066,0.138-0.095,0.316l-0.158,0.968c-0.047,0.205-0.063,0.494-0.089,0.681l-0.336,1.868
                      c-0.037,0.17-0.078,0.458-0.089,0.678c-0.026,0.479-0.182,0.776-0.207,1.279c-0.014,0.272-0.081,0.357-0.129,0.587
                      c-0.054,0.239-0.055,0.432-0.083,0.686l-0.34,1.862c-0.058,0.203-0.074,0.483-0.098,0.673c-0.042,0.331-0.049,0.337-0.12,0.596
                      c-0.083,0.311-0.049,0.698-0.125,1.003c-0.033,0.133-0.044,0.098-0.084,0.276c-0.091,0.405-0.094,0.866-0.205,1.279
                      c-0.185,0.684,0.094,1.091-0.497,1.5l-0.151,0.11c-0.089,0.055-0.14,0.07-0.225,0.13l-0.331,0.285
                      c-0.746,0.69-1.375,1.578-1.623,2.579c-0.113,0.468-0.14,0.991-0.14,1.5L18.99,39.633z" />
                                <g>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0,27.678v4.151h0.101c0,0.376,0.086,0.792,0.104,1.179
                        c0.01,0.229,0.374,2.322,0.481,2.592l0.424,1.47c0.079,0.196,0.087,0.243,0.147,0.468l0.605,1.546
                        c0.141,0.376,0.142,0.29,0.338,0.583l1.884-0.986c0.362-0.155,0.864-0.264,1.09-0.6c-0.083-0.362-0.721-1.765-0.858-2.37
                        l-0.194-0.627c-0.067-0.241-0.042-0.413-0.127-0.642l-0.285-1.301c-0.091-0.487-0.091-1-0.176-1.467
                        c-0.163-0.917-0.125-2.127-0.053-3.024l0.22-2.188c0.045-0.243,0.11-0.365,0.152-0.671l0.456-1.949
                        c0.058-0.338,0.338-0.869,0.409-1.18c0.079-0.342,0.441-1.062,0.588-1.411l0.529-1.11l2.063-3.111
                        c0.135-0.167,0.213-0.29,0.361-0.458c0.124-0.142,0.233-0.28,0.349-0.42l2.011-1.983c0.367-0.31,0.743-0.65,1.148-0.903
                        l2.201-1.435c0.012-0.007,0.022-0.014,0.031-0.021c0.012-0.006,0.025-0.01,0.034-0.018l1.513-0.741
                        c0.34-0.18,0.816-0.316,1.115-0.474c0.373-0.196,0.808-0.241,1.184-0.405l1.244-0.345c0.263-0.045,0.41-0.138,0.643-0.176
                        c0.254-0.041,0.493-0.049,0.712-0.109c0.315-0.082,0.258-0.076,0.66-0.107c0.18-0.013,0.531-0.042,0.744-0.075
                        c0.25-0.037,0.474-0.087,0.654-0.115c0.542-0.076,2.63-0.071,3.086,0.016c0.434,0.083,0.948,0.158,1.414,0.179l1.36,0.227
                        c0.242,0.054,0.382,0.138,0.647,0.174c0.265,0.035,0.338,0.113,0.592,0.173l1.25,0.39c0.126,0.053,0.185,0.047,0.318,0.091
                        c0.207,0.071,0.106,0.064,0.274,0.136c0.118,0.048,0.196,0.048,0.313,0.097l4.802,2.782c0.157,0.125,0.275,0.222,0.424,0.343
                        l1.896,1.742c0.458,0.471,0.841,0.997,1.277,1.491c0.326,0.369,0.55,0.763,0.845,1.148l0.864,1.393
                        c0.004,0.009,0.016,0.022,0.02,0.031l0.047,0.058c0.004,0.01,0.015,0.02,0.02,0.029c0.066,0.108,0.056,0.135,0.113,0.245
                        l1.453,3.365c0.076,0.289,0.147,0.331,0.198,0.621c0.036,0.215,0.156,0.476,0.239,0.938c0.037,0.205,0.044,0.16,0.1,0.312
                        l0.262,1.324c0.042,0.242,0.028,0.434,0.065,0.705c0.025,0.206,0.061,0.481,0.098,0.721c0.146,0.993,0.144,2.752-0.025,3.787
                        c-0.069,0.418-0.073,0.978-0.167,1.42c-0.055,0.263-0.113,0.356-0.156,0.665l-0.275,1.312l-0.937,2.65
                        c-0.046,0.098-0.065,0.165-0.089,0.271c0.169,0.253,0.463,0.316,0.719,0.458c0.165,0.09,0.251,0.082,0.406,0.157l1.848,0.971
                        c0.125-0.189,0.185-0.141,0.276-0.389c0.033-0.089,0.051-0.17,0.076-0.232l0.754-2.014c0.069-0.184,0.067-0.308,0.129-0.482
                        c0.065-0.186,0.107-0.219,0.147-0.47c0.044-0.265,0.078-0.316,0.149-0.516c0.06-0.171,0.062-0.362,0.09-0.522l0.205-1.026
                        c0.113-0.706,0.273-1.427,0.273-2.187h0.103v-4.151h-0.103c0-0.609-0.086-1.299-0.233-1.917c-0.058-0.24-0.053-0.379-0.083-0.637
                        c-0.025-0.229-0.104-0.34-0.151-0.563c-0.04-0.183-0.044-0.423-0.111-0.609l-1.271-3.75c-0.051-0.109-0.064-0.078-0.128-0.23
                        L45.23,18.52c-0.065-0.125-0.078-0.104-0.14-0.22c-0.054-0.096-0.06-0.149-0.109-0.25c-0.038-0.073-0.076-0.129-0.134-0.227
                        l-0.143-0.213c-0.09-0.139-0.051-0.107-0.118-0.243l-0.857-1.295c-0.068-0.089-0.093-0.107-0.153-0.206
                        c-0.051-0.077-0.022-0.048-0.075-0.129l-0.37-0.502c-0.054-0.064-0.118-0.129-0.167-0.192l-3.237-3.422
                        c-0.011-0.009-0.043-0.04-0.051-0.051c-0.009-0.004-0.02-0.016-0.027-0.024l-0.593-0.483c-0.155-0.101-0.074-0.045-0.203-0.154
                        l-0.138-0.118c-0.008-0.009-0.02-0.017-0.027-0.026c-0.24-0.229-0.772-0.592-1.066-0.776l-1.771-1.097
                        c-0.356-0.187-0.687-0.423-1.059-0.583c-0.014-0.005-0.101-0.036-0.111-0.042c-0.174-0.073-0.089-0.057-0.227-0.131l-2.537-1.05
                        c-0.425-0.193-1.693-0.494-2.212-0.657C29.498,6.363,29.3,6.367,29.1,6.316c-0.193-0.049-0.362-0.125-0.57-0.146l-3.178-0.407
                        c-0.659-0.078-2.726-0.036-3.41,0.104c-0.208,0.042-0.413,0.021-0.678,0.04c-0.774,0.062-0.688,0.116-1.232,0.203
                        c-0.214,0.034-0.421,0.031-0.646,0.071l-1.74,0.412l-3.184,1.122c-0.136,0.075-0.036,0.044-0.238,0.118
                        c-0.155,0.06-0.118,0.038-0.247,0.113c-0.118,0.069-0.095,0.065-0.24,0.118l-0.487,0.23c-0.559,0.231-1.096,0.612-1.634,0.876
                        c-0.376,0.185-0.734,0.476-1.077,0.717l-0.84,0.594c-0.098,0.069-0.137,0.084-0.219,0.14l-2.928,2.656
                        c-0.519,0.541-1.024,1.106-1.467,1.709l-0.983,1.323c-0.053,0.088-0.064,0.135-0.131,0.227c-0.063,0.086-0.054,0.06-0.092,0.115
                        L3.28,17.638c-0.273,0.39-0.808,1.429-0.991,1.88l-1.286,3.376c-0.067,0.265-0.145,0.258-0.185,0.534
                        c-0.02,0.149-0.014,0.191-0.051,0.307c-0.171,0.543-0.096,0.416-0.207,0.868c-0.054,0.211-0.125,0.305-0.149,0.57
                        c-0.074,0.867-0.311,1.478-0.311,2.505H0z" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.578,15.538c0,0.221,0.293,0.523,0.448,0.73
                        c0.086,0.118,0.151,0.209,0.233,0.331l2.45,3.392c0.056,0.081-0.008-0.006,0.067,0.084l0.432,0.592
                        c0.089,0.123,0.187,0.238,0.266,0.351c0.201-0.095,0.197-0.006,0.411-0.151c0.079-0.053,0.087-0.069,0.176-0.132l0.149-0.106
                        c0.009-0.007,0.018-0.018,0.026-0.024l0.261-0.203c0.009-0.004,0.023-0.014,0.029-0.02l1.764-1.26l-0.438-0.739
                        c0,0-0.042-0.056-0.045-0.06c-0.088-0.125-0.098-0.149-0.193-0.265l-1.486-2.049c-0.296-0.367-1.611-2.305-1.776-2.379
                        c-0.241-0.106-0.339,0.071-0.47,0.16c-0.133,0.088-0.138,0.088-0.27,0.193c-0.09,0.071-0.024,0.019-0.12,0.085
                        c-0.014,0.008-0.049,0.033-0.062,0.042c-0.01,0.003-0.02,0.013-0.03,0.019l-1.602,1.165C11.689,15.36,11.578,15.382,11.578,15.538
                        " />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.828,23.406c-0.065-0.271-0.069-0.144-0.152-0.361
                        c-0.106-0.28-0.068-0.66-0.387-0.68c-0.143-0.01-0.242,0.104-0.39,0.153c-0.156,0.055-0.26,0.038-0.42,0.094l-1.598,0.553
                        c-0.148,0.054-0.28,0.054-0.411,0.103l-1.201,0.386c-0.236,0.064-0.167,0.1-0.401,0.162c-0.16,0.044-0.267,0.038-0.415,0.1
                        c-0.456,0.198-0.471,0.147-0.794,0.281c-0.185,0.076-0.244,0.06-0.44,0.122c-0.141,0.044-0.216,0.105-0.245,0.258
                        c-0.022,0.123,0.133,0.499,0.211,0.81c0.051,0.209,0.104,0.243,0.155,0.408c0.045,0.142,0.045,0.275,0.101,0.414
                        c0.075,0.193,0.11,0.127,0.16,0.403c0.047,0.267,0.02,0.169,0.124,0.385c0.123,0.247,0.078,0.683,0.343,0.683
                        c0.318,0,0.113-0.017,0.32-0.086l0.578-0.191c0.167-0.068,0.286-0.06,0.452-0.111c0.214-0.067,0.125-0.09,0.361-0.152
                        c0.879-0.236,2.072-0.706,2.846-0.895c0.23-0.057,0.163-0.088,0.36-0.153c0.152-0.049,0.301-0.046,0.454-0.109
                        c0.43-0.178,1.033-0.271,1.033-0.506L41.828,23.406z" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.716,19.123l1.733,1.237c0.009,0.007,0.022,0.016,0.031,0.023
                        c0.007,0.006,0.02,0.016,0.027,0.02c0.009,0.006,0.022,0.016,0.031,0.022l0.555,0.421c0.242,0.177,0.222,0.069,0.44,0.173
                        l2.682-3.671c0.062-0.098,0.06-0.098,0.12-0.185c0.251-0.363,1.092-1.391,1.092-1.625c0-0.167-0.263-0.249-0.601-0.527
                        c-0.138-0.109-0.199-0.12-0.333-0.229l-1.627-1.191c-0.196,0-0.275,0.081-0.37,0.194l-1.266,1.756
                        c-0.067,0.103-0.045,0.083-0.129,0.18l-1.403,1.927c-0.091,0.131-0.162,0.233-0.26,0.356l-0.31,0.407
                        c-0.007,0.009-0.015,0.02-0.022,0.029C29.941,18.658,29.776,18.866,29.716,19.123" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.937,27.678c0.165,0,0.197-0.169,0.236-0.326
                        c0.118-0.513,0.093-0.183,0.249-0.83c0.053-0.228,0.076-0.146,0.149-0.361l0.392-1.25c0.289-0.621-0.207-0.55-0.653-0.73
                        l-4.458-1.433c-0.147-0.04-0.203-0.096-0.363-0.145c-0.191-0.058-0.274-0.031-0.454-0.116c-0.111-0.046-0.213-0.14-0.356-0.116
                        c-0.314,0.058-0.448,1.147-0.641,1.509c-0.113,0.214-0.059,0.203-0.115,0.448c-0.057,0.226-0.081,0.146-0.152,0.36
                        c-0.049,0.142-0.056,0.262-0.106,0.404c-0.118,0.334-0.422,0.521,0.573,0.755c0.216,0.051,0.203,0.098,0.403,0.16
                        c0.167,0.051,0.243,0.036,0.409,0.104l1.567,0.531c0.171,0.056,0.28,0.059,0.44,0.123c0.477,0.197,0.329,0.073,0.794,0.284
                        c0.197,0.087,0.211,0.039,0.415,0.098c0.195,0.057,0.185,0.109,0.402,0.16l0.986,0.294C11.878,27.688,11.549,27.678,11.937,27.678
                        " />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.345,37.26c0.12-0.162,0.138-0.068,0.24-0.324l0.744-2.383
                        c0.133-0.309,0.205-0.145,0.093-0.623c-0.289-0.023-0.407-0.074-0.64-0.178l-3-0.948c-0.27-0.076-0.143-0.099-0.619-0.201
                        c-0.287-0.06-0.311-0.154-0.563-0.205c-0.554-0.106-0.278-0.12-0.612-0.204c-0.142-0.036-0.191-0.029-0.333-0.08
                        c-0.115-0.04-0.135-0.069-0.251-0.109c-0.632-0.226-0.514,0.267-0.718,0.721l-0.386,1.204c-0.165,0.362-0.088,0.374-0.189,0.63
                        c-0.053,0.135-0.082,0.144-0.125,0.293c-0.098,0.36,0.114,0.376,0.17,0.4l0.585,0.183c0.294,0.062,0.185,0.081,0.403,0.162
                        c0.158,0.061,0.247,0.047,0.414,0.099c0.334,0.104,0.777,0.3,1.19,0.398c0.205,0.046,0.198,0.089,0.363,0.146
                        c0.171,0.061,0.261,0.03,0.454,0.112l1.206,0.382c0.233,0.065,0.162,0.103,0.396,0.17c0.15,0.044,0.267,0.036,0.409,0.102
                        c0.165,0.076,0.118,0.098,0.368,0.145C41.193,37.198,41.154,37.216,41.345,37.26" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.436,36.971c0.108,0.245,0.103,0.123,0.225,0.289
                        c0.14-0.031,0.095-0.042,0.229-0.077l0.576-0.191c0.154-0.063,0.294-0.051,0.452-0.111c0.218-0.085,0.087-0.09,0.361-0.155
                        l1.199-0.386c0.154-0.063,0.269-0.045,0.417-0.099c0.197-0.068,0.09-0.086,0.407-0.155l2.587-0.845
                        c0.074-0.038,0.158-0.084,0.154-0.267l-0.721-2.248c-0.2-0.443-0.091-0.465-0.196-0.625c-0.211-0.321-0.632-0.031-0.815,0.027
                        c-0.431,0.136-0.006-0.051-0.612,0.207c-0.126,0.054-0.141,0.036-0.293,0.064c-0.26,0.054-0.28,0.145-0.605,0.214l-0.901,0.28
                        c-0.149,0.058-0.126,0.071-0.295,0.114c-0.59,0.138-0.334,0.112-0.575,0.191c-0.392,0.129-0.826,0.218-1.208,0.38
                        c-0.218,0.096-0.41,0.085-0.632,0.189c-0.18,0.087-0.37,0.145-0.605,0.163c-0.027,0.115-0.051,0.118-0.051,0.255L6.436,36.971z" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.644,17.792h2.717c0.116,0,0.278-0.064,0.336-0.129
                        c0.114-0.129,0.045-0.129,0.027-0.332l-0.004-5.79c0.002-0.383,0.098-0.667-0.308-0.667h-2.819c-0.392,0-0.312,0.245-0.307,0.614
                        c0.011,1.122,0.051,4.964-0.002,5.792C22.279,17.371,22.118,17.792,22.644,17.792" />
                                </g>
                            </g>
                        </svg>
                    </th>
                    <th *ngIf="showBtnEdit">
                        <i class="fa fa-pencil fa-lg"></i>
                    </th>
                </tr>
            </thead>

            <tbody class="border-top: inherit;">
                <tr *ngFor="let geo of tblDataGeoFiltered; let indice=index">
                    <td>
                        <button class="btn btn-xs btn-link p-n eye-column" (click)="clickShow(geo.trama.id)">
                            <i [ngClass]="{'fas fa-eye': geo.trama.zone_visible === true, 'far fa-eye-slash' : geo.trama.zone_visible === false}"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-xs btn-link p-n" (click)="clickLocate(geo.trama.id)">
                            <div [ngStyle]="{'background-color': geo.trama.zone_color}" class="geofence-color"></div>
                        </button>
                    </td>
                    <td>
                        <span class="d-flex text-start">{{geo.trama.zone_name}}</span>
                    </td>
                    <td class="inline-png-checkbox">
                        <p-checkbox [ngModel]="geo.trama.zone_name_visible_bol" [binary]="true" (onChange)="clickShowNameGeocerca(geo.trama.id)"></p-checkbox>
                    </td>
                    <td>{{ geo.trama.int_limite_velocidad_0>0? geo.trama.int_limite_velocidad_0 + ' km/h': '&mdash;'}}</td>
                    <td *ngIf="showBtnEdit">
                        <div ngbDropdown container="body" class="d-flex justify-content-center align-items-center edit-column" [autoClose]="true" placement="right-top right-bottom left-top left-bottom">
                          <i class="fa fa-pencil fa-lg" ngbDropdownToggle></i>
                          <div ngbDropdownMenu class="gl-custom-dropdown geofences-table-dropdown">
                            <div ngbDropdownItem (click)="clickConfigurarGeocerca(geo.trama.id)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Configurar</div>
                            <div ngbDropdownItem (click)="clickEliminarGeocerca(geo.trama.id)"><i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar</div>
                          </div>
                        </div>
                    </td>
                </tr>

                <tr *ngIf="noResults">
                    <td colspan="6" class="text-center">Sin coinicidencias</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>