<p-dialog [(visible)]="display" (onHide)="onHide()" (onShow)="onShow()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" class="gl-p-dialog" [modal]="true" [resizable]="false">
    <ng-template class="p-dialog-title" pTemplate="header">
      <div>
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        <strong>Crear Operación/Grupo/Convoy</strong>
      </div>
    </ng-template>

    <div class="dialog-body-container">

      <div class="p-fluid p-formgrid p-grid">

        <div class="dialog-form-container">

          <div class="gl-middleline gl-permanent-middleline mt-2">
            <div class="row">
              <div class="col p-field">
                <div class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Creación de:</label>
                  </div>
                  <div class="col-7">
                    <p-dropdown (onChange)="onOption(option)" [options]="stateOptions" [(ngModel)]="option" optionLabel="label" optionValue="value" placeholder="Seleccione...">
                      <ng-template let-option pTemplate="item">
                        <div>
                            {{option.label}}
                        </div>
                    </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="gv_name">Nombre:</label>
                  </div>
                  <div class="col-7">
                    <input (keyup)="onName($event)" #name id="gv_name" type="text" pInputText [disabled]="loading" (change)="validateFormsInputs()" >
                  </div>
                </label>
              </div>

              <!-- <div class="col p-field" [ngStyle]="{'display':formDisplayCreate}">
                <div class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Descripción:</label>
                  </div>
                  <div class="col-7">
                    <input (keyup)="onDescription($event)" #description id="float-input" type="text" pInputText>
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div class="row gl-out-middleline">
            <div class="col p-field p-0" *ngIf="option=='grupo' || option=='convoy'">
              <div class="row gl-row-underline m-0 w-100">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label>Seleccione Operación:</label>
                </div>
                <div class="col-7">
                  <p-dropdown (onChange)="onChangeOperation(option)" [options]="operations" [(ngModel)]="selectedOperation" optionLabel="nameoperation" optionValue="idoperation" placeholder="Seleccione una Operación" [disabled]="loading"></p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="row gl-out-middleline">
            <div class="col p-field p-0" *ngIf="option=='convoy'">
              <div class="row gl-row-underline m-0 w-100">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label>Seleccione Grupo:</label>
                </div>
                <div class="col-7">
                  <p-dropdown (onChange)="onChangeGroup(option)" [options]="groups" [(ngModel)]="selectedGroup" optionLabel="namegrupo" optionValue="idgrupo" [placeholder]="placeholder_groups" [disabled]="loading"></p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-column mt-3">
            <div class="row gl-listbox-container">
                <div class="col">
                  <p-listbox [options]="list1" [(ngModel)]="selectedList1" [multiple]="true" [checkbox]="true" [filter]="true" filterPlaceHolder="Buscar por placa" optionLabel="name" [listStyle]="{'height':'200px'}" [disabled]="loading"  emptyMessage="La lista de vehículos está vacía." emptyFilterMessage="No se encontró vehículos.">
                    <ng-template let-car pTemplate="item">
                      <div class="car-item">
                        <img border="0" width="25" height="25" [src]="car.sanitizer_custom_svg" style="display:inline-block;margin:2px 0 2px 2px">
                        <label>{{car.name}}</label>
                      </div>
                    </ng-template>
                  </p-listbox>
                </div>
                <div class="col-1 d-flex flex-column justify-content-center align-items-center">
                  <div style="height: 1.5rem;"></div>
                  <div class="">
                    <button (click)="upList2()" type="button" class="btn-gl-light-blue btn-circle" name="button" [disabled]="loading">
                      <span>
                        <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div style="height: 1rem;"></div>
                  <div class="">
                    <button (click)="upList1()" type="button" class="btn-gl-light-blue btn-circle" name="button" [disabled]="loading">
                      <span>
                        <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="col">
                  <p-listbox [options]="list2" [(ngModel)]="selectedList2" [multiple]="true" [checkbox]="true" [filter]="true" filterPlaceHolder="Buscar por placa" optionLabel="name" [listStyle]="{'height':'200px'}" [disabled]="loading"  emptyMessage="La lista de vehículos está vacía." emptyFilterMessage="No se encontró vehículos.">
                    <ng-template let-car pTemplate="item">
                      <div class="car-item">
                        <img border="0" width="25" height="25" [src]="car.sanitizer_custom_svg" style="display:inline-block;margin:2px 0 2px 2px">
                        <label>{{car.name}}</label>
                      </div>
                    </ng-template>
                  </p-listbox>
                </div>

              </div>

          </div>
        </div>
      </div>
    </div>

    <p-footer>
      <div class="row d-flex justify-content-center">
        <div class="col-3">
          <button (click)="onHide()" _ngcontent-cic-c118="" type="button" class="btn-gl-cancel btn-dark-hover btn-sm w-100" [disabled]="loading">Cancelar</button>
        </div>
        <div class="col-3">
          <button (click)="onConfirmGroup()" _ngcontent-cic-c118="" type="button" class="btn-gl-save btn-dark-hover btn-sm w-100" [disabled]="loading">Guardar</button>
        </div>
      </div>
    </p-footer>
</p-dialog>
