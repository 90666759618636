<div class="container-fluid" style="background-color: var(--gl-body-background-color);">
  <div class="row">
    <div class="col-4" style="overflow: auto; height: 100vh; max-height: 100vh; padding: 0.8rem; position: relative;">
      <app-form></app-form>

    </div>
    <div class="col-8" style="overflow: auto; max-height: 100vh; padding: 0.8rem; position: relative;">
      <app-result></app-result>
    </div>


  </div>

</div>
