<p-dialog [(visible)]="nomostrarmodal" [modal]="false" [resizable]="false" [style]="{'width':'480px'}" >

  <ng-template class="p-dialog-title" pTemplate="header">
    <div >

        <!-- <div class="col-8"> -->
        <i class="fa fa-play me-2" aria-hidden="true"></i>
        <!-- <strong [innerHTML]="this.historialService.action == 'edit' ? 'Configurar Subcuenta' : 'Crear nueva Subcuenta' " ></strong> -->
        <strong>Reproductor de recorrido</strong>
        <!-- </div> -->
        <!-- <div class="col-3"> -->
        <a href="{{urlGS}}" target='_blank'>
          <i class="fa fa-crosshairs" aria-hidden="true" pTooltip="Abrir en Google Street View" tooltipPosition="right" ></i>
        </a>   
        <!-- </div> -->

    </div>
  </ng-template>


  <!-- <div class="row"><br></div> -->

  <div class="row p-3">
    <div class="col p-field">
      <div class="row ">
        <div class="col-5 d-flex align-items-center justify-content-start text-start">
          <label for="nameCreate">Operación:</label>
        </div>
        <div class="col-7">
          {{HM.nameoperation}}
          <!-- <input id="nameCreate" type="text" pInputText class="p-inputtext-sm" formControlName="nombre" autocomplete="no"> -->
        </div>
      </div>
    </div>
    <div class="col p-field">
      <div class="row ">
        <div class="col-5 d-flex align-items-center justify-content-start text-start">
          <label for="passCreate">Unidad:</label>
        </div>
        <div class="col-7">
          <span class="p-input-icon-right">
            {{HM.nombre}}
            <!-- <i class="pi pi-eye-slash" role="button" *ngIf="eyeStateCreate" (click)="eyeStateCreate = !eyeStateCreate; togglePassEye();"></i>
            <i class="pi pi-eye" role="button" *ngIf="!eyeStateCreate" (click)="eyeStateCreate = !eyeStateCreate; togglePassEye();"></i>
            <input id="passCreate" type="password" pInputText class="p-inputtext-sm" formControlName="contrasena" autocomplete="no" readonly onfocus="$(this).removeAttr('readonly');"> -->
          </span>
        </div>
      </div>
    </div>
  </div>


  <div class="row p-3">

      <div class="col-11 p-field">
    
          <div class="d-flex align-items-center justify-content-start text-start">
            <label for="nameCreate">
              {{sliderValueReferencia}}
            </label>
          </div>
          <div class="">
            {{sliderValueFecha}}
          </div>
      
      </div>

      <div class="col-1 p-field">
    
          <div class="d-flex align-items-center justify-content-center text-start">
            <label for="nameCreate">
              {{sliderValueVelocidad}}km/h
            </label>
          </div>

          <div class="d-flex align-items-center justify-content-center text-start" (click)="modalUbicar()">
            <i class="fa fa-crosshairs" aria-hidden="true" pTooltip="Ubicar Posición" tooltipPosition="right" ></i>

          </div>
      
      </div>


  </div>



  <div class="row p-3">

      <div class="col p-field">
          <div class="row ">
              <p-slider [(ngModel)]="sliderValue" [(max)]="sliderValueMax" (onChange)="onChangeSlider()" (onSlideEnd)="onSlideEndSlider()"   ></p-slider>
          </div>
      </div>

  </div>


  <!-- <div class="row p-3">

    <div class="col p-field">
        <div class="row ">
          <input type="text" pInputText [(ngModel)]="value" />
          <p-slider min="10" max="2000" [(ngModel)]="value"></p-slider>
        </div>
    </div>

  </div>
  -->






  <div class="row p-3">
    <div class="col-7 p-field">
      <div class="row ">

        <div class="col-4">
           
            <button type="button" class="btn btn-dark-hover flex-none" (click)="modalhistorialPlay()">
                <i class="fa fa-play" aria-hidden="true"></i>
            </button>

        </div>
        <div class="col-8">

            <button type="button" class="btn btn-dark-hover flex-none" (click)="modalhistorialPause()">
              <i class="fa fa-pause" aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-dark-hover flex-none" (click)="modalhistorialStop()">
              <i class="fa fa-stop" aria-hidden="true"></i>
            </button>

            <!-- <button type="button" class="btn btn-dark-hover flex-none">
                <i class="fa fa-fast-backward" aria-hidden="true"></i>
            </button> -->

            <button type="button" class="btn btn-dark-hover flex-none" (click)="modalhistorialBtnLento()" >
                <i class="fa fa-step-backward" aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-dark-hover flex-none" (click)="modalhistorialBtnRapido()" >
                <i class="fa fa-step-forward" aria-hidden="true"></i>
            </button>


            <!-- <button type="button" class="btn btn-dark-hover flex-none" >
                <i class="fa fa-fast-forward" aria-hidden="true"></i>
            </button> -->

        </div>
      </div>
    </div>

    <div class="col-5 p-field">
      <div class="row ">
        <div class="col-6 d-flex align-items-center justify-content-start text-start">
          <label>Velocidad:</label>
        </div>
        <div class="col-6">
          <span class="p-input-icon-right">

            <!-- <select id="opcionTamanoConsola" class="claseinputs">
                <option value="1" selected="selected">1x</option>
                <option value="2">2x</option>
                <option value="3">3x</option>
                <option value="2">4x</option>
                <option value="3">5x</option>
            </select> -->

            <select class="claseinputs" id="opcionVelocidadGraficoModalSlider" (change)="changeOpcionVelocidadGraficoModalSlider()" [(ngModel)]="opcionVelocidadGraficoModalSlider" title="Velocidad de Recorrido" data-bs-toggle="tooltip" data-bs-placement="top"  >
              <option [value]="item.id" *ngFor="let item of availableOpcionVelocidadGraficoModalSlider">{{item.name}}</option>
            </select>

          </span>
        </div>

      </div>
    </div>
  </div>




  <ng-template pTemplate="footer">

      <!-- <div class="row">
          <div class="col-6">
              <div class="d-flex align-items-center justify-content-center text-start">
                <label pTooltip="Precisión" tooltipPosition="right"  >
                    {{sliderValueDistanciaImg}}
                </label>
              </div>
          </div>

          <div class="col-6">
            <div class="d-flex align-items-center justify-content-center text-start" (click)="modalUbicar2()">
              <i class="fa fa-crosshairs" aria-hidden="true" pTooltip="Ubicar Referencia" tooltipPosition="right" ></i>
            </div> 
          </div>

          <div style="width: 100%; background-color: rgb(255, 255, 255);">
            <img [(src)]="sliderValueImgUrl" width="100%" (error)="onImgError($event)">
          </div>
        
      </div>
     -->
  </ng-template>


</p-dialog>

