<span
  class="btn-label"
  title="Mostrar nombre"
  data-bs-toggle="tooltip"
  data-bs-placement="top"
>
  <!-- <i class="fas fa-id-card ag-grid-header-icon"></i> -->

  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M0 165 l0 -75 40 0 c38 0 40 -2 34 -24 -8 -35 4 -66 27 -66 10 0 19
 5 19 10 0 6 11 10 25 10 14 0 25 -4 25 -10 0 -5 9 -10 20 -10 22 0 36 36 25
 69 -5 15 -2 21 9 21 13 0 16 14 16 75 l0 75 -120 0 -120 0 0 -75z m177 58
 c-31 -2 -83 -2 -115 0 -31 2 -5 3 58 3 63 0 89 -1 57 -3z m53 -68 c0 -42 -3
 -55 -15 -55 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -11 10
 -25 10 -14 0 -25 -4 -25 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5
 -18 -10 -40 -10 l-40 0 0 55 0 55 110 0 110 0 0 -55z m-73 -31 c-3 -3 -12 -4
 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m41 -31 c3 -10 -12 -13 -52 -13 -51 0
 -66 7 -50 23 11 11 97 2 102 -10z m-5 -30 c-7 -2 -13 -11 -13 -19 0 -9 -12
 -14 -36 -14 -27 0 -35 4 -31 14 4 9 -1 17 -11 19 -9 3 10 5 43 5 33 0 54 -2
 48 -5z"
      />
      <path
        d="M20 165 c0 -33 2 -35 35 -35 33 0 35 2 35 35 0 33 -2 35 -35 35 -33
 0 -35 -2 -35 -35z m60 10 c0 -7 -11 -12 -25 -12 -14 0 -25 5 -25 12 0 7 11 13
 25 13 14 0 25 -6 25 -13z m-7 -32 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
 -2 13 -5z"
      />
      <path d="M153 193 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
      <path
        d="M138 173 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"
      />
      <path
        d="M138 153 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"
      />
      <path d="M33 113 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -17 0 -22 -2 -12 -5z" />
    </g>
  </svg>
</span>
