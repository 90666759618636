<span
  class="btn-label"
  title="Seguir vehículo"
  data-bs-toggle="tooltip"
  data-bs-placement="top"
  (click)="onClick()"
>
  <!-- <i class="fas fa-crosshairs ag-grid-header-icon"></i> -->

  <!-- <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="708.000000pt"
    height="708.000000pt"
    viewBox="0 0 708.000000 708.000000"
    preserveAspectRatio="xMidYMid meet"
    style="width: 78%; height: 30px"
  >
    <g
      transform="translate(0.000000,708.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M3405 6983 c-1521 -53 -2834 -1105 -3215 -2578 -308 -1186 34 -2442
 899 -3305 728 -726 1719 -1086 2741 -995 867 77 1649 460 2234 1093 908 982
 1164 2415 653 3658 -171 416 -419 786 -747 1115 -615 616 -1396 962 -2280
 1009 -96 5 -182 9 -190 8 -8 -1 -51 -3 -95 -5z m349 -593 c249 -19 508 -74
 741 -157 714 -255 1319 -810 1636 -1502 60 -130 157 -406 147 -417 -10 -10
 -246 29 -993 161 -526 93 -660 128 -945 250 -204 87 -338 132 -478 161 -115
 23 -143 25 -287 21 -247 -7 -335 -34 -695 -212 -266 -131 -361 -170 -495 -205
 -164 -42 -535 -105 -790 -134 -272 -32 -811 -74 -820 -64 -3 2 4 38 16 79 84
 307 257 642 479 929 41 52 128 151 194 219 613 630 1416 935 2290 871z m-1
 -1866 c260 -58 482 -260 574 -520 26 -75 28 -89 28 -249 0 -161 -2 -174 -28
 -250 -74 -210 -246 -396 -442 -478 -144 -60 -335 -76 -485 -42 -116 26 -265
 104 -350 182 -175 162 -260 354 -260 588 0 366 237 673 590 764 97 25 273 27
 373 5z m1507 -1319 c202 -25 449 -84 680 -161 108 -36 346 -128 367 -142 20
 -13 -45 -244 -126 -444 -315 -787 -1037 -1440 -1839 -1663 -34 -10 -65 -15
 -68 -12 -11 11 -64 289 -89 467 -92 652 -34 1272 153 1632 135 263 467 379
 922 323z m-2985 -16 c126 -21 269 -77 363 -141 192 -132 340 -416 404 -773 63
 -362 32 -827 -88 -1285 -31 -115 -64 -222 -73 -232 -9 -10 -202 41 -336 90
 -162 58 -270 108 -425 195 -222 126 -420 277 -615 472 -352 351 -595 760 -729
 1230 -14 49 -26 97 -26 106 0 44 617 258 920 319 193 38 441 46 605 19z"
      />
      <path
        d="M3412 4380 c-278 -73 -481 -334 -482 -617 -1 -362 287 -653 645 -653
 174 0 332 66 455 190 133 134 190 268 190 449 0 154 -39 273 -126 392 -149
 203 -436 303 -682 239z"
      />
    </g>
  </svg> -->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style="color: black;"
  >
    <path
      d="M9.5 10.287c0-.41-.336-.743-.75-.743s-.75.333-.75.743.336.743.75.743.75-.333.75-.743zm4.5.495c0-.137-.112-.248-.25-.248h-3.5c-.138 0-.25.111-.25.248s.112.248.25.248h3.5c.138-.001.25-.112.25-.248zm2-.495c0-.41-.336-.743-.75-.743s-.75.333-.75.743.336.743.75.743.75-.333.75-.743zm-8.649-3.219h-1.101c-.138 0-.25.111-.25.248v.253c0 .393.463.49.808.49l.543-.991zm9.659 1.569c-.435-.8-.866-1.597-1.342-2.382-.393-.649-.685-.96-1.375-1.083-.698-.124-1.341-.172-2.293-.172s-1.595.048-2.292.172c-.69.123-.982.433-1.375 1.083-.477.785-.907 1.582-1.343 2.382-.344.63-.49 1.194-.49 1.884 0 .653.21 1.195.5 1.89v1.094c0 .273.224.495.5.495h.75c.276 0 .5-.222.5-.495v-.495h6.5v.495c0 .273.224.495.5.495h.75c.276 0 .5-.222.5-.495v-1.094c.29-.695.5-1.237.5-1.89 0-.69-.146-1.254-.49-1.884zm-7.821-1.873c.335-.554.426-.569.695-.617.635-.113 1.228-.157 2.116-.157s1.481.044 2.116.156c.269.048.36.064.695.617.204.337.405.687.597 1.03-.728.11-2.01.266-3.408.266-1.524 0-2.759-.166-3.402-.275.19-.34.389-.686.591-1.02zm5.798 5.256h-5.974c-.836 0-1.513-.671-1.513-1.498 0-.813.253-1.199.592-1.821.52.101 1.984.348 3.908.348 1.74 0 3.28-.225 3.917-.333.332.609.583.995.583 1.805 0 .828-.677 1.499-1.513 1.499zm2.763-4.952c.138 0 .25.111.25.248v.253c0 .393-.463.49-.808.49l-.543-.99h1.101zm-5.75-7.068c-5.523 0-10 4.394-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.421-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8c4.419 0 8 3.582 8 8s-3.581 8-8 8z"
    />
  </svg>
</span>
