<p-dialog [(visible)]="showDialog" (onHide)="onHide()">
  <ng-template pTemplate="header" class="p-dialog-title"
    >Exportar Geocercas</ng-template
  >
  <ng-container *ngIf="loading">
    <div class="p-0" style="width: 700px">
      <div class="row mb-4">
        <div class="d-flex">
          <div class="col-12">
            <p-dropdown
              [showClear]="true"
              [options]="listOperations"
              (onChange)="listedByTag()"
              [(ngModel)]="selectedOperation"
              optionLabel="name"
              optionValue="key"
              [filter]="true"
              filterBy="name"
              placeholder="Búsqueda por operaciones"
              [disabled]="!loading"
              autoDisplayFirst="false"
            ></p-dropdown>
          </div>

          <!-- <p-dropdown
            [options]="listOperations"
            [(ngModel)]="selectedOperation"
            placeholder="Búsqueda por operaciones"
            optionLabel="name"
            optionValue="key"
            [showClear]="false"
          ></p-dropdown> -->
        </div>
      </div>
      <div class="row">
        <p-dropdown
          autoDisplayFirst="false"
          class="col-12"
          [showClear]="true"
          (onChange)="listedByTag()"
          [options]="listTags"
          [(ngModel)]="selectedTag"
          optionLabel="var_name"
          [filter]="true"
          filterBy="var_name"
          optionValue="id"
          placeholder="Búsqueda por etiqueta"
          [disabled]="!loading || selectedOperation == null"
        ></p-dropdown>
      </div>
      <div class="flex-column mt-3">
        <div class="row gl-listbox-container">
          <div class="col">
            <p-listbox
              [options]="listGeofences"
              [(ngModel)]="selectedGeofences"
              [multiple]="true"
              [checkbox]="true"
              [filter]="true"
              emptyFilterMessage="No hay resultados de su busqueda"
              emptyMessage="Listado de geocercas vacio"
              filterPlaceHolder="Buscar geocerca por nombre"
              optionLabel="geoName"
              [listStyle]="{ height: '200px' }"
              optionDisabled="!zone_visible"
            >
              <ng-template let-geo pTemplate="item">
                <div class="car-item">
                  <div
                    *ngIf="geo['type'] == 'polig'"
                    width="25"
                    height="25"
                    class="pentagon me-2"
                    [ngStyle]="{ 'background-color': geo.zone_color }"
                  ></div>
                  <div
                    *ngIf="geo['type'] == 'circ'"
                    class="circle me-2"
                    [ngStyle]="{ 'background-color': geo.zone_color }"
                  ></div>
                  <div
                    *ngIf="geo['type'] == 'lin'"
                    class="circle me-2"
                    [ngStyle]="{ 'background-color': geo.zone_color }"
                  ></div>
                </div>
                <div class="me-2">
                  <svg
                    *ngIf="geo.type == 'circ'"
                    [style.color]="geo.colorGeofence"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                  <svg
                    *ngIf="geo.type == 'polig'"
                    [style.color]="geo.colorGeofence"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pentagon-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
                    />
                  </svg>
                </div>
                <div>
                  <label>{{ geo.geoName }}</label>
                </div>
              </ng-template>
            </p-listbox>
            <span
              >{{ selectedGeofences.length }} de
              {{ listGeofences.length }} geocercas seleccionadas</span
            >
          </div>
          <div
            class="col-1 d-flex flex-column justify-content-center align-items-center"
          >
            <div style="height: 1.5rem"></div>
            <div class="">
              <button
                (click)="upListGeofencesToExport()"
                type="button"
                class="btn-gl-light-blue btn-circle"
                name="button"
              >
                <span>
                  <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div style="height: 1rem"></div>
            <div class="">
              <button
                (click)="upListGeofences()"
                type="button"
                class="btn-gl-light-blue btn-circle"
                name="button"
              >
                <span>
                  <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="col">
            <p-listbox
              [options]="listGeofencesToExport"
              [(ngModel)]="selectedGeofencesToExport"
              [multiple]="true"
              [checkbox]="true"
              [filter]="true"
              emptyFilterMessage="No hay resultados de su busqueda"
              emptyMessage="Listado de geocercas vacio"
              filterPlaceHolder="Buscar geocerca por nombre"
              optionLabel="geoName"
              [listStyle]="{ height: '200px' }"
              optionDisabled="!zone_visible"
            >
              <ng-template let-geo pTemplate="item">
                <div class="car-item">
                  <div
                    *ngIf="geo['type'] == 'polig'"
                    width="25"
                    height="25"
                    class="pentagon me-2"
                    [ngStyle]="{ 'background-color': geo.zone_color }"
                  ></div>
                  <div
                    *ngIf="geo['type'] == 'circ'"
                    class="circle me-2"
                    [ngStyle]="{ 'background-color': geo.zone_color }"
                  ></div>
                  <div
                    *ngIf="geo['type'] == 'lin'"
                    class="circle me-2"
                    [ngStyle]="{ 'background-color': geo.zone_color }"
                  ></div>
                </div>
                <div class="me-2">
                  <svg
                    *ngIf="geo.type == 'circ'"
                    [style.color]="geo.colorGeofence"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                  <svg
                    *ngIf="geo.type == 'polig'"
                    [style.color]="geo.colorGeofence"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pentagon-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
                    />
                  </svg>
                </div>
                <div>
                  <label>{{ geo.geoName }}</label>
                </div>
              </ng-template>
            </p-listbox>
            <span>{{ listGeofencesToExport.length }} geocercas a exportar</span>
          </div>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="row d-flex justify-content-center">
        <button
          (click)="export('kml')"
          _ngcontent-cic-c118=""
          type="button"
          [style.backgroundColor]="'#ff8800'"
          class="btn-gl-save btn-dark-hover btn-sm col-3"
          [disabled]="errorToExport || !exportStatus"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-file-earmark-code"
            viewBox="0 0 16 16"
          >
            <path
              d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"
            />
            <path
              d="M8.646 6.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 9 8.646 7.354a.5.5 0 0 1 0-.708m-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 9l1.647-1.646a.5.5 0 0 0 0-.708"
            />
          </svg>
          Exportar KML
        </button>
        <!-- <button
          (click)="export('kmz')"
          _ngcontent-cic-c118=""
          type="button"
          class="btn-gl-save btn-dark-hover btn-sm col-3"
          [disabled]="errorToExport"
        >
          Exportar KMZ
        </button> -->
        <button
          (click)="export('csv')"
          _ngcontent-cic-c118=""
          type="button"
          class="btn-gl-save btn-dark-hover btn-sm col-3"
          [disabled]="errorToExport || !exportStatus"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-filetype-csv"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"
            />
          </svg>
          Exportar CSV
        </button>
      </div>
    </ng-template>
  </ng-container>
</p-dialog>
