import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private dbName = 'blobDB';
  private storeName = 'multimediaStore';
  private db!: IDBDatabase;
  public isReady = false;
  public completed:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.initializeDatabase();
  }

  private initializeDatabase(): void {
    const openDBRequest = indexedDB.open(this.dbName, 1);

    openDBRequest.onupgradeneeded = (event: any) => {
      this.db = event.target.result;
      if (!this.db.objectStoreNames.contains(this.storeName)) {
        this.db.createObjectStore(this.storeName, { keyPath: 'id', autoIncrement: true });
      }
      this.isReady = true;
      this.completed.emit();
    };

    openDBRequest.onsuccess = (event: any) => {
      this.db = event.target.result;
      this.isReady = true;
      this.completed.emit();
    };
  }

  public saveBlob(arrayBuffer: ArrayBuffer, type: string): Observable<any> {
    return new Observable(observer => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      const blobData = {
        data: arrayBuffer,
        type: type,
        timestamp: new Date().getTime()
      };
      console.log("STORING BLOB: ", blobData);
      const request = store.add(blobData);
      console.log("STORING result: ", request);
      request.onsuccess = () => {
        observer.next(request.result);
        observer.complete();
      };

      request.onerror = (error) => {
        observer.error(error);
      };
    });
  }

  public getBlob(id: string): Observable<Blob> {
    console.log("GETBLOB ID:", id);

    return new Observable(observer => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const getRequest = store.get(id);

      getRequest.onsuccess = (event: any) => {
        const blobData = event.target.result;
        if (blobData) {
          const blob = new Blob([blobData.data], { type: blobData.type });
          //console.log("BLOB RETRIVED: ", blob);

          observer.next(blob);
          observer.complete();

        } else {
          // observer.next(undefined);
          observer.error('Blob not found');
        }
      };

      getRequest.onerror = (error) => {
        observer.error(error);
      };
    });
  }

  public deleteBlob(id: string): Observable<void>{
    return new Observable<void>( observer => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const deleteRequest = store.delete(id);

      deleteRequest.onsuccess = () => {
        observer.next();
        observer.complete();
      }

      deleteRequest.onerror = (error) => {
        observer.error("Error al eliminar el elemento de IndexedDB: " + error)
      }
    });
  }

  public deleteAllBlob(): Observable<void> {
    return new Observable<void>(observer => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      // Obtener todos los elementos del almacén de objetos
      const getAllRequest = store.getAll();
      getAllRequest.onsuccess = () => {
        const items = getAllRequest.result;
        console.log("IndexedDB Lista de elementos:",items);
        items.forEach((item: any) => {
          console.log('IndexedDB -> ', item);
          // Eliminar cada elemento
          const deleteRequest = store.delete(item.id);
          deleteRequest.onsuccess = () => {
            console.log("IndexedDB Elemento eliminado");
          };
          deleteRequest.onerror = (error: any) => {
            console.error("Error al eliminar el elemento:", error);
          };
        });
        // Completar la observación una vez que se hayan eliminado todos los elementos
        observer.next();
        observer.complete();
      };

      getAllRequest.onerror = (error) => {
        observer.error("Error al obtener elementos de IndexedDB: " + error);
      };
    });
  }



  public async cleanOldItems(store: any) {
    console.log('IndexedDB CLEANER');
    const now = new Date(); // Calcula la fecha actual una vez
    const limite_time = 1 * 5 * 60 * 1000;
    console.log('IndexedDB Fecha NOW -> ', now.getTime());

    const getAllRequest = store.getAll();
    getAllRequest.onsuccess = () => {
      const items = getAllRequest.result;
      console.log("IndexedDB Lista de elementos:");
      items.forEach((item: any) => {
        console.log('IndexedDB -> ', item);
        // Validar si el timestamp del elemento es mayor a 1 hora
        const itemTimestamp = item.timestamp;
        const itemDate = new Date(itemTimestamp);
        console.log('IndexedDB -> ',itemDate,' blob:',item.id);
        if ((now.getTime() - itemDate.getTime()) > (limite_time)) {
          console.log("IndexedDB Elemento antiguo encontrado y eliminado:");
          console.log(item);

          // Eliminar el elemento antiguo
          const deleteRequest = store.delete(item.id);
          deleteRequest.onsuccess = () => {
            console.log("IndexedDB Elemento eliminado con éxito.");
          };
          deleteRequest.onerror = (error: any) => {
            console.error("IndexedDB Error al eliminar el elemento:", error);
          };
        }
      });
    };
  }

}
