import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RepProgramadosService {

  constructor() { }


  listReportes : any;
  reportesProgramados : any;

  titulo_modal_add_edit = "";

  reporteProgramado : any;


  modalReportesProgramadosActive : boolean = true;
  modalReportesProgramadosActiveDetalle : boolean = false;
  modalReportesProgramadosActiveAddEdit : boolean = false;
  
  paramsReportesProgramados : any;

  modal_add0 = false;
  modal_add = false;


}
