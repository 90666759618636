<div *ngIf="display" class="d-flex justify-content-between gap-4" [ngClass]="type=='minimap'? 'recorder-content-minimap':'recorder-content'">
    <button pButton type="button" pRipple class="p-button-text p-button-danger" label="Cerrar" (click)="close()"></button>
    <div class="sidebar-recorder d-flex justify-content-center  align-items-center gap-4">
        <p-toggleButton 
            [(ngModel)]="play"
            (click)="playButton();"
            [onIcon]="'pi pi-pause'" 
            [offIcon]="'pi pi-play'"
            [style]="{padding:'0.3rem', width:'1.2rem', border:'0px'}"
            [pTooltip]="play?'Detener':'Grabar'"
            [disabled]="type =='minimap'? !selectedElement : false"
        >
        </p-toggleButton>
        <div class="cronometer">
            <span class="fs-5 fw-light">{{hours | number: '2.0'}}:{{minutes | number: '2.0'}}:{{seconds | number: '2.0'}}</span>
        </div>
        <p-dropdown *ngIf="type == 'minimap'"
            [options]="recordingElements" 
            optionLabel="label" 
            [filter]="true" 
            filterBy="label" 
            [(ngModel)]="selectedElement"
            [showClear]="true" 
            placeholder="Seleccione mapa"
            (onChange)="onSelectElement($event)"
            [appendTo]="'body'"
            [disabled]="play"
        >
        </p-dropdown>
        <div *ngIf="videoReady" class="d-flex justify-content-center gap-4">
            <button pButton type="button" label="Ver preview" class="p-button-text p-0 w-auto" (click)="showPreview = true"></button>
            <button pButton type="button" pTooltip="Descargar" class="p-button-sm p-button-text p-button-secondary p-0 w-auto" icon="pi pi-download" (click)="downloadLastVideo()"></button>
            <button pButton type="button" pTooltip="Eliminar" class="p-button-sm p-button-text p-button-danger p-0 w-auto" icon="pi pi-trash" (click)="clearRecorder()"></button>
        </div>
    </div>
    <div></div>
</div>
<p-dialog [header]="titleModal" [(visible)]="showPreview" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw',height: 'auto'}" [closable]="true">
    <div class="preview-container w-100 h-auto d-flex">
        <video class="preview-video"
        #videoPreview
        controls
        autoplay
        playsInline
        >
            <source [src]="videoBlobUrl">
        </video>
    </div>
</p-dialog>
<p-dialog [header]="'Ups!'" [(visible)]="showReplaceDialog" [breakpoints]="{'960px': '50vw', '640px': '750vw'}" [style]="{width: '30vw',height: 'auto'}" [closable]="false">
    <div class="d-flex justify-content-center flex-column pb-3 align-items-center">
        <h4 class="my-2 text-center">¿Desea guardar el video actual?</h4>
        <div class="d-flex justify-content-center gap-4">
            <button pButton type="button" label="Si" class="p-button-secondary" (click)="downloadLastVideo(); "></button>
            <button pButton type="button" label="No" class="p-button-success" (click)="play=true;downloaded=true; playButton()"></button>
        </div>
    </div>
</p-dialog>