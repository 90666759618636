<!-- <p-dialog id="modal-grafico" [(visible)]="historialService.modalActive" [modal]="false" [resizable]="true" [style]="{'width':'500px', 'height':'500px'}" > -->
  <p-dialog id="modal-grafico" [(visible)]="historialService.modalActive" [modal]="false" [resizable]="true" [style.width]="width_modal" [style.height]="height_modal" >




    <ng-template class="p-dialog-title" pTemplate="header">
        <div >
  
            <!-- <div class="col-8"> -->
            <i class="fa fa-play me-2" aria-hidden="true"></i>
            <!-- <strong [innerHTML]="this.historialService.action == 'edit' ? 'Configurar Subcuenta' : 'Crear nueva Subcuenta' " ></strong> -->
            <strong>Reproductor de recorrido 2  ->        </strong>
  
            <a href="{{urlGS}}" target='_blank'>
              <i class="fa fa-crosshairs" aria-hidden="true" pTooltip="Abrir en Google Street View" tooltipPosition="right" ></i>
            </a>  
  
        </div>
    </ng-template>
  
    <br>
    
    <div class="row p-1">
      <div class="col p-field">
        <div class="row ">
          <div class="col-5 d-flex align-items-center justify-content-start text-start">
            <label for="nameCreate">Operación:</label>
          </div>
          <div class="col-7">
            {{historialService.nameoperation}}
            <!-- <input id="nameCreate" type="text" pInputText class="p-inputtext-sm" formControlName="nombre" autocomplete="no"> -->
          </div>
        </div>
      </div>
      <div class="col p-field">
        <div class="row ">
          <div class="col-5 d-flex align-items-center justify-content-start text-start">
            <label for="passCreate">Unidad:</label>
          </div>
          <div class="col-7">
            <span class="p-input-icon-right">
              {{historialService.nombreUnidad}}
              <!-- <i class="pi pi-eye-slash" role="button" *ngIf="eyeStateCreate" (click)="eyeStateCreate = !eyeStateCreate; togglePassEye();"></i>
              <i class="pi pi-eye" role="button" *ngIf="!eyeStateCreate" (click)="eyeStateCreate = !eyeStateCreate; togglePassEye();"></i>
              <input id="passCreate" type="password" pInputText class="p-inputtext-sm" formControlName="contrasena" autocomplete="no" readonly onfocus="$(this).removeAttr('readonly');"> -->
            </span>
          </div>
        </div>
      </div>
    </div>
  
  
  
    <div class="row p-1">
  
        <div class="col-11 p-field">
      
            <div class="d-flex align-items-center justify-content-start text-start" [style]="{'width':'350px'}">
              <label for="nameCreate">
                {{sliderValueReferencia}}
              </label>
            </div>
            <div class="">
                {{sliderValueFecha}}
            </div>
        
        </div>
  
        <div class="col-1 p-field">
      
            <div class="d-flex align-items-center justify-content-center text-start">
              <label for="nameCreate">
                {{sliderValueVelocidad}}km/h
              </label>
            </div>
  
            <!-- <div class="d-flex align-items-center justify-content-center text-start" >
              <i class="fa fa-crosshairs" aria-hidden="true" pTooltip="Ubicar Posición" tooltipPosition="right" ></i>
  
            </div> -->
  
            <div class="d-flex align-items-center justify-content-center text-start" (click)="modalUbicar()">
              <i class="fa fa-crosshairs" aria-hidden="true" pTooltip="Ubicar Posición" tooltipPosition="right" ></i>
  
            </div>
        
        </div>
  
  
    </div>
  
  
    <div class="row p-1">
  
      <div class="col p-field">
          <div class="row ">
  
              <p-slider [(ngModel)]="sliderValue" [(max)]="sliderValueMax" (onChange)="onChangeSlider()" (onSlideEnd)="onSlideEndSlider()"   ></p-slider>
              <!-- <p-slider [(ngModel)]="sliderValue" [(max)]="sliderValueMax" (onChange)="onChangeSlider()" (onSlideEnd)="onSlideEndSlider()"   ></p-slider> -->
          </div>
      </div>
  
    </div>
  
  
  
    <!-- <div class="row p-3">
      <div class="col-7 p-field">
        <div class="row ">
          <div class="col-4">
              <button type="button" class="btn btn-dark-hover flex-none" (click)="clickbtnPlayConsola()" >
                  <i class="fa fa-play" aria-hidden="true"></i>
              </button>
          </div>
          <div class="col-8">
              <button type="button" class="btn btn-dark-hover flex-none" (click)="clickbtnPauseConsola()">
                <i class="fa fa-pause" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-dark-hover flex-none" (click)="clickbtnStopConsola()">
                <i class="fa fa-stop" aria-hidden="true"></i>
              </button>
  
              <button type="button" class="btn btn-dark-hover flex-none"  >
                  <i class="fa fa-step-backward" aria-hidden="true"></i>
              </button>
  
              <button type="button" class="btn btn-dark-hover flex-none" >
                  <i class="fa fa-step-forward" aria-hidden="true"></i>
              </button>
  
          </div>
        </div>
      </div>
  
      <div class="col-5 p-field">
        <div class="row ">
          <div class="col-6 d-flex align-items-center justify-content-start text-start">
            <label>Velocidad de Recorrido:</label>
          </div>
          <div class="col-6">
            <span class="p-input-icon-right">
              <select class="claseinputs" id="opcionVelocidadGraficoModalSlider" title="Velocidad de Recorrido" data-bs-toggle="tooltip" data-bs-placement="top"  >
              </select>
            </span>
          </div>
        </div>
      </div>
    </div> -->
  
    <div class="row p-1">
      <div class="p-field">
        <table style="width: 100%;">
          <tbody>
            <tr>
              <!-- <td width="200px">
                <select class="claseinputs" id="opcionGraficoConsola" (change)="changeOpcionGraficoConsola()" [(ngModel)]="opcionGraficoConsola" title="Tipo de Gráfico"  data-bs-toggle="tooltip" data-bs-placement="top">
                  <option [value]="item.id" *ngFor="let item of availableOpcionGraficoConsola">{{item.name}}</option>
                </select>
              </td> -->
              <td class="claseicon" id="btnPlayConsola" title="Iniciar recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnPlayConsola()" ><span class="fa fa-play"></span></td>
              <td class="claseicon" id="btnPauseConsola" title="Pausar recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnPauseConsola()" ><span class="fa fa-pause"></span></td>
              <td class="claseicon" id="btnStopConsola" title="Terminar recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnStopConsola()" ><span class="fa fa-stop"></span></td>
  
              <td class="claseicon" id="btnBajarVelocidadConsola" title="Bajar velocidad de recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="modalhistorialBtnLento()" ><span class="fa fa-step-backward"></span></td>
              <td class="claseicon" id="btnAumentarVelocidadConsola" title="Aumentar Velocidad de recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="modalhistorialBtnRapido()" ><span class="fa fa fa-step-forward"></span></td>
  
  
              <td width="80px">
  
                <select class="claseinputs" id="opcionVelocidadGraficoConsola" (change)="changeOpcionVelocidadGraficoConsola()" [(ngModel)]="opcionVelocidadGraficoConsola" title="Velocidad de Recorrido" data-bs-toggle="tooltip" data-bs-placement="top"  >
                  <option [value]="item.id" *ngFor="let item of availableOpcionVelocidadGraficoConsola">{{item.name}}</option>
                </select>
  
              </td>
     
              <td id="choices" width="20px" align="left" style="padding-left: 40px;"></td>
              <!-- <td id="datoslegend" width="400px" align="left" [innerHTML]="datosLegend" >
              </td> -->
              <!-- <td class="claseicon" id="btnIzqConsola" title="Desplazar a la Izquierda" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnIzqConsola()" ><span class="fa fa-chevron-left"></span></td>
              <td class="claseicon" id="btnDerConsola" title="Desplazar a la Derecha" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnDerConsola()" ><span class="fa fa-chevron-right"></span></td>
              <td class="claseicon" id="btnZoomInConsola" title="Zoom +" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnZoomInConsola()" ><span class="fa fa-search-plus"></span></td>
              <td class="claseicon" id="btnZoomOutConsola" title="Zoom -" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnZoomOutConsola()" ><span class="fa fa-search-minus"></span></td> -->
  
              <td width="35px" align="center">
                  <div>
                      <button id="botonverconsola" (click)="clickVerTablaRecorrido()" title="Ver Tabla del Recorrido" data-bs-toggle="tooltip" data-bs-placement="top" type="button" class="btn btn-success btn-sm btn-xss">Ver Grafico</button>
                      <button id="botonocultarconsola" (click)="clickOcultarTablaRecorrido()" title="Ocultar Tabla del Recorrido" data-bs-toggle="tooltip" data-bs-placement="top" type="button" style="display : none;" class="btn btn-primary btn-sm btn-xss">Ocultar Grafico</button>
                    </div>
              </td>
  
            </tr>
          </tbody>
        </table>
  
      </div>
    </div>
  
  
  
  
  
  
  
  
  
  
        <div class="row p-1" *ngIf="verTabla">
          <div id="graficohistorial" style="width: 100%;height: 100%;" class="col-md-9">
  
  
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td width="200px">
                      <select class="claseinputs" id="opcionGraficoConsola" (change)="changeOpcionGraficoConsola()" [(ngModel)]="opcionGraficoConsola" title="Tipo de Gráfico"  data-bs-toggle="tooltip" data-bs-placement="top">
                          <option [value]="item.id" *ngFor="let item of availableOpcionGraficoConsola">{{item.name}}</option>
                      </select>
                  </td>
  
                  <td id="choices" width="20px" align="left" style="padding-left: 40px;"></td>
                  <td id="datoslegend" width="400px" align="left" [innerHTML]="datosLegend" ></td>
                  <td class="claseicon" id="btnIzqConsola" title="Desplazar a la Izquierda" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnIzqConsola()" ><span class="fa fa-chevron-left"></span></td>
                  <td class="claseicon" id="btnDerConsola" title="Desplazar a la Derecha" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnDerConsola()" ><span class="fa fa-chevron-right"></span></td>
                  <td class="claseicon" id="btnZoomInConsola" title="Zoom +" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnZoomInConsola()" ><span class="fa fa-search-plus"></span></td>
                  <td class="claseicon" id="btnZoomOutConsola" title="Zoom -" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnZoomOutConsola()" ><span class="fa fa-search-minus"></span></td>
      
                </tr>
              </tbody>
            </table>
  
  
  
  
  
            <!-- <table>
              <tbody>
                <tr>
                  <td width="150px">
  
  
                  <select class="claseinputs" id="opcionGraficoConsola" (change)="changeOpcionGraficoConsola()" [(ngModel)]="opcionGraficoConsola" title="Tipo de Gráfico"  data-bs-toggle="tooltip" data-bs-placement="top">
                    <option [value]="item.id" *ngFor="let item of availableOpcionGraficoConsola">{{item.name}}</option>
                  </select>
  
  
                  </td>
                  <td class="claseicon" id="btnPlayConsola" title="Iniciar recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnPlayConsola()" ><span class="fa fa-play"></span></td>
                  <td class="claseicon" id="btnPauseConsola" title="Pausar recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnPauseConsola()" ><span class="fa fa-pause"></span></td>
                  <td class="claseicon" id="btnStopConsola" title="Terminar recorrido" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnStopConsola()" ><span class="fa fa-stop"></span></td>
  
  
  
  
                  <td width="80px">
  
                    <select class="claseinputs" id="opcionVelocidadGraficoConsola" (change)="changeOpcionVelocidadGraficoConsola()" [(ngModel)]="opcionVelocidadGraficoConsola" title="Velocidad de Recorrido" data-bs-toggle="tooltip" data-bs-placement="top"  >
                      <option [value]="item.id" *ngFor="let item of availableOpcionVelocidadGraficoConsola">{{item.name}}</option>
                    </select>
  
                  </td>
                  <td width="60px">
                    <select class="claseinputs" id="opcionTamanoConsola" (change)="changeOpcionTamanoConsola()" [(ngModel)]="opcionTamanoConsola" title="Tamaño de Consola" data-bs-toggle="tooltip" data-bs-placement="top" >
                      <option [value]="item.id" *ngFor="let item of availableOpcionTamanoConsola">{{item.name}}</option>
                    </select>
                  </td>
                  <td id="choices" width="20px" align="left" style="padding-left: 40px;"></td>
                  <td id="datoslegend" width="400px" align="left" [innerHTML]="datosLegend" >
                  </td>
                  <td class="claseicon" id="btnIzqConsola" title="Desplazar a la Izquierda" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnIzqConsola()" ><span class="fa fa-chevron-left"></span></td>
                  <td class="claseicon" id="btnDerConsola" title="Desplazar a la Derecha" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnDerConsola()" ><span class="fa fa-chevron-right"></span></td>
                  <td class="claseicon" id="btnZoomInConsola" title="Zoom +" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnZoomInConsola()" ><span class="fa fa-search-plus"></span></td>
                  <td class="claseicon" id="btnZoomOutConsola" title="Zoom -" data-bs-toggle="tooltip" data-bs-placement="top" (click)="clickbtnZoomOutConsola()" ><span class="fa fa-search-minus"></span></td>
  
                  <td width="35px" align="center">
                      <div>
                          <button id="botonverconsola" (click)="clickVerTablaRecorrido()" title="Ver Tabla del Recorrido" data-bs-toggle="tooltip" data-bs-placement="top" type="button" class="btn btn-success btn-sm btn-xss">Ver Mensaje</button>
                          <button id="botonocultarconsola" (click)="clickOcultarTablaRecorrido()" title="Ocultar Tabla del Recorrido" data-bs-toggle="tooltip" data-bs-placement="top" type="button" style="display : none;" class="btn btn-primary btn-sm btn-xss">Ocultar Mensaje</button>
          
                        </div>
                  </td>
  
                </tr>
              </tbody>
            </table> -->
  
            <div id="placeholder" style="margin-top: 5px; border: black 1px solid;height: 160px;width: 100%;" class="demo-placeholder"></div>
            <!-- <div id="placeholder" style="width:600px;height:300px"></div> -->
            <!-- <div id="tab-mensaje"></div> -->
  
            <table id="tbl_consola" style="width: 100%;height: 100%;" *ngIf="verTabla">
              <colgroup>
                <col width="100px" />
                <col width="70px" />
                <col width="70px" />
                <col width="50px" />
                <col width="50px" />
                <col width="50px" />
                <col width="500px" />
  
              </colgroup>
              <thead>
                  <tr>
                      <td>Hora</td>
                      <td>Latitud</td>
                      <td>Longitud</td>
                      <td>Altitud</td>
                      <td>Ángulo</td>
                      <td>Velocidad</td>
                      <td>Parámetros</td>
  
                  </tr>
              </thead>
              <tbody>
  
  
  
                  <tr *ngFor="let row of tConsola;let indice=index"  (click)="moveIconG(row)"  >
  
                      <!-- <td class="text-center" style="padding: 1px !important;" >
                        <div title="Localizar"  data-bs-toggle="tooltip" data-bs-placement="bottom" >
                          <img class="icon-car" [src]="transfer.icono" [style.height]="transfer.icono_height" [style.width]="transfer.icono_width"  />
                        </div>
                      </td> -->
  
                      <td>{{row.dt_tracker}}</td>
                      <td>{{row.lat}}</td>
                      <td>{{row.lng}}</td>
                      <td>{{row.altitude}}</td>
                      <td>{{row.angle}}</td>
                      <td>{{row.speed}}</td>
                      <td style="text-align: left;overflow-y: hidden;"><div [innerHTML]="row.params2"></div></td>
  
                      <!-- <td *ngIf="indice==0">x</td>
                      <td *ngIf="indice!=0 && indice!=transfers.length-1">{{transfer.trama.temp}}</td>
                      <td *ngIf="indice!=0 && indice==transfers.length-1">x</td> -->
  
                  </tr>
              </tbody>
            </table>
  
          </div>
        </div>
  
    <ng-template pTemplate="footer">
    
    </ng-template>
  
  </p-dialog>
  
  