import { Component, OnInit } from '@angular/core';

import { RepProgramadosService } from '../../services/rep-programados.service';


import * as moment from 'moment';

@Component({
  selector: 'app-rp-show',
  templateUrl: './rp-show.component.html',
  styleUrls: ['./rp-show.component.scss']
})
export class RpShowComponent implements OnInit {

  constructor(
    public repProgramadosService: RepProgramadosService,
  ) { }


  form: any;
  reporteProgramado:any;

  ngOnInit(): void {

    this.reporteProgramado = this.repProgramadosService.reporteProgramado;

  }

}
