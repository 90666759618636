import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { RepProgramadosService } from '../../services/rep-programados.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';

@Component({
  selector: 'app-rp-add',
  templateUrl: './rp-add.component.html',
  styleUrls: ['./rp-add.component.scss']
})
export class RpAddComponent implements OnInit {

  constructor(
    public repProgramadosService: RepProgramadosService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }

  fullScreenSpinnerMsg:any = '';
  form: any;

  reports: any=[];
  reportesProgramados: any=[];
  selectedReport: any;


  nom_reporte_add:any;

  ngOnInit(): void {

    this.reports = this.repProgramadosService.listReportes;


    this.repProgramadosService.modal_add = false;
    if (this.repProgramadosService.modal_add0) {
      console.log("=============================== add");

      console.log(this.selectedReport);
      

      const hoy = Date.now();
      this.form = {
     
          nombre : '',
          hora_programado : {label:'00:00', value:0},
          hora_programado2 : new Date(moment(hoy).format("MM/DD/YYYY")),
          notificacion_email:true,
          bol_activo:true,
          notificacion_email_lista:''
  
      };

    } else {
      console.log("=============================== edit");
      console.log(this.repProgramadosService.reporteProgramado);
      
      var rp = this.repProgramadosService.reporteProgramado;

      const hoy = Date.now();
      this.form = {
          nombre_reporte    : rp.nombre_reporte,
          nombre            : rp.nombre_programacion,
          hora_programado   : {label:'00:00', value:0},
          hora_programado2  : new Date(moment(hoy).format("MM/DD/YYYY")),
          notificacion_email: rp.notificacion_email,
          bol_activo        : true,
          notificacion_email_lista:rp.notificacion_email_lista,
  
      };


    }
    


  }

  cancelarReporteProgramado() {
    console.log('=======> cancelarAddEditReporteProgramado ');
    this.repProgramadosService.modalReportesProgramadosActiveAddEdit = false;
  }


  agregarReporteProgramado() {
    console.log('=======> agregarReporteProgramadoFase2 ');

    console.log(this.reports);
    console.log(this.selectedReport);

    this.nom_reporte_add = this.reports.find((report:any) => report.codigo === this.selectedReport);
    
    console.log(this.nom_reporte_add);

    this.form.nombre_reporte = this.nom_reporte_add.value;
    




    this.repProgramadosService.modal_add = true;
    this.repProgramadosService.titulo_modal_add_edit = "Agregar Nuevo Reporte Programado";
    this.repProgramadosService.modalReportesProgramadosActiveAddEdit = true;
  }



  guardarReporteProgramado() {
    this.fullScreenSpinnerMsg = "Guardando Reporte Programado...";
    this.spinner.show("reloadSpinner");
    console.log('=======> guardarReporteProgramado ');
    console.log(this.form);
    console.log(this.repProgramadosService.paramsReportesProgramados);

    this.repProgramadosService.paramsReportesProgramados.nombre_programacion      = this.form.nombre;
    this.repProgramadosService.paramsReportesProgramados.notificacion_email       = this.form.notificacion_email;
   
    var notificacion = moment(new Date(this.form.hora_programado2));
    this.repProgramadosService.paramsReportesProgramados.notificacion_hora        = notificacion.hours();
    this.repProgramadosService.paramsReportesProgramados.notificacion_minuto      = notificacion.minutes();
    this.repProgramadosService.paramsReportesProgramados.notificacion_email_lista = this.form.notificacion_email_lista;



    var param = this.repProgramadosService.paramsReportesProgramados;
    this.http.post(environment.apiUrl + '/api/saveReporteProgramado', param).subscribe({
      next: data => {
        //console.log(this.selectedConvoy.length);
        // console.log(typeof data);
        // console.log(data);
        // this.spinner.hide("reloadSpinner");
        // this.reportService.modalReportesProgramadosActive = false;

        this.fullScreenSpinnerMsg = "Actualizando ...";

        this.repProgramadosService.modal_add0 = false;
        this.repProgramadosService.modal_add = false;

        //=============================================================================================================
        this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
          next: data => {
              // this.listTags = data.data;
              console.log("reportProgranados", data);
              this.reportesProgramados = data;
              this.repProgramadosService.reportesProgramados = this.reportesProgramados;

              for (let i = 0; i < this.reportesProgramados.length; i++) {
                const element = this.reportesProgramados[i];
                element.unidades = JSON.parse(element.p_vehiculos);
                element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                  const reporte = this.repProgramadosService.listReportes[j];
                  if (element.p_numRep == reporte.codigo ) {
                    element.nombre_reporte = reporte.value;
                  }
                }
              }
              this.spinner.hide("reloadSpinner");
          },
          error: () => {
            console.log('Fallo al obtener reportesProgramados');
          }
        });
        //=============================================================================================================
  
      },
      
    });

    

    // this.reportService.modalReportesProgramadosActiveDetalle = true;
  }

}
