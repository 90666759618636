<div id="panel_sidebar" style="display: none;">
  <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
    <li class="nav-item">
      <a class="nav-link py-3 border-bottom" (click)="clickShowPanel('VEHICLES')" title="Vehículos" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="fa fal fa-bus"></i>
      </a>
    </li>
    <li>
      <a  class="nav-link py-3 border-bottom" (click)="clickShowPanel('EVENT-USER')"  title="Eventos" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="fa far fa-bell"></i>
      </a>
    </li>

    <li ngbDropdown placement="right-bottom">
      <a href="#"  id="dropdownAlert" ngbDropdownToggle  class="nav-link py-3 border-bottom dropdown-toggle" id="dropdownAlert" title="Alertas" role="button" data-bs-toggle="dropdown" data-bs-placement="right" aria-expanded="false">
          <i class="fa far fa-exclamation-circle"></i>
      </a>
      <ul ngbDropdownMenu aria-labelledby="dropdownAlert">
          <li><a ngbDropdownItem (click)="clickShowPanel('ALERTS')">Todas las alertas</a></li>
          <li><a ngbDropdownItem (click)="clickShowPanel('ALERTS-GPS')">Alertas GPS</a></li>
          <li><a ngbDropdownItem (click)="clickShowPanel('ALERTS-PLATFORMS')">Alertas plataformas</a></li>
          <li><a ngbDropdownItem (click)="clickShowPanel('ALERTS-ACCESSORIES')">Alertas 360°</a></li>
      </ul>
    </li>
    <li>
      <a class="nav-link py-3 border-bottom" (click)="clickShowPanel('GEOFENCES')" title="Geocercas" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="fa far fa-globe"></i>
      </a>
    </li>

    <li>
      <a  class="nav-link py-3 border-bottom" (click)="clickShowPanel('GEOPOINTS')" title="Geopuntos"  data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="fa far fa-street-view"></i>
      </a>
    </li>
    <li>
      <a class="nav-link py-3 border-bottom" (click)="clickShowPanel('HISTORIAL')" title="Historial" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="fa fas fa-map"></i>
      </a>
    </li>
  </ul>
</div>
