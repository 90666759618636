import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/profile-config/services/user-data.service';

declare var $: any;

@Component({
  selector: 'app-panel-conf',
  templateUrl: './panel-conf.component.html',
  styleUrls: ['./panel-conf.component.scss']
})
export class PanelConfComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private userDataService: UserDataService,

  ) {
    /* this.recentLogIn = this.router.getCurrentNavigation()?.extras.state.recentLogIn; */
  }

  recentLogIn = false;

  ngOnInit(): void {

    if(!this.userDataService.userDataInitialized){
      console.log('(Panel-conf) User Data no está listo. Subscribiendo para obtener data...');
      this.userDataService.userDataCompleted.subscribe({
        next: (result: boolean) => {
          if(result){
            // this.getUserData();
            // variables requerida:
            // tiene Notification ? booleano
            // tipo de notificacion : ofActionSuccessful,error,warm,info
            // titulo
            // cuerpo (html)

            // if (this.userDataService.userData.nombre_usuario == 'nombre_usuario') {
            if (false) {
                setInterval(() => {
                  this.toastr.warning('Tu plan está próximo a finalizar y termina el 11/03/2024 a las 11:59pm. </br>No te quedes sin tiempo y renueva tu plan.</br>Comunicate al número 978726211 para gestionar tu renovación.', 'TU PLAN ESTA PRÓXIMO A VENCER', {
                    // this.toastr.warning( this.userDataService.userData.nombre_usuario+' : Tu plan está próximo a finalizar y termina el 11/03/2024 a las 11:59pm. </br>No te quedes sin tiempo y renueva tu plan.</br>Comunicate al número 978726211 para gestionar tu renovación.', 'TU PLAN ESTA PRÓXIMO A VENCER', {
                      
                      enableHtml: true,
                      tapToDismiss: false,
                      closeButton: true,
                      disableTimeOut: true,
                      // timeOut: 50000,
                      // closeButton: true,
                      // positionClass : 'toast-top-full-width',
                      positionClass : 'toast-bottom-full-width',
                    });

                    $("#toast-container").css("width", "50%");

                }, 10000);

                // this.toastr.warning('cuerpo', 'titulo', { });
                // this.toastr.success('cuerpo', 'titulo', { });
                // this.toastr.info('cuerpo', 'titulo', { });
                // this.toastr.error('cuerpo', 'titulo', { });
            }
          }
        },
        error: (errMsg: any) => {
          console.log('(Panel-conf) Error al obtener userData: ', errMsg);
        }
      });
    } else {
      console.log('(Panel-conf) User Data está listo. Subscribiendo para obtener data...');
      // this.getUserData();
    }

    $(document).ready(function() {
        $("body").tooltip({ selector: '[data-bs-toggle=tooltip]' });
    });
    if(history.state.recentLogIn){
      history.state.recentLogIn = false;
      this.showLogInToastr();
    }

  }

  showLogInToastr(){
    this.toastr.success('Has iniciado sesión satisfactoriamente.')
  }

  showPassUpdateToastr(){
    this.toastr.success('Has actualizado tu contraseña exitosamente.')
  }


}
