//import { Component } from '@angular/core';
import { Component, HostListener, OnInit } from '@angular/core';


import { EventSocketService } from './events/services/event-socket.service';
import { EventService } from './events/services/event.service';
import { TabService } from './panel/services/tab.service';
import { UserDataService } from './profile-config/services/user-data.service';
import { VehicleService } from './vehicles/services/vehicle.service';
import { Router } from '@angular/router';
import { SessionService } from './logout/services/session.service';
import Swal from 'sweetalert2';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-app';
  isSessionExpired = false; // Asegúrate de definir esta propiedad

  constructor(
    private eventService: EventService,
    private eventSocketService: EventSocketService,
    private userDataService: UserDataService,
    private tabService: TabService,
    private vehicleService: VehicleService,
    private router: Router, // Importar Router
    private sessionService: SessionService, // Inject SessionService

  ){    if(localStorage.getItem('user_id') != null){
      this.tabService.currentTabReady.subscribe({
        next: (response: boolean) => {
          if(response){
            if(this.tabService.requiresVehicleServices()){
              // this.vehicleService.initialize();
            }
            if(this.tabService.requiresEventServices()){
              this.eventService.getAll();
              this.eventSocketService.listen();
            } 
            if(this.tabService.requiresUserDataServices()){
              // this.userDataService.getUserData();
            }
            
          }
        },
        error: (errorMsg: any) => {
          console.log(errorMsg);
        }
      });
    }
  }
  ngOnInit(): void {
    this.checkSessionExpired();
    this.sessionService.sessionExpired().subscribe(() => {
      this.handleSessionExpired();
    });
    this.sessionService.sessionRestored().subscribe(() => {
      this.handleSessionRestored();
    });

    window.addEventListener('storage', this.handleStorageEvent.bind(this));

    
  }

  checkSessionExpired() {
    if (localStorage.getItem('sessionExpired') === 'true') {
      this.handleSessionExpired();
    }
  }

  handleSessionExpired() {
/*
   if (localStorage.getItem('manualLogout') !== 'true') {
    this.isSessionExpired = true;
  } else {
    localStorage.clear();
    window.location.reload();
  }
 */
  if (localStorage.getItem('manualLogout') !== 'true') {
    this.isSessionExpired = true;
    this.showSessionExpiredAlert();
  } else {
    localStorage.clear();
    window.location.reload();
  }
  }

  handleSessionRestored() {
    this.clearSessionExpiredState();
    //window.location.reload(); // Recargar para actualizar la sesión
    localStorage.setItem('SessionRestored', 'true');
    this.refreshAppState();

  }

  showSessionExpiredAlert() {
    Swal.fire({
      title: "Sesión Expirada",
      text: "Su sesión ha expirado. Por favor, inicie sesión de nuevo.",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
       //localStorage.clear();
        window.location.reload(); // Redirigir a la página de inicio de sesión de la aplicación Angular
      }
    });
  }

  clearSessionExpiredState() {
    this.sessionService.clearSessionExpired();
    this.isSessionExpired = false;
  }

  refreshAppState(){

  }
  handleStorageEvent(event: StorageEvent) {
    // if (event.key === 'sessionExpired' && event.newValue === 'false') {
    //   this.handleSessionRestored();
    // }

    if (event.key === 'sessionRestored' && event.newValue === 'true') {
      // Recargar la página cuando la sesión se restaure en otra pestaña
      localStorage.removeItem('sessionRestored'); // Limpiar el flag para evitar recargas infinitas
      window.location.reload();
    }
  }



}
