<div class="d-flex justify-content-center">
  <!-- <span *ngIf="sortStatus==1" (click)="onVehicleSort(-1)" class="btn-label" ngbPopover="Popover content" title="Ordenar" data-bs-toggle="tooltip" data-bs-placement="top" style="padding: 0px 5px; cursor: pointer;" container="body">
    <i  class="fas fa-sort-alpha-down ag-grid-header-icon"></i>
  </span>
  <span *ngIf="sortStatus==-1" (click)="onVehicleSort(1)" class="btn-label" ngbPopover="Popover content" title="Ordenar" data-bs-toggle="tooltip" data-bs-placement="top" style="padding: 0px 5px; cursor: pointer;" container="body">
    <i  class="fas fa-sort-alpha-up ag-grid-header-icon"></i>
  </span>
  <span (click)="onGroup()" #spanGroup class="btn-label" title="Mostrar por grupos" data-bs-toggle="tooltip" data-bs-placement="top" style="padding: 0px 5px; cursor: pointer;">
    <i  class="fas fa-sitemap ag-grid-header-icon"></i>
  </span>
  <span #spanGeneral (click)="onGeneral()" class="btn-label" title="Mostrar vista general" data-bs-toggle="tooltip" data-bs-placement="top" style="padding: 0px 5px; cursor: pointer;">
    <i class="fas fa-list ag-grid-header-icon"></i>
  </span> -->
</div>

<!-- <p>vehicle-header works!</p> -->
<!-- fas fa-sort-alpha-down -->
	<!-- fas fa-sort-alpha-down-alt -->
  <!-- fas fa-list -->
