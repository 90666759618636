import { Component, OnInit } from '@angular/core';

import { ReportService } from '../../services/report.service';

import * as moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

import Swal from 'sweetalert2';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-modal-reportes-programados',
  templateUrl: './modal-reportes-programados.component.html',
  styleUrls: ['./modal-reportes-programados.component.scss']
})
export class ModalReportesProgramadosComponent implements OnInit {

  constructor(
    public reportService:ReportService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }


  form: any;
  reportesProgramados: any;
  reporteProgramado: any;

  fullScreenSpinnerMsg:any = '';

  ngOnInit(): void {
    // this.dateInit = new Date(moment(hoy).format("MM/DD/YYYY"));
    // // this.dateEnd = new Date(moment(hoy).format("MM/DD/YYYY"));
    // this.dateEnd = this.dateInit;
    console.log(" =====> INNITIT ");
    console.log(this.reportService.paramsReportesProgramados);
    
    const hoy = Date.now();
    this.form = {
        nombre : '',
        hora_programado : {label:'00:00', value:0},
        hora_programado2 : new Date(moment(hoy).format("MM/DD/YYYY")),
        notificacion_email:true,
        notificacion_email_lista:''

    };


    this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
      next: data => {
          console.log("reportProgranados", data);
          this.reportesProgramados = data;
          for (let i = 0; i < this.reportesProgramados.length; i++) {
            const element = this.reportesProgramados[i];
            element.unidades = JSON.parse(element.p_vehiculos);
            element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
            element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");

            for (let j = 0; j < this.reportService.listReportes.length; j++) {
              const reporte = this.reportService.listReportes[j];
              if (element.p_numRep == reporte.codigo ) {
                element.nombre_reporte = reporte.value;
              }
            }
          }
      },
      error: () => {
        console.log('Fallo al obtener reportesProgramados');
        // this.errorFlag++;
        // this.endInit();
      }
    });


  }

    verDetalleReporteProgramado(rp:any){
      console.log("==== verDetalleReporteProgramado ====");
      console.log(rp);
      this.reportService.modalReportesProgramadosActiveDetalle = true;
      this.reporteProgramado = rp;
    }

    EliminarReporteProgramado(rp:any){
      console.log("==== EliminarReporteProgramado ====");
      console.log(rp);

      Swal.fire({
        // title: 'Título de la Alerta',
        text: '¿Desea Eliminiar el Reporte Programado : '+rp.nombre_programacion+' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        confirmButtonColor: '#30a9d9',
        cancelButtonText: 'No',
        cancelButtonColor: '#e3e3e3',
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log('Sí');
          this.fullScreenSpinnerMsg = 'Eliminando Reporte Programado...';
          this.spinner.show("reloadSpinner");
  
          // this.fullScreenSpinnerMsg = "Eliminando Reporte Programado...";
          // this.spinner.show("reloadSpinner");
          
          this.http.post(environment.apiUrl + '/api/deleteReporteProgramado', rp).subscribe({
            next: data => {
              //console.log(this.selectedConvoy.length);
              console.log(typeof data);
              console.log(data);
              //this.spinner.hide("reloadSpinner");
              // this.reportService.modalReportesProgramadosActive = false;
              this.fullScreenSpinnerMsg = "Actualizando ...";
              //=============================================================================================================
              this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
                next: data => {
                    console.log("reportProgranados", data);
                    this.reportesProgramados = data;
                    for (let i = 0; i < this.reportesProgramados.length; i++) {
                      const element = this.reportesProgramados[i];
                      element.unidades = JSON.parse(element.p_vehiculos);
                      element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                      element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                      for (let j = 0; j < this.reportService.listReportes.length; j++) {
                        const reporte = this.reportService.listReportes[j];
                        if (element.p_numRep == reporte.codigo ) {
                          element.nombre_reporte = reporte.value;
                        }
                      }
                    }
                    this.spinner.hide("reloadSpinner");
                },
                error: () => {
                  console.log('Fallo al obtener reportesProgramados');
                }
              });
              //=============================================================================================================
        
            },
          });


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('No');
          // this.spinner.show("reportSpinner");
 
        } else {
          //CUANDO ES ESC O X
          console.log('Cancel');
        }
      });




    }

    guardarReporteProgramado() {
      this.fullScreenSpinnerMsg = "Guardando Reporte Programado...";
      this.spinner.show("reloadSpinner");
      console.log('=======> guardarReporteProgramado ');
      console.log(this.form);
      console.log(this.reportService.paramsReportesProgramados);

      this.reportService.paramsReportesProgramados.nombre_programacion      = this.form.nombre;
      this.reportService.paramsReportesProgramados.notificacion_email       = this.form.notificacion_email;
     
      var notificacion = moment(new Date(this.form.hora_programado2));
      this.reportService.paramsReportesProgramados.notificacion_hora        = notificacion.hours();
      this.reportService.paramsReportesProgramados.notificacion_minuto      = notificacion.minutes();
      this.reportService.paramsReportesProgramados.notificacion_email_lista = this.form.notificacion_email_lista;



      var param = this.reportService.paramsReportesProgramados;
      this.http.post(environment.apiUrl + '/api/saveReporteProgramado', param).subscribe({
        next: data => {
          //console.log(this.selectedConvoy.length);
          // console.log(typeof data);
          // console.log(data);
          // this.spinner.hide("reloadSpinner");
          // this.reportService.modalReportesProgramadosActive = false;

          this.fullScreenSpinnerMsg = "Actualizando ...";
          //=============================================================================================================
          this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
            next: data => {
                // this.listTags = data.data;
                console.log("reportProgranados", data);
                this.reportesProgramados = data;
                for (let i = 0; i < this.reportesProgramados.length; i++) {
                  const element = this.reportesProgramados[i];
                  element.unidades = JSON.parse(element.p_vehiculos);
                  element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                  element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                  for (let j = 0; j < this.reportService.listReportes.length; j++) {
                    const reporte = this.reportService.listReportes[j];
                    if (element.p_numRep == reporte.codigo ) {
                      element.nombre_reporte = reporte.value;
                    }
                  }
                }
                this.spinner.hide("reloadSpinner");
            },
            error: () => {
              console.log('Fallo al obtener reportesProgramados');
            }
          });
          //=============================================================================================================
    
        },
        
      });

      

      // this.reportService.modalReportesProgramadosActiveDetalle = true;
    }

    cancelarReporteProgramado() {
      console.log('=======> cancelarReporteProgramado ');
      this.reportService.modalReportesProgramadosActive = false;
    }



}
