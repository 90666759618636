<div id="rowBusqueda" class="bg-gl-blue-dark">
  <div class="d-flex">
    <div class="d-flex flex-grow-1">

      <span class="p-input-icon-right flex-grow-1 me-2">
        <i class="fa fa-search" aria-hidden="true"></i>
        <input (keyup)="onQuickFilterChanged($event)" type="text" ng-model="something.terms" placeholder="IMEI/Placa/Código/Nombre" class="form-control ng-pristine ng-untouched ng-valid ng-empty" ng-change="something.search()" aria-invalid="false">
      </span>

      <!-- <button type="button" class="btn-gl-blue btn-circle btn-dark-hover flex-none me-2">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button> -->

      <div ngbDropdown container="body" placement="right-top left-top" class="flex-none">
        <button ngbDropdownToggle class="btn-gl-gray-blue btn-dark-hover btn-circle">
          <i class="fa fa-fw fa-bars fa-1" aria-hidden="true"></i>
        </button>
        <div ngbDropdownMenu class="dropdown-customize-vehicle-panel">
          <div class="text-center">
            Personalizar Panel
          </div>
          <div class="container pt-2 png-checkbox-container">
            <!-- Filtrar Nombre -->
            <div class="button-group">
              <p-selectButton [options]="vehicleService.optionsFilterNameVehicle" [(ngModel)]="vehicleService.selectedFilterNameVehicle" (onChange)="onChangeSelection($event.value)"></p-selectButton>
            </div>
            <div class="dropdown-divider"></div>
            <div>
              <span (click)="onClickSetting('eye')">
                <p-checkbox [(ngModel)]="!setting['eye']" [binary]="true"></p-checkbox>
                Ver
              </span>
            </div>
            <div>
              <label>
                <p-checkbox [(ngModel)]="vehicleTransmissionStatus" [binary]="true"></p-checkbox>
                Estado de Transmisión
              </label>
            </div>
            <div>
              <label>
                <p-checkbox [(ngModel)]="vehicleIconState" [binary]="true"></p-checkbox>
                Ícono de Vehículo
              </label>
            </div>
            <div>
              <span (click)="onClickSetting('imei')">
                <p-checkbox [(ngModel)]="!setting['imei']" [binary]="true"></p-checkbox>
                IMEI
              </span>
            </div>
            <div>
              <span (click)="onClickSetting('vehicle')">
                <p-checkbox [(ngModel)]="!setting['vehicle']" [binary]="true"></p-checkbox>
                Vehículos
              </span>
            </div>
            <div>
              <span (click)="onClickSetting('tag_driver')">
                <p-checkbox [(ngModel)]="!setting['tag_driver']" [binary]="true"></p-checkbox>
                Mostrar Conductor
              </span>
            </div>
            <div>
              <span (click)="onClickSetting('tag')">
                <p-checkbox [(ngModel)]="!setting['tag']" [binary]="true"></p-checkbox>
                Mostrar nombre
              </span>
            </div>
            <div>
              <span (click)="onClickSetting('follow')">
                <p-checkbox [(ngModel)]="!setting['follow']" [binary]="true"></p-checkbox>
                Seguir Vehículo
              </span>
            </div>
            <div>
              <span (click)="onClickSetting('limit')">
                <p-checkbox [(ngModel)]="!setting['limit']" [binary]="true"></p-checkbox>
                Velocidad
              </span>
            </div>
            <!-- <div>
              <span (click)="onClickSetting('gps')">
                <p-checkbox [(ngModel)]="!setting['gps']" [binary]="true"></p-checkbox>
                GPS
              </span>
            </div>
            <div>
              <span (click)="onClickSetting('gsm')">
                <p-checkbox [(ngModel)]="!setting['gsm']" [binary]="true"></p-checkbox>
                GSM
              </span>
            </div> -->
            <!-- <div>
              <span (click)="onClickSetting('transmission')">
                <p-checkbox [(ngModel)]="!setting['transmission']" [binary]="true"></p-checkbox>
                Transmisión
              </span>
            </div> -->
            <div>
              <span (click)="onClickSetting('config')">
                <p-checkbox [(ngModel)]="!setting['config']" [binary]="true"></p-checkbox>
                Configurar
              </span>
            </div>
            <div class="dropdown-divider"></div>
          </div>

          <div class="dropdown-item" style="padding: 0.125rem 0.75rem;">
            <i  class="fas fa-sitemap ag-grid-header-icon text-center" style="width: 20px;"></i>
            <span (click)="onTableGroup()">Vista por Operación</span>
          </div>
          <!-- <div class="dropdown-item active-view" style="padding: 0.125rem 0.75rem;"> -->
          <div class="dropdown-item active" style="padding: 0.125rem 0.75rem;">
            <i class="fas fa-list ag-grid-header-icon text-center" style="width: 20px;"></i>
            <span (click)="onTableGeneral()">Vista General</span>
          </div>
          <!-- <div class="dropdown-item" style="padding: 0.125rem 0.75rem;">
            <i class="fas fa-signal ag-grid-header-icon text-center" style="width: 20px;"></i>
            <span (click)="onTableTransmision()">Vista por Transmisión*</span>
          </div> -->

        </div>
      </div>

    </div>
  </div>

</div>
<app-vehicle-config [display]="display" [config]="config" (eventDisplay)="onChangeDisplay($event)" (eventUpdate)="onUpdate($event)"></app-vehicle-config>
<div class="ag-theme-alpine" [ngClass]="{'show-vehicle-icon': vehicleIconState,'show-vehicle-status': vehicleTransmissionStatus }" style="width: 100%; height: calc(100% - var(--row-busqueda-height));">
    <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%; font-size: 14px; overflow: auto;"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [rowHeight]="rowHeight"
    (gridReady)="onGridReady($event)"
    [embedFullWidthRows]="true"
    >

    </ag-grid-angular>
</div>
