import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomToastMessage } from 'src/app/alerts/models/alert.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomToastMessagessService {
  private customToasts: CustomToastMessage[] = [];
  private customToastsSubject: BehaviorSubject<CustomToastMessage[]> =
    new BehaviorSubject<CustomToastMessage[]>([]);
  constructor() {}
  get customToasts$() {
    return this.customToastsSubject.asObservable();
  }
  addCustomToast(toast: CustomToastMessage) {
    console.log('TOAST : ',toast.id);
    if (this.existCustomToastById(toast.id)) {
      this.updateCustomToastById(toast);
    } else {
      this.customToasts.push(toast);
      this.updateCustomToasts();
      if (!toast.sticky) {
        setTimeout(() => {
          this.deleteCustomToastById(toast.id);
        }, 11000);
      }
    }
  }
  deleteCustomToastById(id: string) {
    this.customToasts = this.customToasts.filter((t) => t.id !== id);
    this.updateCustomToasts();
  }
  deleteAllCustomToast() {
    this.customToasts = [];
  }
  updateCustomToastById(toast: CustomToastMessage) {
    console.log('TOAST UPDATE: ',toast.id);
    const toastIndex = this.customToasts.findIndex((t) => t.id === toast.id);
    if (toastIndex !== -1) {
      this.customToasts[toastIndex] = {
        ...this.customToasts[toastIndex],
        ...toast,
      };
      this.updateCustomToasts();
    }
    if (!toast.sticky) {
      setTimeout(() => {
        this.deleteCustomToastById(toast.id);
      }, 11000);
    }
  }
  existCustomToastById(id: string): boolean {
    return this.customToasts.filter((t) => t.id == id).length > 0
      ? true
      : false;
  }
  private updateCustomToasts() {
    this.customToastsSubject.next(this.customToasts);
  }
}
