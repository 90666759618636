import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng-lts/api';
import { EventPopupComponent } from 'src/app/events/components/event-popup/event-popup.component';
import { EventSocketService } from 'src/app/events/services/event-socket.service';
import { PopupContent } from 'src/app/multiview/models/interfaces';
import { LayersService } from 'src/app/multiview/services/layers.service';
import { MultimediaService } from 'src/app/multiview/services/multimedia.service';
import { CarouselComponent } from 'src/app/shared/components/carousel/carousel.component';
import { CarouselService } from 'src/app/shared/services/carousel.service';
import { PanelService } from '../../services/panel.service';
import { ConnectionService, ConnectionServiceOptions, ConnectionServiceOptionsToken, ConnectionState } from 'ngx-connection-service';
import { ToastrService } from 'ngx-toastr';
import {Observable} from 'rxjs';




@Component({
  selector: 'app-footbar',
  templateUrl: './footbar.component.html',
  styleUrls: ['./footbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [

  ]
  
})
export class FootbarComponent implements OnInit {

  itemsMenu: MenuItem[]=[];
  showScreenRecorder = false;
  showCipiaExample = false;
  showPopupDialog = true;
  showPanel = true;
  currentPanelName = "";
  lastPanelName = "";
  @ViewChild('MapView') mapView!: ElementRef;
  @ViewChild('carousel') carousel!: CarouselComponent;


  
/* variables for internet */
  isConnected: boolean = true;
  isConnectionStable: boolean = true;
  disconnectToastId: number | undefined;
  connectionLevel: string = 'no-connection';
  initialCheckDone: boolean = false;
  unstableToastId: number | undefined;
  firstStableConnection: boolean = true;

  status: string = '';
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 1};

  constructor(
    private multimediaService: MultimediaService,
    private layersService: LayersService,
    private eventSocketService: EventSocketService,
    private carouselService: CarouselService,
    private panelService: PanelService,
    private connectionService: ConnectionService,
    private toastr: ToastrService,

    

  ) { 


  }
    

  
  
  ngOnInit(): void {

    /* creation of code for the internet */
    this.connectionService.monitor().subscribe((currentState: ConnectionState) => {
      const wasConnected = this.isConnected;
      const wasConnectionStable = this.isConnectionStable;

      this.isConnected = currentState.hasNetworkConnection;
      this.isConnectionStable = currentState.hasInternetAccess;

      if (!this.isConnected) {
        this.connectionLevel = 'no-connection';
        if (this.initialCheckDone && !this.disconnectToastId) {
          const toast = this.toastr.error('No tienes conexión a internet', '', {
            timeOut: 0,
            extendedTimeOut: 0,
            closeButton: false,
            tapToDismiss: false
          });
          this.disconnectToastId = toast.toastId;
        }
        if (this.unstableToastId) {
          this.toastr.clear(this.unstableToastId);
          this.unstableToastId = undefined;
        }
      } else {
        if (this.disconnectToastId) {
          this.toastr.clear(this.disconnectToastId);
          this.disconnectToastId = undefined;
        }

        if (!this.isConnectionStable) {
          this.connectionLevel = 'unstable-connection';
          //this.initialCheckDone 
          if (this.initialCheckDone && !this.unstableToastId) {
            const toast = this.toastr.warning('Tu internet es inestable', '', {
              timeOut: 0,
              extendedTimeOut: 0,
              closeButton: false,
              tapToDismiss: false
            });
            this.unstableToastId = toast.toastId;
            console.log("JESUS: ", this.unstableToastId)
          }
        } else {
          this.connectionLevel = 'good-connection';
          if (this.initialCheckDone && (!wasConnected || !wasConnectionStable) && this.unstableToastId) {
            this.toastr.success('Te has conectado a internet', '', {
              timeOut: 3000
            });
            if (this.unstableToastId) {
              this.toastr.clear(this.unstableToastId);
              this.unstableToastId = undefined;
            }
          }
          // this.firstStableConnection = false; // Marcar que la primera conexión estable se ha realizado
        }
      }

      this.initialCheckDone = true;
    });

    this.connectionService.updateOptions({
      enableHeartbeat: true,
      heartbeatInterval: 5000,
      heartbeatExecutor: options => new Observable<any>(subscriber => {
        const controller = new AbortController();
        const timeout = setTimeout(() => {
          controller.abort();
          subscriber.error(new Error('No Internet Access'));
        }, 3000); // Ajusta el timeout a 3 segundos

        fetch('https://jsonplaceholder.typicode.com/posts/1', { 
          method: 'get', 
          signal: controller.signal 
        })
          .then(response => {
            clearTimeout(timeout);
            if (response.ok) {
              subscriber.next();
              subscriber.complete();
            } else {
              subscriber.error(new Error('No Internet Access'));
            }
          })
          .catch(error => {
            clearTimeout(timeout);
            subscriber.error(error);
          });
      })
    });
  
    this.layersService.initializeServices();
    this.itemsMenu = [
      {
        id: '1',
        label: 'Grabar Pantallas',
        icon: 'pi pi-fw pi-video',
        command: (event) => {
          console.log("grabar pantallas: ", event);
          this.showScreenRecorder = true;
        },
      },
      {
        id: '2',
        label: 'Tomar captura',
        icon: 'pi pi-fw pi-camera',
        command: (event) => {
          this.multimediaService.screenShot(this.mapView.nativeElement);
        },
      },
      {
        id: '3',
        label: 'Ejemplo cipia video',
        icon: 'pi pi-fw pi-video',
        command: (event) => {
          this.showCipiaExample = true;
        },
      }
    ];
    this.eventSocketService.eventPopup.subscribe(data => {
      this.onEvent(data);
    });

    
  }

  closeScreenRecorder(){
    this.showScreenRecorder = false;
  }

  onEvent(event:any){
    console.log("eventssss on event: ", event);
    const auxEvent = {
      id: Date.now().toString(),
      vehicles: [event.tracker],
      event: event.event,
      mapConf: { containerId: "popup-container-" + event.event.evento_id.toString() + Date.now(), maxZoom: 17, zoom: 17}
    }
    this.carouselService.add(EventPopupComponent,{configuration: auxEvent})
  }

  clearPopups(){
    this.carousel.clearPopups();
  }

  trackByFn(index: number, item: any): any {
    return item.id; 
  }

  hidePanel(){
    console.log("Ocultar/Mostrar panel");
    if (this.lastPanelName){
      this.showPanel = !this.showPanel;
    }
    const currentActivePanel = this.panelService.activePanelClass();
    console.log("currentActivePanel", currentActivePanel);
    if(currentActivePanel){
      this.currentPanelName = this.panelService.getComponentNameFromPanelName(currentActivePanel);
      console.log("panelName", this.currentPanelName);
      this.panelService.clickShowPanel(this.currentPanelName);
      this.lastPanelName = this.currentPanelName;
    }else{
      console.log("using LASTPANELNAME");
      this.panelService.clickShowPanel(this.lastPanelName);
    }
  }
}
