import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { EventSocketService } from './../../services/event-socket.service';
import { MapServicesService } from 'src/app/map/services/map-services.service';
import { EventService } from '../../services/event.service';
import { ReferenceService } from '../../services/reference.service';
import { ConnectionService } from '../../services/connection.service';
import { getContentPopup } from '../../helpers/event-helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SliderMultimediaComponent } from 'src/app/shared/components/slider-multimedia/slider-multimedia.component';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';
import {
  Alert,
  CustomToastMessage,
  Evaluation,
  WaitTime,
} from 'src/app/alerts/models/alert.interface';
import { Operator } from 'src/app/shared/models/interfaces';
import Swal from 'sweetalert2';
import { event } from 'jquery';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Subscription } from 'rxjs';
import { ComunicationService } from 'src/app/shared/services/comunication.service';
import { CustomToastMessagessService } from 'src/app/map/services/custom-toast-messagess.service';
import { WaitTimeService } from '../../services/wait-time.service';
import { Vehicle, VehicleList } from 'src/app/vehicles/models/vehicle';
import { OperatorService } from 'src/app/shared/services/operator.service';
import { WhatsappService } from '../../services/whatsapp.service';

interface Data {
  alert: Alert;
  htmlElement: HTMLElement;
}
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit, OnDestroy {
  private subscription!: Subscription;
  tipoEvento: any = [];
  selectedEvent: any[] = [];
  activeEvent: any = false;
  noResults: boolean = false;
  expanded: boolean = false;

  //Variable button get more
  isLoadingMoreEvents: boolean = false;
  canLoadMoreEvents: boolean = true;

  public uuid_evaluation: any = [];
  public events: any[] = [];
  // public placa: string = '';

  public imei_debug: string = '864200050708453';
  public data_debug: any = ['-', '-', '-', '-'];
  evaluation_criteria_filter: any[] = [];
  list_events_slug_criteria = [
    'conductor-adormitado-360',
    'conductor-somnoliento-360',
    'posible-fatiga',
    'conductor-distraido-360',
    'cinturon-desabrochado-360',
    'conductor-fumando-360',
  ];
  evaluation_criteria = [
    {
      label: 'SEÑALES DE POSIBLE FATIGA',
      items: [
        {
          label: 'Bostezo continuo (>3 en 30 seg.)',
          value: 'Bostezo continuo (>3 en 30 seg.)',
        },
        {
          label: 'Gestos o movimientos a manera de autogestión',
          value: 'Gestos o movimientos a manera de autogestión',
        },
        {
          label: 'Inclinación de la cabeza hacia adelante y hacia atrás',
          value: 'Inclinación de la cabeza hacia adelante y hacia atrás',
        },
        {
          label: 'Mano con acción de frotación en el ojo',
          value: 'Mano con acción de frotación en el ojo',
        },
        { label: 'Parpadeo involuntario', value: 'Parpadeo involuntario' },
      ],
    },
    {
      label: 'SIN SEÑALES DE POSIBLE FATIGA',
      items: [
        {
          label: 'Característica física de ojos "Rasgos asiáticos"',
          value: 'Característica física de ojos "Rasgos asiáticos"',
        },
        {
          label: 'Cobertura de rostro con bufanda y/o chalina',
          value: 'Cobertura de rostro con bufanda y/o chalina',
        },
        {
          label: 'Comunicación radial con el conductor',
          value: 'Comunicación radial con el conductor',
        },
        {
          label: 'Existe mirada del Conductor al tablero',
          value: 'Existe mirada del Conductor al tablero',
        },
        {
          label: 'Lentes correctores mal puestos',
          value: 'Lentes correctores mal puestos',
        },
        {
          label: 'Lentes correctores con marco reducido',
          value: 'Lentes correctores con marco reducido',
        },
        {
          label: 'Lentes de seguridad mal puestos',
          value: 'Lentes de seguridad mal puestos',
        },
        { label: 'Otro tipo de lentes', value: 'Otro tipo de lentes' },
        {
          label: 'Presencia de deslumbramientos',
          value: 'Presencia de deslumbramientos',
        },
        {
          label: 'Uso de chullo, gorra con vísera o casco',
          value: 'Uso de chullo, gorra con vísera o casco',
        },
        {
          label: 'Uso de maquillaje en el rostro',
          value: 'Uso de maquillaje en el rostro',
        },
        { label: 'Uso de sobrelentes', value: 'Uso de sobrelentes' },
      ],
    },
    {
      label: 'CELULAR DETECTADO',
      items: [
        {
          label: 'ACCIÓN INSEGURA',
          value: 'ACCIÓN INSEGURA',
        },
        {
          label: 'Cinta reflectiva del chaleco y/o casaca',
          value: 'Cinta reflectiva del chaleco y/o casaca',
        },
        {
          label: 'Conductor hace uso de PTT',
          value: 'Conductor hace uso de PTT',
        },
        {
          label: 'Mano a la altura del oido',
          value: 'Mano a la altura del oido',
        },
      ],
    },
    {
      label: 'DISTRACCIÓN DETECTADA',
      items: [
        {
          label: 'ACCIÓN INSEGURA',
          value: 'ACCIÓN INSEGURA',
        },
        {
          label: 'Existe mirada del conductor hacia el tablero',
          value: 'Existe mirada del conductor hacia el tablero',
        },
        { label: 'Maniobras de conducción', value: 'Maniobras de conducción' },
        {
          label: 'Manipulación de equipos del tablero / vehículo',
          value: 'Manipulación de equipos del tablero / vehículo',
        },
        {
          label: 'Visualización de espejos laterales',
          value: 'Visualización de espejos laterales',
        },
        {
          label: 'Visualización de vía',
          value: 'Visualización de vía',
        },
      ],
    },
    {
      label: 'CONDUCTOR SOMNOLIENTO',
      items: [
        {
          label: 'ACCIÓN INSEGURA',
          value: 'ACCIÓN INSEGURA',
        },
        {
          label: 'Existe mirada del conductor hacia el tablero',
          value: 'Existe mirada del conductor hacia el tablero',
        },
        {
          label: 'Inclinación por manipulación de equipos del tablero',
          value: 'Inclinación por manipulación de equipos del tablero',
        },
        {
          label: 'Parpadeo constante',
          value: 'Parpadeo constante',
        },
        {
          label: 'Presencia de deslumbramiento hacia el Conductor',
          value: 'Presencia de deslumbramiento hacia el Conductor',
        },
      ],
    },
    {
      label: 'CONDUCTOR ADORMITADO',
      items: [
        {
          label: 'ACCIÓN INSEGURA',
          value: 'ACCIÓN INSEGURA',
        },
        {
          label: 'Cerrado de ojos por bostezo',
          value: 'Cerrado de ojos por bostezo',
        },
        {
          label: 'Cerrado de ojos por sonrisa',
          value: 'Cerrado de ojos por sonrisa',
        },
        {
          label: 'Cerrado de ojos por deslumbramiento',
          value: 'Cerrado de ojos por deslumbramiento',
        },
        {
          label: 'Existe mirada del conductor hacia el tablero',
          value: 'Existe mirada del conductor hacia el tablero',
        },
        {
          label: 'Inclinación por manipulación de equipos del tablero',
          value: 'Inclinación por manipulación de equipos del tablero',
        },
      ],
    },
    {
      label: 'CINTURÓN NO DETECTADO', //cinturon no detectado
      items: [
        {
          label: 'ACCIÓN INSEGURA',
          value: 'ACCIÓN INSEGURA',
        },
        {
          label: 'Cinta reflectiva del chaleco y/o casaca',
          value: 'Cinta reflectiva del chaleco y/o casaca',
        },
        {
          label: 'Deslumbramiento hacia el Conductor',
          value: 'Deslumbramiento hacia el Conductor',
        },
        {
          label: 'Obstrucción de visualización de cinturón',
          value: 'Obstrucción de visualización de cinturón',
        },
        {
          label: 'Uso inadecuado del cinturón',
          value: 'Uso inadecuado del cinturón',
        },
      ],
    },
    {
      label: 'CIGARRO DETECTADO', // cigarro detectado
      items: [
        {
          label: 'ACCIÓN INSEGURA',
          value: 'ACCIÓN INSEGURA',
        },
        {
          label: 'Cinta reflectiva del chaleco y/o casaca',
          value: 'Cinta reflectiva del chaleco y/o casaca',
        },
        {
          label: 'Conductor con objeto en la boca',
          value: 'Conductor con objeto en la boca',
        },
        {
          label: 'Conductor hace uso de PTT',
          value: 'Conductor hace uso de PTT',
        },
        {
          label: 'Deslumbramiento hacia el Conductor',
          value: 'Deslumbramiento hacia el Conductor',
        },
        {
          label: 'Mano a la altura de la boca',
          value: 'Mano a la altura de la boca',
        },
      ],
    },
  ];

  operators = [
    {
      label: 'Operadores',
      items: [
        {
          label: 'ATENCIO ALFARO, Claudia Isabel',
          value: 'ATENCIO ALFARO, Claudia Isabel',
        },
        {
          label: 'FERNANDEZ CRUZ, Rosellia Yanina',
          value: 'FERNANDEZ CRUZ, Rosellia Yanina',
        },
        {
          label: 'HAÑARI SALCEDO, Katherine Liliana',
          value: 'HAÑARI SALCEDO, Katherine Liliana',
        },
        { label: 'MAMANI SIERRA, Raquel', value: 'MAMANI SIERRA, Raquel' },
        {
          label: 'NUÑEZ CHAMBI, Michelle Solzire',
          value: 'NUÑEZ CHAMBI, Michelle Solzire',
        },
        {
          label: 'NUÑONCA ALARCON, Daniela Stefany',
          value: 'NUÑONCA ALARCON, Daniela Stefany',
        },
        {
          label: 'PACCI QUISPE, Betzy Belquis',
          value: 'PACCI QUISPE, Betzy Belquis',
        },
        {
          label: 'PACHECO MOLLO, Cesar Vidal',
          value: 'PACHECO MOLLO, Cesar Vidal',
        },
        {
          label: 'ROJAS RONDON, Carla Beatriz',
          value: 'ROJAS RONDON, Carla Beatriz',
        },
        {
          label: 'SUARES PACURI, Ayelen Melani',
          value: 'SUARES PACURI, Ayelen Melani',
        },
      ],
    },
  ];
  //state for show display dialog of a event
  currentRowSelected!: HTMLElement;
  display_dialog_event: boolean = false; // Use in onHiden(), rowExpanded(), submitEvaluation()
  display_dialog_content_event: boolean = true; // Use in onHiden(), rowExpanded()
  eventsEvaluated: number = 0;
  sendEvaluationByWhatsapp: boolean = false;

  vehiclesList: VehicleList[] = [];
  selectedVehicles: VehicleList[] = [];
  onHide() {
    this.display_dialog_event = true;
    if (this.display_dialog_event) {
      if (this.display_dialog_content_event) {
        this.display_dialog_content_event = false;
      } else {
        this.display_dialog_content_event = true;
      }
    }
  }

  loading_evaluation = false;
  expandedRows: { [s: string]: boolean } = {};
  submitting = false;

  constructor(
    public eventService: EventService,
    public referenceService: ReferenceService,
    public connectionService: ConnectionService,
    public mapService: MapServicesService,
    public eventSocketService: EventSocketService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private vehicleService: VehicleService,
    private toastService: ToastService,
    private comunicationService: ComunicationService,
    private customToastMessagess: CustomToastMessagessService,
    private appRef: ApplicationRef,
    private waitTimeService: WaitTimeService,
    private operatorService: OperatorService,
    private whatsappService: WhatsappService
  ) {
    this.subscription = comunicationService.event$.subscribe((data: Data) => {
      // data.htmlElement.click();
      document.getElementById(data.alert.uuid_event)?.click();
    });
    // this.tipoEvento = [
    //   { id: 0, option: 'Todos los Eventos', tipo: '' },
    //   { id: 1, option: 'Alcoholemia', tipo: '' },
    //   { id: 2, option: 'Somnolencia', tipo: 'Somnolencia', clase: 'somnolencia' },
    //   { id: 3, option: 'Distracción', tipo: 'Distraccion', clase: 'distraccion' },
    //   { id: 4, option: 'Batería Desconectada', tipo: 'Bateria desconectada', clase: 'bateria-desconectada' },
    //   { id: 5, option: 'Aceleración Brusca', tipo: 'Aceleracion brusca', clase: 'aceleracion-brusca' },
    //   { id: 6, option: 'Frenada Brusca', tipo: 'Frenada brusca', clase: 'frenada-brusca' },
    //   { id: 7, option: 'S.O.S.', tipo: 'SOS', clase: 'sos-event' },
    //   { id: 8, option: 'Zona de Entrada', tipo: 'Zona de entrada', clase: 'zona-entrada' },
    //   { id: 9, option: 'Zona de Salida', tipo: 'Zona de salida', clase: 'zona-salida' },
    //   { id: 10, option: 'Tiempo de estadía en zona', tipo: 'Tiempo de estadia en zona', clase: 'tiempo-estadia-zona' },
    //   { id: 11, option: 'Parada en zona no autorizada', tipo: 'Parada en zona no autorizada', clase: 'parada-zona-no-autorizada' },
    //   { id: 12, option: 'Exceso de velocidad', tipo: 'Exceso de Velocidad', clase: 'exceso-velocidad' },
    //   { id: 13, option: 'Transgresión', tipo: '' },
    //   { id: 14, option: 'Infracción', tipo: 'Infraccion', clase: 'infraccion' },
    //   { id: 15, option: 'Vehículo sin programación', tipo: 'Vehiculo sin programacion', clase: 'vehiculo-sin-programacion' },
    //   { id: 16, option: 'Mantenimiento preventivo', tipo: 'Mantenimiento preventivo', clase: 'mantenimiento-preventivo' },
    //   { id: 16, option: 'Mantenimiento preventivo realizado', tipo: 'Mantenimiento preventivo realizado', clase: 'mantenimiento-preventivo-realizado' },
    //   { id: 17, option: 'Mantenimiento correctivo', tipo: 'Mantenimiento correctivo', clase: 'mantenimiento-correctivo' },
    //   { id: 18, option: 'Mantenimiento correctivo realizado', tipo: 'Mantenimiento correctivo realizado', clase: 'mantenimiento-correctivo-realizado' },
    //   { id: 19, option: 'Motor apagado', tipo: 'Motor apagado', clase: 'motor-apagado' },
    //   { id: 20, option: 'Motor encendido', tipo: 'Motor encendido', clase: 'motor-encendido' },
    //   { id: 21, option: 'Fatiga', tipo: 'Fatiga', clase: 'fatiga' },
    //   { id: 22, option: 'Posible Fatiga', tipo: 'Posible Fatiga', clase: 'posible-fatiga' },
    //   { id: 23, option: 'Fatiga Extrema', tipo: 'Fatiga Extrema', clase: 'fatiga-extrema' },
    //   { id: 24, option: 'Desvío de carril hacia la izquierda', tipo: 'Desvío de carril hacia la izquierda', clase: 'desvio-carril-izq' },
    //   { id: 25, option: 'Desvío de carril hacia la derecha', tipo: 'Desvío de carril hacia la derecha', clase: 'desvio-carril-der' },
    //   { id: 26, option: 'Bloqueo de visión del Mobileye', tipo: 'Bloqueo de visión del mobileye', clase: 'bloqueo-vision-mobileye' },
    //   { id: 27, option: 'Colisión con peatones', tipo: 'Colisión con peatones', clase: 'colision-peatones' },
    //   { id: 28, option: 'Colisión con delantera', tipo: 'Colisión delantera', clase: 'colision-delantera' },
    //   { id: 29, option: 'Bloqueo de visión del mobileye', tipo: 'Bloqueo de visión del mobileye', clase: 'bloqueo-vision-mobileye' },
    // ];
  }

  async ngOnInit() {
    // console.log("event list on init ========================================================================");
    this.selectedEvent = await this.eventService.getEventSelected();
    console.log('seleccionEventos: ', this.selectedEvent);

    this.selectedVehicles = await this.vehicleService.getSelectedVehicles();
    console.log('seleccionVehicles: ', this.selectedVehicles);
    // this.connectionService.monitor();

    if (!this.eventService.eventsLoaded || !this.eventService.filterLoaded) {
      this.spinner.show('loadingEventList');
      console.log('cargandoeventos:', this.eventService.eventsLoaded);
    }
    this.loadFilterData();

    /* const storedFilter = localStorage.getItem('filter') || '';
    this.selectedEvent = storedFilter ? [{ value: storedFilter }] : []; */

    this.eventService.debugEventStream.subscribe((res) => {
      console.log('desde event list -> debugEventStream res: ', res);
      this.data_debug = res.data;
    });
    this.eventService.evaluationEventStream.subscribe((info) => {
      console.log('desde event list -> evaluationEventStream info: ', info);
      // this.data_debug = res.data;
      this.eventService.integrateEvaluationEvent(info);
    });
    this.eventService.newEventStream.subscribe(() => {
      // console.log("desde event list ",res);
      // this.searchByPlate();
      this.changeTypeEvent();
      this.filterByVehicles();
    });

    this.referenceService.searchReferenceEventCompleted.subscribe((event) => {
      this.showEvent(event);
    });

    // EN ESPERA DE USAR OPERADORES DE PANEL ADMIN
    if (this.operatorService.stateOperators == false) {
      this.spinner.show('loadingOperators');
      this.operatorService.initialize();
      this.operatorService.dataComplete.subscribe(
        async (operators) => {
          console.log('OPERADORES OBTENIDOS: 1 vez', operators);
          this.operators = this.setListOperators();
          this.spinner.hide('loadingOperators');
        },
        (error) => {
          this.spinner.hide('loadingOperators');
          console.error('Error al obtener los Operadores de Monitoreo:', error);
        }
      );
    } else {
      this.spinner.show('loadingOperators');
      console.log('OPERADORES LOADED');
      this.operators = this.setListOperators();
      this.spinner.hide('loadingOperators');
    }

    if (this.eventService.eventsUserLoaded == false) {
      try {
        const data = await this.eventService.getEventsForUser().toPromise();
        console.log('EVENTOS DEL USUARIO OBTENIDOS:', data);
        if (data.success) {
          this.eventService.createEventList(data.data);
        } else {
          console.log('EL USUARIO NO TIENE EVENTOS');
        }
      } catch (error) {
        console.error('Error al obtener los eventos:', error);
      } finally {
        this.spinner.hide('loadingEventList');
      }
    } else {
      this.spinner.hide('loadingEventList');
      // this.spinner.show('loadingHistorialForm');
      // this.spinner.hide('loadingHistorialForm');
    }

    /* carga anterior de los eventons */
    // if (this.eventService.eventsUserLoaded == false) {
    //   //this.spinner.show('loadingEventsPanel');
    //   this.eventService.getEventsForUser().subscribe(
    //     async (data) => {
    //       // Aquí puedes trabajar con los datos obtenidos
    //       console.log('EVENTOS DEL USUARIO OBTENIDOS:', data);
    //       // Realiza cualquier acción con los datos recibidos
    //       if (data.success) {
    //         this.eventService.createEventList(data.data);
    //       } else {
    //         console.log('EL USUARIO NO TIENE EVENTOS');
    //       }
    //       this.spinner.hide('loadingEventsPanel');
    //     },
    //     (error) => {
    //       // Maneja los errores si ocurre alguno durante la solicitud
    //       console.error('Error al obtener los eventos:', error);
    //     }
    //   );
    // } else {
    //   this.spinner.show('loadingHistorialForm');
    //   // console.log(
    //   //   'TEST IN EVENTS PANEL->',
    //   //   this.eventService.eventsGroupedList
    //   // );
    //   this.spinner.hide('loadingHistorialForm');
    // }
    await this.changeTypeEvent();

    if (this.vehicleService.statusDataVehicle) {
      this.spinner.show('loadingVehiculos');
      try {
        await this.generateVehicleList();
      } catch (error) {
        console.error('Error al generar la lista de vehículos:', error);
      } finally {
        this.spinner.hide('loadingVehiculos');
      }
    } else {
      this.spinner.show('loadingVehiculos');
      this.vehicleService.vehicleCompleted.subscribe(async (status) => {
        try {
          await this.generateVehicleList();
        } catch (error) {
          console.error('Error al generar la lista de vehículos:', error);
        } finally {
          this.spinner.hide('loadingVehiculos');
        }
      });
    }
  }

  async loadMore() {
    if (this.isLoadingMoreEvents || !this.canLoadMoreEvents) {
      return;
    }
    this.isLoadingMoreEvents = true;
    this.spinner.show('loadingEventList');

    try {
      const hasMoreEvents = await this.eventService.loadMoreEvents();
      this.canLoadMoreEvents = hasMoreEvents;
    } catch (error) {
      console.error('Error al cargar más eventos:', error);
    } finally {
      this.isLoadingMoreEvents = false;
      this.spinner.hide('loadingEventList');
    }
  }

  private async generateVehicleList() {
    this.vehiclesList = [];

    for (let vh of this.vehicleService.vehicles) {
      const item: VehicleList = {
        id: vh.id!,
        plate_number: vh.plate_number!,
        IMEI: vh.IMEI!,
        cod_interno: vh.cod_interno!,
        name: vh.name_old!,
      };
      this.vehiclesList.push(item);
    }

    console.log('JESUSDAVID: NAME', this.vehiclesList);
    console.log('jesus: ', this);
  }

  public setListOperators(): any {
    this.operators = [];
    return this.operatorService.listOperators;
  }

  public async filterByVehicles() {
    await this.eventService.setEventSelected(this.selectedEvent);
    if (this.selectedVehicles.length === 0 && this.selectedEvent.length === 0) {
      this.eventService.eventsFiltered = this.eventService.getData();
      this.noResults = false;
    } else {
      this.eventService.eventsFiltered = this.eventService
        .getData()
        .filter((event: any) => this.eventFilter(event));
      this.noResults = this.eventService.eventsFiltered.length === 0;
    }
    this.vehicleService.setSelectedVehicles(this.selectedVehicles);

    console.log('Filtered Vehicles:', this.selectedVehicles);
  }

  public async changeTypeEvent() {
    // console.log("cambiando filtro de eventos ...... *****************+ ");
    // console.log("selectedEvent",this.selectedEvent);
    await this.eventService.setEventSelected(this.selectedEvent);
    if (this.selectedEvent.length === 0 && this.selectedVehicles.length === 0) {
      this.eventService.eventsFiltered = this.eventService.getData();

      this.noResults = false;
    } else {
      this.eventService.getEventFilter(this.selectedEvent);
      this.eventService.eventsFiltered = this.eventService
        .getData()
        .filter((event: any) => {
          // console.log(" --- event: ",event);
          let result_filter = this.eventFilter(event);
          // console.log("result_filter",result_filter);
          return result_filter;
        });
      this.noResults = this.eventService.eventsFiltered.length === 0;
    }
  }

  ngOnDestroy() {
    if (this.eventService.activeEvent) {
      this.hideEvent(this.eventService.activeEvent);
    }
    this.subscription.unsubscribe();
  }
  clickDatosDebug(): void {
    this.eventSocketService.debug(this.imei_debug);
  }
  clickEndDeveloper(): void {
    this.eventService.eventDeveloperStatus = false;
    this.eventService.eventDeveloperCount = 0;
    this.data_debug = ['-', '-', '-', '-'];
  }
  clickEventPanel(): void {
    if (this.eventService.eventDeveloperCount > 5) {
      this.eventService.eventDeveloperStatus = true;
    } else {
      this.eventService.eventDeveloperCount++;
    }
  }

  async loadFilterData() {
    if (!this.eventService.hasEventPanelBeenOpened) {
      this.eventService.hasEventPanelBeenOpened = true;
      await this.eventService.getAllEventsForTheFilter();
      this.eventService.filterLoaded = true;
    }
    this.tipoEvento = this.eventService.getFilters();
    console.log('filtrosobtenidos', this.tipoEvento);
    this.eventService.showEventPanel();

    /* this.tipoEvento.unshift({ id: 0, option: 'Todos los Eventos', tipo: '' }); */
  }

  public addEvaluation(uuid: any) {
    let max = 10;
    this.uuid_evaluation.push(uuid);
    if (this.uuid_evaluation.length > max) this.uuid_evaluation.shift();
  }

  public showEvent(event: any) {
    const objParams: any = {};
    /*
    antes de procesar parametros  string
    event-list.component.ts:130 despues de procesar parametros  object
    */
    // console.log("event",event);

    if (event.parametros && typeof event.parametros == 'string') {
      event.parametros.split('|').forEach((item: any) => {
        const [key, value] = item.split('=');
        objParams[key] = value;
      });
      //reemplazo el atributo parametros (string) con el objeto
      event.parametros = objParams;
    }

    if (this.eventService.activeEvent) {
      if (
        this.eventService.activeEvent.id == event.id &&
        event.layer.isPopupOpen()
      ) {
        return;
      }
      this.eventService.activeEvent.layer.closePopup();
      this.eventService.activeEvent.layer.unbindPopup();
      this.eventService.activeEvent.layer.off();
      this.hideEvent(this.eventService.activeEvent);
    }

    if (!event.viewed) {
      event.viewed = true;
      // this.markAsRead(event.evento_id);
    }
    this.eventService.activeEvent = event;

    var eventClass: any = this.eventService.eventsClassList.filter(
      (eventClass: any) => eventClass.tipo == event.tipo
    );
    eventClass = eventClass.length > 0 ? eventClass[0].clase : 'default-event';
    console.log(' ############ eventclass: ', eventClass);
    this.mapService.map.fitBounds(
      [[event.layer.getLatLng().lat, event.layer.getLatLng().lng]],
      { padding: [50, 50] }
    );

    // console.log("eventlayer",event.layer)
    //no-rostro
    //infraccion-geocerca
    event.layer.bindPopup(getContentPopup(event), {
      className: eventClass,
      minWidth: 250,
      maxWidth: 350,
    });
    event.layer.on('click', () => {
      this.addMultimediaComponent(event);
    });
    event.layer.addTo(this.mapService.map).openPopup();
    this.addMultimediaComponent(event);
  }

  addMultimediaComponent(event: any) {
    if (
      event.parametros &&
      event.parametros.gps == 'cipia' &&
      (event.parametros.has_video != '0' || event.parametros.has_image != '0')
    ) {
      try {
        const factory = this.resolver.resolveComponentFactory(
          SliderMultimediaComponent!
        );
        const componentRef: ComponentRef<any> =
          this.container.createComponent(factory);

        const params: any = {
          event: event!,
          rowSelected: this.currentRowSelected!,
          driver:
            this.vehicleService.vehicles.find((vh) => vh.IMEI == event.imei)
              ?.namedriver ?? '',
          showFooter: false,
          showMultimediaFirst: true,
          showMultimedias: false,
          hasMultimedia: true,
          showTitle: false,
        };
        console.log(
          'eventRowSelected event list',
          event,
          this.currentRowSelected
        );

        Object.keys(params).forEach((key) => {
          componentRef.instance[key] = params[key];
        });
        const divContainer = document.getElementById(
          'multimedia-' + event.uuid_event
        )!;
        divContainer.innerHTML = '';
        divContainer.appendChild(componentRef.location.nativeElement); // Agrega un componente hijo
        this.appRef.attachView(componentRef.hostView); // Adjunta la vista del componente
        componentRef.changeDetectorRef.detectChanges(); // Forzar la detección de cambios
        this.appRef.tick(); // Forzar la detección de cambios en toda la aplicación
      } catch (error) {
        // console.log('SlideMultimediaComponent: inicialización fallida', error);
      }
    }
  }

  public hideEvent(event: any) {
    this.mapService.map.removeLayer(event.layer);
    this.eventService.activeEvent = false;
  }

  private markAsRead(event_id: any) {
    //console.log('desde event list Marking ' + event_id + ' as read...');
    //this.eventService.decreaseUnreadCounter();
    this.eventService.updateUnreadCounter();
    this.http
      .get<any>(
        environment.apiUrl + '/api/event-user/mark-as-viewed/' + event_id
      )
      .subscribe({
        next: (data) => {
          //console.log('desde event list Mark ' + event_id + ' as read Success? : ', data.success);
        },
        error: () => {
          //console.log(event_id + ': Hubo un error al marcar como leído');
        },
      });
  }

  public async switchEventOnMap(event: any, currentRow?: HTMLElement) {
    this.waitTimeService.startTime({
      id: event.uuid_event,
      start: Date.now(),
      itemsId: [],
      end: [],
      event: [],
      type: [],
      size: [],
      duration: [],
    });
    this.currentRowSelected = currentRow!;
    // console.log("switchEventOnMap #########################");
    console.log('eventList:: FILTER TEST EVENT:', event);
    // console.log("this.eventService.activeEvent.id",this.eventService.activeEvent.id);
    // if(event.event_id == this.eventService.activeEvent.id){
    if (false) {
      // this.hideEvent(this.eventService.activeEvent);
    } else {
      await this.switchTypeEventFilter(event.tipo);
      // currentRow?.classList?.add('watched-event');
      document
        .getElementById(event.uuid_event)
        ?.classList?.add('watched-event');

      //console.log('Mostrando evento con ID: ', event.evento_id);
      // let reference = await this.eventService.getReference(
      //   event.latitud,
      //   event.longitud
      // );
      let buffer = this.referenceService.getBuffer();
      if (buffer[event.id] == undefined) {
        this.referenceService.searchReferenceEvent(event);
      } else {
        event.referencia = buffer[event.id];
        this.showEvent(event);
      }
      // this.referenceService.searchReferenceEvent(event);
      // event.referencia = "Cargando ...";
      // this.showEvent(event);
    }
  }

  public checkPopupExist() {
    return document.querySelectorAll('.leaflet-popup').length > 0;
  }

  /* Filtro anterior de  ingreso placa */

  // public searchByPlate() {
  //   //if(this.selectedEvent === null && this.placa == ''){
  //   if (this.selectedEvent.length == 0 && this.placa == '') {
  //     this.eventService.eventsFiltered = this.eventService.getData();
  //     this.noResults = false;
  //   } else {
  //     this.eventService.eventsFiltered = this.eventService
  //       .getData()
  //       .filter((event: any) => {
  //         // console.log('this.eventFilter(event)', this.eventFilter(event));
  //         return this.eventFilter(event);
  //       });
  //     this.noResults = this.eventService.eventsFiltered.length == 0;
  //   }
  //   //console.log(this.eventService.eventsFiltered);
  //   //console.log(this.noResults);
  // }

  public async switchTypeEventFilter(event_slug: string): Promise<void> {
    // console.log('FILTER TEST Switch Slug:', event_slug);
    // if (event_slug == 'conductor-distraido-360') {
    //   this.filterCriteriaByEvent('DISTRACCIÓN');
    // } else if (event_slug == 'cinturon-desabrochado-360') {
    //   this.filterCriteriaByEvent('CINTURÓN');
    // } else if (event_slug == 'conductor-fumando-360') {
    //   this.filterCriteriaByEvent('FUMAR');
    // } else {
    //   this.filterCriteriaByEvent('ALL');
    //   // PARA ESTO Y PARA CUALQUIER OTRO
    //   // 'conductor-adormitado-360',
    //   // 'conductor-somnoliento-360',
    //   // 'posible-fatiga',
    // }
    const eventSlug: { [key: string]: string } = {
      'conductor-distraido-360': 'DISTRACCIÓN DETECTADA',
      'cinturon-desabrochado-360': 'CINTURÓN NO DETECTADO',
      'conductor-fumando-360': 'CIGARRO DETECTADO',
      'uso-de-celular-360': 'CELULAR DETECTADO',
      'conductor-adormitado-360': 'CONDUCTOR ADORMITADO',
      'conductor-somnoliento-360': 'CONDUCTOR SOMNOLIENTO',
    };
    this.filterCriteriaByEvent(eventSlug[event_slug] ?? 'ALL');
  }
  public filterCriteriaByEvent(event_filter: string): void {
    console.log('FILTER TEST event_filter:', event_filter);
    if (event_filter == 'ALL') {
      this.evaluation_criteria_filter = this.evaluation_criteria.slice(0, 2);
    } else {
      // Filtrar los elementos de evaluation_criteria que coincidan con el evento seleccionado
      this.evaluation_criteria_filter = this.evaluation_criteria.filter(
        (criteria) => criteria.label === event_filter
      );
    }
    console.log('FILTER TEST CRITERIOS:', this.evaluation_criteria);
    console.log('FILTER TEST CRITERIOS:', this.evaluation_criteria_filter);
  }

  /*--Filtro anterior --*/

  // private eventFilter(event: any) {
  //   // console.log("eventFilter filter ===> ");
  //   // console.log("event",event);
  //   // console.log("tipo select",event.tipo +"=="+ this.selectedEvent);
  //   const eventsTypesSelected: string[] = this.selectedEvent.map(
  //     (event: any) => {
  //       return event.value;
  //     }
  //   );
  //   const vehicle = this.vehicleService.vehicles.find(
  //     (vh) => vh.IMEI == event.imei
  //   );
  //   if (vehicle != undefined) {
  //     let aux_imei = vehicle.IMEI ?? '';
  //     let aux_cod = vehicle.cod_interno ?? '';
  //     let aux = event.nombre_objeto + aux_imei + aux_cod;
  //     return (
  //       (aux
  //         .toString()
  //         .trim()
  //         .toLowerCase()
  //         .match(this.placa.trim().toLowerCase()) ||
  //         this.placa == '') &&
  //       (eventsTypesSelected.includes(event.tipo) ||
  //         this.selectedEvent.length == 0)
  //     );
  //   } else {
  //     return false;
  //   }
  // }
  private eventFilter(event: any) {
    const vehiclesSelected: number[] = this.selectedVehicles.map(
      (vehicle: VehicleList) => vehicle.id
    );

    const eventsTypesSelected: string[] = this.selectedEvent.map(
      (event: any) => {
        return event.value;
      }
    );

    const vehicle = this.vehicleService.vehicles.find(
      (vh) => vh.IMEI == event.imei
    );

    if (vehicle != undefined) {
      return (
        (vehiclesSelected.includes(vehicle.id!) ||
          this.selectedVehicles.length == 0) &&
        (eventsTypesSelected.includes(event.tipo) ||
          this.selectedEvent.length == 0)
      );
    } else {
      return false;
    }
  }

  rowExpandend(event: any) {
    console.log('########## uuid_event ');
    if (event.data) {
      this.expandedRows[event.data.uuid_event] =
        !this.expandedRows[event.data.uuid_event];
    }
    this.loading_evaluation = true;
    this.display_dialog_event = true;
    this.display_dialog_content_event = true;

    if (event.data.uuid_event) {
      this.eventService
        .getEvaluations(event.data.uuid_event)
        .then((evaluations) => {
          console.log('exito al buscar evaluation :', evaluations);
          if (evaluations.length > 0) {
            //console.log(" EVALUATIONS GETS ", evaluations);
            let auxEvent = this.eventService.eventsFiltered.find(
              (ev: any) => ev.uuid_event == event.data.uuid_event
            );
            auxEvent.evaluations = evaluations as Evaluation[];
            auxEvent.evaluated = 1;
            //console.log("EVENTS EVALUATIONS GETS ", auxEvent);
          }
        })
        .finally(() => {
          this.loading_evaluation = false;
        });
    } else {
      this.loading_evaluation = false;
      this.display_dialog_event = false;
    }
  }
  submitSendEvaluationByWhatsapp(T: boolean) {
    console.log('whatsapp', T);
    this.sendEvaluationByWhatsapp = T;
  }

  submitEvaluation(
    evaluation: Evaluation,
    event: Alert | any,
    currentRow: HTMLElement
  ) {
    evaluation.arrival_time = this.waitTimeService.getTime(event.uuid_event)!;
    evaluation.operador_monitoreo?.replace(/\s+/g, '').trim();
    if (evaluation.operador_monitoreo?.trim() == '') {
      // Swal.fire(
      //   'Espera!',
      //   'Ingrese o seleccione el nombre del operador.',
      //   'info'
      // );
      this.customToastMessagess.addCustomToast({
        id: evaluation.uuid_event!.toString(),
        severity: 'warn',
        summary: `Alerta: ${event.evaluations![0].tipo_evento}`,
        detail: `Ingrese o seleccione el nombre del operador`,
        data: {
          unit: `Unidad: ${event.nombre_objeto}`,
          datetime: `Fecha: ${event.fecha_tracker}`,
          dataEvent: event,
          dataHTML: this.currentRowSelected,
        },
        sticky: false,
        closable: false,
      });
      return;
    }
    if (
      evaluation.senales_posible_fatiga == true &&
      evaluation.valoracion_evento == '0'
    ) {
      // Swal.fire(
      //   'Espera!',
      //   'Debe valorar la precisión del evento (1-5 estrellas)',
      //   'info'
      // );
      this.customToastMessagess.addCustomToast({
        id: evaluation.uuid_event!.toString(),
        severity: 'warn',
        summary: `Alerta: ${event.evaluations![0].tipo_evento}`,
        detail: `Debe valorar la precisión del evento (1-5 estrellas)`,
        data: {
          unit: `Unidad: ${event.nombre_objeto}`,
          datetime: `Fecha: ${event.fecha_tracker}`,
          dataEvent: event,
          dataHTML: this.currentRowSelected,
        },
        sticky: false,
        closable: false,
      });
      return;
    }
    if (!evaluation.senales_posible_fatiga) {
      evaluation.valoracion_evento = '0';
      evaluation.identificacion_video = '';
    }
    if (
      (typeof evaluation.criterio_evaluacion !== 'string' ||
        evaluation.criterio_evaluacion.trim() === '') &&
      (typeof evaluation.observacion_evaluacion !== 'string' ||
        evaluation.observacion_evaluacion.trim() === '')
    ) {
      // Swal.fire(
      //   'Espera!',
      //   'Seleccione un criterio de evaluación o ingrese su observación.',
      //   'info'
      // );
      this.customToastMessagess.addCustomToast({
        id: evaluation.uuid_event!.toString(),
        severity: 'warn',
        summary: `Alerta: ${event.name}`,
        detail: `Seleccione un criterio de evaluación o ingrese una observación`,
        data: {
          unit: `Unidad: ${event.nombre_objeto}`,
          datetime: `Fecha: ${event.fecha_tracker}`,
          dataEvent: event,
          dataHTML: this.currentRowSelected,
        },
        sticky: false,
        closable: false,
      });
      return;
    }
    this.customToastMessagess.addCustomToast({
      id: evaluation.uuid_event!.toString(),
      severity: 'info',
      summary: `${event.evaluations![0].tipo_evento}: Guardando`,
      detail: ``,
      data: {
        unit: `Unidad: ${event.nombre_objeto}`,
        datetime: `Fecha: ${event.fecha_tracker}`,
        dataEvent: event,
        dataHTML: this.currentRowSelected,
      },
      sticky: true,
      closable: false,
    });

    this.eventsEvaluated++;

    // console.log('eventEval:event', event);
    // console.log('eventEval:evaluation', evaluation);
    this.expandedRows = {};
    this.submitting = true;
    this.eventService
      .saveEvaluations(evaluation)
      .then(async (response) => {
        // console.log('response after saveEvaluations', response);
        let realEvent = this.eventService.eventsFiltered.find(
          (event) => event.uuid_event == evaluation.uuid_event
        );
        realEvent.evaluations = response[0] as Evaluation[];
        realEvent.evaluated += 1;
        // console.log(
        //   '######################## realEvent after response-->>',
        //   realEvent
        // );
        this.customToastMessagess.updateCustomToastById({
          id: evaluation.uuid_event!.toString(),
          severity: 'success',
          summary: `${event.name}: Guardado`,
          detail: ``,
          data: {
            unit: `Unidad: ${event.nombre_objeto}`,
            datetime: `Fecha: ${event.fecha_tracker}`,
          },
          sticky: false,
          closable: false,
        });

        if (typeof response[1] === 'number' && response[1] == 0) {
          if (typeof response[0] === 'object') {
            let retryEvaluation: Evaluation = response[0];
            this.eventService
              .updateEvaluatedToEvaluations(retryEvaluation)
              .then((r) => {
                console.log('eventEval: Force then', r);
              })
              .catch((c) => {
                console.log('eventEval: Force catch', c);
              })
              .finally(() => {
                console.log('eventEval: Force finaly');
              });
          }
        }
        console.log('whatsapp', this.sendEvaluationByWhatsapp);

        if (this.sendEvaluationByWhatsapp) {
          this.whatsappService
            .sendWhatsAppAlertEvaluated(evaluation, event)
            .then((r) => {
              this.whatsappService.sendResponseOfPostWhastApp({
                usuario_id: evaluation.usuario_id!.toString(),
                uuid_event: evaluation.uuid_event!.toString(),
                ws_json: JSON.stringify(r),
              });
              console.log('whatsapp json', JSON.stringify(r));
            });
        }

        this.sendEvaluationByWhatsapp = false;
        // Swal.fire('Éxito', 'Los cambios se guardaron exitosamente', 'success');
        this.display_dialog_event = true;
        this.addEvaluation(realEvent.uuid_event);
        this.eventSocketService.evaluationEmit({
          id: realEvent.id,
          uuid: realEvent.uuid_event,
        });
        this.eventsEvaluated > 0 ? this.eventsEvaluated-- : null;
      })
      .catch((error) => {
        // Swal.fire('Error', 'No se pudo guardar la evaluación', 'error');
        // console.log('eventEval: Error', error);
        var errorMessage = `Error desconocido`;
        var typeError = 'unknown';
        var typeServerity = 'error';
        var summaryError = `Error!: ${event.name}`;
        if (
          new Date(event.fecha_tracker!) <
          new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
        ) {
          errorMessage = `El tiempo de evaluacion expirado`;
          typeError = 'time';
        }
        // if (
        //   new Date(event.fecha_tracker!) < new Date(new Date().getTime() + 2000)
        // ) {
        //   typeServerity = 'success';
        //   typeError = 'none';
        //   summaryError = `${event.nombre}: Guardado correctamente!`;
        // }

        if (
          error.error.messages.includes(
            'duplicate key value violates unique constraint'
          )
        ) {
          typeError = 'duplicate';
          errorMessage = `El evento ya ha sido evaluado`;
        }
        // console.log('eventEval: errores', error.error.messages);

        this.customToastMessagess.updateCustomToastById({
          id: evaluation.uuid_event!.toString(),
          severity: typeServerity,
          summary: summaryError,
          detail: `${errorMessage}`,
          data: {
            type: typeError,
            dataEvent: event,
            error: errorMessage,
            dataHTML: this.currentRowSelected,
            unit: `Unidad: ${event.nombre_objeto}`,
            datetime: `Fecha: ${event.fecha_tracker}`,
            nowTime: this.nowDateTime(),
            rawError: error.error.messages.toString(),
          },
          sticky: typeServerity == 'success' ? false : true,
          closable: false,
        });
        this.eventsEvaluated > 0 ? this.eventsEvaluated-- : null;
      })
      .finally(() => {
        this.submitting = false;
      });
  }
  nowDateTime(): string {
    const now = new Date();
    const AAAA = now.getFullYear();
    const MM = now.getMonth() + 1; // Los meses comienzan desde 0, por lo que se suma 1
    const DD = now.getDate();
    const hh = now.getHours();
    const mm = now.getMinutes();
    const ss = now.getSeconds();
    // console.log(`iog: A/M/D h/m/s _${AAAA}${MM}${DD}_${hh}.${mm}.${ss}_`);
    return `${AAAA}/${MM}/${DD} ${hh}:${mm}:${ss}`;
  }
  clearEvaluation(evaluation: Evaluation) {
    //console.log("evaluation to clean", evaluation);

    if (!evaluation.id) {
      evaluation.criterio_evaluacion = '';
      evaluation.identificacion_video = '';
      evaluation.observacion_evaluacion = '';
      evaluation.operador_monitoreo = '';
      evaluation.valoracion_evento = '0';
      evaluation.senales_posible_fatiga = false;
    }
  }

  criteriaSelected(event: any, evaluation: Evaluation) {
    evaluation.senales_posible_fatiga = false;
    this.evaluation_criteria[0].items.forEach((criteria) => {
      if (criteria.value == event.value) {
        evaluation.senales_posible_fatiga = true;
      }
    });
  }

  closeEvaluationExpanded(evaluation: Evaluation) {
    this.clearEvaluation(evaluation);
    this.expandedRows = {};
  }

  /* // Añadido: Guarda el filtro seleccionado en el localStorage
  private saveFilterToLocalStorage(filter: string): void {
    localStorage.setItem('filter', filter);
  }

  // Añadido: Recupera el filtro almacenado en el localStorage
  private getFilterFromLocalStorage(): string {
    return localStorage.getItem('filter') || '';
  } */
}
