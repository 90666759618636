import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drivers-panel',
  templateUrl: './drivers-panel.component.html',
  styleUrls: ['./drivers-panel.component.scss']
})
export class DriversPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
