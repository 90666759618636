import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Alert, Country, Pais } from '../models/alert.interface';
import { ResponseInterface } from 'src/app/core/interfaces/response-interface';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  isLoadingData: boolean = true;

  public alerts: Alert[] = [];
  public alertEdit: any;
  public alertsByType: any = [];
  public panelAlertKey: Number = 0;

  dataCompleted = false;

  public alertsForEventSocket: any[] = [];
  public codes: Pais[] = [
    { label: 'US (+1)', code: +1, name: 'Estados Unidos ' },
    { label: 'CA (+1)', code: +1, name: 'Canadá ' },
    { label: 'MX (+52)', code: +52, name: 'México ' },
    { label: 'BR (+55)', code: +55, name: 'Brasil ' },
    { label: 'AR (+54)', code: +54, name: 'Argentina ' },
    { label: 'CO (+57)', code: +57, name: 'Colombia ' },
    { label: 'CL (+56)', code: +56, name: 'Chile ' },
    { label: 'VE (+58)', code: +58, name: 'Venezuela ' },
    { label: 'PE (+51)', code: +51, name: 'Perú ' },
    { label: 'EC (+593)', code: +593, name: 'Ecuador ' },
    { label: 'CU (+53)', code: +53, name: 'Cuba ' },
    { label: 'BO (+591)', code: +591, name: 'Bolivia ' },
    { label: 'CR (+506)', code: +506, name: 'Costa Rica ' },
    { label: 'PA (+507)', code: +507, name: 'Panamá ' },
    { label: 'UY (+598)', code: +598, name: 'Uruguay ' },
    { label: 'ES (+34)', code: +34, name: 'España ' },
    { label: 'DE (+49)', code: +49, name: 'Alemania ' },
    { label: 'FR (+33)', code: +33, name: 'Francia ' },
    { label: 'IT (+39)', code: +39, name: 'Italia ' },
    { label: 'UK (+44)', code: +44, name: 'Reino Unido ' },
    { label: 'RU (+7)', code: +7, name: 'Rusia ' },
    { label: 'UA (+380)', code: +380, name: 'Ucrania ' },
    { label: 'PL (+48)', code: +48, name: 'Polonia ' },
    { label: 'RO (+40)', code: +40, name: 'Rumania ' },
    { label: 'NL (+31)', code: +31, name: 'Países Bajos ' },
    { label: 'BE (+32)', code: +32, name: 'Bélgica ' },
    { label: 'GR (+30)', code: +30, name: 'Grecia ' },
    { label: 'PT (+351)', code: +351, name: 'Portugal ' },
    { label: 'SE (+46)', code: +46, name: 'Suecia ' },
    { label: 'NO (+47)', code: +47, name: 'Noruega ' },
    { label: 'CN (+86)', code: +86, name: 'China ' },
    { label: 'IN (+91)', code: +91, name: 'India ' },
    { label: 'JP (+81)', code: +81, name: 'Japón ' },
    { label: 'KR (+82)', code: +82, name: 'Corea del Sur ' },
    { label: 'ID (+62)', code: +62, name: 'Indonesia ' },
    { label: 'TR (+90)', code: +90, name: 'Turquía ' },
    { label: 'PH (+63)', code: +63, name: 'Filipinas ' },
    { label: 'TH (+66)', code: +66, name: 'Tailandia ' },
    { label: 'VN (+84)', code: +84, name: 'Vietnam ' },
    { label: 'IL (+972)', code: +972, name: 'Israel ' },
    { label: 'MY (+60)', code: +60, name: 'Malasia ' },
    { label: 'SG (+65)', code: +65, name: 'Singapur ' },
    { label: 'PK (+92)', code: +92, name: 'Pakistán ' },
    { label: 'BD (+880)', code: +880, name: 'Bangladés ' },
    { label: 'SA (+966)', code: +966, name: 'Arabia Saudita ' },
    { label: 'EG (+20)', code: +20, name: 'Egipto ' },
    { label: 'ZA (+27)', code: +27, name: 'Sudáfrica ' },
    { label: 'NG (+234)', code: +234, name: 'Nigeria ' },
    { label: 'KE (+254)', code: +254, name: 'Kenia ' },
    { label: 'MA (+212)', code: +212, name: 'Marruecos ' },
    { label: 'DZ (+213)', code: +213, name: 'Argelia ' },
    { label: 'UG (+256)', code: +256, name: 'Uganda ' },
    { label: 'GH (+233)', code: +233, name: 'Ghana ' },
    { label: 'CM (+237)', code: +237, name: 'Camerún ' },
    { label: 'CI (+225)', code: +225, name: 'Costa de Marfil ' },
    { label: 'SN (+221)', code: +221, name: 'Senegal ' },
    { label: 'TZ (+255)', code: +255, name: 'Tanzania ' },
    { label: 'SD (+249)', code: +249, name: 'Sudán ' },
    { label: 'LY (+218)', code: +218, name: 'Libia ' },
    { label: 'TN (+216)', code: +216, name: 'Túnez ' },
    { label: 'AU (+61)', code: +61, name: 'Australia ' },
    { label: 'NZ (+64)', code: +64, name: 'Nueva Zelanda ' },
    { label: 'FJ (+679)', code: +679, name: 'Fiji ' },
    { label: 'PG (+675)', code: +675, name: 'Papúa Nueva Guinea ' },
    { label: 'TO (+676)', code: +676, name: 'Tonga ' },
    { label: 'IR (+98)', code: +98, name: 'Irán ' },
    { label: 'IQ (+964)', code: +964, name: 'Iraq ' },
    { label: 'JO (+962)', code: +962, name: 'Jordania ' },
    { label: 'LB (+961)', code: +961, name: 'Líbano ' },
    { label: 'KW (+965)', code: +965, name: 'Kuwait ' },
    { label: 'AE (+971)', code: +971, name: 'Emiratos Árabes Unidos ' },
    { label: 'OM (+968)', code: +968, name: 'Omán ' },
    { label: 'QA (+974)', code: +974, name: 'Catar ' },
    { label: 'BH (+973)', code: +973, name: 'Bahrein ' },
    { label: 'YE (+967)', code: +967, name: 'Yemen ' },
  ];

  public country: Country[] = [
    {
      label: 'América',
      pais: [
        { label: 'Estados Unidos (+1)', code: +1 },
        { label: 'Canadá (+1)', code: +1 },
        { label: 'México (+52)', code: +52 },
        { label: 'Brasil (+55)', code: +55 },
        { label: 'Argentina (+54)', code: +54 },
        { label: 'Colombia (+57)', code: +57 },
        { label: 'Chile (+56)', code: +56 },
        { label: 'Venezuela (+58)', code: +58 },
        { label: 'Perú (+51)', code: +51 },
        { label: 'Ecuador (+593)', code: +593 },
        { label: 'Cuba (+53)', code: +53 },
        { label: 'Bolivia (+591)', code: +591 },
        { label: 'Costa Rica (+506)', code: +506 },
        { label: 'Panamá (+507)', code: +507 },
        { label: 'Uruguay (+598)', code: +598 },
      ],
    },
    {
      label: 'Europa',
      pais: [
        { label: 'España (+34)', code: +34 },
        { label: 'Alemania (+49)', code: +49 },
        { label: 'Francia (+33)', code: +33 },
        { label: 'Italia (+39)', code: +39 },
        { label: 'Reino Unido (+44)', code: +44 },
        { label: 'Rusia (+7)', code: +7 },
        { label: 'Ucrania (+380)', code: +380 },
        { label: 'Polonia (+48)', code: +48 },
        { label: 'Rumania (+40)', code: +40 },
        { label: 'Países Bajos (+31)', code: +31 },
        { label: 'Bélgica (+32)', code: +32 },
        { label: 'Grecia (+30)', code: +30 },
        { label: 'Portugal (+351)', code: +351 },
        { label: 'Suecia (+46)', code: +46 },
        { label: 'Noruega (+47)', code: +47 },
      ],
    },

    {
      label: 'Asia',
      pais: [
        { label: 'China (+86)', code: +86 },
        { label: 'India (+91)', code: +91 },
        { label: 'Japón (+81)', code: +81 },
        { label: 'Corea del Sur (+82)', code: +82 },
        { label: 'Indonesia (+62)', code: +62 },
        { label: 'Turquía (+90)', code: +90 },
        { label: 'Filipinas (+63)', code: +63 },
        { label: 'Tailandia (+66)', code: +66 },
        { label: 'Vietnam (+84)', code: +84 },
        { label: 'Israel (+972)', code: +972 },
        { label: 'Malasia (+60)', code: +60 },
        { label: 'Singapur (+65)', code: +65 },
        { label: 'Pakistán (+92)', code: +92 },
        { label: 'Bangladés (+880)', code: +880 },
        { label: 'Arabia Saudita (+966)', code: +966 },
      ],
    },

    {
      label: 'África',
      pais: [
        { label: 'Egipto (+20)', code: +20 },
        { label: 'Sudáfrica (+27)', code: +27 },
        { label: 'Nigeria (+234)', code: +234 },
        { label: 'Kenia (+254)', code: +254 },
        { label: 'Marruecos (+212)', code: +212 },
        { label: 'Argelia (+213)', code: +213 },
        { label: 'Uganda (+256)', code: +256 },
        { label: 'Ghana (+233)', code: +233 },
        { label: 'Camerún (+237)', code: +237 },
        { label: 'Costa de Marfil (+225)', code: +225 },
        { label: 'Senegal (+221)', code: +221 },
        { label: 'Tanzania (+255)', code: +255 },
        { label: 'Sudán (+249)', code: +249 },
        { label: 'Libia (+218)', code: +218 },
        { label: 'Túnez (+216)', code: +216 },
      ],
    },
    {
      label: 'Oceanía',
      pais: [
        { label: 'Australia (+61)', code: +61 },
        { label: 'Nueva Zelanda (+64)', code: +64 },
        { label: 'Fiji (+679)', code: +679 },
        { label: 'Papúa Nueva Guinea (+675)', code: +675 },
        { label: 'Tonga (+676)', code: +676 },
      ],
    },
    {
      label: 'Medio Oriente',
      pais: [
        { label: 'Irán (+98)', code: +98 },
        { label: 'Iraq (+964)', code: +964 },
        { label: 'Jordania (+962)', code: +962 },
        { label: 'Líbano (+961)', code: +961 },
        { label: 'Kuwait (+965)', code: +965 },
        { label: 'Emiratos Árabes Unidos (+971)', code: +971 },
        { label: 'Omán (+968)', code: +968 },
        { label: 'Catar (+974)', code: +974 },
        { label: 'Bahrein (+973)', code: +973 },
        { label: 'Yemen (+967)', code: +967 },
      ],
    },
  ];
  public listaSonidos = [
    { id: 1, ruta: 'sonidos/alarm8.mp3', label: 'Sonido 1' },
    { id: 2, ruta: 'sonidos/alarm2.mp3', label: 'Sonido 2' },
    { id: 3, ruta: 'sonidos/CartoonBullets3.mp3', label: 'Sonido 3' },
    { id: 4, ruta: 'sonidos/DjStop4.mp3', label: 'Sonido 4' },
    { id: 5, ruta: 'sonidos/messenger5.mp3', label: 'Sonido 5' },
    { id: 6, ruta: 'sonidos/Ping6.mp3', label: 'Sonido 6' },
    { id: 7, ruta: 'sonidos/Twitter7.mp3', label: 'Sonido 7' },
    { id: 8, ruta: 'sonidos/Whatsap8.mp3', label: 'Sonido 8' },
    { id: 9, ruta: 'sonidos/WhatsappSound9.mp3', label: 'Sonido 9' },
    // Nuevos sonidos
    { id: 10, ruta: 'sonidos/bloop.mp3', label: 'Sonido 10' },
    { id: 11, ruta: 'sonidos/error.mp3', label: 'Sonido 11' },
    { id: 12, ruta: 'sonidos/happypop1.mp3', label: 'Sonido 12' },
    { id: 13, ruta: 'sonidos/happypop2.mp3', label: 'Sonido 13' },
    { id: 14, ruta: 'sonidos/phonehanging.mp3', label: 'Sonido 14' },
    { id: 15, ruta: 'sonidos/notification.mp3', label: 'Sonido 15' },
    { id: 16, ruta: 'sonidos/ringtone.mp3', label: 'Sonido 16' },
    { id: 17, ruta: 'sonidos/shortsuccess.mp3', label: 'Sonido 17' },
    {
      id: 18,
      ruta: 'sonidos/alerta.mp3',
      label: 'Sonido 18',
    },

    { id: 19, ruta: 'sonidos/sound.mp3', label: 'Sonido 19' },
    { id: 20, ruta: 'sonidos/sound1.MP3', label: 'Sonido 20' },
    { id: 21, ruta: 'sonidos/ALARMA1.MP3', label: 'Sonido 21' },
    { id: 22, ruta: 'sonidos/ALARMA2.MP3', label: 'Sonido 22' },
    { id: 23, ruta: 'sonidos/ALARMA3.MP3', label: 'Sonido 23' },
    { id: 24, ruta: 'sonidos/ALARMA4.MP3', label: 'Sonido 24' },
    { id: 25, ruta: 'sonidos/digitalalarm.mp3', label: 'Sonido 25' },
    { id: 26, ruta: 'sonidos/watchalarm.mp3', label: 'Sonido 26' },

    // SONIDOS PERSONALIZADOS
    {
      id: 27, //X
      ruta: 'sonidos/aceleracion brusca.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 28, //X
      ruta: 'sonidos/anticolisión frontal.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 29, //X
      ruta: 'sonidos/desvio carril derecha.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 30, //X
      ruta: 'sonidos/desvio carril izquierda.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 31, //X
      ruta: 'sonidos/distracción.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 32, //X
      ruta: 'sonidos/exceso velocidad.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 33, //X
      ruta: 'sonidos/fatiga extrema.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 34, //X
      ruta: 'sonidos/frenada brusca.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 35, //X
      ruta: 'sonidos/infracción.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 36, //x
      ruta: 'sonidos/no rostro.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 37, //X
      ruta: 'sonidos/posible fatiga.mp3',
      label: 'Voz Personalizada',
    },
    {
      id: 38, //X
      ruta: 'sonidos/riesgo de colisión con peatones.mp3',
      label: 'Voz Personalizada',
    },
  ];
  sonidosObtenidos: any;
  public alertCategories: string[] = [];
  constructor(private http: HttpClient) {
    console.log('INICIANDO-----ALERTSERVICE');
  }

  public async get(id: string): Promise<Alert[]> {
    const response: ResponseInterface = await this.http
      .get<ResponseInterface>(`${environment.apiUrl}/api/alerts/${id}`)
      .toPromise();
    return response.data;
  }

  public async getAll(
    key: string = '',
    show_in_page: number = 15,
    page: number = 1
  ): Promise<Alert[]> {
    // console.log('Obteniendo Alertas...');
    const response: ResponseInterface = await this.http
      .get<ResponseInterface>(`${environment.apiUrl}/api/alerts`)
      .toPromise();
    let i = 1;
    let alerts_for_events_socket: any[] = [];
    this.alerts = response.data.map((data: Alert): Alert => {
      let sistema_notificacion = data?.sistema_notificacion?.split(',');
      data.sonido_sistema_bol = sistema_notificacion[2] == 'true';
      data.activo_bol = data.activo == 'true';
      data.notificacion_email_bol = data.notificacion_email == 'true';

      alerts_for_events_socket.push({
        evento_id: data.id,
        sonido_sistema_bol: data.sonido_sistema_bol,
        ruta_sonido: sistema_notificacion[3],
      });

      data.nr = i;
      i++;

      return data;
    });
    this.alertsForEventSocket = alerts_for_events_socket;
    // console.log('Alertas obtenidas');
    //console.log(this.alertsForEventSocket);
    this.dataCompleted = true;
    return this.alerts;
  }

  public getDataAll() {
    return this.alerts;
  }

  public clearDataAll() {
    this.alerts = [];
  }

  public async getAllEvents() {
    const response: ResponseInterface = await this.http
      .get<ResponseInterface>(`${environment.apiUrl}/api/events`)
      .toPromise();
    let events = response.data;
    return events;
  }

  public async getEventsByType(type: string) {
    const response: ResponseInterface = await this.http
      .get<ResponseInterface>(`${environment.apiUrl}/api/events/${type}`)
      .toPromise();
    let event = response.data;

    // event = event.filter(function( obj:any ) {
    //   return obj.id !== 23;  // id=23	name=Somnolencia	slug=somnolencia	type=accessories		 ==> 7.	Quitar los eventos de Somnolencia
    // });

    event = event.map((ev: any) => {
      ev.name = this.toCamelCase(ev.name);
      return ev;
    });

    return event;
  }

  toCamelCase(str: any) {
    const palabras = str.split(' ');

    var palabraM = palabras
      .map((palabra: any) => {
        if (
          palabra == 'de' ||
          palabra == 'en' ||
          palabra == 'con' ||
          palabra == 'de' ||
          palabra == 'la'
        ) {
          return palabra;
        } else {
          return palabra[0].toUpperCase() + palabra.substring(1);
        }
      })
      .join(' ');
    return palabraM;
  }

  public async getAlertsByType(type: string) {
    const response: ResponseInterface = await this.http
      .get<ResponseInterface>(`${environment.apiUrl}/api/alerts/${type}`)
      .toPromise();
    this.alertsByType = response.data;

    response.data.forEach((alert: any) => {
      //Cada vez que se cargan las listas de alerta por tipo, aprovechar en actualizar el sonido de la alerta.
      let i = this.alertsForEventSocket.findIndex(
        (alert_for_socket) => alert_for_socket.evento_id == alert.id
      );
      if (i != -1) {
        let sistema_notificacion = alert?.sistema_notificacion?.split(',');
        this.alertsForEventSocket[i].sonido_sistema_bol =
          sistema_notificacion[2] == 'true';
        this.alertsForEventSocket[i].ruta_sonido = sistema_notificacion[3];
      } else {
        console.log(
          'Alerts For Events Socket could be empty, or the alert deleted: ',
          this.alertsForEventSocket
        );
      }
    });
    //console.log(this.alertsForEventSocket);
    //console.log(response.data);
    return this.alertsByType;
  }

  public getDataByType() {
    return this.alertsByType;
  }

  public clearDataByType() {
    this.alertsByType = [];
  }

  public async create(alert: any) {
    console.log('data enviada', alert);
    const response: ResponseInterface = await this.http
      .post<ResponseInterface>(`${environment.apiUrl}/api/alerts`, alert)
      .toPromise();
    console.log('response', response);
    return response.data;
  }

  public async delete(id: string) {
    const response: ResponseInterface = await this.http
      .delete<ResponseInterface>(`${environment.apiUrl}/api/alerts/${id}`)
      .toPromise();
    return response.data;
  }

  public async edit(alert: any) {
    const response: ResponseInterface = await this.http
      .put<ResponseInterface>(
        `${environment.apiUrl}/api/alerts/${alert.id}`,
        alert
      )
      .toPromise();
    return response.data;
  }

  public getDataAlerts() {
    return this.alerts;
  }

  public getAlertEditData() {
    return this.alertEdit;
  }

  /*   public getAlertById(id: string) {
    console.log('id->', id);
    //console.log("Alerts----", this.alerts);
    return this.alerts.find((alert) => alert.id == id.toString());
  } */

  public getAlertById(id: string): Alert | undefined {
    // console.log('id->', id);
    return this.alerts.find((alert) => alert.id.toString() === id);
  }

  //INICIO PRUEBA

  public getSoundAll() {
    return this.listaSonidos;
  }

  //FIN TEST

  public async getEventsCategory(eventsCategories: string[]) {
    this.alertCategories = eventsCategories;
    console.log('ALEN: ', this.alertCategories);
  }
}
