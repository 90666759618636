
  <div>

    <div class="accordion" id="accordionExample">
        <div class="accordion-item">

          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              ¿Cómo funciona?
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">

            
                <dl>
                    <dt>Descargue la plantilla de EXCEL</dt>
                    <dd>Puede obtenerlo en el boton "Exportar" seleccionando la opción "Plantilla de vehículos" o puede obtenerlo desde aqui. </dd>
                    <dt>Complete los datos </dt>
                    <dd>Complete la tabla de datos de acuerdo a cada columna</dd>
                    <dd>- Placa:</dd>
                    <dd>- Chasis:</dd>
                    <dt>Suba el archivo con datos </dt>
                    <dd>- Haga click en el boton para subir su archivo. Podrá previsualizar sus datos y solucionar posibles errores.</dd>
                    <dd>- Guarde la programación en en sistema.</dd>
                  </dl>

              <!-- Descargue la plantilla de EXCEL
              Puede obtenerlo en el boton "Exportar" seleccionando la opción "Plantilla de vehículos" o puede obtenerlo desde aqui.
          
              Complete los datos 
              Complete la tabla de datos de acuerdo a cada columna
              -Placa:
              -Chasis
          
              Suba el archivo con datos 
              -Haga click en el boton para subir su archivo. Podrá previsualizar sus datos y solucionar posibles errores.
              -Guarde la programación en en sistema. -->

            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Importar programación
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">


                <div class="row justify-content-center">


                    <!-- <div class="col-4">
                    </div> -->
                 
                        <p-fileUpload
                            mode="basic"
                            [chooseLabel]="nameFile" 
                            chooseIcon="pi pi-upload"
                            
                            [auto]="true"
                            [files]="myFiles"
                            title="upload file"
                            [customUpload]="true"
                            (uploadHandler)="upload_document($event);" >
                        </p-fileUpload>

              
                    <!-- <div class="col-4">
                    </div> -->
                </div>
      
                  





            </div>
          </div>
        </div>
 
      </div>
      




      <div>
          
          <!-- <p-fileUpload 
          mode="basic" 
          chooseLabel="Choose" 
          chooseIcon="pi pi-upload"
          name="demo[]" 
          url="https://www.primefaces.org/cdn/api/upload.php" 
          accept="image/*" 
          maxFileSize="1000000" 
          (onUpload)="onUpload($event)" /> -->
          
          <!-- <p-fileUpload 
              mode="basic" 
              chooseLabel="Chooseee" 
              chooseIcon="pi pi-upload"

              [auto]="true"
              [files]="myFiles"

              [customUpload]="true"
              (uploadHandler)="upload_document($event)" >
            </p-fileUpload>

          <p-fileUpload 
              mode="basic" 
              chooseLabel="Choose" 
              chooseIcon="pi pi-upload"
              name="demo[]" 
              url="https://www.primefaces.org/cdn/api/upload.php" 
              maxFileSize="1000000" 
              (uploadHandler)="upload_document($event)" >
            </p-fileUpload> -->



          
      </div>
  

      <!-- <div id="output"></div>
      <br>
      <div *ngIf="willDownload">
        <a id="download"> Download JSON File </a>
      </div> -->





        <div *ngIf="programaciones.length > 0">
          <p-table 
              [value]="programaciones" 
              styleClass="p-datatable-striped" 
              [tableStyle]="{'min-width': '50rem'}">
                  <ng-template pTemplate="header">
                      <tr>
                          <th style="width:5%">N°</th>
                          <th style="width:40%">Nombre</th>
                          <th style="width:15%">Placa</th>
                          <th style="width:20%">Fecha Inicio</th>
                          <th style="width:20%">Fecha Final</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-programacion let-i="rowIndex">
                      <tr>
                          <td>{{ i+1 }}</td>
                          <td>{{programacion.nombre}}</td>
                          <td>{{programacion.placa}}</td>
                          <td>{{programacion["fecha inicio"]}}</td>
                          <td>{{programacion["fecha final"]}}</td>
                      </tr>
                  </ng-template>

                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="5">
                        There are no order for this product yet.
                      </td>
                    </tr>
                  </ng-template>

          </p-table>

        </div>


        

        
        <div *ngIf="programaciones.length > 0" class="row justify-content-center mt-4 pt-2 panel-izq-container-footer">
          <div class="col-4">
              <button type="button" class="btn-gl-cancel btn-dark-hover w-100" click="driversService.modalProgramacionActive = false">Cancelar</button>
          </div>
          <div class="col-4">
              <button type="submit" class="btn-gl-save btn-dark-hover w-100" >Guardar</button>
          </div>
        </div>
      <!-- </ng-template> -->



      <ng-template pTemplate="footer" style="width: 60vw;">
      </ng-template>




      
  </div>




