
<p-card>

    <ng-template pTemplate="header">
      <span class="d-block p-2 text-white">
        <i class="fas fa-chart-bar"></i> Reportes Programados</span>
    </ng-template>


    <p-button label="Agregar" icon="pi pi-plus" (click)="agregarReporteProgramado0();" styleClass="p-button-secondary"></p-button>

    <div class="p-fluid y-form-container">

        <p-table [value]="reportesProgramados" [tableStyle]="{ 'min-width': '50rem', 'font-size': '0.9rem' }" styleClass="p-datatable-sm" >
        <!-- <p-table [value]="reportesProgramados" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm" > -->

          <ng-template pTemplate="header">
              <tr>
                  <th style="width:5%">N°</th>
                  <th style="width:40%">Nombre</th>
                  <th style="width:5%">Hora</th>

                  <th style="width:40%">Tipo de Reporte</th>
                  <th style="width:10%">Opciones</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-reporteProgramado let-rowIndex="rowIndex">
              <tr>
                  <td>{{rowIndex + 1}}</td>
                  <td>{{ reporteProgramado.nombre_programacion }}</td>
                  <td>{{ reporteProgramado.notificacion_hora }}</td>
                  <td>{{ reporteProgramado.nombre_reporte }}</td>
            
                  <td>
                      <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editReporteProgramado(reporteProgramado)"></button>
                      <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" (click)="verDetalleReporteProgramado(reporteProgramado)"></button>
                      <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="EliminarReporteProgramado(reporteProgramado)"></button>
                  </td>

              </tr>
          </ng-template>
        </p-table>
    </div>






    <ng-template pTemplate="footer">
      <!-- <button class="btn-gl-save btn-dark-hover" style="width: 10rem; border: none;" (click)="generate();">Generar</button> -->
    </ng-template>

  </p-card>
