import { Component, OnInit } from '@angular/core';

import { DriversService } from '../../services/drivers.service';
import * as XLSX from 'xlsx';


interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-drivers-programacion-modal',
  templateUrl: './drivers-programacion-modal.component.html',
  styleUrls: ['./drivers-programacion-modal.component.scss']
})
export class DriversProgramacionModalComponent implements OnInit {

  constructor(
    public driversService: DriversService
  ) { }

  ngOnInit(): void {

    console.log("-- ngOnInit DriversProgramacionModalComponent");
    console.log(this.driversService.modalProgramacionActive);

    
  }



  
}
