import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-panel-alerts-gps',
  templateUrl: './panel-alerts-gps.component.html',
  styleUrls: ['./panel-alerts-gps.component.scss']
})
export class PanelAlertsGpsComponent implements OnInit {
  

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
