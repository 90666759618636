<div
    cdkDropList
    [cdkDropListData]="items"
    class="drop-list"
    (cdkDropListDropped)="drop($event)">
    <div class="drop-box" *ngFor="let item of items" cdkDrag [cdkDragData]="item">
        <div class="custom-placeholder" *cdkDragPlaceholder></div>
        <ng-content select="div" class="w-100 h-100">
        </ng-content>
        <div *ngIf="showHandler" class="handler" cdkDragHandle>
            <svg width="24px" fill="#c1c1c1" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
        </div>
    </div>
</div>