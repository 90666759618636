import {
  AfterContentInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GeofencesService } from '../../services/geofences.service';
import { MapServicesService } from 'src/app/map/services/map-services.service';
import { Subscription } from 'rxjs';
import { DirectionStyleService } from '../../services/direction-style.service';
import { DirectionStyle, Line, LineEssential } from '../../models/interfaces';
import L, { LatLngExpression, marker } from 'leaflet';
import { Point } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-geocerca-direction',
  templateUrl: './geocerca-direction.component.html',
  styleUrls: ['./geocerca-direction.component.scss'],
})
export class GeocercaDirectionComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @Input() areGeofenceAddressesEnabled: boolean = false;
  @Input() idGeofence!: number;
  @Input() geofenceDirectionActivated!: boolean;
  // ---  ---
  geofence: any;
  directionsLimit: number;
  idDirection: number | undefined;
  polygonLine: L.Polyline | undefined;
  geofenceDirections: Line[];
  directionStyle!: DirectionStyle;
  directionStyleSubscription: Subscription;
  // --- State flags ---
  isAddingNew: boolean = false;
  canAddDirections: boolean = false;
  newDirectionAdded: boolean = false;
  editingAnDirection: boolean = false;
  drawingDirectionPolygonLine: boolean = false;
  modifyingDirectionPolygonLine: boolean = false;
  // --- Options list ---
  booleanOptions: { label: string; value: boolean }[] = [
    { label: 'Sí', value: true },
    { label: 'No', value: false },
  ];
  directions: { label: string; value: string; selected: boolean }[] = [
    { label: 'Subida', value: 'rise', selected: false },
    { label: 'Bajada', value: 'fall', selected: false },
  ];
  constructor(
    private geofenceService: GeofencesService,
    private mapService: MapServicesService,
    private directionStyleService: DirectionStyleService
  ) {
    this.directionStyleSubscription =
      directionStyleService.directionStyle$.subscribe((T) => {
        this.directionStyle = T;
      });
    this.geofenceDirections = [];
    this.directionsLimit = 2;
  }
  ngOnInit(): void {
    this.mapService.map.editTools.stopDrawing();
    this.geofenceService.geofences.map((g: any) => {
      this.geofenceService.clearDrawingsOfGeofence(g);
    });
    this.geofence = this.geofenceService.geofences.find(
      (g: any) => g.id == this.idGeofence
    );
  }
  ngAfterContentInit(): void {
    this.mapService.map.removeLayer(this.geofence.geo_elemento);
    this.geofence.geo_elemento = new L.Polygon(
      JSON.parse(this.geofence.geo_coordenadas).coordinates[0].map((t: any) => [
        t[1],
        t[0],
      ]),
      {
        weight: 3,
        fill: true,
        color: this.geofence.zone_color,
      }
    ).addTo(this.mapService.map);
    if (
      this.geofence.polygonLines &&
      Array.isArray(this.geofence.polygonLines)
    ) {
      this.geofence.polygonLines.map((d: LineEssential) => {
        let _d = { ...this.initLine(), ...d };
        this.geofenceDirections.push(_d);
        this.geofenceDirectionActivated ? this.draw(_d) : null;
      });
    }
    // this.drawDirectionsWhenDirectionIsEnabled();
    this.isAddingNew = this.geofenceDirections.length < this.directionsLimit;
    this.canAddDirections =
      this.geofenceDirections.length < this.directionsLimit;
  }
  ngOnDestroy(): void {
    this.mapService.map.editTools.stopDrawing();
    this.eraseAll();
    this.geofenceService.geofences.map((g: any) => {
      g.zone_visible == 'true'
        ? this.geofenceService.showDrawingsOfGeofence(g)
        : null;
      g.id == this.idGeofence ? g.geo_elemento.editing.enable() : null;
    });
    this.directionStyleSubscription.unsubscribe();
  }
  private initLine(): Line {
    return {
      id: this.generateId(),
      zoneId: 0,
      name: '',
      points: [],
      hasSpeed: false,
      speed: 0,
      type: undefined,
      color: this.determineParity()
        ? this.invertHexColor(this.directionStyle.color)
        : this.directionStyle.color,
      start: this.directionStyle.start,
      end: this.directionStyle.end,
      style: this.directionStyle.style,
    };
  }
  // --- Father ---
  storeGeofenceDirectionToGeofenceServices() {
    if (
      !(this.geofenceDirections.length > 0) &&
      this.geofenceDirectionActivated == true
    ) {
      throw 'Añada al menos una dirección o desactive la dirección de geocerca. Por favor, complételo para continuar';
    }
    this.geofenceDirections.map((d) => {
      if (!d.name.trim()) {
        throw 'El nombre de la dirección de la geocerca es obligatorio. Por favor, complételo para continuar';
      }
      if (d.hasSpeed && d.speed <= 0) {
        throw 'La velocidad de la dirección debe se mayor a 0. Por favor, complételo para continuar';
      }
      d.speed = d.hasSpeed ? d.speed : d.speed > 0 ? d.speed : 0;
    });
    this.tabChange();
    this.geofenceService.directionsToSave.idGeofence = this.idGeofence;
    this.geofenceService.directionsToSave.directions = this
      .geofenceDirections as LineEssential[];
  }
  drawDirectionsWhenDirectionIsEnabled() {
    !this.geofenceDirectionActivated ? this.drawAll() : this.eraseAll();
  }

  // --- Erase ---
  eraseAll() {
    this.polygonLine ? this.mapService.map.removeLayer(this.polygonLine) : null;
    this.geofenceDirections.map((g) => {
      this.eraseLine(g, this.mapService.map);
    });
  }
  eraseLine(line: Line, context: L.Map) {
    this.erasePolygonLine(line, context);
    this.eraseMarcker(line, context);
    this.erasePolylineDecoration(line);
  }
  erasePolygonLine(line: Line, context: L.Map) {
    line.layer ? context.removeLayer(line.layer) : null;
  }
  eraseMarcker(line: Line, context: L.Map) {
    line.marker ? context.removeLayer(line.marker) : null;
  }
  erasePolylineDecoration(line: Line) {
    line.polylineDecorator ? line.polylineDecorator.remove() : null;
  }
  // --- Editing ---

  // --- Drawing ---
  draw(line: Line): Line {
    line.layer = this.drawPolygonLine(
      line.points,
      line.color,
      this.mapService.map
    );
    line.marker = this.drawMarcker(line.points, line, this.mapService.map);
    line.polylineDecorator = this.drawPolylineDecoration(
      line.layer,
      line.color,
      this.mapService.map
    );
    return line;
  }
  drawAll() {
    this.polygonLine
      ? this.polygonLine.addTo(this.mapService.map).enableEdit()
      : null;
    this.geofenceDirections.map((g) => {
      if (g.points.length > 2) {
        g.layer = this.drawPolygonLine(g.points, g.color, this.mapService.map);
        g.marker = this.drawMarcker(g.points, g, this.mapService.map);
        g.polylineDecorator = this.drawPolylineDecoration(
          g.layer,
          g.color,
          this.mapService.map
        );
      }
    });
  }
  drawPolygonLine(
    points: number[][],
    color: string,
    context: L.Map
  ): L.Polyline {
    return new L.Polyline(points.map(([x, y]) => [x, y]))
      .setStyle({
        color: color,
      })
      .addTo(context);
  }
  drawMarcker(points: number[][], line: Line, context: L.Map): L.CircleMarker {
    return L.circleMarker(this.getSetPoint(points), {
      radius: 0,
      fillColor: line.color,
      fillOpacity: 1,
      color: line.color,
      weight: 1,
      opacity: 1,
    })
      .bindTooltip(
        `<b class="" style="position: absolute; color : ${
          line.color
        }; font-size: 10px; transform: rotate(0deg);transform-origin: center;">${
          line.name
        } ${line.hasSpeed ? `${line.speed} km/h` : ''}</b>`,
        {
          permanent: true,
          direction: 'center',
          className: 'leaflet-tooltip-own geofence-tooltip',
        }
      )
      .addTo(context);
  }
  drawPolylineDecoration(
    layer: L.Polyline,
    color: string,
    context: L.Map,
    symbol: string = 'arrowHead'
  ): L.PolylineDecorator {
    const settings = {
      pixelSize: 10,
      polygon: true,
      pathOptions: {
        color: color,
        opacity: 1,
        fillColor: color,
        fillOpacity: 1,
      },
    };
    let _symbol: Record<string, () => L.Symbol.Dash | L.Symbol.ArrowHead> = {
      arrowHead: () => L.Symbol.arrowHead(settings),
      dash: () => L.Symbol.dash(settings),
    };
    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: '0%',
          repeat: `${50 / layer.getLatLngs().length + 1}%`,
          symbol: _symbol[symbol](),
        },
      ],
    }).addTo(context);
  }
  addPolygonLineStyles(layer: L.Polyline) {}
  // --- ---
  determineParity(): boolean {
    return this.geofenceDirections.length % 2 === 0;
  }
  getSetPoint(
    points: number[][],
    position: string = 'center'
  ): [number, number] {
    const o: Record<string, (T: number[][]) => number[]> = {
      center: (T) => T[Math.floor(T.length / 2)],
      last: (T) => T[1],
      first: (T) => T[T.length - 1],
    };
    let point: number[] = o[position](points);
    return [point[0], point[1]];
  }
  status() {
    this.mapService.map.on('editable:drawing:start', () => {});
    this.mapService.map.on('editable:drawing:end', () => {});
  }
  tabChange() {
    if (this.drawingDirectionPolygonLine) {
      this.newDirectionAdded ? this.addDrawingDataToDirection() : null;
      this.modifyingDirectionPolygonLine
        ? this.addDrawingDataToDirectionByUpdate()
        : null;
    }
    this.eraseAll();
    this.polygonLine = undefined;
    this.drawAll();
  }
  tabChangeEnabled(): boolean {
    let _boolean: boolean = !this.geofenceDirections.some(
      (d) => !d.name.trim() || (d.hasSpeed && d.speed <= 0)
    );
    if (this.drawingDirectionPolygonLine) {
      _boolean = this.polygonLine!.getLatLngs().length > 2 && _boolean;
    }
    return _boolean;
  }
  deleteDirection(line: Line) {
    if (this.drawingDirectionPolygonLine) {
      this.mapService.map.editTools.stopDrawing();
      this.tabChange();
    }
    this.eraseAll();
    this.geofenceDirections = this.geofenceDirections.filter(
      (d) => d.id !== line.id
    );
    this.drawAll();
    this.isAddingNew = true;
    this.mapService.map.editTools.stopDrawing();
    this.polygonLine ? this.mapService.map.removeLayer(this.polygonLine) : null;
  }
  updateDirection(line: Line) {
    this.mapService.map.editTools.stopDrawing();
    this.idDirection = line.id;
    this.eraseLine(line, this.mapService.map);
    this.polygonLine = this.drawPolygonLine(
      line.points,
      line.color,
      this.mapService.map
    );
    this.polygonLine.enableEdit();
    this.drawingDirectionPolygonLine = true;
    this.modifyingDirectionPolygonLine = true;
  }
  addDrawingDataToDirection() {
    if (
      this.polygonLine &&
      this.polylineToLatLngByMap(this.polygonLine).length < 3
    ) {
      throw 'La dirección de la geocerca debe tener al menos 3 puntos. Por favor, añade más para continuar.';
    }
    this.mapService.map.editTools.stopDrawing();
    let lastDirection = this.geofenceDirections.slice(-1)[0];
    lastDirection.points = this.polylineToLatLngByMap(this.polygonLine!);
    this.mapService.map.removeLayer(this.polygonLine!);
    lastDirection = this.draw(lastDirection);
    this.drawingDirectionPolygonLine = false;
    this.newDirectionAdded = false;
  }
  addDrawingDataToDirectionByUpdate() {
    this.mapService.map.editTools.stopDrawing();
    let directionEdit = this.geofenceDirections.find(
      (d) => d.id === this.idDirection
    );
    if (directionEdit) {
      directionEdit.points = this.polylineToLatLngByMap(this.polygonLine!);
      this.mapService.map.removeLayer(this.polygonLine!);
      directionEdit = this.draw(directionEdit);
    }
    this.drawingDirectionPolygonLine = false;
    this.modifyingDirectionPolygonLine = false;
  }
  polylineToLatLngByMap(line: L.Polyline): number[][] {
    return (line.getLatLngs() as L.LatLng[]).map((l) => [l.lat, l.lng]);
  }
  createDirectionEnable(): boolean {
    let _boolean: boolean = false;
    _boolean = this.canAddDirections
      ? this.canAddDirections && this.tabChangeEnabled()
      : this.tabChangeEnabled();
    return _boolean;
  }
  disabledOption(event: any, id: number) {
    this.disabledOptionInList();
    this.geofenceDirections.map((d) => {
      if (d.id == id) {
        d.name = !d.name.trim()
          ? this.directions.find((_) => _.value == event.value)?.label ?? ''
          : d.name;
      }
    });
  }
  disabledOptionInList() {
    this.directions.map((_) => {
      _.selected = false;
      this.geofenceDirections.map((d) => {
        _.value == d.type ? (_.selected = true) : null;
      });
    });
  }
  // --- create ---
  createDirection() {
    this.mapService.map.editTools.stopDrawing();
    this.polygonLine ? this.mapService.map.removeLayer(this.polygonLine) : null;
    this.disabledOptionInList();
    this.tabChange();
    this.isAddingNew = true;
    this.geofenceDirections.push(this.initLine());
    this.newDirectionAdded = true;
    this.polygonLine = this.mapService.map.editTools.startPolyline();
    this.drawingDirectionPolygonLine = true;
    this.canAddDirections =
      this.geofenceDirections.length < this.directionsLimit;
  }
  // --- helpers ---
  invertHexColor(hex: string): string {
    if (hex.startsWith('#')) {
      hex = hex.slice(1);
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const invertedR = (255 - r).toString(16).padStart(2, '0');
    const invertedG = (255 - g).toString(16).padStart(2, '0');
    const invertedB = (255 - b).toString(16).padStart(2, '0');
    return `#${invertedR}${invertedG}${invertedB}`;
  }
  generateId(min: number = 1, max: number = 1000000): number {
    return -(Math.floor(Math.random() * (max - min + 1)) + min);
  }
}
