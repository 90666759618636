<p-dialog [(visible)]="display" (onHide)="onHide()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" class="gl-p-dialog" [modal]="true" [resizable]="false">
    <ng-template class="p-dialog-title" pTemplate="header">
      <div>
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        <strong>Crear nueva etiqueta</strong>
      </div>
    </ng-template>

    <div class="dialog-body-container">
      <div class="p-fluid p-formgrid p-grid">
        <div class="dialog-form-container">
          <div class="gl-permanent-middleline mt-2">
            <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="gv_name">Nombre:</label>
                  </div>
                  <div class="col-7">
                    <input (keyup)="onName($event)" #name id="gv_name" type="text" pInputText [disabled]="loading" (change)="validateFormsInputs()" placeholder="Nombre de la etiqueta" >
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="row gl-out-middleline">
            <div class="col p-field p-0" >
              <div class="row gl-row-underline m-0 w-100">
                <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label>Seleccione Operación:</label>
                </div>
                <div class="col-7">
                  <p-dropdown (onChange)="onChangeOperation()" [options]="operations" [(ngModel)]="selectedOperation" optionLabel="nameoperation" optionValue="idoperation" placeholder="Seleccione una Operación" [disabled]="loading"></p-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-column mt-3">
            <div class="row gl-listbox-container">
                <div class="col">
                  <p-listbox [options]="list1" [(ngModel)]="selectedList1" [multiple]="true" [checkbox]="true" [filter]="true" filterPlaceHolder="Buscar geocerca" optionLabel="zone_name" [listStyle]="{'height':'200px'}" [disabled]="loading">
                    <ng-template let-geo pTemplate="item">
                      <div class="car-item">
                        <div *ngIf="(geo['type'])=='polig'"  width="25" height="25" class="pentagon me-2" [ngStyle]="{'background-color': geo.zone_color}"></div>
                        <div *ngIf="(geo['type'])=='circ'" class="circle me-2" [ngStyle]="{'background-color': geo.zone_color}"></div>
                        <div *ngIf="(geo['type'])=='lin'" class="circle me-2" [ngStyle]="{'background-color': geo.zone_color}"></div>
                      </div>
                      <div><label>{{geo.zone_name}}</label></div>
                    </ng-template>
                  </p-listbox>
                </div>
                <div class="col-1 d-flex flex-column justify-content-center align-items-center">
                  <div style="height: 1.5rem;"></div>
                  <div class="">
                    <button (click)="upList2()" type="button" class="btn-gl-light-blue btn-circle" name="button" [disabled]="loading">
                      <span>
                        <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div style="height: 1rem;"></div>
                  <div class="">
                    <button (click)="upList1()" type="button" class="btn-gl-light-blue btn-circle" name="button" [disabled]="loading">
                      <span>
                        <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="col">
                  <p-listbox [options]="list2" [(ngModel)]="selectedList2" [multiple]="true" [checkbox]="true" [filter]="true" filterPlaceHolder="Buscar geocerca" optionLabel="zone_name" [listStyle]="{'height':'200px'}" [disabled]="loading">
                    <ng-template let-geo pTemplate="item">
                      <div class="car-item">
                        <div *ngIf="(geo['type'])=='polig'"  width="25" height="25" class="pentagon me-2" [ngStyle]="{'background-color': geo.zone_color}"></div>
                        <div *ngIf="(geo['type'])=='circ'" class="circle me-2" [ngStyle]="{'background-color': geo.zone_color}"></div>
                        <div *ngIf="(geo['type'])=='lin'" class="circle me-2" [ngStyle]="{'background-color': geo.zone_color}"></div>
                      </div>
                      <div><label>{{geo.zone_name}}</label></div>
                    </ng-template>
                  </p-listbox>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <p-footer>
      <div class="row d-flex justify-content-center">
        <div class="col-3">
          <button (click)="onHide()" _ngcontent-cic-c118="" type="button" class="btn-gl-cancel btn-dark-hover btn-sm w-100" [disabled]="loading">Cancelar</button>
        </div>
        <div class="col-3">
          <button (click)="onConfirmTag()" _ngcontent-cic-c118="" type="button" class="btn-gl-save btn-dark-hover btn-sm w-100" [disabled]="loading">Guardar</button>
        </div>
      </div>
    </p-footer>
</p-dialog>
