<p>rp-add works!</p>

<p-dialog [(visible)]="repProgramadosService.modal_add0" [style]="{ width: '50%', 'font-size': '0.9rem'  }" >

    <ng-template pTemplate="header" class="p-dialog-title" >
      <div>
          <i class="fa fa-plus me-2" aria-hidden="true" ></i>
          <strong>Agregar Nuevo Reporte Programado</strong>
      </div>
    </ng-template>
    
    <ng-template pTemplate="body">
  
        <div class="row">
            <div class="col-2">
                <label >Reportee:</label>
            </div>
            <div class="col-10">
                <!-- <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" optionValue="id" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." (onChange)="resetAllFields(); changedReport(); validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown> -->
                <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" [filter]="true" filterBy="value" filterPlaceholder="Buscar..." emptyFilterMessage="No se encontró reporte para el filtro." optionValue="codigo" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." ></p-dropdown>
            </div>
        </div>
  
    </ng-template>
  
    <ng-template pTemplate="footer" style="width: 60vw; padding-top: 0;">
      <p-button label="Agregar" icon="pi pi-plus" (click)="agregarReporteProgramado();" styleClass="p-button-secondary"></p-button>
    </ng-template> 
  
</p-dialog>
  

<p-dialog [(visible)]="repProgramadosService.modalReportesProgramadosActiveAddEdit" [style]="{ width: '50%', 'font-size': '0.9rem' }" [modal]="true" styleClass="p-fluid">

    <ng-template pTemplate="header" class="p-dialog-title" >
      <div>
          <i class="fa fa-plus me-2" aria-hidden="true" *ngIf="modal_add"></i>
          <i class="fa fa-edit me-2" aria-hidden="true" *ngIf="!modal_add"></i>
          <strong>{{repProgramadosService.titulo_modal_add_edit}}</strong>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
        <!-- <img [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image" [alt]="product.image" class="block m-auto pb-3" *ngIf="product.image" /> -->
        
        <!-- <p-card> -->

          <!-- <ng-template pTemplate="header">
            <img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />
          </ng-template> -->


      


          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Informe:</label>
                  </div>
                  <div class="col-9">
                    <input
                      type="text"
                      size="70"
                      placeholder="Nombre del reporte"
                      pInputText
                      [(ngModel)]="form.nombre_reporte"
                      readonly="true"
                    />
                  </div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Nombre del informe:</label>
                  </div>
                  <div class="col-9">
                    <input
                      type="text"
                      size="70"
                      placeholder="Nombre del informe"
                      pInputText
                      [(ngModel)]="form.nombre"
                    />
                  </div>
              </label>
            </div>
          </div>


          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Vehículos:</label>
                  </div>
                  <div class="col-9">
                    <input
                      type="text"
                      size="70"
                      placeholder="Nombre del informe"
                      pInputText
                      [(ngModel)]="form.nombre"
                    />
                  </div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Hora del dia:</label>
                  </div>
                  <div class="col-9">
                    <input
                      type="text"
                      size="70"
                      placeholder="Nombre del informe"
                      pInputText
                      [(ngModel)]="form.nombre"
                    />
                  </div>
              </label>
            </div>
          </div>



          <div class="row">
            <div class="col p-field">
              <label class="row gl-row-underline">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Formatos disponibles:</label>
                  </div>
                  <div class="col-9">
                    <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" [filter]="true" filterBy="value" filterPlaceholder="Buscar..." emptyFilterMessage="No se encontró reporte para el filtro." optionValue="codigo" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." ></p-dropdown>

                  </div>
              </label>
            </div>
          </div>

  

          <div class="row">
            <div class="col-3">
              <label>HORA PROGRAMADA:</label>
            </div>
            <div class="col-3 gl-row-underline">
              <div class="p-field">
                  <p-calendar
                    [(ngModel)]="form.hora_programado2"
                    [timeOnly]="true"
                    [appendTo]="'body'"
                    [disabled]="false"
                    ></p-calendar>
              </div>
            </div>

            

            <div class="col-6">
            </div>

          </div>

          <br>

          <div class="row">
            <div class="col-4">
              <label>AGREGAR CORREO:</label>
            </div>
            <div class="col-2">
                <p-checkbox
                  inputId="binary"
                  [binary]="true"
                  [(ngModel)]="form.notificacion_email"
                ></p-checkbox>
            </div>


            <div class="col-4">
              <label>ACTIVO:</label>
            </div>
            <div class="col-2">
                <p-checkbox
                  inputId="binary"
                  [binary]="true"
                  [(ngModel)]="form.bol_activo"
                ></p-checkbox>
            </div>


          </div>

          <br>

          <div class="gl-row-underline">
  
            <div class="col-12">
              <input
                type="text"
                size="70"
                placeholder="correo-1@mail.com,correo-2@mail.com..."
                pInputText
                [disabled]="!form.notificacion_email"
                [(ngModel)]="form.notificacion_email_lista"
              />
            </div>
          </div>


          <!-- emails -->
          <!-- <div class="row d-flex justify-content-center">
            <div class="col-5">
              <label class="row">
                <span class="col-9 form-check-label">Agregar Correo:</span>
                <div class="col-2 d-flex flex-column justify-content-center">
                  <div class="form-check">
                    <p-checkbox
                      inputId="binary"
                
                      [binary]="true"
                      [(ngModel)]="form.notificacion_email"
                
                    ></p-checkbox>
                  </div>
                </div>
              </label>
            </div>
            <div class="col-5"></div>
          </div> -->

          <div class="row"></div>

          <!-- <div class="form-group row">
            <div class="col-12 d-flex">
              <input
                type="text"
                placeholder="ejemplo@mail.com"
                class="flex-grow-1"
                pInputText
                [(ngModel)]="form.notificacion_email_lista"
                name="email"
                (keydown.enter)="addEmail()"
              />
              <button
                type="button"
                class="btn-gl-clear flex-none ms-2"
                (click)="addEmail()"
                [disabled]="!alertForm.value.chkCorreo"
              >
                <i
                  class="fa fa-plus-circle fa-2x icon-gl-blue"
                  data-placement="bottom"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div> -->

          <!-- <div class="form-group row">
            <div class="col-9">
              <div class="text">
                <ul>
                  <li
                    *ngFor="
                      let email of alertForm.value.lista_emails;
                      let i = index
                    "
                    ng-bind-html-unsafe="opt"
                  >
                    {{ email }}
                    <i
                      class="ms-1 fa fa-minus-circle"
                      (click)="restEmail(i)"
                      type="button"
                      data-placement="bottom"
                      aria-hidden="true"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <!-- fin emails -->


          <!-- <div class="row">
              <div class="col-3">
                  Nombre:
              </div>
              <div class="col-9">
                  <input type="text" pInputText [(ngModel)]="form.nombre" />            
              </div>
              
              <div class="col-3">
                  Diariamente:
              </div>
              <div class="col-9">
                  <p-autoComplete [(ngModel)]="form.hora_programado" [virtualScroll]="true" [suggestions]="filteredItems" [virtualScrollItemSize]="34" (completeMethod)="filterItems($event)" field="label" [dropdown]="true"> </p-autoComplete>
              </div>
          </div> -->



        <!-- </p-card> -->





    </ng-template>

    <ng-template pTemplate="footer">
      <p-button label="Guardar" icon="pi pi-check" (click)="guardarReporteProgramado();"></p-button>
      <p-button label="Cancelar" icon="pi pi-times" (click)="cancelarReporteProgramado();" styleClass="p-button-secondary" [style]="{ 'margin-left': '.5em' }"></p-button>
        <!-- <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> -->
        <!-- <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" (click)="reportService.modalReportesProgramadosActiveDetalle = false;"></button> -->
    </ng-template>
</p-dialog>
