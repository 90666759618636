import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { FollowService } from '../../services/follow.service';

@Component({
  selector: 'app-follow-header',
  templateUrl: './follow-header.component.html',
  styleUrls: ['./follow-header.component.scss']
})
export class FollowHeaderComponent implements IHeaderAngularComp {

  agInit(headerParams: IHeaderParams): void {}

  constructor(private followService:FollowService) { }

  refresh(params: any) : boolean {
        return true;
  }
  onClick(){
    this.followService.emitResetFollow();
  }

}
